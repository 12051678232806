export default {
  data() {
    return {
      form: {
        deletable_translations: [],
      },
    };
  },
  methods: {
    getLocaleName(locale) {
      let language = this.languages.find((l) => l.locale == locale);

      if (!language) {
        return locale;
      }

      return language.name;
    },
    isLanguageDeleted(locale) {
      if (!this.form.deletable_translations.includes(locale)) {
        return false;
      }

      return true;
    },
    removeLanguage(locale) {
      if (this.form.deletable_translations.includes(locale)) {
        return null;
      }
      this.form.deletable_translations.push(locale);
    },
    restoreLanguage(locale) {
      if (!this.form.deletable_translations.includes(locale)) {
        return null;
      }

      this.form.deletable_translations = this.form.deletable_translations.filter(
        (l) => l != locale
      );
    },
  },
};
