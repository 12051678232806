<template>
  <b-sidebar
    backdrop-variant="dark"
    backdrop
    shadow
    :visible="showSidebar"
    @hidden="closeSidebar"
  >
    <SidebarContent />
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SidebarContent from "./SidebarContent.vue";

export default {
  components: {
    SidebarContent,
  },
  computed: {
    ...mapGetters({
      showSidebar: "global/showSidebar",
    }),
  },
  methods: {
    ...mapActions({
      closeSidebar: "global/closeSidebar",
    }),
  },
};
</script>
