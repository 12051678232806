<template>
  <div>
    <h3 class="mb-3">
      Product tracking
      <b-button variant="primary" size="sm" @click.prevent="openAddingForm">
        Add new
      </b-button>
    </h3>

    <b-row v-if="adding">
      <b-col md="6" class="mb-3 position-relative">
        <loader :overlay="true" v-if="isAddLoading" />
        <b-card>
          <h5>Add new product tracking</h5>
          <b-form-group>
            <b-form-input
              v-model="form.id"
              placeholder="Product ID"
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-textarea
              placeholder="Info"
              v-model="form.info"
              rows="3"
              size="sm"
            ></b-form-textarea>
          </b-form-group>
          <b-button variant="success" size="sm" @click.prevent="addNewItem">
            Add new
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <loader v-if="productTrackings === null" />

    <b-table-simple small bordered responsive v-if="productTrackings !== null">
      <b-tr variant="primary">
        <b-th style="width: 5%;"></b-th>
        <b-th style="width: 50%;">Name</b-th>
        <b-th style="width: 10%;" class="text-center">Notification sent</b-th>
        <b-th>Stocks</b-th>
        <b-th style="width: 5%;">Actions</b-th>
      </b-tr>
      <b-tr v-for="product in productTrackings" :key="product.id">
        <b-td>
          <img
            v-bind:src="product.product.main_image"
            alt=""
            class="img-fluid"
          />
        </b-td>
        <b-td>
          <router-link
            class="product-link"
            :to="{
              name: 'products.edit',
              params: { productId: product.product.id },
            }"
          >
            {{ product.product.name }} <br
          /></router-link>
          <small><strong>ID:</strong> {{ product.product.id }} </small><br />
          <template v-if="product.info">
            <strong>Info: </strong> <br />
            <small>{{ product.info }}</small>
          </template>
        </b-td>
        <b-td class="text-center">
          <b-badge variant="success" v-if="product.is_notification_sent">
            Yes
          </b-badge>
          <b-badge variant="dark" v-else>
            No
          </b-badge>
        </b-td>
        <b-td>
          <b-table-simple>
            <b-tr variant="primary">
              <b-th style="width: 20%;">
                Name
              </b-th>
              <b-th style="width: 40%;">
                In Stock
              </b-th>
              <b-th style="width: 40%;">
                Price
              </b-th>
            </b-tr>
            <b-tr v-for="stock in product.product.stocks" :key="stock.id">
              <b-td>{{ stock.supplier.frontend_name }}</b-td>
              <b-td>{{ stock.in_stock }}</b-td>
              <b-td>
                <template v-if="stock.manual_price">
                  {{ stock.manual_price }}
                  <small style="text-decoration: line-through;">
                    {{ stock.price }}
                  </small>
                </template>
                <template v-else>
                  {{ stock.price }}
                </template>
              </b-td>
            </b-tr>
          </b-table-simple>
        </b-td>
        <b-td>
          <b-button
            variant="danger"
            size="sm"
            @click.prevent="deleteItem(product.id)"
          >
            Delete
          </b-button>
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { successMessage } from "@/lib/messages";
import { errorMessage } from "@/lib/messages";

export default {
  data() {
    return {
      productTrackings: null,
      isAddLoading: false,
      adding: false,
      form: {
        id: null,
        info: null,
      },
    };
  },
  computed: {},
  mounted() {
    RestService.getProductTrackings().then((response) => {
      this.productTrackings = response.data.data;
    });
  },
  methods: {
    deleteItem(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.productTrackings = this.productTrackings.filter(
              (item) => item.id != id
            );
            RestService.deleteProductTrancking(id).then(() => {
              successMessage("Teade!", "Kustutatud");
            });
          }
        },
      });
    },
    openAddingForm() {
      this.adding = true;
    },
    addNewItem() {
      if (!this.form.id) {
        return false;
      }

      this.isAddLoading = true;
      RestService.postAddNewProductTracking(this.form)
        .then((response) => {
          this.productTrackings = response.data.data;
          this.isAddLoading = false;
          this.adding = false;
          this.form = {
            id: null,
            info: null,
          };
        })
        .catch(() => {
          this.isAddLoading = false;
          errorMessage("Viga!", "Toodet ei leitud!");
        });
    },
  },
};
</script>
