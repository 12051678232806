<template>
  <ul>
    <tree-item
      v-for="child in items"
      :key="child.id"
      :item="child"
      @add="handleAdd"
      :showAdd="showAdd"
      @itemClicked="handleItemClicked"
      :activeId="activeId"
    />
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeId: null,
    };
  },
  methods: {
    handleAdd(val) {
      this.$emit("add", val);
    },
    handleItemClicked(val) {
      this.activeId = val;
      this.$emit("itemClicked", val);
    },
  },
};
</script>
