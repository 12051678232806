export default {
  data() {
    return {
      validation: null,
    };
  },
  computed: {
    errorMessage() {
      if (!this.validation) {
        return null;
      }

      return this.validation.message;
    },
  },
  methods: {
    handleResponseErrors(error) {
      this.validation = error.response.data;
    },
    clearValidationErrors() {
      this.validation = null;
    },
    getFieldStateFromErrors(fieldName) {
      if (!this.validation) {
        return null;
      }

      if (!this.validation.errors) {
        return null;
      }

      if (this.validation.errors[fieldName] === undefined) {
        return null;
      }

      return false;
    },
    getFieldError(fieldName) {
      if (!this.validation) {
        return null;
      }

      if (!this.validation.errors) {
        return null;
      }

      if (this.validation.errors[fieldName] === undefined) {
        return null;
      }

      return this.validation.errors[fieldName][0];
    },
  },
};
