import { Translations } from "../components";

export default [
  {
    path: "/translations",
    component: Translations,
    name: "translations",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
