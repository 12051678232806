<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      Tarnija muutmine <small v-if="supplier">- {{ supplier.name }}</small>
    </h2>

    <template v-if="supplier">
      <b-row>
        <b-col md="6">
          <b-card class="mb-3" header="Tarnija info">
            <b-form-group class="mb-1" label="Nimi">
              <b-form-input
                v-model="supplier.name"
                size="sm"
                :state="getFieldStateFromErrors('name')"
                disabled
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("name") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Lühend">
              <b-form-input
                v-model="supplier.frontend_name"
                size="sm"
                :state="getFieldStateFromErrors('frontend_name')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("frontend_name") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-3" label="Transpordi hind">
              <b-form-input
                type="number"
                v-model.number="supplier.delivery_price"
                size="sm"
                :state="getFieldStateFromErrors('delivery_price')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("delivery_price") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1">
              <b-form-checkbox v-model="supplier.is_hidden" switch>
                Tarnija on peidetud?
              </b-form-checkbox>
            </b-form-group>

            <b-button
              variant="success"
              class="float-right"
              @click.prevent="saveSupplier"
            >
              Salvesta
            </b-button>
          </b-card>
          <b-card header="Transpordi lisahinnastus">
            <loader v-if="isCategoryLoading" :overlay="true" />

            <b-row class="mb-3" v-if="maps">
              <b-col md="10">
                <v-select
                  v-model="selectedMap"
                  :options="mapsForSelect"
                  :reduce="(item) => item.code"
                ></v-select>
              </b-col>
              <b-col md="2">
                <b-button
                  variant="primary"
                  block
                  size="sm"
                  @click.prevent="addNewDeliveryPrice"
                >
                  Lisa
                </b-button>
              </b-col>
            </b-row>

            <b-table-simple
              bordered
              small
              v-if="delivery_prices && delivery_prices.length"
            >
              <b-tr>
                <b-th>Kategooria</b-th>
                <b-th style="width: 30%;">Hind</b-th>
                <b-th style="width: 5%;"></b-th>
              </b-tr>
              <b-tr
                v-for="(price, dPriceKey) in delivery_prices"
                :key="dPriceKey"
              >
                <b-td>
                  {{ price.map_name }}
                </b-td>
                <b-td>
                  <b-form-input
                    type="number"
                    v-model.number="price.price"
                    size="sm"
                  ></b-form-input>
                </b-td>
                <b-td class="text-right">
                  <b-button
                    variant="danger"
                    size="sm"
                    @click.prevent="removeDeliveryPrice(dPriceKey)"
                  >
                    <font-awesome-icon icon="times" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-table-simple>
            <b-button
              v-if="delivery_prices && delivery_prices.length"
              class="float-right"
              variant="success"
              @click.prevent="saveDeliveryPrices"
            >
              Salvesta hinnad
            </b-button>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card class="mb-3" header="Toodete hinnastus">
            <b-table-simple bordered small>
              <b-tr>
                <b-th style="width: 25%;">Min hind</b-th>
                <b-th style="width: 25%;">Max hind</b-th>
                <b-th></b-th>
                <b-th style="width: 20%;">Väärtus</b-th>
                <b-th style="width: 5%;">
                  <b-button
                    variant="primary"
                    size="sm"
                    @click.prevent="addNewPriceRow"
                  >
                    <font-awesome-icon icon="plus" />
                  </b-button>
                </b-th>
              </b-tr>
              <b-tr
                v-for="(price, priceKey) in supplier.prices"
                :key="priceKey"
              >
                <b-td>
                  <b-form-input
                    type="number"
                    v-model.number="price.min"
                    size="sm"
                  ></b-form-input>
                </b-td>
                <b-td>
                  <b-form-input
                    type="number"
                    v-model.number="price.max"
                    size="sm"
                  ></b-form-input>
                </b-td>
                <b-td>
                  <b-form-select
                    v-model="price.expression"
                    :options="priceExpressionOptions"
                    size="sm"
                  ></b-form-select>
                </b-td>
                <b-td>
                  <b-form-input
                    type="number"
                    v-model.number="price.value"
                    size="sm"
                  ></b-form-input>
                </b-td>
                <b-td class="text-right">
                  <b-button
                    variant="danger"
                    size="sm"
                    @click.prevent="removePriceRow(priceKey)"
                  >
                    <font-awesome-icon icon="times" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-table-simple>
            <b-button
              class="float-right"
              variant="success"
              @click.prevent="savePrices"
            >
              Salvesta hinnad
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";
import { errorMessage } from "@/lib/messages";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,
      isCategoryLoading: false,
      supplier: null,
      maps: null,
      delivery_prices: null,
      selectedMap: null,
      priceExpressionOptions: [
        { value: "*", text: "Korruta" },
        { value: "+", text: "Liida" },
      ],
    };
  },
  computed: {
    mapsForSelect() {
      if (!this.maps) {
        return [];
      }

      return this.maps.map((map) => {
        return {
          label: map.supplier_category,
          code: map.id,
        };
      });
    },
  },
  mounted() {
    this.fetchSupplierDeliveryPrices();
    this.fetchSupplier();
    this.fetchCategoryMaps();
  },
  methods: {
    saveSupplier() {
      this.isLoading = true;

      let payload = this.supplier;

      RestService.postSaveSupplier(this.supplier.id, payload)
        .then((response) => {
          this.supplier = response.data.data;

          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
    addNewDeliveryPrice() {
      if (!this.selectedMap) {
        errorMessage("Viga", "Kategooriat pole valitud!");

        return false;
      }

      let selectedMap = this.maps.find((m) => m.id == this.selectedMap);

      if (!selectedMap) {
        this.selectedMap = null;
        errorMessage("Viga", "Kategooriat ei leitud!");

        return false;
      }

      if (
        this.delivery_prices.filter(
          (p) => p.map_name == selectedMap.supplier_category
        ).length
      ) {
        this.selectedMap = null;
        errorMessage("Viga", "Kategooria on juba lisatud!");

        return false;
      }

      this.delivery_prices.push({
        id: null,
        price: 0,
        map_id: selectedMap.id,
        map_name: selectedMap.supplier_category,
      });
      this.selectedMap = null;
    },
    removeDeliveryPrice(key) {
      this.delivery_prices.splice(key, 1);
    },
    saveDeliveryPrices() {
      this.isLoading = true;
      RestService.postSaveSupplierDeliveryPrices(this.supplier.id, {
        prices: this.delivery_prices,
      })
        .then((response) => {
          this.delivery_prices = response.data.data;

          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            errorMessage("Viga", error.response.data.message);
          }
        });
    },
    addNewPriceRow() {
      let last = this.supplier.prices[this.supplier.prices.length - 1];

      if (last) {
        this.supplier.prices.push({
          min: last.max,
          max: last.max,
          expression: "+",
          value: last.value,
        });
      } else {
        this.supplier.prices.push({
          min: 0,
          max: 0,
          expression: "+",
          value: 0,
        });
      }
    },
    removePriceRow(key) {
      this.supplier.prices.splice(key, 1);
    },
    savePrices() {
      this.isLoading = true;
      RestService.postSaveSupplierPrices(this.supplier.id, {
        prices: this.supplier.prices,
      })
        .then((response) => {
          this.supplier = response.data.data;

          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            errorMessage("Viga", error.response.data.message);
          }
        });
    },
    fetchSupplier() {
      this.isLoading = true;
      RestService.getSupplier(this.$route.params.supplierId).then(
        (response) => {
          this.supplier = response.data.data;

          this.isLoading = false;
        }
      );
    },
    fetchCategoryMaps() {
      this.isCategoryLoading = true;
      RestService.getCategoryMaps(this.$route.params.supplierId).then(
        (response) => {
          this.maps = response.data.data;

          this.isCategoryLoading = false;
        }
      );
    },
    fetchSupplierDeliveryPrices() {
      RestService.getSupplierDeliveryPrices(this.$route.params.supplierId).then(
        (response) => {
          this.delivery_prices = response.data.data;
        }
      );
    },
  },
};
</script>
