export const SET_PRELOADING = (state, trueOrFalse) => {
  state.preLoading = trueOrFalse;
};

export const SET_CONNECTION = (state, trueOrFalse) => {
  state.connection = trueOrFalse;
};

export const TOGGLE_SIDEBAR = (state) => {
  state.showSidebar = !state.showSidebar;
};

export const CLOSE_SIDEBAR = (state) => {
  state.showSidebar = false;
};

export const SET_TAB_STATE = (state, trueOrFalse) => {
  state.isTabActive = trueOrFalse;
};
