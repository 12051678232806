var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative"},[(_vm.isLoading)?_c('loader',{attrs:{"overlay":true}}):_vm._e(),_c('h1',[_vm._v(" Tellimuse staatused "),_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.addNew.apply(null, arguments)}}},[_vm._v(" Lisa uus ")])],1),_c('b-table-simple',{attrs:{"bordered":"","responsive":"","small":""}},[_c('b-tr',{attrs:{"variant":"primary"}},[_c('b-th',[_vm._v(" Nimi ")]),_c('b-th',[_vm._v("Peastaatus")]),_c('b-th')],1),_vm._l((_vm.statuses),function(status){return _c('b-tr',{key:status.id},[_c('b-td',[_c('span',{staticClass:"badge",style:({
            backgroundColor: status.bg_color,
            color: status.text_color,
          })},[_vm._v(" "+_vm._s(status.name)+" ")])]),_c('b-td',[_c('b-badge',{staticClass:"status",class:_vm.getStatusClass(status.status_type)},[_vm._v(" "+_vm._s(status.status_type_name)+" ")])],1),_c('b-td',{staticClass:"text-right"},[_c('router-link',{staticClass:"btn btn-warning btn-sm mr-2",attrs:{"to":{
            name: 'orderStatus.edit',
            params: { statusId: status.id },
          }}},[_vm._v(" Muuda ")]),_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.deleteStatus(status)}}},[_vm._v(" X ")])],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }