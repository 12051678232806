<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Kliendid
      <router-link
        class="btn btn-primary btn-sm"
        :to="{
          name: 'user.new',
        }"
      >
        Lisa uus
      </router-link>
    </h1>

    <b-row>
      <b-col md="6">
        <b-form @submit.prevent="fetchUsers">
          <b-form-group class="mb-1">
            <b-input-group>
              <b-form-input
                class="form-control"
                v-model="keyword"
                size="sm"
                placeholder="Otsi klienti"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" size="sm" type="submit">
                  Otsi
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>

    <b-table-simple bordered responsive small class="mt-3 mb-0">
      <b-tr variant="primary">
        <b-th style="width: 5%;" class="text-center">#</b-th>
        <b-th>
          Nimi
        </b-th>
        <b-th class="text-center">
          Tüüp
        </b-th>
        <b-th style="width: 25%;" class="text-center">
          Email
        </b-th>
        <b-th style="width: 15%;" class="text-center">
          Telefon
        </b-th>
        <b-th style="width: 10%;"></b-th>
      </b-tr>

      <b-tr v-for="(user, userKey) in users" :key="user.id">
        <b-td class="text-center">
          {{ (userKey + 1) * currentPage }}
        </b-td>
        <b-td>
        <router-link
            class="customer-link"
            :to="{ name: 'user.edit', params: { userId: user.id } }"
          >
            {{ user.first_name }} {{ user.last_name }} <small>(#{{ user.id }})</small> <br />
            <small class="font-weight-bold" v-if="user.person_type != 1">
              {{ user.company_name }}
            </small>
          </router-link>
            
        </b-td>
        <b-td class="text-center">
          <b-badge variant="primary" v-if="user.person_type == 1">Eraisik</b-badge>
          <b-badge variant="warning" v-else-if="user.person_type == 2">Ettevõte</b-badge>
          <b-badge variant="warning" v-else-if="user.person_type == 3">Riigiasutus</b-badge>
        </b-td>
        <b-td class="text-center">
          {{ user.email }}
        </b-td>
        <b-td class="text-center">
          {{ user.phone }}
        </b-td>
        <b-td class="text-right">
          <router-link
            class="btn btn-warning btn-sm mr-2"
            :to="{
              name: 'user.edit',
              params: { userId: user.id },
            }"
          >
            Muuda
          </router-link>

          <b-button
            variant="danger"
            size="sm"
            @click.prevent="deleteUser(user)"
          >
            X
          </b-button>
        </b-td>
      </b-tr>
    </b-table-simple>

    <template v-if="pagination">
      <small>Kasutajaid kokku: {{ pagination.total }}</small>
      <b-pagination
        class="mt-3"
        v-model="currentPage"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        @change="pageChange"
      ></b-pagination>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      keyword: null,
    };
  },
  computed: {
    ...mapGetters({
      users: "users/users",
      pagination: "users/pagination",
    }),
  },
  mounted() {
    this.currentPage = 1;
    this.fetchUsers();
  },
  methods: {
    ...mapActions({
      getUsers: "users/getUsers",
    }),
    fetchUsers(page = 1) {
      this.isLoading = true;
      this.getUsers({
        page: page,
        keyword: this.keyword,
      }).then(() => {
        this.isLoading = false;
      });
    },
    pageChange(page) {
      this.fetchUsers(page);
    },
    deleteUser(user) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.postDeleteUser(user.id).then(() => {
              this.fetchUsers(this.currentPage);
            });
          }
        },
      });
    },
    search() {

    }
  },
};
</script>


<style lang="scss">
.customer-link {
  color: #000;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
</style>
