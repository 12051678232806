<template>
  <b-tr>
    <b-td>
      <b-select
        v-model="form.shipping_method_id"
        :options="shippingMethods"
        size="sm"
      ></b-select>
    </b-td>
    <b-td>
      <b-select
        v-model="form.supplier_id"
        :options="suppliers"
        size="sm"
      ></b-select>
    </b-td>
    <b-td>
      <b-form-input
        v-model="form.price"
        autocomplete="0"
        placeholder="Price"
        size="sm"
      ></b-form-input>
    </b-td>
    <b-td>
      <b-form-input
        v-model="form.min_free_price"
        autocomplete="0"
        placeholder="Min free price"
        size="sm"
      ></b-form-input>
    </b-td>
    <b-td>
      <b-form-checkbox v-model="form.is_active" switch>
        Is active
      </b-form-checkbox>
    </b-td>
    <b-td>
      <b-button
        class="mr-1"
        variant="success"
        size="sm"
        @click.prevent="save"
        :disabled="isLoading"
      >
        <b-spinner small v-if="isLoading"></b-spinner>
        save
      </b-button>
      <b-button variant="danger" size="sm" @click.prevent="close">
        cancel
      </b-button>
    </b-td>
  </b-tr>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      form: {
        shipping_zone_id: null,
        shipping_method_id: null,
        supplier_id: null,
        price: null,
        min_free_price: null,
        is_active: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      shippingZone: "shippingZones/shippingZone",
      shippingMethods: "shippingZones/shippingMethods",
      suppliers: "shippingZones/suppliers",
    }),
  },
  methods: {
    ...mapActions({
      addNewSupplierShipping: "shippingZones/addNewSupplierShipping",
    }),
    save() {
      this.isLoading = true;
      this.form.shipping_zone_id = this.shippingZone.id;
      this.addNewSupplierShipping(this.form).then(() => {
        this.close();
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
