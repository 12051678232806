export const users = (state) => {
  return state.users;
};

export const pagination = (state) => {
  if (!state.users_meta) {
    return null;
  }

  return state.users_meta.pagination;
};
