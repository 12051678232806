<template>
  <div>
    <loader :overlay="true" v-if="isLoading" />
    <b-row class="mt-2">
      <b-col md="6">
        <b-form @submit.prevent="search">
          <b-form-group class="mb-1">
            <b-input-group>
              <b-form-input
                class="form-control"
                v-model="form.value"
                size="sm"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" size="sm" type="submit">
                  Otsi
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <b-form-radio-group size="sm" v-model="form.type">
              <b-form-radio value="id">Toote ID</b-form-radio>
              <b-form-radio value="icecat_id" v-if="!isEemeli">Icecat ID</b-form-radio>
              <b-form-radio
                :value="code"
                v-for="(code, codeKey) in productCodeTypes"
                :key="codeKey"
              >
                {{ code }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <b-row v-if="products">
      <b-col>
        <b-row class="mb-2" v-if="selectedProducts.length >= 2">
          <b-col>Valitud tooted: {{ selectedProducts }} </b-col>
          <b-col class="text-right">
            <b-dropdown
              split
              text="Merge valitud"
              size="sm"
              variant="warning"
              @click.prevent="createMerge"
            >
              <b-dropdown-item @click.prevent="createAutoMerge">
                Auto merge
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
        <b-table-simple bordered responsive small v-if="products.length">
          <b-tr variant="primary">
            <b-th style="width: 1%"></b-th>
            <b-th style="width: 5%"></b-th>
            <b-th style="width: 50%">Nimi</b-th>
            <b-th>Laod</b-th>
            <b-th style="width: 5%"></b-th>
          </b-tr>
          <ProductRow
            v-for="product in products"
            :product="product"
            :key="product.id"
            @selected="handleCheck"
            @update="handleProductUpdate"
          />
        </b-table-simple>
        <b-alert v-else variant="warning" show>
          Tulemusi ei leitud!
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage, successMessage } from "@/lib/messages";
import ProductRow from "../productList/ProductRow";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ProductRow,
  },
  data() {
    return {
      isLoading: false,
      products: null,
      selectedProducts: [],
      form: {
        value: null,
        type: "id",
      },
    };
  },
  computed: {
    ...mapGetters({
      productCodeTypes: "generic/productCodeTypes",
      isEemeli: "global/isEemeli",
    }),
  },
  mounted() {
    this.fetchProductCodeTypes();
  },
  methods: {
    ...mapActions({
      fetchProductCodeTypes: "generic/fetchProductCodeTypes",
    }),
    search() {
      this.isLoading = true;
      this.products = null;
      RestService.searchProductsViaCode(this.form)
        .then((response) => {
          this.selectedProducts = [];
          let productsData = response.data.data;
          productsData.map((p) => {
            p.isChecked = false;
            return p;
          });
          this.products = productsData;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isLoading = false;
        });
    },
    createMerge() {
      this.isLoading = true;
      RestService.postCreateProductMerge({
        ids: this.selectedProducts,
      })
        .then(() => {
          this.search();
          successMessage("Teade!", "Merge on loodud!");
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    createAutoMerge() {
      this.isLoading = true;
      RestService.postCreateProductMergeAuto({
        ids: this.selectedProducts,
        main_id: null,
      })
        .then(() => {
          this.search();
          successMessage("Teade!", "Auto merge on loodud!");
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    handleCheck(product) {
      let isChecked;
      if (this.selectedProducts.includes(product.id)) {
        isChecked = true;
        const index = this.selectedProducts.indexOf(product.id);
        if (index > -1) {
          this.selectedProducts.splice(index, 1);
        }
      } else {
        isChecked = false;
        this.selectedProducts.push(product.id);
      }

      const pindex = this.products.findIndex((p) => p.id == product.id);
      if (pindex > -1) {
        let newProduct = this.products[pindex];
        newProduct.isChecked = !isChecked;
        this.$set(this.products, pindex, newProduct);
      }
    },
    handleProductUpdate(product) {
      const pindex = this.products.findIndex((p) => p.id == product.id);
      if (pindex > -1) {
        this.$set(this.products, pindex, product);
      }
    }
  },
};
</script>
