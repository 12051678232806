import { Brands, BrandView } from "../components";

export default [
  {
    path: "/brands",
    component: Brands,
    name: "brands",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/brands/:brandId",
    component: BrandView,
    name: "brands.view",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
