import Vue from "vue";

export const SET_CATEGORY_MAPS = (state, payload) => {
  state.categoryMaps = payload;
};

export const SET_CATEGORY_MAP = (state, payload) => {
  let mapIndex = state.categoryMaps.findIndex((map) => map.id == payload.id);
  Vue.set(state.categoryMaps, mapIndex, payload);
};

export const SET_SELECTED_MAP = (state, payload) => {
  state.selectedMap = payload;
};

export const SET_SEARCH_KEY = (state, payload) => {
  state.searchKey = payload;
};

export const SET_SHOW_NOT_MAPPED = (state, trueOrFalse) => {
  state.showNotMapped = trueOrFalse;
};

export const SET_SHOW_WITH_PRODUCTS = (state, trueOrFalse) => {
  state.showWithProducts = trueOrFalse;
};

export const SET_SELECTED_MAP_CATEGORIES = (state, payload) => {
  let mapIndex = state.categoryMaps.findIndex((map) => map.id == payload.id);

  Vue.set(state.categoryMaps[mapIndex], "categories", payload.categories);
  Vue.set(state.selectedMap, "categories", payload.categories);
};

export const RESET = (state) => {
  state.showNotMapped = false;
  state.showWithProducts = false;
  state.searchKey = null;
  state.categoryMaps = [];
  state.selectedMap = null;
};
