<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Bränd
      <small v-if="manufacturer"> - {{ manufacturer.name }}</small>
    </h1>

    <b-row v-if="manufacturer">
      <b-col md="6">
        <b-form @submit.prevent="saveBrand">
          <b-form-group class="mb-1">
            <b-form-checkbox v-model="manufacturer.is_featured" switch>
              Is featured
            </b-form-checkbox>
          </b-form-group>

          <b-form-group label="Logo">
            <b-form-file
              v-model="file"
              accept="image/*"
              size="sm"
              :state="getFieldStateFromErrors('file')"
              placeholder="Vali fail"
              drop-placeholder="Lohista fail siia"
            ></b-form-file>
            <b-form-invalid-feedback>
              {{ getFieldError("file") }}
            </b-form-invalid-feedback>

            <div v-if="manufacturer.image">
              <img :src="manufacturer.image.thumbnail_url" class="img-fluid mt-3" alt="" />
            </div>
          </b-form-group>

          <div class="text-right mt-3">
            <b-button type="submit" variant="success">
              Salvesta
            </b-button>
          </div>
        </b-form>
      </b-col>
      <b-col md="6">
        <h4>Alternatiiv bränd</h4>
        <b-form-group class="mb-1">
            <b-input-group>
              <b-form-input
                class="form-control"
                v-model="newMapName"
                size="sm"
                placeholder="Alternatiivi nimi"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" size="sm" @click.prevent="addNewMap">
                  Lisa uus
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        <b-table-simple bordered small class="mb-0" v-if="manufacturer.importProductManufacturerMappings && manufacturer.importProductManufacturerMappings.length">
          <b-tr variant="primary">
            <b-th>
              Nimi
            </b-th>
            <b-th style="width: 15%;"></b-th>
          </b-tr>

          <b-tr v-for="mapping in manufacturer.importProductManufacturerMappings" :key="mapping.id">
            <b-td>
              <small>{{ mapping.mapping_name }}</small>
            </b-td>
            <b-td> </b-td>
          </b-tr>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";
import { errorMessage } from '@/lib/messages';

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: true,
      manufacturer: null,
      newMapName: null,
      file: null,
    };
  },
  mounted() {
    this.fetchManufacturer();
  },
  methods: {
    fetchManufacturer() {
      this.isLoading = true;
      RestService.getBrandManufacturer({}, this.$route.params.brandId)
        .then((response) => {
          this.manufacturer = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    addNewMap() {
      this.isLoading = true;
      RestService.postBrandManufacturerNewMap({
        id: this.manufacturer.id,
        new_name: this.newMapName,
      }, this.$route.params.brandId)
        .then((response) => {
          this.manufacturer = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    async saveBrand() {
      this.isLoading = true;

      let file = null;
      if (this.file) {
        file = await this.toBase64(this.file);
      }

      RestService.postSaveBrandManufacturer(this.manufacturer.id, {
        ...this.manufacturer,
        ...{
          file: file,
        },
      })
        .then((response) => {
          this.manufacturer = response.data.data
          this.clearValidationErrors();
          this.file = null;

          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>
