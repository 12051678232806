<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Delivery mapper
      <b-button variant="primary" size="sm" @click.prevent="handleShowNew">
        Lisa uus
      </b-button>
    </h1>

    <template v-if="showNewForm">
      <b-form @submit.prevent="handleNewSubmit" class="mb-5">
        <b-form-group class="mb-1" label="Tarnija key">
          <b-form-input
            required
            v-model="newForm.supplier_delivery"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group class="mb-1" label="Epoe key">
          <b-form-select
            required
            v-model="newForm.status_key"
            :options="stockStatusesSelectByKey"
            size="sm"
          ></b-form-select>
        </b-form-group>

        <b-form-group class="mb-1" label="Tarnija">
          <b-form-select
            required
            v-model="newForm.supplier_id"
            :options="suppliersSelect"
            size="sm"
          ></b-form-select>
        </b-form-group>

        <b-button type="submit" variant="success" size="sm" class="mr-3">
          Salvesta
        </b-button>
        <b-button variant="danger" size="sm" @click.prevent="resetNewForm">
          Katkesta
        </b-button>
      </b-form>
    </template>

    <b-table-simple bordered responsive small>
      <b-tr variant="primary">
        <b-th>Tarnija key</b-th>
        <b-th>Epoe key</b-th>
        <b-th>Tarnija</b-th>
        <b-th></b-th>
      </b-tr>
      <b-tr v-for="map in maps" :key="map.id">
        <b-td>{{ map.supplier_delivery }}</b-td>
        <b-td>
          <b-form-select
            v-model="map.status_key"
            :options="stockStatusesSelectByKey"
            size="sm"
            @change="statusKeyUpdated(map)"
          ></b-form-select>
        </b-td>
        <b-td>
          <span v-if="map.supplier">{{ map.supplier.name }}</span>
          <span v-else>{{ map.supplier_id }}</span>
        </b-td>
        <b-td>
          <b-button
            variant="danger"
            size="sm"
            @click.prevent="statusKeyDelete(map)"
          >
            Kustuta
          </b-button>
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapActions, mapGetters } from "vuex";
import { errorMessage } from "@/lib/messages";

export default {
  data() {
    return {
      isLoading: false,
      showNewForm: false,
      maps: [],
      newForm: {
        supplier_delivery: null,
        status_key: null,
        supplier_id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      stockStatusesSelectByKey: "generic/stockStatusesSelectByKey",
      suppliersSelect: "generic/suppliersSelect",
    }),
  },
  mounted() {
    this.fetchSuppliers();
    this.fetchProductStockStatuses();
    this.fetchMaps();
  },
  methods: {
    ...mapActions({
      fetchProductStockStatuses: "generic/fetchProductStockStatuses",
      fetchSuppliers: "generic/fetchSuppliers",
    }),
    fetchMaps() {
      this.isLoading = true;
      return RestService.getDeliveryMaps().then((response) => {
        this.maps = response.data.data;
        this.isLoading = false;
      });
    },
    statusKeyUpdated(map) {
      this.isLoading = true;
      return RestService.postDeliveryMapUpdate(map.id, map).then(() => {
        this.isLoading = false;
      });
    },
    statusKeyDelete(map) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            return RestService.postDeliveryMapDelete(map.id).then(() => {
              this.maps = this.maps.filter((m) => m.id != map.id);
              this.isLoading = false;
            });
          }
        },
      });
    },
    handleShowNew() {
      this.showNewForm = true;
    },
    resetNewForm() {
      this.showNewForm = false;
      this.newForm = {
        supplier_delivery: null,
        status_key: null,
        supplier_id: null,
      };
    },
    handleNewSubmit() {
      this.isLoading = true;
      return RestService.postDeliveryMapNew(this.newForm)
        .then(() => {
          this.resetNewForm();
          this.fetchMaps();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
  },
};
</script>
