import RestService from "@/lib/RestService";

export const fetchShippingMethods = ({ commit }) => {
  return RestService.getAllShippingMethods().then((response) => {
    commit("SET_SHIPPING_METHODS", response.data.data);
  });
};

export const fetchPaymentMethods = ({ commit }) => {
  return RestService.getAllPaymentMethods().then((response) => {
    commit("SET_PAYMENT_METHODS", response.data.data);
  });
};