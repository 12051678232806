import Vue from "vue";

export const Dashboard = Vue.component(
  "dashboard",
  require("./Dashboard.vue").default
);
export const Couriers = Vue.component(
  "couriers",
  require("./Couriers.vue").default
);
