import { Emails, EmailEdit, EmailNew } from "../components";

export default [
  {
    path: "/emails",
    component: Emails,
    name: "emails",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/emails/new",
    component: EmailNew,
    name: "email.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/emails/:emailId",
    component: EmailEdit,
    name: "email.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
