<template>
  <div>
    <h1>
      Shipping Zone
    </h1>
    <loader v-if="!shippingZone" />
    <template v-if="shippingZone">
      <b-row>
        <b-col md="6">
          <loader :overlay="true" v-if="isSaveLoading" />
          <b-form-group label="Name">
            <b-form-input v-model="form.name" trim></b-form-input>
          </b-form-group>

          <b-form-group label="Countries" v-if="countries">
            <v-select
              v-model="form.countries"
              :options="countries"
              :reduce="(item) => item.code"
              multiple
            ></v-select>
          </b-form-group>
          <b-button
            class="mb-3"
            variant="primary"
            size="sm"
            @click.prevent="save"
          >
            Save
          </b-button>
        </b-col>
      </b-row>

      <h3>Shipping methods</h3>
      <b-table-simple bordered small>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th>Method</b-th>
            <b-th>Supplier</b-th>
            <b-th>Price</b-th>
            <b-th>Min free price</b-th>
            <b-th>Status</b-th>
            <b-th style="width: 10%;">Actions</b-th>
          </b-tr>
        </b-thead>
        <SupplierShippingRow
          v-for="method in shippingZone.supplierShippings"
          :key="method.id"
          :method="method"
        />
        <SupplierShippingNewRow v-if="showNew" @close="cancelNewRow" />
      </b-table-simple>
      <b-button
        variant="primary"
        size="sm"
        v-if="!showNew"
        @click.prevent="showNew = !showNew"
        >Add new</b-button
      >
    </template>
  </div>
</template>

<script>
import SupplierShippingRow from "./SupplierShippingRow";
import SupplierShippingNewRow from "./SupplierShippingNewRow";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SupplierShippingRow,
    SupplierShippingNewRow,
  },
  mounted() {
    this.fetchShippingZone({
      id: this.$route.params.shippingZoneId,
    }).then(() => {
      this.form = {
        name: this.shippingZone.name,
        countries: this.shippingZone.countries,
      };
    });
    this.fetchShippingMethods();
    this.fetchSuppliers();
    this.fetchCountries();
  },
  data() {
    return {
      showNew: false,
      isSaveLoading: false,
      form: {
        name: null,
        countries: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      shippingZone: "shippingZones/shippingZone",
      countries: "shippingZones/countries",
    }),
  },
  methods: {
    ...mapActions({
      fetchShippingZone: "shippingZones/fetchShippingZone",
      fetchCountries: "shippingZones/fetchCountries",
      fetchShippingMethods: "shippingZones/fetchShippingMethods",
      fetchSuppliers: "shippingZones/fetchSuppliers",
      saveShippingZone: "shippingZones/saveShippingZone",
    }),
    save() {
      this.isSaveLoading = true;
      this.saveShippingZone({
        id: this.shippingZone.id,
        form: this.form,
      }).then(() => {
        this.isSaveLoading = false;
      });
    },
    cancelNewRow() {
      this.showNew = false;
    },
  },
};
</script>
