<template>
  <b-form-datepicker
    boundary="viewport"
    size="sm"
    v-model="currentValue"
    button-only
    button-variant="outline-primary"
    right
    @input="changed"
  ></b-form-datepicker>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      currentValue: this.value,
    };
  },
  methods: {
    changed() {
      this.$emit("input", this.currentValue);
    },
  },
};
</script>
