import { Banners, BannerNew, BannerEdit } from "../components";

export default [
  {
    path: "/banners",
    component: Banners,
    name: "banners",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/banners/new",
    component: BannerNew,
    name: "banners.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/banners/:bannerId",
    component: BannerEdit,
    name: "banners.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
