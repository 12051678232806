<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Emaili muutmine <small v-if="email">- {{ email.name }}</small>
    </h1>

    <b-form @submit.prevent="saveEmail">
      <template v-if="email">
        <b-row class="text-right">
          <b-col>
            <b-button type="submit" class="mb-3" variant="success">
              Salvesta
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-card header="Emaili info">
              <b-form-group class="mb-1" label="Süsteemi class">
                <b-form-input
                  v-model="email.class"
                  size="sm"
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group class="mb-1" label="Nimi">
                <b-form-input
                  v-model="email.name"
                  size="sm"
                  :state="getFieldStateFromErrors('name')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("name") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="From">
                <b-form-input
                  v-model="email.from"
                  size="sm"
                  :state="getFieldStateFromErrors('from')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("from") }}
                </b-form-invalid-feedback>
                <b-form-text id="input-live-help">
                  Vaikimisi: {{ email.default_from }}
                </b-form-text>
              </b-form-group>

              <b-form-group class="mb-1" label="Cc">
                <b-form-input
                  v-model="email.cc"
                  size="sm"
                  :state="getFieldStateFromErrors('cc')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("cc") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Bcc">
                <b-form-input
                  v-model="email.bcc"
                  size="sm"
                  :state="getFieldStateFromErrors('bcc')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("bcc") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Reply-To">
                <b-form-input
                  v-model="email.phone"
                  size="sm"
                  :state="getFieldStateFromErrors('phone')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("phone") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card>
              <template #header>
                <div>
                  Tõlked
                  <b-dropdown
                    class="float-right"
                    variant="primary"
                    size="sm"
                    text="Lisa tõlge"
                  >
                    <b-dropdown-item
                      v-for="language in languages"
                      :key="language.locale"
                      @click.prevent="addNewLanguage(language.locale)"
                      :disabled="false"
                    >
                      {{ language.name }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
              <small class="d-block mb-3">
                <strong>Available props: </strong>
                <span v-for="(prop, propKey) in email.props" :key="propKey">
                  {{ prop }},
                </span>
              </small>
              <div
                v-for="translation in email.translations"
                :key="translation.id"
                class="language"
              >
                <div
                  class="deletable-language"
                  v-if="isLanguageDeleted(translation.locale)"
                >
                  This language is marked for delete <br />
                  <b-button
                    variant="warning"
                    @click.prevent="restoreLanguage(translation.locale)"
                  >
                    Restore
                  </b-button>
                </div>
                <h4>
                  {{ getLocaleName(translation.locale) }}
                  <b-button
                    variant="danger"
                    size="sm"
                    @click.prevent="removeLanguage(translation.locale)"
                  >
                    Delete language
                  </b-button>
                </h4>
                <b-form-group label="Pealkiri" class="mb-1">
                  <b-form-input
                    v-model="translation.title"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
                <Editor v-model="translation.body" v-if="!isLoading" />
                <hr />
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
            <b-button type="submit" variant="success">
              Salvesta
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-form>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
import Editor from "@/components/Editor";
import TranslationsMixin from "@/helpers/TranslationsMixin";
import { mapGetters } from "vuex";
import ErrorsMixin from "@/helpers/ErrorsMixin";

export default {
  mixins: [TranslationsMixin, ErrorsMixin],
  components: {
    Editor,
  },
  data() {
    return {
      isLoading: false,
      email: null,
    };
  },
  computed: {
    ...mapGetters({
      languages: "generic/languages",
    }),
  },
  mounted() {
    this.fetchEmail();
  },
  methods: {
    fetchEmail() {
      this.isLoading = true;
      RestService.getEmail(this.$route.params.emailId).then((response) => {
        this.email = response.data.data;
        this.isLoading = false;
      });
    },
    saveEmail() {
      this.isLoading = true;
      RestService.saveEmail(this.$route.params.emailId, {
        ...this.email,
        ...this.form,
      })
        .then((response) => {
          this.email = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
    addNewLanguage(locale) {
      if (this.email.translations.filter((t) => t.locale == locale).length) {
        errorMessage("Viga", "See keel on juba lisatud");
        return;
      }

      this.email.translations.push({
        locale: locale,
        title: null,
        body: null,
      });
    },
  },
};
</script>
