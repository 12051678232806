<template>
  <div class="position-relative">
    <OrdersHeader type="my-orders" />
    <loader v-if="isLoading" :overlay="true" />

    <div class="mb-4 mt-2" style="max-width: 250px;" v-if="orderTypes.length">
      <v-select
        v-model="selectedStatus"
        :options="orderTypes"
        label="text"
        :reduce="(item) => item.value"
        @input="fetchOrders()"
      ></v-select>
    </div>

    <template v-if="orders !== null && !orders.length">
      <b-alert variant="warning" show>
        Tellimusi ei leitud.
      </b-alert>
    </template>
    <template v-else>
      <OrderItem v-for="order in orders" :order="order" :key="order.id" />
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
import OrderItem from "./OrderItem";
import OrdersHeader from "./OrdersHeader";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    OrderItem,
    OrdersHeader,
  },
  data() {
    return {
      isLoading: false,
      orders: null,
      selectedStatus: 1,
    };
  },
  computed: {
    ...mapGetters({
      orderTypes: "orders/orderTypes",
    }),
  },
  mounted() {
    if (!this.orderTypes.length) {
      this.fetchOrderTypes();
    }
    this.fetchOrders();
  },
  methods: {
    ...mapActions({
      fetchOrderTypes: "orders/fetchOrderTypes",
    }),
    fetchOrders() {
      this.isLoading = true;

      RestService.getMyOrders({
        status: this.selectedStatus,
      })
        .then((response) => {
          this.orders = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    },
  },
};
</script>
