export const isPreLoading = (state) => {
  return state.preLoading;
};

export const connection = (state) => {
  return state.connection;
};

export const languages = (state) => {
  return state.languages;
};

export const isEemeli = (state) => {
  return state.isEemeli;
};

export const showSidebar = (state) => {
  return state.showSidebar;
};

export const webHostUrl = () => {
  return process.env.VUE_APP_API_HOST;
};

export const isTabActive = (state) => {
  return state.isTabActive;
};
