import Vue from "vue";

export const Menus = Vue.component("menus", require("./Menus.vue").default);
export const MenuEdit = Vue.component(
  "menu-edit",
  require("./MenuEdit.vue").default
);
export const MenuNew = Vue.component(
  "menu-new",
  require("./MenuNew.vue").default
);
