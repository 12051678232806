<template>
  <div>
    <h4>{{ selectedMap.name }}</h4>
    <div class="mt-3 mb-2">
      Vali kategooria
      <v-select
        v-model="selectedCategory"
        :options="flatCategories"
        :reduce="(item) => item.code"
      ></v-select>
    </div>
    <div class="mt-3 mb-2" v-if="selectedCategory">
      <b-button variant="primary" size="sm" @click.prevent="addCategoryToMap">
        Lisa kategooria icecati kategooria külge
      </b-button>
    </div>

    <hr />

    <div class="mt-3 mb-2">
      <strong>Seotud kategooriad</strong>
    </div>

    <b-table-simple
      small
      bordered
      responsive
      v-if="selectedMap.categories.length"
    >
      <b-tr v-for="category in selectedMap.categories" :key="category.id">
        <b-td style="vertical-align: middle;">{{ category.full_name }}</b-td>
        <b-td class="text-center">
          <b-button
            variant="danger"
            size="sm"
            @click.prevent="removeCategoryFromMap(category.id)"
          >
            X
          </b-button>
        </b-td>
      </b-tr>
    </b-table-simple>
    <b-alert v-else show variant="warning">Seosed puuduvad</b-alert>

    <div class="mt-3 mb-2">
      <strong>Tooted siin kategoorias</strong>
      <b-button
        :disabled="productsInMapIsLoading"
        class="ml-2"
        variant="primary"
        size="sm"
        @click.prevent="loadProductsInMap"
      >
        <b-spinner small v-if="productsInMapIsLoading"></b-spinner>
        Load
      </b-button>
    </div>

    <template v-if="productsInMap !== null">
      <table class="table table-bordered table-sm" v-if="productsInMap.length">
        <tr v-for="product in productsInMap" v-bind:key="product.id">
          <td style="width: 100px;">
            <img v-bind:src="product.main_image" alt="" class="img-fluid" />
          </td>
          <td>
            <router-link
              target="_blank"
              :to="{
                name: 'products.edit',
                params: { productId: product.id },
              }"
            >
              {{ product.name }}
            </router-link>
          </td>
        </tr>
      </table>

      <b-alert v-else show variant="warning">
        No products in this map.
      </b-alert>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapGetters } from "vuex";
export default {
  props: {
    selectedMap: {
      type: Object,
    },
  },
  data() {
    return {
      selectedCategory: null,
      productsInMapIsLoading: false,
      productsInMap: null,
    };
  },
  computed: {
    ...mapGetters({
      categories: "categories/categories",
    }),
    flatCategories() {
      let categories = [];

      if (this.categories) {
        categories = this.makeCategoryList(this.categories);
      }

      return categories;
    },
  },
  mounted() {
    this.loadProductsInMap();
  },
  methods: {
    addCategoryToMap() {
      this.$emit("startLoading");
      RestService.postAddCategoryToIcecatMap(
        this.selectedMap.id,
        this.selectedCategory
      ).then((response) => {
        this.selectedCategory = null;
        this.$emit("stopLoading", response.data.data);
      });
    },
    removeCategoryFromMap(categoryId) {
      this.$emit("startLoading");
      RestService.postRemoveCategoryFromIcecatMap(
        this.selectedMap.id,
        categoryId
      ).then((response) => {
        this.$emit("stopLoading", response.data.data);
      });
    },
    loadProductsInMap() {
      this.productsInMapIsLoading = true;
      RestService.getIcecatCategoryProducts(this.selectedMap.id).then(
        (response) => {
          this.productsInMapIsLoading = false;
          this.productsInMap = response.data.data;
        }
      );
    },
    makeCategoryList(items, name = "") {
      let list = [];

      items.forEach((item) => {
        list.push({
          label: `${name}${item.name}`,
          code: item.id,
        });

        let childList = [];
        if (item.children.length) {
          childList = this.makeCategoryList(
            item.children,
            `${name}${item.name} > `
          );
        }

        list = list.concat(childList);
      });

      return list;
    },
  },
};
</script>
