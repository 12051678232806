<template>
  <div class="position-relative">
    <loader :overlay="true" v-if="isLoading" />
    <h1>
      Icecat otsing
    </h1>

    <b-row>
      <b-col md="6">
        <b-form @submit.prevent="search">
          <b-form-group class="mb-1">
            <b-input-group>
              <b-form-input
                class="form-control"
                v-model="icecat_id"
                placeholder="Icecat ID"
                size="sm"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" size="sm" type="submit">
                  Otsi
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <b-form-radio-group v-model="locale">
              <b-form-radio value="en">ENG</b-form-radio>
              <b-form-radio value="et">EST</b-form-radio>
              <b-form-radio value="fi">FIN</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-form>

        <div class="mt-3" v-if="result">
          <h4>{{ result.title }}</h4>
          <strong>Icecat Code: </strong> {{ result.icecat_id }} <br />
          <strong>Quality: </strong> {{ result.quality }} <br />
          <strong>Release Date: </strong> {{ result.release_date }} <br />
          <strong>Manufacturer: </strong> {{ result.supplier }} <br />
          <strong>Category: </strong> {{ result.category }} <br />
          <strong>MPN: </strong> {{ result.mpn }} <br />
          <strong>EAN: </strong> {{ result.eans }} <br />
          <b-card header="Long description" class="mb-2 mt-3" no-body>
            <div class="p-2" v-html="result.long_description"></div>
          </b-card>
          <b-card header="Short description" class="mb-2" no-body>
            <div class="p-2" v-html="result.short_description"></div>
          </b-card>
          <b-card header="Long summary" class="mb-2" no-body>
            <div class="p-2" v-html="result.long_summary"></div>
          </b-card>
          <b-card header="Short summary" class="mb-2" no-body>
            <div class="p-2" v-html="result.short_summary"></div>
          </b-card>
          <b-table-simple small bordered responsive="">
            <b-tr v-for="item in result.specs" :key="`spec1-${item.spec_id}`">
              <b-td style="width: 40%">{{ item.name }}</b-td>
              <b-td>{{ item.data }}</b-td>
            </b-tr>
          </b-table-simple>
        </div>
      </b-col>
      <b-col md="6">
        <b-form @submit.prevent="search2">
          <b-form-group class="mb-1">
            <b-input-group>
              <b-form-input
                class="form-control"
                v-model="icecat_id2"
                placeholder="Icecat ID"
                size="sm"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" size="sm" type="submit">
                  Otsi
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <b-form-radio-group v-model="locale2">
              <b-form-radio value="en">ENG</b-form-radio>
              <b-form-radio value="et">EST</b-form-radio>
              <b-form-radio value="fi">FIN</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-form>
        <div class="mt-3" v-if="result2">
          <h4>{{ result2.title }}</h4>
          <strong>Icecat Code: </strong> {{ result2.icecat_id }} <br />
          <strong>Quality: </strong> {{ result2.quality }} <br />
          <strong>Release Date: </strong> {{ result2.release_date }} <br />
          <strong>Manufacturer: </strong> {{ result2.supplier }} <br />
          <strong>Category: </strong> {{ result2.category }} <br />
          <strong>MPN: </strong> {{ result2.mpn }} <br />
          <strong>EAN: </strong> {{ result2.eans }} <br />
          <b-card header="Long description" class="mb-2 mt-3" no-body>
            <div class="p-2" v-html="result2.long_description"></div>
          </b-card>
          <b-card header="Short description" class="mb-2" no-body>
            <div class="p-2" v-html="result2.short_description"></div>
          </b-card>
          <b-card header="Long summary" class="mb-2" no-body>
            <div class="p-2" v-html="result2.long_summary"></div>
          </b-card>
          <b-card header="Short summary" class="mb-2" no-body>
            <div class="p-2" v-html="result2.short_summary"></div>
          </b-card>
          <b-table-simple small bordered responsive="">
            <b-tr v-for="item in result2.specs" :key="`spec2-${item.spec_id}`">
              <b-td style="width: 40%">{{ item.name }}</b-td>
              <b-td>{{ item.data }}</b-td>
            </b-tr>
          </b-table-simple>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";

export default {
  components: {},
  data() {
    return {
      isLoading: false,
      icecat_id: null,
      icecat_id2: null,
      locale: "en",
      locale2: "en",
      result: null,
      result2: null,
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.icecat_id = this.$route.query.id;
      this.search();
    }
    if (this.$route.query.id2) {
      this.icecat_id2 = this.$route.query.id2;
      this.search2();
    }
  },
  methods: {
    search() {
      this.isLoading = true;
      RestService.postIcecatInfo({
        icecat_id: this.icecat_id,
        language: this.locale,
      })
        .then((response) => {
          this.result = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isLoading = false;
        });
    },
    search2() {
      this.isLoading = true;
      RestService.postIcecatInfo({
        icecat_id: this.icecat_id2,
        language: this.locale2,
      })
        .then((response) => {
          this.result2 = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isLoading = false;
        });
    },
  },
};
</script>
