import Vue from "vue";

export const ImportLog = Vue.component(
  "import-log",
  require("./ImportLog.vue").default
);

export const ImportStatus = Vue.component(
  "import-status",
  require("./ImportStatus.vue").default
);
