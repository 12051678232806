<template>
  <div class="position-relative">
    <loader v-if="catLoading" :overlay="true" />
    <loader v-if="!categories.length" />
    <b-row v-else>
      <b-col md="3">
        <div class="mb-3">
          <strong>Kategooriad</strong>
          <b-button
            class="ml-2"
            variant="primary"
            size="sm"
            @click.prevent="refreshCategories"
          >
            <font-awesome-icon icon="sync" />
          </b-button>
        </div>

        <TreeView :items="categories" @itemClicked="itemClicked" />
      </b-col>
      <b-col md="9">
        <ProductList v-if="selectedCategory">

          <b-button class="float-right" variant="outline-primary" pill @click.prevent="showFiltersModal = true">
            <font-awesome-icon icon="filter" />
          </b-button>
          
          <FiltersModal v-if="showFiltersModal" @close="hideFiltersModal" />
          
        </ProductList>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TreeView from "@/components/treeView/TreeView";
import ProductList from "./ProductList";
import FiltersModal from "./FiltersModal";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    TreeView,
    ProductList,
    FiltersModal,
  },
  data() {
    return {
      catLoading: false,
      showFiltersModal: false,
    };
  },
  computed: {
    ...mapGetters({
      categories: "catalog/categories",
      selectedCategory: "catalog/selectedCategory",
    }),
  },
  mounted() {
    if (!this.categories.length) {
      this.fetchCategories();
    }
  },
  methods: {
    ...mapActions({
      fetchCategories: "catalog/fetchCategories",
      fetchCategory: "catalog/fetchCategory",
      fetchCategoryProducts: "catalog/fetchCategoryProducts",
    }),
    itemClicked(val) {
      this.fetchCategoryProducts({ id: val });
      this.fetchCategory(val);
    },
    refreshCategories() {
      this.catLoading = true;
      this.fetchCategories().then(() => {
        this.catLoading = false;
      });
    },
    hideFiltersModal() {
      this.showFiltersModal = false;
    }
  },
};
</script>
