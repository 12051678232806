<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      <b-button
        variant="success"
        class="float-right"
        @click.prevent="handleSave"
      >
        Salvesta
      </b-button>
      Tellimuse staatuse lisamine
    </h2>

    <template>
      <b-row>
        <b-col md="6">
          <b-card header="Staatuse info">
            <b-form-group class="mb-1" label="Slug">
              <b-form-input v-model="form.slug" size="sm"></b-form-input>
            </b-form-group>
            <b-form-group label="Staatuse tüüp">
              <b-form-select
                v-if="orderStatusTypes.length"
                v-model="form.status_type"
                :options="orderStatusTypes"
                size="sm"
              ></b-form-select>
            </b-form-group>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="form.is_default" switch>
                Esimene staatus süsteemselt?
              </b-form-checkbox>
            </b-form-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Staatuse värvid">
            <b-form-group class="mb-1" label="Admini taustavärv">
              <b-form-input
                type="color"
                v-model="form.bg_color"
                size="sm"
              ></b-form-input>
            </b-form-group>
            <b-form-group class="mb-1" label="Admini teksti värv">
              <b-form-input
                type="color"
                v-model="form.text_color"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-card>
            <template #header>
              <div>
                Tõlked
                <b-dropdown
                  class="float-right"
                  variant="primary"
                  size="sm"
                  text="Lisa tõlge"
                >
                  <b-dropdown-item
                    v-for="language in languages"
                    :key="language.locale"
                    @click.prevent="addNewLanguage(language.locale)"
                    :disabled="false"
                  >
                    {{ language.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <div
              v-for="translation in form.translations"
              :key="translation.id"
              class="language"
            >
              <div
                class="deletable-language"
                v-if="isLanguageDeleted(translation.locale)"
              >
                This language is marked for delete <br />
                <b-button
                  variant="warning"
                  @click.prevent="restoreLanguage(translation.locale)"
                >
                  Restore
                </b-button>
              </div>
              <h4>
                {{ getLocaleName(translation.locale) }}
                <b-button
                  variant="danger"
                  size="sm"
                  @click.prevent="removeLanguage(translation.locale)"
                >
                  Delete language
                </b-button>
              </h4>
              <b-form-group label="Nimi" class="mb-1">
                <b-form-input
                  v-model="translation.name"
                  size="sm"
                ></b-form-input>
              </b-form-group>
              <hr />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapGetters } from "vuex";
import { errorMessage } from "@/lib/messages";

export default {
  data() {
    return {
      form: {
        deletable_translations: [],
        translations: [],
        slug: null,
        status_type: 0,
        is_default: false,
        bg_color: null,
        text_color: null,
      },
      orderStatusTypes: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      languages: "generic/languages",
    }),
  },
  mounted() {
    this.fetchOrderStatusTypes();
  },
  methods: {
    handleSave() {
      this.isLoading = true;

      RestService.postNewOrderStatus(this.form)
        .then((response) => {
          this.$router.push({
            name: "orderStatus.edit",
            params: { statusId: response.data.data.id },
          });
        })
        .catch((error) => {
          let errors = error.response.data.errors
            ? JSON.stringify(error.response.data.errors)
            : "";
          errorMessage("Viga", `${error.response.data.message} ${errors}`);
          this.isLoading = false;
        });
    },
    fetchOrderStatusTypes() {
      RestService.getOrderStatusTypes().then((response) => {
        this.orderStatusTypes = response.data.data;
      });
    },
    addNewLanguage(locale) {
      if (this.form.translations.filter((t) => t.locale == locale).length) {
        errorMessage("Viga", "See keel on juba lisatud");
        return;
      }

      this.form.translations.push({
        locale: locale,
        name: null,
        description: null,
      });
    },
    getLocaleName(locale) {
      let language = this.languages.find((l) => l.locale == locale);

      if (!language) {
        return locale;
      }

      return language.name;
    },
    isLanguageDeleted(locale) {
      if (!this.form.deletable_translations.includes(locale)) {
        return false;
      }

      return true;
    },
    removeLanguage(locale) {
      if (this.form.deletable_translations.includes(locale)) {
        return null;
      }
      this.form.deletable_translations.push(locale);
    },
    restoreLanguage(locale) {
      if (!this.form.deletable_translations.includes(locale)) {
        return null;
      }

      this.form.deletable_translations = this.form.deletable_translations.filter(
        (l) => l != locale
      );
    },
  },
};
</script>
