<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Veebi tõlked
    </h1>

    <b-form-group class="mb-3">
      <b-form-input
        class="form-control"
        v-model="keyword"
        size="sm"
        placeholder="Otsingu sõna"
      ></b-form-input>
    </b-form-group>

    <template v-if="filteredTranslations.length">
      <b-row v-for="translation in filteredTranslations" :key="translation.key">
        <b-col cols="12">
          <strong>
            {{ translation.key }}
          </strong>
        </b-col>
        <TranslationItem
          v-for="item in translation.item"
          :key="`${item.key}-${item.locale}`"
          :item="item"
        />
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-alert v-if="!isLoading" variant="warning" show>
        Tulemusi ei leitud
      </b-alert>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapActions, mapGetters } from "vuex";
import TranslationItem from "./TranslationItem";

export default {
  components: {
    TranslationItem,
  },
  data() {
    return {
      isLoading: false,
      translations: [],
      keyword: null,
    };
  },
  computed: {
    ...mapGetters({}),
    filteredTranslations() {
      if (!this.keyword) {
        return this.translations;
      }

      return this.translations.filter((translation) => {
        if (
          translation.key.toLowerCase().indexOf(this.keyword.toLowerCase()) !=
          -1
        ) {
          return true;
        }

        return false;
      });
    },
  },
  mounted() {
    this.fetchTranslations();
  },
  methods: {
    ...mapActions({}),
    fetchTranslations() {
      this.isLoading = true;
      RestService.getStringTranslations().then((response) => {
        this.translations = response.data.data;
        this.isLoading = false;
      });
    },
  },
};
</script>
