import RestService from "@/lib/RestService";

export const fetchCountries = ({ commit }) => {
  return RestService.getCountries().then((response) => {
    commit("SET_COUNTRIES", response.data.data);
  });
};

export const fetchOrderStatuses = ({ commit }) => {
  return RestService.getOrderStatusesForList().then((response) => {
    commit("SET_ORDER_STATUSES", response.data.data);
  });
};

export const fetchProductStockStatuses = ({ commit }) => {
  return RestService.getProductStockStatusesMisc().then((response) => {
    commit("SET_PRODUCT_STOCK_STATUSES", response.data.data);
  });
};

export const fetchLanguages = ({ commit }) => {
  return RestService.getLanguages().then((response) => {
    commit("SET_LANGUAGES", response.data.data);
  });
};

export const fetchSuppliers = ({ commit }) => {
  return RestService.getSuppliers().then((response) => {
    commit("SET_SUPPLIERS", response.data.data);
  });
};

export const fetchProductCodeTypes = ({ commit }) => {
  return RestService.getProductCodeTypes().then((response) => {
    commit("SET_PRODUCT_CODE_TYPES", response.data.data);
  });
};

export const fetchAdminUsers = ({ commit }) => {
  return RestService.getAdminUsers().then((response) => {
    commit("SET_ADMIN_USERS", response.data.data);
  });
};

export const fetchShippingMethods = ({ commit }) => {
  return RestService.getAllShippingMethods().then((response) => {
    commit("SET_SHIPPING_METHODS", response.data.data);
  });
};

export const fetchMainCategories = ({ commit }) => {
  return RestService.getMiscMainCategories().then((response) => {
    commit("SET_MAIN_CATEGORIES", response.data.data);
  });
};
