<template>
  <div class="d-relative">
    <loader :overlay="true" v-if="isLoading" />
    <h1>
      Rma #{{ rmaOrderId }}
      <b-button variant="primary" size="sm" @click.prevent="fetchOrder">
        <font-awesome-icon icon="sync" />
      </b-button>
    </h1>
    <template v-if="rmaOrder">
      <pre>{{ rmaOrder }}</pre>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapActions, mapGetters } from "vuex";
import { errorMessage } from "@/lib/messages";
export default {
  components: {
    
  },
  data() {
    return {
      isLoading: true,
      rmaOrder: null,
    };
  },
  computed: {
    ...mapGetters({
      isEemeli: "global/isEemeli",
      languages: "generic/languages",
    }),
    selectLocales() {
      return this.languages.map((locale) => {
        return {
          value: locale.locale,
          text: locale.name,
        };
      });
    },
    rmaOrderId() {
      if (!this.rmaOrder) {
        return this.$route.params.rmaId;
      }

      return this.rmaOrder.id;
    },
  },
  mounted() {
    this.fetchRmaOrder();
  },
  methods: {
    ...mapActions({

    }),
    fetchRmaOrder() {
      this.isLoading = true;
      return RestService.getRmaOrder(this.rmaOrderId)
        .then((response) => {
          this.rmaOrder = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>
