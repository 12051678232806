import { OrderStatuses, OrderStatusEdit, OrderStatusNew } from "../components";

export default [
  {
    path: "/orders/statuses",
    component: OrderStatuses,
    name: "orderStatuses",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/statuses/new",
    component: OrderStatusNew,
    name: "orderStatus.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/statuses/:statusId",
    component: OrderStatusEdit,
    name: "orderStatus.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
