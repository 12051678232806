<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Tarne tsoonid
      <b-button variant="primary" size="sm" @click.prevent="addNew">
        Lisa uus
      </b-button>
    </h1>

    <b-table-simple bordered responsive small>
      <b-tr variant="primary">
        <b-th>ID</b-th>
        <b-th>Nimi</b-th>
        <b-th>Riigid</b-th>
        <b-th style="width: 5%;"></b-th>
      </b-tr>
      <b-tr v-for="shippingZone in shippingZones" :key="shippingZone.id">
        <b-td>
          {{ shippingZone.id }}
        </b-td>
        <b-td>
          {{ shippingZone.name }}
        </b-td>
        <b-td>
          {{ shippingZone.countries }}
        </b-td>
        <b-td class="text-right">
          <router-link
            class="btn btn-warning btn-sm ml-1"
            :to="{
              name: 'shippingZones.edit',
              params: { shippingZoneId: shippingZone.id },
            }"
          >
            muuda
          </router-link>
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      shippingZones: "shippingZones/shippingZones",
    }),
  },
  mounted() {
    this.isLoading = true;
    this.fetchShippingZones().then(() => {
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions({
      fetchShippingZones: "shippingZones/fetchShippingZones",
    }),
    addNew() {
      this.$router.push({ name: "shippingZones.new" });
    },
  },
};
</script>
