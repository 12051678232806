import router from "@/router";
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
import Axios from "axios";
import Echo from "laravel-echo";

export const setPreLoading = ({ commit }, trueOrFalse) => {
  commit("SET_PRELOADING", trueOrFalse);
};

export const beforePageLoad = ({ commit, dispatch }) => {
  const promise = dispatch("auth/fetchUser", {}, { root: true })
    .then(() => {
      dispatch("setUpEcho");
      commit("SET_PRELOADING", false);
    })
    .catch(() => {
      dispatch("auth/clearAuth", {}, { root: true });
      commit("SET_PRELOADING", false);
    });

  return Promise.all([promise]);
};

export const doHeartBeat = ({ commit, dispatch }) => {
  return RestService.checkHeartBeat()
    .then(() => {
      commit("SET_CONNECTION", true);
    })
    .catch((error) => {
      if (!error.response) {
        commit("SET_CONNECTION", false);

        errorMessage("Error!", "No internet connection!");
        return;
      }

      if (error.response.status === 401) {
        dispatch("auth/clearAuth", {}, { root: true });

        return router.replace({ name: "login" });
      }

      commit("SET_CONNECTION", false);
      errorMessage("Error!", `${error}`);
    });
};

export const toggleSidebar = ({ commit }) => {
  commit("TOGGLE_SIDEBAR");
};

export const closeSidebar = ({ commit }) => {
  commit("CLOSE_SIDEBAR");
};

export const setTabState = ({ commit }, trueOrFalse) => {
  commit("SET_TAB_STATE", trueOrFalse);
};

export const setUpEcho = () => {
  if (process.env.VUE_APP_PUSHER_KEY != "") {
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: process.env.VUE_APP_PUSHER_KEY,
      cluster: "eu",
      forceTLS: true,
      authorizer: (channel) => {
        return {
          authorize: (socketId, callback) => {
            Axios.post(process.env.VUE_APP_API_HOST + "/broadcasting/auth", {
              socket_id: socketId,
              channel_name: channel.name,
            })
              .then((response) => {
                callback(false, response.data);
              })
              .catch((error) => {
                callback(true, error);
              });
          },
        };
      },
    });
  }
};
