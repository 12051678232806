<template>
  <div class="d-relative">
    <loader :overlay="true" v-if="isLoading" />
    <h1>
      Lao inventuur
      <router-link
        class="btn btn-primary btn-sm"
        :to="{
          name: 'stockInventoryNew',
        }"
      >
        Koosta uus invetuur
      </router-link>
    </h1>

    <b-table-simple bordered responsive small>
      <b-tr variant="primary">
        <b-th>Nimi</b-th>
        <b-th>Tulemused</b-th>
        <b-th style="width: 5%"></b-th>
      </b-tr>
      <b-tr v-for="item in items" :key="item.id">
        <b-td>
          {{ item.created_at | moment("DD.MM.YYYY HH:mm") }} -
          {{ item.confirmed_at | moment("DD.MM.YYYY HH:mm") }}
          <br />
        </b-td>
        <b-td>
          <strong>Kokku tooteid: </strong>
          <b-badge variant="primary">122</b-badge> <br />

          <strong>Tooteid üle: </strong>
          <b-badge variant="warning">4</b-badge> <br />

          <strong>Tooteid puudu: </strong>
          <b-badge variant="danger">1</b-badge> <br />
        </b-td>
        <b-td class="text-right">
          <router-link
            class="btn btn-primary btn-sm"
            :to="{
              name: 'stockInventoryProcess',
              params: { inventoryId: item.id },
            }"
          >
            Jätka
          </router-link>
          <b-button variant="info" size="sm">Vaata</b-button>
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
export default {
  data() {
    return {
      isLoading: false,
      items: [],
    };
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      this.isLoading = true;
      return RestService.getStorageInventories().then((response) => {
        this.items = response.data.data;

        this.isLoading = false;
      });
    },
  },
};
</script>
