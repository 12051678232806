<template>
  <li class="item">
    <div :class="{ active: isActive }">
      <span @click.prevent="handleItemClick(item.id)">
        {{ item.name }}
      </span>
      <span class="bold" v-if="hasChildren" @click="toggleOpen">
        [{{ isOpen ? "-" : "+" }}]
      </span>
      <span v-if="!hasChildren && showAdd">
        <b-button
          class="btn-xs"
          size="sm"
          variant="primary"
          @click.prevent="handleAddBtn"
        >
          Add
        </b-button>
      </span>
    </div>
    <ul v-show="isOpen" v-if="hasChildren">
      <tree-item
        class="item"
        v-for="child in item.children"
        :key="child.id"
        :item="child"
        :showAdd="showAdd"
        @add="handleChildAdd"
        :activeId="activeId"
        @itemClicked="handleItemClick"
      />
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    activeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    hasChildren() {
      if (this.item.children === undefined) {
        return false;
      }

      if (!this.item.children.length) {
        return false;
      }

      return true;
    },
    isActive() {
      return this.item.id == this.activeId;
    },
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    handleAddBtn() {
      this.$emit("add", this.item.id);
    },
    handleChildAdd(value) {
      this.$emit("add", value);
    },
    handleItemClick(val) {
      this.$emit("itemClicked", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  cursor: pointer;

  .active {
    font-weight: 800;
  }
}
.bold {
  font-weight: bold;
}
ul {
  padding-left: 1em;
  line-height: 1.5em;
  list-style-type: dot;
}

.btn {
  &.btn-xs {
    font-size: 0.8rem;
    line-height: 1.1;
  }
}
</style>
