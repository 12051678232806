<template>
   <b-modal hide-footer 
      visible
      title="Filtrid"
      size="sm"
      @hidden="hideModal"
    >
      <b-form-group
        label="Tooteid ühe lehekülje kohta"
        class="mb-3"
      >
        <b-form-select
          v-model="perPage"
          :options="perPageOptions"
          size="sm"
        ></b-form-select>
      </b-form-group>

      <b-form-checkbox v-model="onlyStocksCheck" switch>
        Ainult ladudega
      </b-form-checkbox>

      <b-form-checkbox v-model="parcelMachineCheck" switch>
        Pakiautomaat puudub
      </b-form-checkbox>

      <b-form-checkbox v-model="onlyWithoutIcecatCheck" switch>
        Ilma icecatita
      </b-form-checkbox>

      <b-form-checkbox v-model="needsReviewCheck" switch>
        Vajab tähelepanu
      </b-form-checkbox>

      <div class="text-right mt-3">
        <b-button variant="primary" size="sm" @click.prevent="updateFilters">Salvesta</b-button>
      </div>
    </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      perPageOptions: [30, 100, 250, 500, 1000],
      perPage: 30,
      onlyStocksCheck: false,
      onlyWithoutIcecatCheck: false,
      needsReviewCheck: false,
      parcelMachineCheck: false,
    }
  },
  computed: {
  ...mapGetters({
      filters: "catalog/filters",
    }),
  },
  mounted() {
    this.perPage = this.filters.per_page
    this.onlyStocksCheck = this.filters.only_with_stocks ? true : false;
    this.onlyWithoutIcecatCheck = this.filters.only_without_icecat ? true : false;
    this.needsReviewCheck = this.filters.needs_review ? true : false;
    this.parcelMachineCheck = this.filters.parcel_machine ? true : false;
  },
  methods: {
    ...mapActions({
      setFilterData: "catalog/setFilterData",
    }),
    updateFilters() {
      this.setFilterData({
        perPage: this.perPage,
        onlyStocks: this.onlyStocksCheck,
        onlyWithoutIcecat: this.onlyWithoutIcecatCheck,
        needs_review: this.needsReviewCheck,
        parcel_machine: this.parcelMachineCheck,
      })

      this.$emit('close')
    },
    hideModal() {
      this.$emit('close')
    }
  }
}
</script>