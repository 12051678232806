export default {
  preLoading: true,
  connection: true,
  isTabActive: true,
  languages: [
    {
      locale: "et",
      name: "Eesti",
    },
    {
      locale: "en",
      name: "English",
    },
    {
      locale: "de",
      name: "German",
    },
  ],
  isEemeli: process.env.VUE_APP_IS_EEMELI === "Y" ? true : false,
  showSidebar: false,
};
