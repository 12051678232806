import RestService from "@/lib/RestService";

export const fetchCategories = ({ commit }) => {
  return RestService.getCategories().then((response) => {
    commit("SET_CATEGORIES", response.data.data);
  });
};

export const fetchCategorySettings = ({ commit }) => {
  return RestService.getCategorySettings().then((response) => {
    commit("SET_CATEGORY_SETTINGS", response.data.data);
  });
};

export const updateCategory = ({ commit }, payload) => {
  return RestService.postUpdateCategory(payload.id, payload).then(
    (response) => {
      commit("UPDATE_CATEGORY", response.data.data);
      return response;
    }
  );
};
