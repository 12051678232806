<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <b-table-simple responsive bordered small>
      <b-tr :variant="tableVariant">
        <b-th>
          <router-link
            v-if="!isUnAssigned"
            class="product-link"
            :to="{
              name: 'products.edit',
              params: { productId: product.id },
            }"
          >
            {{ product.name }} <small>(ID: {{ product.id }})</small>
          </router-link>
          <span v-else>
            {{ product.name }} <small>(ID: {{ product.id }})</small>
          </span>

          <b-button
            v-if="!isUnAssigned"
            class="float-right"
            size="sm"
            variant="outline-primary"
            @click.prevent="showNewStockItem"
          >
            <font-awesome-icon icon="plus" />
          </b-button>
        </b-th>
      </b-tr>
      <b-tr>
        <b-td>
          <b-table-simple class="mb-0" bordered responsive small>
            <b-tr>
              <b-td style="border: none">
                <b-table-simple class="mb-0" bordered responsive small>
                  <b-tr v-if="showNewForm">
                    <b-td>
                      <b-form-group>
                        <b-textarea
                          rows="6"
                          size="sm"
                          v-model="form.additional_info"
                        ></b-textarea>
                      </b-form-group>
                    </b-td>
                    <b-td style="width: 15%;">
                      <b-form-group class="mb-1">
                        <b-form-input
                          v-model="form.ordered_place"
                          placeholder="Tellitud koht"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                    </b-td>
                    <b-td style="width: 15%;">
                      <b-form-group class="mb-1">
                        <b-form-input
                          type="number"
                          v-model.number="form.price"
                          placeholder="Hind"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                    </b-td>
                    <b-td class="text-center" style="width: 10%;">
                      <b-badge variant="warning">
                        Tellitud
                      </b-badge>
                    </b-td>
                    <b-td style="width: 10%;" class="text-right">
                      <b-button
                        size="sm"
                        variant="danger"
                        class="mr-1"
                        @click.prevent="cancelNew"
                      >
                        <font-awesome-icon icon="times" />
                      </b-button>
                      <b-button
                        size="sm"
                        variant="success"
                        @click.prevent="saveNew"
                      >
                        <font-awesome-icon icon="check" />
                      </b-button>
                    </b-td>
                  </b-tr>
                  <StockItemRow
                    v-for="item in product.storage_items"
                    :key="item.id"
                    :item="item"
                    :isUnAssigned="isUnAssigned"
                    @itemUpdated="updateStorageItem"
                    @removeItem="removeItem"
                    @loadNew="loadNew"
                    @duplicate="duplicateItem"
                  />
                </b-table-simple>
              </b-td>
              <b-td
                style="width: 20%; border: none; border-left: 1px solid #dee2e6;"
              >
                <template v-if="product.codes && product.codes.length">
                  <strong>Koodid:</strong> <br />
                  <small>
                    <div v-for="code in product.codes" :key="code.id">
                      <strong>{{ code.type }}:</strong> {{ code.code }}
                    </div> </small
                  ><br />
                </template>

                <template v-if="product.storage_stock">
                  <strong>
                    Toote ladu:
                    <small>
                      <a
                        href="#"
                        @click.prevent="isEditingPrice = true"
                        v-if="!isEditingPrice"
                      >
                        Muuda
                      </a>
                      <a
                        href="#"
                        @click.prevent="isEditingPrice = false"
                        v-if="isEditingPrice"
                      >
                        Katkesta
                      </a>
                    </small>
                  </strong>
                  <br />
                  <small>
                    <strong>Laoseis:</strong>
                    {{ product.storage_stock.in_stock }} <br />
                    <strong>Müügihind: </strong>
                    <template v-if="product.storage_stock.manual_price">
                      {{ product.storage_stock.manual_price | currency }} &euro;
                    </template>
                    <template v-else>
                      {{ product.storage_stock.price | currency }} &euro;
                    </template>
                    <br />
                    <strong>Marginal: </strong>
                    <span :class="marginClass"
                      >{{ marginSum | currency }} &euro;</span
                    >
                  </small>

                  <div class="mt-3" v-if="isEditingPrice">
                    <strong>Disabled: </strong>
                    <b-badge
                      v-if="product.storage_stock.is_disabled"
                      variant="danger"
                    >
                      Jah
                    </b-badge>
                    <b-badge v-else variant="success">
                      Ei
                    </b-badge>

                    <b-form-group class="mb-1" label="Laoseis:">
                      <b-input v-model="priceForm.in_stock" size="sm"></b-input>
                    </b-form-group>
                    <b-form-group class="mb-2" label="Lao staatus:">
                      <b-form-select
                        v-if="stockStatusesSelect.length"
                        v-model="priceForm.stock_status_id"
                        :options="stockStatusesSelect"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group class="mb-2" label="Müügihind:">
                      <b-input v-model="priceForm.price" size="sm"></b-input>
                    </b-form-group>
                    <div class="text-right">
                      <b-button
                        variant="primary"
                        size="sm"
                        @click.prevent="saveStoragePrices"
                        >Salvesta</b-button
                      >
                    </div>
                  </div>
                </template>
              </b-td>
            </b-tr>
          </b-table-simple>
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import StockItemRow from "./StockItemRow";
import { errorMessage } from "@/lib/messages";
import { mapGetters } from "vuex";

export default {
  props: {
    product: {
      type: Object,
    },
    searchKey: {},
  },
  components: {
    StockItemRow,
  },
  data() {
    return {
      isLoading: false,
      showNewForm: false,
      form: {
        additional_info: null,
        ordered_place: null,
        price: null,
      },
      isEditingPrice: false,
      priceForm: {
        price: null,
        stock_status_id: null,
        in_stock: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      stockStatusesSelect: "generic/stockStatusesSelect",
    }),
    isUnAssigned() {
      return this.product.id == 0;
    },
    inStockCount() {
      return this.product.storage_items.filter((i) => {
        if (i.status != "in-stock") {
          return false;
        }

        return true;
      }).length;
    },
    marginSum() {
      if (!this.product) {
        return 0;
      }

      if (!this.product.storage_stock) {
        return 0;
      }

      let price = this.product.storage_stock.price;
      if (this.product.storage_stock.manual_price) {
        price = this.product.storage_stock.manual_price;
      }

      return price - this.product.storage_stock.purchase_price;
    },
    marginClass() {
      if (this.marginSum < 0) {
        return "text-danger";
      }

      if (this.marginSum == 0) {
        return "text-warning";
      }

      return "text-success";
    },
    tableVariant() {
      if (
        this.product.storage_stock &&
        this.product.storage_stock.in_stock != this.inStockCount
      ) {
        return "danger";
      }

      return "primary";
    },
  },
  mounted() {
    this.setPriceFormData();
  },
  methods: {
    updateStorageItem(data) {
      this.$emit("updateItem", {
        productId: this.product.id,
        item: data,
      });
    },
    removeItem(data) {
      this.$emit("removeItem", {
        productId: this.product.id,
        item: data,
      });
    },
    loadNew() {
      this.$emit("loadNew");
    },
    duplicateItem(data) {
      this.showNewForm = true;
      this.form = {
        additional_info: data.additional_info,
        ordered_place: data.ordered_place,
        price: data.price,
      };
    },
    showNewStockItem() {
      this.form = {
        additional_info: null,
        ordered_place: null,
        price: null,
      };

      this.showNewForm = true;
    },
    cancelNew() {
      this.showNewForm = false;
    },
    saveNew() {
      this.isLoading = true;
      return RestService.postStorageItem({
        product_id: this.product.id,
        ...this.form,
      })
        .then((response) => {
          this.showNewForm = false;
          this.$emit("addItem", {
            productId: this.product.id,
            item: response.data.data,
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data) {
            errorMessage("Viga!", "Toodet ei leitud!");
          } else {
            errorMessage("Viga!", JSON.stringify(error.response.data));
          }
        });
    },
    setPriceFormData() {
      let price = 0;
      let inStock = 0;
      let stockStatusId = null;
      let id = null;

      if (this.product.storage_stock) {
        id = this.product.storage_stock.id;
        inStock = this.product.storage_stock.in_stock;
        stockStatusId = this.product.storage_stock.stock_status_id;

        if (this.product.storage_stock.manual_price) {
          price = this.product.storage_stock.manual_price;
        } else {
          price = this.product.storage_stock.price;
        }
      }

      this.priceForm = {
        id: id,
        price: price,
        stock_status_id: stockStatusId,
        in_stock: inStock,
      };
    },
    saveStoragePrices() {
      this.isLoading = true;
      RestService.postSaveProductStock(this.priceForm)
        .then((response) => {
          this.$emit("updateStorageStock", {
            productId: this.product.id,
            data: response.data.data,
          });
          this.isEditingPrice = false;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
  },
  watch: {
    product() {
      this.setPriceFormData();
    },
  },
};
</script>
