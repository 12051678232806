import RestService from "@/lib/RestService";

export const fetchStorageItems = ({ commit }) => {
  return RestService.getStorageItems().then((response) => {
    commit('SET_STORAGE_ITEMS', response.data.data);
  });
};


export const addNewStockItem = ({ commit }, payload) => {
  return RestService.postStorageItem(payload).then((response) => {
    commit('ADD_NEW_STOCK_ITEM', response.data.data);
  });
};

export const updateStorageItemStatus = ({ commit }, payload) => {
  return RestService.putStorageItemStatus(payload.id, payload).then((response) => {
    commit('SET_STORAGE_ITEM', response.data.data);
  });
};

export const saveStorageItemInfo = ({ commit }, payload) => {
  return RestService.putStorageItemInfo(payload.id, payload).then((response) => {
    commit('SET_STORAGE_ITEM', response.data.data);
  });
};
