<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>Konto seaded</h1>

    <b-row>
      <b-col md="6">
        <b-form @submit.prevent="handleSave">
          <b-card header="Konto info">
            <b-form-group label="Nimi" class="mb-1">
              <b-form-input
                v-model="form.name"
                size="sm"
                :state="getFieldStateFromErrors('name')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("name") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Kehtiv parool" class="mb-1">
              <b-form-input
                type="password"
                v-model="form.password"
                size="sm"
                :state="getFieldStateFromErrors('password')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("password") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Uus parool" class="mb-1">
              <b-form-input
                type="password"
                v-model="form.new_password"
                size="sm"
                :state="getFieldStateFromErrors('new_password')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("new_password") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Uus parool uuesti" class="mb-1">
              <b-form-input
                type="password"
                v-model="form.new_password_confirmation"
                size="sm"
              ></b-form-input>
            </b-form-group>

            <div class="text-right mt-3">
              <b-button type="submit" variant="success" size="sm">
                Salvesta
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
      <b-col md="6">
        <b-card class="position-relative" header="Two Factor Authentication">
          <loader v-if="is2faLoading" :overlay="true" />
          <template
            v-if="user.data.two_factor_enabled && !twoFactorAuthData.image"
          >
            <b-alert variant="success" show>
              Teil on two factor authetication lubatud
            </b-alert>
            <b-button class="mt-3" variant="primary" @click.prevent="setup2fa">
              Seadista 2fa uuesti
            </b-button>
          </template>
          <template v-else>
            <template v-if="twoFactorAuthData.image">
              <b-row>
                <b-col md="6">
                  <b-alert v-if="twoFactorAuthError" variant="danger" show>
                    {{ twoFactorAuthError }}
                  </b-alert>
                  <div v-html="twoFactorAuthData.image"></div>
                  <b-form-group label="Autentimis kood" class="mb-1">
                    <b-form-input
                      v-model="twoFactorAuthData.otp"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                  <b-button
                    class="mt-1"
                    variant="success"
                    @click.prevent="save2faData"
                  >
                    Seadista
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <b-button v-else variant="primary" @click.prevent="setup2fa">
              Seadista 2fa
            </b-button>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ErrorsMixin from "@/helpers/ErrorsMixin";
import RestService from "@/lib/RestService";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,
      is2faLoading: false,
      form: {
        name: null,
        password: null,
        new_password: null,
        new_password_confirmation: null,
      },
      twoFactorAuthData: {
        image: null,
        secret: null,
        otp: null,
      },
      twoFactorAuthError: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    this.form = {
      name: this.user.data.name,
    };
  },
  methods: {
    ...mapActions({
      setUserData: "auth/setUserData",
    }),
    handleSave() {
      this.isLoading = true;
      RestService.postSaveAccountSettings(this.form)
        .then((response) => {
          this.setUserData(response.data.data);
          this.clearForm();
          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
    clearForm() {
      this.clearValidationErrors();
      this.form = {
        name: this.user.data.name,
        password: null,
        new_password: null,
        new_password_confirmation: null,
      };
    },
    setup2fa() {
      this.is2faLoading = true;
      RestService.getAccount2faData().then((response) => {
        this.twoFactorAuthData.image = response.data.data.qrImage;
        this.twoFactorAuthData.secret = response.data.data.secret;
        this.is2faLoading = false;
      });
    },
    save2faData() {
      this.is2faLoading = true;
      RestService.postSaveAccount2faData(this.twoFactorAuthData)
        .then((response) => {
          this.setUserData(response.data.data);
          this.twoFactorAuthError = null;
          this.twoFactorAuthData = {
            image: null,
            secret: null,
            otp: null,
          };
          this.is2faLoading = false;
        })
        .catch((error) => {
          this.twoFactorAuthError = error.response.data.error;
          this.is2faLoading = false;
        });
    },
  },
};
</script>
