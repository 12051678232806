<template>
  <div class="sidebar">
    <MobileSidebar />
    <DesktopSidebar />
  </div>
</template>

<script>
import MobileSidebar from "./sidebar/MobileSidebar";
import DesktopSidebar from "./sidebar/DesktopSidebar";

export default {
  components: {
    MobileSidebar,
    DesktopSidebar,
  },
};
</script>
