<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h3>
      Ladu
      <b-button variant="primary" size="sm" @click.prevent="openNewForm"
        >Lisa uus</b-button
      >
    </h3>

    <template v-if="showNewForm">
      <b-form-group class="mb-1">
        <b-form-input
          v-model="form.product_id"
          placeholder="Toote ID"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="mb-1">
        <b-form-input
          v-model="form.name"
          placeholder="Nimi (Kui toote id puudub)"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="mb-1">
        <b-form-input
          type="number"
          v-model.number="form.price"
          placeholder="Hind"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="mb-1">
        <b-form-input
          v-model="form.ordered_place"
          placeholder="Tellitud koht"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <b-textarea
          rows="6"
          size="sm"
          v-model="form.additional_info"
        ></b-textarea>
      </b-form-group>

      <b-button
        class="mr-3"
        size="sm"
        variant="danger"
        @click.prevent="cancelNew"
      >
        Katkesta
      </b-button>
      <b-button size="sm" variant="success" @click.prevent="saveNew">
        Lisa uus
      </b-button>

      <hr class="mb-5 mt-5" />
    </template>

    <b-form-group>
      <b-form-input
        class="form-control"
        v-model="search"
        size="sm"
        placeholder="Otsingu sõna"
      ></b-form-input>
    </b-form-group>

    <StockProduct
      v-for="product in filteredItems"
      :key="product.id"
      :product="product"
      :searchKey="search"
      @updateItem="updateStorageItem"
      @removeItem="removeItem"
      @loadNew="fetchItems"
      @addItem="addItem"
      @updateStorageStock="updateStorageStock"
    />
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import StockProduct from "./stockProducts/StockProduct";
import { errorMessage } from "@/lib/messages";
import { mapActions } from "vuex";

export default {
  components: {
    StockProduct,
  },
  data() {
    return {
      isLoading: false,
      showNewForm: false,
      search: "",
      items: [],
      form: {
        product_id: null,
        name: null,
        additional_info: null,
        ordered_place: null,
        price: null,
      },
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        if (!this.search) {
          return true;
        }

        if (
          item.name &&
          item.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1
        ) {
          return true;
        }

        let codes = JSON.stringify(item.codes);

        if (codes.toLowerCase().indexOf(this.search.toLowerCase()) != -1) {
          return true;
        }

        let additional_info = JSON.stringify(
          item.storage_items.map((i) => i.additional_info)
        );
        if (
          additional_info.toLowerCase().indexOf(this.search.toLowerCase()) != -1
        ) {
          return true;
        }

        return false;
      });
    },
  },
  mounted() {
    this.fetchProductStockStatuses();
    this.fetchItems();
  },
  methods: {
    ...mapActions({
      fetchProductStockStatuses: "generic/fetchProductStockStatuses",
    }),
    fetchItems() {
      this.isLoading = true;
      return RestService.getStorageItemsGrouped().then((response) => {
        this.items = response.data.data;

        this.isLoading = false;
      });
    },
    updateStorageItem(data) {
      let productIndex = this.items.findIndex((i) => i.id == data.productId);
      let itemIndex = this.items[productIndex].storage_items.findIndex(
        (i) => i.id == data.item.id
      );

      this.$set(this.items[productIndex].storage_items, itemIndex, data.item);
    },
    removeItem(data) {
      let productIndex = this.items.findIndex((i) => i.id == data.productId);
      let items = this.items[productIndex].storage_items.filter(
        (i) => i.id != data.item.id
      );

      this.$set(this.items[productIndex], "storage_items", items);
    },
    addItem(data) {
      let productIndex = this.items.findIndex((i) => i.id == data.productId);
      let items = this.items[productIndex].storage_items;
      items.unshift(data.item);

      this.$set(this.items[productIndex], "storage_items", items);
    },
    openNewForm() {
      this.form = {
        product_id: null,
        name: null,
        additional_info: null,
        ordered_place: null,
        price: null,
      };

      this.showNewForm = true;
    },
    cancelNew() {
      this.showNewForm = false;
    },
    saveNew() {
      this.isLoading = true;
      return RestService.postStorageItem({
        ...this.form,
      })
        .then(() => {
          this.showNewForm = false;
          this.fetchItems();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status == 404) {
            errorMessage("Viga!", "Toodet ei leitud!");
          } else {
            errorMessage("Viga!", JSON.stringify(error.response.data));
          }
        });
    },
    updateStorageStock(payload) {
      let productIndex = this.items.findIndex((i) => i.id == payload.productId);
      this.$set(this.items[productIndex], "storage_stock", payload.data);
    },
  },
};
</script>
