import Vue from "vue";

export const Pages = Vue.component("pages", require("./Pages.vue").default);
export const PageEdit = Vue.component(
  "page-edit",
  require("./PageEdit.vue").default
);
export const PageNew = Vue.component(
  "page-new",
  require("./PageNew.vue").default
);
