import Vue from "vue";

export const ProductStockStatuses = Vue.component(
  "product-stock-statuses",
  require("./ProductStockStatuses.vue").default
);

export const ProductStockStatusEdit = Vue.component(
  "product-stock-status-edit",
  require("./ProductStockStatusEdit.vue").default
);

export const ProductStockStatusNew = Vue.component(
  "product-stock-status-new",
  require("./ProductStockStatusNew.vue").default
);
