<template>
  <b-card class="mt-3" header="Tellitud tooted" no-body>
    <div class="p-1">
      <b-table-simple class="mb-0" bordered small responsive>
        <b-thead>
          <b-tr variant="primary">
            <b-th>Tarnija</b-th>
            <b-th>Toode</b-th>
            <b-th>Kogus</b-th>
            <b-th>Tellimuse nr.</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="item in order.supplierOrders" :key="item.id">
            <b-td>
              {{ item.supplier.name }}
            </b-td>
            <b-td>
              {{ item.name }}<br />
              <small>
                <strong>SKU: </strong> {{ item.sku }} <br />
                <strong>Tellitud: </strong>
                {{ item.created_at | moment("DD.MM.YYYY HH:mm:ss") }} <br />

                <b-alert class="p-1" variant="info" v-if="item.comment" show>
                  <strong>Viimane info: </strong> <br />
                  <span v-html="item.comment"></span>
                </b-alert>
              </small>
            </b-td>
            <b-td class="text-center">
              {{ item.quantity }}
            </b-td>
            <b-td class="text-center">
              {{ item.external_order_id }} <br />

              <b-button
                v-if="item.external_order_id"
                class="mt-2"
                variant="outline-primary"
                size="sm"
                @click.prevent="fetchSupplierOrderInfo(item)"
              >
                Kontrolli infot
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </b-card>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    fetchSupplierOrderInfo(item) {
      this.$emit("startLoading");
      RestService.postCheckOrderSupplierStatus(this.order.id, item.id)
        .then((response) => {
          this.$emit("stopLoading", response.data.data);
        })
        .catch((error) => {
          if (error.response) {
            errorMessage("Viga!", error.response.data.message);
            this.$emit("stopLoading", null);
          }
        });
    },
  },
};
</script>
