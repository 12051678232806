<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      Lisa uus järelmaks
    </h2>

    <b-row>
      <b-col md="6">
        <b-card header="Järelmaksu info">
          <b-form>
            <b-form-group class="mb-1" label="Nimi">
              <b-form-input
                v-model="form.name"
                size="sm"
                :state="getFieldStateFromErrors('name')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("name") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Tüüp">
              <b-form-select
                v-model="form.type"
                :options="typeOptions"
                size="sm"
              ></b-form-select>
              <b-form-invalid-feedback>
                {{ getFieldError("type") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Teenustasu">
              <b-form-input
                type="number"
                v-model.number="form.fee"
                size="sm"
                :state="getFieldStateFromErrors('fee')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("fee") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Intress">
              <b-form-input
                type="number"
                v-model.number="form.interest"
                size="sm"
                :state="getFieldStateFromErrors('interest')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("interest") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Ostukorvi miinimum summa">
              <b-form-input
                type="number"
                v-model.number="form.total_min"
                size="sm"
                :state="getFieldStateFromErrors('total_min')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("total_min") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Ostukorvi maksimum summa">
              <b-form-input
                type="number"
                v-model.number="form.total_max"
                size="sm"
                :state="getFieldStateFromErrors('total_max')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("total_max") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <div class="text-right mt-3">
              <b-button
                type="submit"
                variant="success"
                @click.prevent="saveLease"
              >
                Salvesta
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,
      form: {
        name: null,
        type: "NORMAL",
        fee: null,
        interest: null,
        total_max: null,
        total_min: null,
      },
      typeOptions: [
        { value: "NORMAL", text: "Tavaline järelmaks" },
        { value: "SLICE", text: "Maksa kolmes osas" },
        { value: "PAY_LATER", text: "Maksa hiljem" },
      ],
    };
  },
  methods: {
    saveLease() {
      this.isLoading = true;

      let payload = this.form;

      RestService.postSaveNewLease(payload)
        .then((response) => {
          this.$router.push({
            name: "lease.edit",
            params: { leaseId: response.data.data.id },
          });
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
  },
};
</script>
