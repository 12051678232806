import { Dashboard, Couriers } from "../components";

export default [
  {
    path: "/",
    component: Dashboard,
    name: "dashboard",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/dashboard/couriers",
    component: Couriers,
    name: "dashboard.couriers",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
