import { Leases, LeaseEdit, LeaseNew } from "../components";

export default [
  {
    path: "/leases",
    component: Leases,
    name: "leases",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/leases/new",
    component: LeaseNew,
    name: "lease.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/leases/:leaseId",
    component: LeaseEdit,
    name: "lease.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
