<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Bännerid
      <router-link
        class="btn btn-primary btn-sm"
        :to="{
          name: 'banners.new',
        }"
      >
        Lisa uus
      </router-link>
    </h1>

    <b-table-simple bordered responsive small class="mb-0" v-if="banners">
      <b-tr variant="primary">
        <b-th style="width: 1%;" class="text-center"> </b-th>
        <b-th>
          Nimi
        </b-th>
        <b-th style="width: 15%;" class="text-center">
          Asukoht
        </b-th>
        <b-th style="width: 15%;" class="text-center">
          Keel
        </b-th>
        <b-th style="width: 15%;"></b-th>
      </b-tr>
      <draggable
        tag="tbody"
        v-model="banners"
        group="banners"
        @change="updateBannerOrders"
      >
        <b-tr v-for="banner in banners" :key="banner.id">
          <b-td>
            <font-awesome-icon class="drag-element" icon="bars" />
          </b-td>
          <b-td>
            {{ banner.name }}
          </b-td>
          <b-td class="text-center">
            {{ banner.position }}
          </b-td>
          <b-td class="text-center">
            {{ banner.locale }}
          </b-td>
          <b-td class="text-right">
            <router-link
              class="btn btn-warning btn-sm mr-2"
              :to="{
                name: 'banners.edit',
                params: { bannerId: banner.id },
              }"
            >
              Muuda
            </router-link>

            <b-button
              variant="danger"
              size="sm"
              @click.prevent="deleteBanner(banner)"
            >
              X
            </b-button>
          </b-td>
        </b-tr>
      </draggable>
    </b-table-simple>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      isLoading: false,
      banners: [],
    };
  },
  mounted() {
    this.fetchBanners();
  },
  methods: {
    fetchBanners() {
      this.isLoading = true;
      RestService.getBanners().then((resposne) => {
        this.banners = resposne.data.data;
        this.isLoading = false;
      });
    },
    updateBannerOrders() {
      this.isLoading = true;
      let ids = this.banners.map((banner) => banner.id);

      RestService.updateBannerOrder({
        banner_ids: ids,
      }).then((resposne) => {
        this.banners = resposne.data.data;
        this.isLoading = false;
      });
    },
    deleteBanner(banner) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.postDeleteBanner(banner.id).then(() => {
              this.fetchBanners();
            });
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
.drag-element {
  cursor: pointer;
}
</style>
