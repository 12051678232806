<template>
  <div class="d-relative">
    <OrdersHeader name="Uus tellimus" type="new" />
    <loader :overlay="true" v-if="isLoading" />

    <template v-if="defaultData">
      <b-row class="mt-3">
        <b-col md="8">
          <b-card header="Tellija andmed">
            <b-row>
              <b-col>
                <b-form-group class="mb-1" label="Tellimuse keel">
                  <b-form-select
                    v-model="newOrder.locale"
                    size="sm"
                    :options="selectLocales"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <div class="mb-2">
                  Registreeritud klient
                </div>
                <div v-if="newOrder.user_id && newOrder.user">
                  <strong>
                    {{ newOrder.user.first_name }} {{ newOrder.user.last_name }} <small>(#{{ newOrder.user.id }})</small>
                  </strong>
                  <span v-if="newOrder.user.person_type != 1">
                    <br><small>{{ newOrder.user.company_name }}</small>
                  </span>
                  <a href="#" class="inline-block pl-2" @click.prevent="searchCustomer">
                    <font-awesome-icon icon="edit" />
                  </a>
                </div>
                <div v-else>
                  <a href="#" @click.prevent="searchCustomer">Määra klient</a>
                </div>
                <CustomerSearchModal v-if="showCustomerModal" @close="hideCustomerModal" @selected="assignCustomer" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group class="mb-1" label="Eesnimi">
                  <b-form-input
                    v-model="newOrder.firstname"
                    size="sm"
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="mb-1" label="Perenimi">
                  <b-form-input
                    v-model="newOrder.lastname"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-1" label="Email">
                  <b-form-input
                    v-model="newOrder.email"
                    size="sm"
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="mb-1" label="Telefon">
                  <b-form-input
                    v-model="newOrder.phone"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-2 mb-2">
              <b-col>
                <b-form-radio-group size="sm" v-model="newOrder.customer_type">
                  <b-form-radio value="1">Eraisik</b-form-radio>
                  <b-form-radio value="2">Ettevõte</b-form-radio>
                  <b-form-radio value="3">Riigiasutus</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <template
              v-if="newOrder.customer_type == 2 || newOrder.customer_type == 3"
            >
              <b-row>
                <b-col>
                  <b-form-group class="mb-1" label="Ettevõtte nimi">
                    <b-form-input
                      v-model="newOrder.company_name"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group class="mb-1" label="Ettevõtte reg nr">
                    <b-input-group>
                      <b-form-input
                        v-model="newOrder.company_code"
                        size="sm"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button size="sm" variant="outline-primary">
                          <font-awesome-icon icon="search" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group class="mb-1" label="KMKR nr">
                    <b-form-input
                      v-model="newOrder.company_vat"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h6 class="mt-3 font-weight-bold">
                    Ettevõtte / Maksja aadress:
                  </h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group class="mb-1" label="Riik" v-if="countries">
                    <v-select
                      v-model="newOrder.country"
                      :options="countries"
                      :reduce="(item) => item.code"
                      :clearable="false"
                    ></v-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group class="mb-1" label="Maakond">
                    <b-form-input
                      v-model="newOrder.county"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group class="mb-1" label="Linn/Vald">
                    <b-form-input
                      v-model="newOrder.city"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group class="mb-1" label="Postiindeks">
                    <b-form-input
                      v-model="newOrder.zip"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group class="mb-1" label="Aadress">
                    <b-form-input
                      v-model="newOrder.address"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group class="mb-1 mt-4">
                    <b-form-checkbox
                      v-model="newOrder.einvoice"
                      :value="true"
                      :unchecked-value="false"
                    >
                      E-arve
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
        <b-col>
          <b-card header="Muud andmed">
            <b-form-group class="mb-1" label="Maksemeetod">
              <b-form-select
                v-model="newOrder.payment_method"
                size="sm"
                :options="selectPayments"
              >
              </b-form-select>
            </b-form-group>
            <b-form-group
              class="mb-1"
              label="Arve maksetähtaeg (Päevades)"
              v-if="newOrder.payment_method == 1"
            >
              <b-form-input
                v-model="newOrder.payment_days"
                type="number"
                size="sm"
              ></b-form-input>
            </b-form-group>
            <b-form-group class="mb-1" label="Tarnemeetod">
              <b-form-select
                v-model="newOrder.shipping_method"
                size="sm"
                :options="selectShippings"
              >
              </b-form-select>
            </b-form-group>
            <b-form-group class="mb-1" label="Transpordi hind (km-ita)">
              <b-form-input
                v-model="newOrder.shipping_price"
                type="number"
                size="sm"
              ></b-form-input>
            </b-form-group>

            <div class="mt-3" v-if="selectedShippingMethod">
              <b-form-group
                class="mb-1"
                label="DPD Pakiautomaat"
                v-if="selectedShippingMethod.type == 'dpd-parcel'"
              >
              <v-select
                v-model="newOrder.dpd_parcel"
                :options="selectDpdParcel"
                :reduce="(item) => item.code"
              ></v-select>
              </b-form-group>
              <b-form-group
                class="mb-1"
                label="Omniva pakiautomaat"
                v-if="selectedShippingMethod.type == 'omniva-parcel'"
              >
                <v-select
                  v-model="newOrder.omniva_parcel"
                  :options="selectOmnivaParcel"
                  :reduce="(item) => item.code"
                ></v-select>
              </b-form-group>
              <b-form-group
                class="mb-1"
                label="SmartPost Pakiautomaat"
                v-if="selectedShippingMethod.type == 'smartpost-parcel'"
              >
                <v-select
                  v-model="newOrder.smartpost"
                  :options="selectSmartpostParcel"
                  :reduce="(item) => item.code"
                ></v-select>
              </b-form-group>

              <b-form-group
                class="mb-1"
                label="SmartPost FI Pakiautomaat"
                v-if="selectedShippingMethod.type == 'smartpost-parcel-fi'"
              >
                <v-select
                  v-model="newOrder.smartpost_fi"
                  :options="selectSmartpostFiParcel"
                  :reduce="(item) => item.code"
                ></v-select>
              </b-form-group>

              <b-form-group
                class="mb-1"
                label="Venipak Pakiautomaat"
                v-if="selectedShippingMethod.type == 'venipak-parcel'"
              >
                <v-select
                  v-model="newOrder.venipak"
                  :options="selectVenipakParcel"
                  :reduce="(item) => item.code"
                ></v-select>
              </b-form-group>

              <template v-if="selectedShippingMethod.type == 'courier'">
                <b-form-group class="mb-1">
                  <b-form-checkbox
                    v-model="newOrder.courier_use_billing_address"
                    :value="true"
                    :unchecked-value="false"
                    :disabled="newOrder.customer_type == 1"
                  >
                    Kasuta arve aadressi
                  </b-form-checkbox>
                </b-form-group>

                <div v-if="!newOrder.courier_use_billing_address">
                  <b-form-group class="mb-1" label="Riik" v-if="countries">
                    <v-select
                      v-model="newOrder.delivery_country"
                      :options="countries"
                      :reduce="(item) => item.code"
                      :clearable="false"
                    ></v-select>
                  </b-form-group>
                  <b-form-group class="mb-1" label="Maakond">
                    <b-form-input
                      v-model="newOrder.courier_county"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-1" label="Linn/Vald">
                    <b-form-input
                      v-model="newOrder.courier_city"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-1" label="Postiindeks">
                    <b-form-input
                      v-model="newOrder.courier_zip"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-1" label="Aadress">
                    <b-form-input
                      v-model="newOrder.courier_address"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </template>
            </div>

            <div class="mt-3">
              <b-form-group class="mb-1" label="PDF Arve kommentaar">
                <b-form-textarea
                  v-model="newOrder.invoice_comment"
                  size="sm"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-card>
            <template #header>
              Tooted
              <b-button
                class="float-right"
                variant="primary"
                size="sm"
                @click.prevent="openSearch"
              >
                Otsi toode
              </b-button>
              <b-button
                class="float-right mr-3"
                variant="outline-primary"
                size="sm"
                @click.prevent="addCustomProduct"
              >
                Lisa custom
              </b-button>
            </template>
            <b-table-simple bordered small v-if="newOrder.products.length">
              <b-thead>
                <b-tr variant="primary">
                  <b-th style="width: 10%;" class="text-center">SKU</b-th>
                  <b-th>Nimi</b-th>
                  <b-th style="width: 10%;" class="text-center">
                    Ostuhind <small>(km-ita)</small>
                  </b-th>
                  <b-th style="width: 10%;" class="text-center">
                    Transpordi hind <small>(km-ita)</small>
                  </b-th>
                  <b-th style="width: 10%;" class="text-center">
                    Hind <small>(km-ita)</small>
                  </b-th>
                  <b-th style="width: 5%;" class="text-center">Kogus</b-th>
                  <b-th style="width: 8%;" class="text-center">
                    Hind <small>(km-iga)</small>
                  </b-th>
                  <b-th style="width: 10%;" class="text-center">Transport</b-th>
                  <b-th style="width: 1%;" class="text-right"></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(product, productKey) in newOrder.products"
                  :key="productKey"
                >
                  <b-td>
                    <b-form-input
                      v-model="product.sku"
                      size="sm"
                    ></b-form-input>
                    <small v-if="product.import_product_stock_id">
                      <strong>
                        Stock id:
                      </strong>
                      {{ product.import_product_stock_id }}
                    </small>
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="product.name"
                      size="sm"
                      class="mb-1"
                    ></b-form-input>
                    <b-form-input
                      v-model="product.ean"
                      placeholder="EAN"
                      size="sm"
                      class="mb-1"
                    ></b-form-input>
                    <b-form-input
                      v-model="product.mpn"
                      placeholder="MPN"
                      size="sm"
                      class="mb-1"
                    ></b-form-input>
                    <b-form-input
                      v-model="product.info"
                      placeholder="Lisainfo"
                      size="sm"
                    ></b-form-input>
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="product.margin_net"
                      type="number"
                      class="text-center"
                      size="sm"
                    ></b-form-input>
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="product.margin_shipping"
                      type="number"
                      class="text-center"
                      size="sm"
                    ></b-form-input>
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="product.price"
                      type="number"
                      class="text-center"
                      size="sm"
                    ></b-form-input>
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="product.quantity"
                      type="number"
                      class="text-center"
                      size="sm"
                    ></b-form-input>
                  </b-td>
                  <b-td class="text-center">
                    {{ (product.price * 1.22) | currency }}&nbsp;&euro;
                  </b-td>
                  <b-td>
                    <date-picker size="sm" v-model="product.delivery_date" />
                  </b-td>
                  <b-td class="text-center">
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      @click.prevent="removeProductLine(productKey)"
                    >
                      <font-awesome-icon icon="times" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tfoot>
                <b-tr :variant="totalMarginClass">
                  <b-td class="text-right font-weight-bold" colspan="7">
                    Marginal kokku:
                  </b-td>
                  <b-td colspan="2">
                    {{ totalMarginal | currency }} &euro;
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="text-right font-weight-bold" colspan="7">
                    Transport:
                  </b-td>
                  <b-td colspan="2"
                    >{{ newOrder.shipping_price | currency }} &euro;</b-td
                  >
                </b-tr>
                <b-tr>
                  <b-td class="text-right font-weight-bold" colspan="7">
                    Kokku ilma km-ita:
                  </b-td>
                  <b-td colspan="2">{{ total | currency }} &euro;</b-td>
                </b-tr>
                <b-tr>
                  <b-td class="text-right font-weight-bold" colspan="7">
                    Käibemaks (22%):
                  </b-td>
                  <b-td colspan="2">{{ vat | currency }} &euro;</b-td>
                </b-tr>
                <b-tr>
                  <b-td class="text-right font-weight-bold" colspan="7">
                    Kokku:
                  </b-td>
                  <b-td colspan="2">{{ totalWithVat | currency }} &euro;</b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
          </b-card>
        </b-col>
      </b-row>
      <div class="text-right mt-3">
        <b-form-checkbox
          v-model="newOrder.send_email"
          :value="true"
          :unchecked-value="false"
        >
          Saada kliendile tellimuse kinnitus ja arve
        </b-form-checkbox>

        <b-form-group>
          <b-form-radio
            v-model="newOrder.order_completed"
            value="waiting-payment"
          >
            Arve ootab tasumist
          </b-form-radio>
          <b-form-radio v-model="newOrder.order_completed" value="processing">
            Töötluses
          </b-form-radio>
          <b-form-radio v-model="newOrder.order_completed" value="completed">
            Lõpetatud
          </b-form-radio>
        </b-form-group>

        <b-form-group label="Kas tellimus on makstud?">
          <b-form-radio-group v-model="newOrder.is_paid">
            <b-form-radio :value="true">Jah</b-form-radio>
            <b-form-radio :value="false">Ei</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-alert class="mt-3" variant="danger" v-if="errors.length" show>
          <ul class="list-unstyled mb-0">
            <li v-for="(error, errorKey) in errors" :key="`e-${errorKey}`">
              {{ error }}
            </li>
          </ul>
        </b-alert>

        <b-button
          class="mt-1"
          variant="success"
          :disabled="newOrder.is_paid === null"
          @click.prevent="submitOrder"
        >
          Kinnita tellimus
        </b-button>

      </div>
      <SearchAndSelectProductStock
        ref="searchModal"
        @selected="addNewSelectedProductStock"
        @selected-product="addNewSelectedProduct"
      />
    </template>
  </div>
</template>

<script>
import SearchAndSelectProductStock from "@/components/SearchAndSelectProductStock";
import RestService from "@/lib/RestService";
import moment from "moment";
import OrdersHeader from "../OrdersHeader";
import CustomerSearchModal from "./CustomerSearchModal";
import { mapActions, mapGetters } from "vuex";
import { errorMessage } from "@/lib/messages";

export default {
  components: {
    SearchAndSelectProductStock,
    OrdersHeader,
    CustomerSearchModal,
  },
  data() {
    return {
      errors: [],
      isLoading: true,
      defaultData: null,
      showCustomerModal: false,
      newOrder: {
        locale: "et",
        customer_type: 1,
        shipping_method: 1,
        payment_method: 1,
        delivery_country: "EE",
        shipping_price: 0,
        einvoice: false,
        send_email: false,
        order_completed: "waiting-payment",
        payment_days: 5,
        is_paid: null,
        user_id: null,

        dpd_parcel: null,
        omniva_parcel: null,
        smartpost: null,
        smartpost_fi: null,
        venipak: null,

        firstname: "Kontori",
        lastname: "Müük",
        email: "arved@mts.ee",
        phone: "+37258608800",

        company_name: null,
        company_code: null,
        company_vat: null,
        country: "EE",
        county: null,
        city: null,
        zip: null,
        address: null,

        invoice_comment: null,

        courier_use_billing_address: false,
        courier_county: null,
        courier_city: null,
        courier_zip: null,
        courier_address: null,

        comments: null,

        products: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      countries: "generic/countries",
      orderStatuses: "generic/orderStatuses",
      languages: "generic/languages",
    }),
    selectedShippingMethod() {
      if (!this.newOrder || !this.defaultData) {
        return null;
      }

      return this.defaultData.shipping_methods.find((s) => s.id == this.newOrder.shipping_method);
    },
    selectLocales() {
      return this.languages.map((locale) => {
        return {
          value: locale.locale,
          text: locale.name,
        };
      });
    },
    selectPayments() {
      return this.defaultData.payment_methods.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
    },
    selectShippings() {
      return this.defaultData.shipping_methods.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
    },
    selectDpdParcel() {
      let data = [];

      for (let property in this.defaultData.dpd_parcel) {
        this.defaultData.dpd_parcel[property].forEach((i) => {
          data.push({
            code: i.id,
            label: i.name,
          });
        })
      }

      return data;
    },
    selectSmartpostParcel() {
      let data = [];

      for (let property in this.defaultData.smartpost) {
        this.defaultData.smartpost[property].forEach((i) => {
          data.push({
            code: i.id,
            label: i.name,
          });
        })
      }

      return data;
    },
    selectOmnivaParcel() {
      let data = [];

      for (let property in this.defaultData.omniva_parcel) {
        this.defaultData.omniva_parcel[property].forEach((i) => {
          data.push({
            code: i.id,
            label: i.name,
          });
        })
      }

      return data;
    },
    selectSmartpostFiParcel() {
      let data = [];

      for (let property in this.defaultData.smartpost_fi) {
        this.defaultData.smartpost_fi[property].forEach((i) => {
          data.push({
            code: i.id,
            label: i.name,
          });
        })
      }

      return data;
    },
    selectVenipakParcel() {
      let data = [];

      for (let property in this.defaultData.venipak_parcel) {
        this.defaultData.venipak_parcel[property].forEach((i) => {
          data.push({
            code: i.id,
            label: i.name,
          });
        })
      }

      return data;
    },
    totalMarginal() {
      let totalMarginal = 0;
      this.newOrder.products.forEach((p) => {
        totalMarginal += (parseFloat(p.price) - (parseFloat(p.margin_net) + parseFloat(p.margin_shipping))) * parseFloat(p.quantity);
      });

      return totalMarginal;
    },
    totalMarginClass() {
      if (this.totalMarginal < 0) {
        return 'danger';
      }

      if (this.totalMarginal == 0) {
        return 'warning';
      }

      return 'success';
    },
    total() {
      let total = 0;
      total += parseFloat(this.newOrder.shipping_price);

      this.newOrder.products.forEach((p) => {
        total += parseFloat(p.price) * parseFloat(p.quantity);
      });

      return total;
    },
    totalWithVat() {
      return this.total * 1.22;
    },
    vat() {
      return this.totalWithVat - this.total;
    },
  },
  mounted() {
    this.fetchCountries();
    this.fetchDefaultData();
  },
  methods: {
    ...mapActions({
      fetchCountries: "generic/fetchCountries",
    }),
    fetchDefaultData() {
      this.isLoading = true;

      let orderId = null;
      if (this.$route.query.orderId) {
        orderId = this.$route.query.orderId;
      }

      RestService.getCreateNewOrderDefaultData({
        orderId: orderId,
      }).then((response) => {
        this.defaultData = response.data.data;
        if (this.defaultData.order) {
          this.setDefaultData();
        }

        this.isLoading = false;
      });
    },
    submitOrder() {
      this.isLoading = true;
      RestService.postCreateNewOrder(this.newOrder)
        .then((response) => {
          this.$router.push({
            name: "order.show",
            params: { orderId: response.data.data.id },
          });
        })
        .catch((error) => {
          if (error.response) {
            this.errors = [];

            for (let e in error.response.data.errors) {
              this.errors = this.errors.concat(error.response.data.errors[e]);
            }

            errorMessage("Viga", error.response.data.message);
          }

          this.isLoading = false;
        });
    },
    removeProductLine(index) {
      this.newOrder.products.splice(index, 1);
    },
    openSearch() {
      this.$refs.searchModal.open();
    },
    addNewSelectedProductStock(payload) {
      let ean = null;
      let mpn = null;
      if (payload.product.codes.length) {
        let eanResult = payload.product.codes.find((c) => c.type == "gtin-14");
        if (eanResult) {
          ean = eanResult.code;
        }
        let mpnResult = payload.product.codes.find((c) => c.type == "mpn");
        if (mpnResult) {
          mpn = mpnResult.code;
        }
      }

      let delivery_date = null;
      if (payload.stock.status && payload.stock.status.delivery_date) {
        delivery_date = moment(payload.stock.status.delivery_date).toISOString();
      }

      this.newOrder.products.push({
        sku: payload.product.sku,
        name: payload.product.name,
        info: null,
        quantity: 1,
        price: payload.stock.manual_price
          ? payload.stock.manual_price
          : payload.stock.price,
        margin_net: payload.stock.net_price,
        margin_shipping: payload.stock.delivery_price,
        import_product_stock_id: payload.stock.id,
        delivery_date: delivery_date,
        ean: ean,
        mpn: mpn,
        supplier_id: payload.stock.supplier_id,
        supplier_name: payload.stock.supplier.name,
        stock_name: payload.stock.supplier.frontend_name,
      });
    },
    addNewSelectedProduct(product) {
      let ean = null;
      let mpn = null;
      if (product.codes.length) {
        let eanResult = product.codes.find((c) => c.type == "gtin-14");
        if (eanResult) {
          ean = eanResult.code;
        }
        let mpnResult = product.codes.find((c) => c.type == "mpn");
        if (mpnResult) {
          mpn = mpnResult.code;
        }
      }

      let delivery_date = null;

      this.newOrder.products.push({
        sku: product.sku,
        name: product.name,
        import_product_stock_id: null,
        info: null,
        quantity: 1,
        price: 0,
        margin_net: 0,
        margin_shipping: 0,
        delivery_date: delivery_date,
        ean: ean,
        mpn: mpn,
      });
    },
    addCustomProduct() {
      this.newOrder.products.push({
        sku: "001",
        ean: null,
        mpn: null,
        name: "Toote nimi",
        info: null,
        quantity: 1,
        price: 0,
        margin_net: 0,
        margin_shipping: 0,
        import_product_stock_id: null,
        delivery_date: moment().toISOString(),
      });
    },
    setDefaultData() {
      if (!this.defaultData.order) {
        return;
      }

      this.newOrder = { ...this.newOrder, ...this.defaultData.order };
    },
    searchCustomer() {
      this.showCustomerModal = true;
    },
    hideCustomerModal() {
      this.showCustomerModal = false;
    },
    assignCustomer(user) {
      this.showCustomerModal = false;
      this.newOrder.user = user;
      this.newOrder.user_id = user.id

      this.newOrder.customer_type = user.person_type;
      this.newOrder.firstname = user.first_name;
      this.newOrder.lastname = user.last_name;
      this.newOrder.email = user.email;
      this.newOrder.phone = user.phone;

      if (user.person_type != 1) {
        this.newOrder.company_name = user.company_name;
        this.newOrder.company_code = user.company_code;
        this.newOrder.company_vat = user.company_vat;
        this.newOrder.country = user.company_address.country;
        this.newOrder.county = user.company_address.county;
        this.newOrder.city = user.company_address.city;
        this.newOrder.zip = user.company_address.zip;
        this.newOrder.address = user.company_address.address;

        this.newOrder.einvoice = user.einvoice;
      }
      
    }
  },
};
</script>
