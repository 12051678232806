<template>
  <div>
    <loader :overlay="true" v-if="isLoading" />

    <b-row class="mb-3">
      <b-form-group class="mr-3" label="Tarnija">
        <b-form-select
          v-if="suppliersSelect.length"
          v-model="selectedStock"
          :options="suppliersSelect"
          size="sm"
        ></b-form-select>
      </b-form-group>
      <b-form-group class="mr-3" label="Tüüp">
        <b-form-radio-group size="sm"  v-model="stockSort">
          <b-form-radio value="1">Otsi lao järgi</b-form-radio>
          <b-form-radio value="2">Otsi imporditud lao järgi</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-form-group class="mr-3" label="Icecat">
        <b-form-radio-group size="sm"  v-model="icecatSort">
          <b-form-radio value="0">Kõik</b-form-radio>
          <b-form-radio value="1">Icecat olemas</b-form-radio>
          <b-form-radio value="2">Icecat puudu</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-form-group class="mr-3" label="Lao staatus" style="max-width: 300px;">
        <v-select
          v-if="stockStatusesSelectLabel.length"
          v-model="selectedStockStatus"
          :options="stockStatusesSelectLabel"
          :reduce="(item) => item.value"
          multiple
          clearable
          searchable
        ></v-select>
      </b-form-group>
      <b-form-group class="mr-3" label="Tootjad">
        <b-form-tags
          size="sm"
          v-model="manufacturers"
        ></b-form-tags>
      </b-form-group>
      <b-form-group class="mr-3 pt-4">
        <b-form-checkbox v-model="needsReview" :unchecked-value="0" :value="1" switch>
          Vajab tähelepanu
        </b-form-checkbox>
      </b-form-group>
      <b-form-group class="mr-3 pt-3">
        <b-button variant="primary" size="sm" @click.prevent="fetchNewStocks">Otsi</b-button>
      </b-form-group>
    </b-row>

    <b-row v-if="products">
      <b-col>
        <b-table-simple bordered responsive small v-if="products.length">
          <b-tr variant="primary">
            <b-th style="width: 1%"></b-th>
            <b-th style="width: 5%"></b-th>
            <b-th style="width: 50%">Nimi</b-th>
            <b-th>Laod</b-th>
            <b-th style="width: 5%"></b-th>
          </b-tr>
          <ProductRow
            v-for="product in products"
            :product="product"
            :key="product.id"
            @update="handleProductUpdate"
          />
        </b-table-simple>
        <b-alert v-else variant="warning" show>
          Tulemusi ei leitud!
        </b-alert>

        <template v-if="pagination">
          <small>Tooteid kokku: {{ pagination.total }}</small>
          <b-pagination
            class="mt-3"
            v-model="currentPage"
            :total-rows="pagination.total"
            :per-page="pagination.per_page"
            @change="pageChange"
          ></b-pagination>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
import ProductRow from "../productList/ProductRow";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    type: {
      type: String,
      default: "ordered",
    },
  },
  components: {
    ProductRow,
  },
  data() {
    return {
      isLoading: false,
      selectedStock: 1,
      stockSort: 1,
      icecatSort: 0,
      needsReview: 0,
      manufacturers: [],
      selectedStockStatus: [],
      products: null,
      pagination: null,
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      suppliersSelect: "generic/suppliersSelect",
      stockStatusesSelectLabel: "generic/stockStatusesSelectLabel",
    }),
  },
  mounted() {
    this.fetchSuppliers();
    this.fetchProductStockStatuses();
  },
  methods: {
    ...mapActions({
      fetchSuppliers: "generic/fetchSuppliers",
      fetchProductStockStatuses: "generic/fetchProductStockStatuses",
    }),
    fetchNewStocks() {
      this.fetchProducts(1);
    },
    fetchProducts(page = 1) {
      if (!this.selectedStock) {
        return;
      }

      this.isLoading = true;
      RestService.getProductsViaStock({
        page: page,
        stock_id: this.selectedStock,
        stock_type: this.stockSort,
        icecat_type: this.icecatSort,
        needs_review: this.needsReview,
        manufacturers: this.manufacturers,
        stock_status_ids: this.selectedStockStatus,
      })
        .then((response) => {
          this.products = response.data.data;
          this.pagination = response.data.meta.pagination;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isLoading = false;
        });
    },
    pageChange(page) {
      this.fetchProducts(page);
    },
    handleProductUpdate(product) {
      const pindex = this.products.findIndex((p) => p.id == product.id);
      if (pindex > -1) {
        this.$set(this.products, pindex, product);
      }
    }
  },
};
</script>
