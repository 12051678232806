export default {
  categories: [],
  selectedCategory: null,
  categoryProducts: null,
  categoryProductsMeta: null,
  isProductsLoading: false,
  selectedProductIds: [],
  filters: {
    per_page: 30,
    only_with_stocks: 0,
    only_without_icecat: 0,
    needs_review: 0,
    parcel_machine: 0,
  },
};
