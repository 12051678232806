<template>
  <div>
    <template v-if="offers === null">
      <loader />
    </template>
    <template v-else-if="!offers.length">
      <b-alert variant="warning" show>
        Pakkumisi ei leitud.
      </b-alert>
    </template>
    <template v-else>
      <OfferItem v-for="offer in offers" :key="offer.id" :offer="offer" />
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import OfferItem from "./OfferItem.vue";

export default {
  props: {
    statusId: {
      default: null,
    },
  },
  components: {
    OfferItem,
  },
  mounted() {
    this.fetchOffers();
  },
  data() {
    return {
      offers: null,
    };
  },
  computed: {},
  methods: {
    fetchOffers() {
      RestService.getOffers().then((response) => {
        this.offers = response.data.data;
      });
    },
  },
  watch: {
    statusId() {
      (this.offers = null), this.fetchOffers();
    },
  },
};
</script>
