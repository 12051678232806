import Vue from "vue";

export const CategoryMapper = Vue.component(
  "category-mapper",
  require("./CategoryMapper.vue").default
);

export const CategoryMaps = Vue.component(
  "category-maps",
  require("./CategoryMaps.vue").default
);

export const AllMaps = Vue.component(
  "category-all-maps",
  require("./AllMaps.vue").default
);

export const IcecatMaps = Vue.component(
  "category-icecat-maps",
  require("./IcecatMaps.vue").default
);
