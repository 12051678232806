<template>
  <div>
    <strong>Eeldatav tarne: </strong>
    <span
      :class="isRedEstimatedDeliveryClass"
      v-if="order.estimated_delivery_date"
    >
      {{ order.estimated_delivery_date | moment("DD.MM.YYYY") }}
    </span>
    <span class="badge badge-danger" v-else>Puudub</span>
    <a href="#" class="pl-1" @click.prevent="showModal = true">
      <small>Muuda</small>
    </a>

    <b-modal v-model="showModal" hide-footer title="Muuda eeldatavat tarneaega">
      <loader :overlay="true" v-if="isModalLoading" />
      <b-form-group label="Eeldatav tarne:">
        <date-picker size="sm" v-model="form.estimated_delivery_date" />
      </b-form-group>

      <b-form-group class="mb-1" label="Kommentaar">
        <b-form-textarea
          v-model="form.comment"
          rows="2"
          size="sm"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group class="mb-1">
        <b-form-checkbox
          v-model="form.notify"
          :value="true"
          :unchecked-value="false"
        >
          Teavita klienti
        </b-form-checkbox>
      </b-form-group>

      <div class="text-right">
        <b-button variant="success" size="sm" @click.prevent="saveDate"
          >Muuda</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModalLoading: false,
      showModal: false,
      form: {
        estimated_delivery_date: null,
        comment:
          "Teie tellimus viibib tarne probleemide tõttu.\nVabandame ebamugavuste pärast!",
        notify: false,
      },
    };
  },
  computed: {
    isRedEstimatedDeliveryClass() {
      if (!this.order) {
        return "";
      }

      let date = moment(this.order.estimated_delivery_date);
      let now = moment();

      if (date.isBefore(now)) {
        return "badge badge-danger";
      }

      return "";
    },
  },
  mounted() {
    this.setDeliveryDate();
  },
  methods: {
    saveDate() {
      this.isModalLoading = true;
      RestService.postSaveOrderDeliveryDate(this.order.id, this.form)
        .then((response) => {
          this.$emit("stopLoading", response.data.data);

          this.form.comment =
            "Teie tellimus viibib tarne probleemide tõttu.\nVabandame ebamugavuste pärast!";
          this.form.notify = false;

          this.isModalLoading = false;
          this.showModal = false;
        })
        .catch((error) => {
          this.isModalLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    setDeliveryDate() {
      this.form.estimated_delivery_date = this.order.estimated_delivery_date;
    },
  },
  watch: {
    order: {
      deep: true,
      handler() {
        this.setDeliveryDate();
      },
    },
  },
};
</script>
