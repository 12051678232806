<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      Lisa uus tarnija
    </h2>

    <b-row>
      <b-col md="6">
        <b-card header="Tarnija info">
          <b-form-group class="mb-1" label="Nimi">
            <b-form-input
              v-model="form.name"
              size="sm"
              :state="getFieldStateFromErrors('name')"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ getFieldError("name") }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="mb-3" label="Lühend">
            <b-form-input
              v-model="form.frontend_name"
              size="sm"
              :state="getFieldStateFromErrors('frontend_name')"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ getFieldError("frontend_name") }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="mb-1">
            <b-form-checkbox v-model="form.is_hidden" switch>
              Tarnija on peidetud?
            </b-form-checkbox>
          </b-form-group>

          <b-button
            variant="success"
            class="float-right"
            @click.prevent="saveSupplier"
          >
            Salvesta
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6"> </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,
      form: {
        name: null,
        frontend_name: null,
        is_hidden: false,
      },
    };
  },
  methods: {
    saveSupplier() {
      this.isLoading = true;

      let payload = this.form;

      RestService.postSaveNewSupplier(payload)
        .then((response) => {
          this.$router.push({
            name: "suppliers.edit",
            params: { supplierId: response.data.data.id },
          });
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
  },
};
</script>
