<template>
  <b-tr :variant="rowVariant">
    <b-td>
      <span v-b-tooltip.hover :title="stock.supplier.name">
        {{ stock.supplier.frontend_name }}
      </span>
    </b-td>
    <b-td>{{ stock.in_stock }}</b-td>
    <b-td>
      <template v-if="stock.manual_price">
        {{ stock.manual_price | currency }}
        <small style="text-decoration: line-through;">
          {{ stock.price | currency }}
        </small>
      </template>
      <template v-else>
        {{ stock.price | currency }}
      </template>
    </b-td>
    <b-td class="text-center">
      <StockRowHistory :stock="stock" />
    </b-td>
  </b-tr>
</template>

<script>
import StockRowHistory from "./StockRowHistory.vue"

export default {
  components: {
    StockRowHistory
  },
  props: {
    stock: {
      type: Object,
    },
  },
  data() {
    return {
      showHistoryModal: false,
    };
  },
  computed: {
    rowVariant() {
      if (this.stock.is_disabled) {
        return "danger";
      }

      return "";
    },
  },
};
</script>
