export const categories = (state) => {
  return state.categories;
};

export const selectedCategory = (state) => {
  return state.selectedCategory;
};

export const categoryProducts = (state) => {
  return state.categoryProducts;
};

export const categoryProductsMeta = (state) => {
  return state.categoryProductsMeta;
};

export const isProductsLoading = (state) => {
  return state.isProductsLoading;
};

export const selectedProductIds = (state) => {
  return state.selectedProductIds;
};

export const filters = (state) => {
  return state.filters;
};

export const perPage = (state) => {
  return state.filters.perPage;
};