import {
  GroupedOrders,
  SearchOrders,
  WaitingForOffice,
  AttentionNeededOrders,
  Orders,
  Order,
  NewOrder,
  MarginalReport,
  MyOrders,
  UnPaidOrders,
  RmaList,
  RmaShow,
  NotSentToMeritOrders,
} from "../components";

export default [
  {
    path: "/orders",
    component: Orders,
    name: "orders",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/mine",
    component: MyOrders,
    name: "orders.mine",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/unpaid",
    component: UnPaidOrders,
    name: "orders.unpaid",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/not-sent-merit",
    component: NotSentToMeritOrders,
    name: "orders.not-sent-merit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/s/:statusTypeId",
    component: GroupedOrders,
    name: "orders.status.type",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/search",
    component: SearchOrders,
    name: "orders.search",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/waiting/office",
    component: WaitingForOffice,
    name: "orders.waiting-office",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/attention/needed",
    component: AttentionNeededOrders,
    name: "orders.attention",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/new",
    component: NewOrder,
    name: "order.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/reports/marginal",
    component: MarginalReport,
    name: "order.reports.marginal",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/rma",
    component: RmaList,
    name: "order.rma.list",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/rma/:rmaId",
    component: RmaShow,
    name: "order.rma.show",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/:orderId",
    component: Order,
    name: "order.show",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
