<template>
  <b-dropdown
    :variant="variant"
    size="sm"
    dropleft
    no-caret
    :text="selectedName"
    v-if="adminUsers.length"
  >
    <b-dropdown-item :active="!value" @click="changeValue(null)">
      Määramata
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item
      v-for="user in adminUsers"
      :key="user.id"
      :active="user.id == value"
      @click="changeValue(user.id)"
    >
      {{ user.name }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {},
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/user",
      adminUsers: "generic/adminUsers",
    }),
    selectedName() {
      if (!this.value) {
        return "Määramata";
      }

      let user = this.adminUsers.find((u) => u.id == this.value);

      if (!user) {
        return "Määramata";
      }

      return user.name;
    },
    isMe() {
      if (!this.value) {
        return false;
      }

      if (this.value != this.currentUser.data.id) {
        return false;
      }

      return true;
    },
    variant() {
      if (!this.value) {
        return "secondary";
      }

      if (this.isMe) {
        return "primary";
      }

      return "warning";
    },
  },
  created() {
    if (!this.adminUsers.length) {
      this.fetchAdminUsers();
    }
  },
  methods: {
    ...mapActions({
      fetchAdminUsers: "generic/fetchAdminUsers",
    }),
    changeValue(userId) {
      if (this.value == userId) {
        return;
      }
      this.$emit("change", userId);
    },
  },
};
</script>
