var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative"},[(_vm.isLoading)?_c('loader',{attrs:{"overlay":true}}):_vm._e(),_c('h1',[_vm._v(" Tarnijad "),_c('router-link',{staticClass:"btn btn-primary btn-sm",attrs:{"to":{
        name: 'suppliers.new',
      }}},[_vm._v(" Lisa uus ")])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-table-simple',{staticClass:"mb-0",attrs:{"bordered":"","responsive":"","small":""}},[_c('b-tr',{attrs:{"variant":"primary"}},[_c('b-th',[_vm._v(" Nimi ")]),_c('b-th',{staticClass:"text-center",staticStyle:{"width":"15%"}},[_vm._v(" Peidetud ")]),_c('b-th',{staticStyle:{"width":"15%"}})],1),_vm._l((_vm.suppliers),function(supplier){return _c('b-tr',{key:supplier.id},[_c('b-td',[_c('b-badge',[_vm._v(" "+_vm._s(supplier.frontend_name)+" ")]),_c('router-link',{attrs:{"to":{
                name: 'suppliers.edit',
                params: { supplierId: supplier.id },
              }}},[_vm._v(" "+_vm._s(supplier.name)+" ")])],1),_c('b-td',{staticClass:"text-center"},[(supplier.is_hidden)?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":"check"}}):_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":"times"}})],1),_c('b-td',{staticClass:"text-right"},[_c('router-link',{staticClass:"btn btn-warning btn-sm mr-2",attrs:{"to":{
                name: 'suppliers.edit',
                params: { supplierId: supplier.id },
              }}},[_vm._v(" Muuda ")])],1)],1)})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }