import { ProductCategories } from "../components";

export default [
  {
    path: "/product-categories",
    component: ProductCategories,
    name: "product-categories",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
