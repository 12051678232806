<template>
  <div class="mt-3">
    <b-button variant="outline-primary" size="sm" @click.prevent="openModal">
      Vaata konkurentide hindu
    </b-button>

    <b-modal
      id="product-competitors"
      centered
      hide-footer
      size="lg"
      title="Konkurentide info"
      v-model="showModal"
    >
      <loader :overlay="false" v-if="isLoading" />
      <div v-else>
        <b-alert variant="warning" v-if="productInfo === null">
          Tulemusi ei leitud
        </b-alert>
        <div v-else>
          <div class="float-left">
            Info viimati uuendatud:
            <strong>{{ productInfo.last_imported_at }}</strong>
          </div>
          <b-button
            class="float-right"
            variant="primary"
            size="sm"
            target="_blank"
            :href="productInfo.hv_url"
          >
            Ava hinnavaatluses
          </b-button>

          <div class="clearfix"></div>

          <b-table-simple responsive bordered small class="mt-3">
            <b-thead>
              <b-tr variant="primary">
                <b-th>Müüja</b-th>
                <b-th>Hind</b-th>
                <b-th>Saadavus</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="seller in productInfo.sellers" :key="seller.id">
                <b-td>{{ seller.name }}</b-td>
                <b-td>{{ seller.price | currency }} &euro;</b-td>
                <b-td>{{ seller.availability }}</b-td>
                <b-td class="text-right">
                  <b-button
                    variant="outline-primary"
                    size="sm"
                    target="_blank"
                    :href="seller.url"
                  >
                    Ava
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";

export default {
  props: {
    productId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      productInfo: null,
    };
  },
  methods: {
    fetchProductInfo() {
      this.isLoading = true;
      RestService.getDataCollectionProductInfo(this.productId)
        .then((response) => {
          this.productInfo = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    openModal() {
      this.showModal = true;
      this.fetchProductInfo();
    },
  },
};
</script>
