<template>
  <div class="position-relative">
    <loader :overlay="true" v-if="isLoading" />
    <h1>
      Koosta uus toodete merge
    </h1>

    <b-row>
      <b-col md="6">
        <b-card header="Koosta uus merge">
          <b-form-group
            class="mb-1"
            label="Toote id"
            v-for="(id, idKey) in form.productIds"
            :key="idKey"
          >
            <b-form-input v-model="id.value" size="sm"></b-form-input>
          </b-form-group>
          <div class="mt-4">
            <b-button
              variant="outline-primary"
              size="sm"
              @click.prevent="addNewField"
            >
              Lisa uus id väli
            </b-button>
            <b-button
              class="float-right"
              variant="success"
              size="sm"
              @click.prevent="createNewMerge"
            >
              Loo uus merge
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";

export default {
  data() {
    return {
      isLoading: false,
      form: {
        productIds: [
          {
            value: null,
          },
        ],
      },
    };
  },
  methods: {
    addNewField() {
      this.form.productIds.push({
        value: null,
      });
    },
    createNewMerge() {
      this.isLoading = true;

      let productIds = this.form.productIds.map((p) => p.value);
      productIds = productIds.filter((id) => id);

      if (productIds.length < 2) {
        errorMessage("Viga!", "Vähemalt 2 toodet peab olema lisatud!");
        this.isLoading = false;
        return;
      }

      RestService.postCreateProductMerge({
        ids: productIds,
      })
        .then(() => {
          this.$router.push({
            name: "products-merge",
          });
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
  },
};
</script>
