<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Toodete kategoriseerimine
    </h1>

    <b-row>
      <b-col md="4">
        <b-list-group>
          <b-list-group-item v-if="selectedCategory" active>
            <b-button
              class="mr-3"
              variant="warning"
              size="sm"
              @click.prevent="goBack"
            >
              Tagasi
            </b-button>
            {{ selectedCategory.name }}
          </b-list-group-item>
          <b-list-group-item
            class="p-2"
            v-for="category in categories"
            :key="category.id"
          >
            <a href="#" @click.prevent="openCategory(category.id)">
              {{ category.name }}
            </a>
            <b-button
              v-if="selectedProducts.length > 0"
              class="float-right"
              variant="outline-primary"
              size="sm"
              @click.prevent="addSelectedProductsToCategory(category.id)"
            >
              Lisa valitud
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col md="8">
        <loader v-if="!isLoading && isProductsLoading" :overlay="true" />
        <small>Tooteid valitud: {{ selectedProducts.length }}</small>
        <a class="d-inline-block ml-2" href="#" @click.prevent="clearSelected"
          >x</a
        >

        <b-form-group class="mb-1">
          <b-form-input
            class="form-control"
            v-model="searchKey"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-table-simple sticky-header="1000px" bordered responsive small>
          <b-thead>
            <b-tr variant="primary">
              <b-th class="text-center" style="width: 1%;">
                <span style="cursor: pointer;" @click.prevent="selectAll">
                  <font-awesome-icon
                    class="check-icon"
                    :icon="['far', 'check-square']"
                  />
                </span>
              </b-th>
              <b-th style="width: 5%;">Pilt</b-th>
              <b-th>Nimi</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="product in filteredProducts" :key="product.id">
              <b-td style="vertical-align:middle;">
                <span
                  class="checkable"
                  @click.prevent="selectProduct(product.id)"
                >
                  <font-awesome-icon
                    class="check-icon"
                    :icon="['far', 'check-square']"
                    v-if="isProductSelected(product.id)"
                  />
                  <font-awesome-icon
                    class="check-icon inactive"
                    :icon="['far', 'square']"
                    v-else
                  />
                </span>
              </b-td>
              <b-td>
                <!-- <img
                  src="https://via.placeholder.com/300"
                  alt=""
                  style="max-width: 60px;"
                /> -->
                <img
                  v-bind:src="product.main_image"
                  style="max-width: 60px;"
                  alt=""
                  class="img-fluid"
                />
              </b-td>
              <b-td>
                {{ product.name }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-pagination
          v-if="products_pagination"
          v-model="currentPage"
          :total-rows="products_pagination.total"
          :per-page="products_pagination.per_page"
          @change="fetchNewPage"
          class="mb-0"
        ></b-pagination>
        <small v-if="products_pagination">
          <strong>Kokku tooteid: </strong>
          {{ products_pagination.total }}
        </small>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
export default {
  data() {
    return {
      isLoading: false,
      isProductsLoading: false,
      categories: [],
      products: [],
      products_pagination: null,
      selectedCategory: null,
      currentPage: 1,
      selectedProducts: [],
      searchKey: null,
    };
  },
  computed: {
    filteredProducts() {
      let results = this.products.filter((p) => {
        if (!this.searchKey) {
          return true;
        }

        return p.name.toLowerCase().indexOf(this.searchKey.toLowerCase()) != -1;
      });

      return results;
    },
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    fetchCategories(categoryId = null) {
      this.isLoading = true;
      RestService.getCategoriesById({
        categoryId: categoryId,
      })
        .then((response) => {
          this.categories = response.data.data;
          this.selectedCategory = response.data.meta.selected;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga", error.response.data.message);
          }
        });
    },
    fetchCategoryProducts(categoryId, page = 1) {
      this.isProductsLoading = true;
      RestService.getCategoryProductsSimple(categoryId, page, {
        per_page: 500,
      })
        .then((response) => {
          this.products = response.data.data;
          this.products_pagination = response.data.meta.pagination;
          this.searchKey = null;
          this.isProductsLoading = false;
        })
        .catch((error) => {
          this.isProductsLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga", error.response.data.message);
          }
        });
    },
    openCategory(id) {
      this.currentPage = 1;
      this.fetchCategories(id);
      if (id) {
        this.fetchCategoryProducts(id);
      } else {
        this.products = [];
      }
    },
    goBack() {
      this.openCategory(this.selectedCategory.parent_id);
    },
    fetchNewPage(page) {
      this.fetchCategoryProducts(this.selectedCategory.id, page);
    },
    selectProduct(productId) {
      if (!this.selectedProducts.includes(productId)) {
        this.selectedProducts.push(productId);
        return;
      }

      const index = this.selectedProducts.indexOf(productId);
      if (index > -1) {
        this.selectedProducts.splice(index, 1);
      }
    },
    selectAll() {
      this.filteredProducts.forEach((item) => {
        if (!this.selectedProducts.includes(item.id)) {
          this.selectedProducts.push(item.id);
          return;
        }
      });
    },
    clearSelected() {
      this.selectedProducts = [];
    },
    isProductSelected(productId) {
      if (!this.selectedProducts.includes(productId)) {
        return false;
      }

      return true;
    },
    addSelectedProductsToCategory(categoryId) {
      let callback = () => {
        this.isLoading = true;
        return RestService.postCategoryProductsMove({
          old_category_id: this.selectedCategory.id,
          new_category_id: categoryId,
          product_ids: this.selectedProducts,
        })
          .then(() => {
            this.isLoading = false;
            this.selectedProducts = [];
            this.searchKey = null;
            this.fetchCategoryProducts(
              this.selectedCategory.id,
              this.currentPage
            );
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.data.message) {
              errorMessage("Viga", error.response.data.message);
            }
          });
      };

      this.$confirm({
        message: `Kas oled kindel?`,
        button: {
          no: "Ei",
          yes: "Jah",
        },
        callback: (confirm) => {
          if (confirm) {
            callback();
          }
        },
      });
    },
  },
};
</script>
