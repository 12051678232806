<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Emailid
      <router-link
        class="btn btn-primary btn-sm"
        :to="{
          name: 'email.new',
        }"
      >
        Lisa uus
      </router-link>
    </h1>

    <b-table-simple bordered responsive small>
      <b-tr variant="primary">
        <b-th>Nimi</b-th>
        <b-th>Pealkiri</b-th>
        <b-th>Saatja</b-th>
        <b-th></b-th>
      </b-tr>
      <b-tr v-for="email in emails" :key="email.id">
        <b-td>
          {{ email.name }}
        </b-td>
        <b-td>
          {{ email.title }}
        </b-td>
        <b-td>
          <span v-if="email.from">
            {{ email.from }}
          </span>
          <span v-else>
            {{ email.default_from }}
          </span>
        </b-td>
        <b-td class="text-right">
          <router-link
            class="btn btn-warning btn-sm mr-2"
            :to="{
              name: 'email.edit',
              params: { emailId: email.id },
            }"
          >
            Muuda
          </router-link>
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
export default {
  data() {
    return {
      isLoading: false,
      emails: [],
    };
  },
  computed: {},
  mounted() {
    this.fetchEmails();
  },
  methods: {
    fetchEmails() {
      this.isLoading = true;
      return RestService.getEmails().then((response) => {
        this.emails = response.data.data;
        this.isLoading = false;
      });
    },
  },
};
</script>
