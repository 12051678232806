<template>
  <div class="login-box position-relative">
    <loader :overlay="true" v-if="isLoading" />
    <MTLogo class="logo" v-if="!isEemeli"></MTLogo>
    <EemeliLogo class="logo" v-else></EemeliLogo>

    <b-alert v-if="errorMessage" variant="danger" show>
      {{ errorMessage }}
    </b-alert>
    <template v-if="otp">
      <form @submit.prevent="submit">
        <b-form-group class="mb-1" label="2FA kood:">
          <b-form-input v-model="form.otp"></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="mt-green" block>Valideeri</b-button>
      </form>
    </template>

    <template v-else>
      <b-form @submit.prevent="submit">
        <b-form-group class="mb-1" label="Email:">
          <b-form-input v-model="form.email"></b-form-input>
        </b-form-group>

        <b-form-group class="mb-3" label="Parool:">
          <b-form-input type="password" v-model="form.password"></b-form-input>
        </b-form-group>

        <b-form-group class="mb-3">
          <b-form-checkbox v-model="form.remember" switch>
            Hoia mind sisselogituna
          </b-form-checkbox>
        </b-form-group>

        <b-button type="submit" variant="mt-green" block>Logi sisse</b-button>
      </b-form>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MTLogo from "@/components/MTLogo";
import EemeliLogo from "@/components/EemeliLogo";

export default {
  components: {
    MTLogo,
    EemeliLogo,
  },
  data() {
    return {
      isLoading: false,
      otp: false,
      errorType: null,
      errorMessage: null,
      form: {
        email: null,
        password: null,
        remember: false,
        otp: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      isEemeli: "global/isEemeli",
    }),
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    async submit() {
      this.isLoading = true;
      this.errorType = null;
      this.errorMessage = null;

      this.login({
        payload: this.form,
      }).catch((e) => {
        this.isLoading = false;
        switch (e.response.data.error_type) {
          case "REQUIRES_OTP":
            this.otp = true;
            this.errorType = e.response.data.error_type;
            this.errorMessage = null;
            break;
          default:
            this.otp = false;
            this.form.otp = null;
            this.errorType = e.response.data.error_type;
            this.errorMessage = e.response.data.message;
            break;
        }
      });
    },
  },
};
</script>
