<template>
  <div class="connection">
    <b-card
      header-bg-variant="danger"
      header-text-variant="white"
      border-variant="danger"
      header="Connection lost"
      class="connection-box text-center"
    >
      <b-card-text>
        Connection to server is lost!<br />
        <b-button
          :disabled="isLoading"
          variant="primary"
          class="mt-3"
          @click.prevent="tryConnection"
        >
          <b-spinner small type="grow" v-if="isLoading"></b-spinner>
          Try again
        </b-button>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      doHeartBeat: "global/doHeartBeat",
    }),
    tryConnection() {
      this.isLoading = true;
      this.doHeartBeat().then(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.connection {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  top: 0;
  bottom: 0;
  padding: 15px;

  .connection-box {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 10vh;
  }
}
</style>
