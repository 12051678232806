import RestService from "@/lib/RestService";

export const getUsers = ({ commit }, payload) => {
  return RestService.getUsers(payload).then((response) => {
    commit("SET_USERS", response.data.data);
    commit("SET_USERS_META", response.data.meta);

    return response;
  });
};
