<template>
  <div>
    <strong>Tarnemeetod:</strong>
    {{ order.shipping_method_name }}
    <a href="#" class="pl-1" @click.prevent="showModal = true">
      <small>Muuda</small>
    </a>
    <br />
    <template v-if="order.delivery_data.additional_data.country">
      <strong>Tarne riik:</strong>
      {{ order.delivery_data.additional_data.country }}<br />
    </template>

    <b-modal v-model="showModal" hide-footer title="Transport">
      <loader :overlay="true" v-if="isModalLoading" />

      <b-form-group class="mb-1" label="Riik" v-if="countries">
        <v-select
          v-model="form.delivery_country"
          :options="countries"
          :reduce="(item) => item.code"
          :clearable="false"
        ></v-select>
      </b-form-group>

      <b-form-group class="mb-1" label="Tarnemeetod">
        <b-form-select
          v-model="form.shipping_method"
          size="sm"
          :options="selectShippingMethods"
        >
        </b-form-select>
      </b-form-group>

      <template v-if="selectedShippingMethod">
        <ShippingMethodData v-model="form.courier_data" :method="selectedShippingMethod" v-if="selectedShippingMethod.type == 'courier'" />
        <ShippingMethodData v-model="form.dpd_parcel" :method="selectedShippingMethod" v-if="selectedShippingMethod.type == 'dpd-parcel'" />
        <ShippingMethodData v-model="form.smartpost" :method="selectedShippingMethod" v-if="selectedShippingMethod.type == 'smartpost-parcel'" />
        <ShippingMethodData v-model="form.smartpost_fi" :method="selectedShippingMethod" v-if="selectedShippingMethod.type == 'smartpost-parcel-fi'" />
        <ShippingMethodData v-model="form.omniva_parcel" :method="selectedShippingMethod" v-if="selectedShippingMethod.type == 'omniva-parcel'" />
        <ShippingMethodData v-model="form.venipak" :method="selectedShippingMethod" v-if="selectedShippingMethod.type == 'venipak-parcel'" />
      </template>

      <div class="mt-5">
        <b-alert variant="danger" show v-if="errors">
          <span v-for="(error, errorKey) in errors" :key="errorKey">
            {{ error[0] }} <br>
          </span>
        </b-alert>
        <b-button block variant="success" size="sm" @click.prevent="updateDeliveryData">
          Muuda
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ShippingMethodData from "@/components/ShippingMethodData";
import RestService from '@/lib/RestService';
import { errorMessage } from '@/lib/messages';

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    ShippingMethodData
  },
  data() {
    return {
      isModalLoading: false,
      showModal: false,
      errors: null,
      form: {
        delivery_country: "EE",
        shipping_method: null,
        dpd_parcel: null,
        omniva_parcel: null,
        smartpost: null,
        smartpost_fi: null,
        venipak: null,
        courier_use_billing_address: false,
        courier_data: {
          courier_county: null,
          courier_city: null,
          courier_zip: null,
          courier_address: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      countries: "generic/countries",
      shippingMethods: "generic/shippingMethods",
    }),
    selectShippingMethods() {
      if (!this.shippingMethods) {
        return [];
      }

      return this.shippingMethods.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
    },
    selectedShippingMethod() {
      if (!this.shippingMethods) {
        return null;
      }

      if (!this.form.shipping_method) {
        return null;
      }

      let method = null;
      this.shippingMethods.forEach(item => {
        if (item.id == this.form.shipping_method) {
          method =  item;
        }
      });

      return method;
    }
  },
  mounted() {
    this.isModalLoading = true;
    this.fetchShippingMethods().then(() => {
      this.setCurrentOrderData();
      this.isModalLoading = false;
    });
  },
  methods: {
    ...mapActions({
      fetchShippingMethods: "generic/fetchShippingMethods"
    }),
    setCurrentOrderData() {
      this.form.delivery_country = this.order.delivery_data.additional_data.country_code
      this.form.shipping_method = this.order.shipping_method_id

      this.shippingMethods.forEach(item => {
        if (item.id == this.order.shipping_method_id) {
          if (item.type == 'courier') {
            this.form.courier_data = {
              courier_county: this.order.delivery_data.additional_data.county,
              courier_city: this.order.delivery_data.additional_data.city,
              courier_zip: this.order.delivery_data.additional_data.zip,
              courier_address: this.order.delivery_data.additional_data.address,
            }
          } else if (item.type == 'dpd-parcel') {
            this.form.dpd_parcel = this.order.delivery_data.additional_data.id;
          } else if (item.type == 'smartpost-parcel') {
            this.form.smartpost = this.order.delivery_data.additional_data.id;
          } else if (item.type == 'smartpost-parcel-fi') {
            this.form.smartpost_fi = this.order.delivery_data.additional_data.id;
          } else if (item.type == 'omniva-parcel') {
            this.form.omniva_parcel = this.order.delivery_data.additional_data.id;
          }
        }
        
      });

    },
    updateDeliveryData() {
      this.isModalLoading = true;
      this.errors = null;
      RestService.postUpdateOrderShippingInformation(this.order.id, {
        ...this.form, ...this.form.courier_data
      }).then((response) => {
        this.showModal = false;
        this.$emit('stopLoading', response.data.data);
        this.isModalLoading = false;
      }).catch((error) => {
        this.isModalLoading = false;
        if (error.response.data.message) {
          errorMessage("Viga!", error.response.data.message);
        } else {
          errorMessage("Viga!", error);
        }

        if (error.response.data.errors) {
          this.errors = error.response.data.errors;
        }
      });
    },
  },
  watch: {
    order: {
      deep: true,
      handler() {
        this.setCurrentOrderData();
      },
    },
  },
};
</script>
