<template>
  <b-col md="3">
    <b-form-group :label="item.locale" class="mb-0">
      <b-overlay :show="isLoading">
        <b-form-textarea
          :id="`${item.key}-${item.locale}`"
          v-model="item.value"
          @blur="saveTranslation(item)"
        ></b-form-textarea>
      </b-overlay>
    </b-form-group>
    <b-tooltip
      :show.sync="showMessage"
      :target="`${item.key}-${item.locale}`"
      placement="top"
      variant="success"
      triggers="manual"
    >
      Tõlge salvestatud!
    </b-tooltip>
  </b-col>
</template>

<script>
import RestService from "@/lib/RestService";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      showMessage: false,
    };
  },
  methods: {
    saveTranslation(item) {
      this.isLoading = true;
      RestService.postSaveStringTranslations(item).then(() => {
        this.showMessage = true;
        this.isLoading = false;

        setTimeout(() => {
          this.showMessage = false;
        }, 2000);
      });
    },
  },
};
</script>
