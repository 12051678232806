<template>
  <div :class="{ 'd-flex': user.authenticated }" id="app">
    <vue-confirm-dialog></vue-confirm-dialog>
    <template v-if="isPreLoading">
      <ClipLoader
        class="preloader"
        :loading="true"
        :color="color"
        :size="size"
      ></ClipLoader>
    </template>
    <template v-else>
      <template v-if="user.authenticated">

        <template v-if="user.data.is_limited">
        <div class="container-fluid">
          <div class="limited-access my-5">
            See kasutaja on limiteeritud õigustega! <br>
            <b-button variant="outline-primary" size="sm" @click.prevent="handleLogout">Logi välja</b-button>
          </div>
        </div>
        </template>
        <template v-else>
          <Connection v-if="!connection" />
            <Sidebar></Sidebar>

            <div id="page-content-wrapper">
              <Navbar></Navbar>
              <div class="container-fluid">
                <b-row>
                  <b-col>
                    <b-card class="content" no-body>
                      <router-view></router-view>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
            </div>
        </template>
      </template>
      <template v-else>
        <router-view></router-view>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import ClipLoader from "vue-spinner/src/ClipLoader";
import Connection from "./components/Connection";

export default {
  components: {
    ClipLoader,
    Sidebar,
    Navbar,
    Connection,
  },
  data() {
    return {
      color: "#7a9b09",
      size: "100px",
    };
  },
  computed: {
    ...mapGetters({
      isPreLoading: "global/isPreLoading",
      connection: "global/connection",
      user: "auth/user",
    }),
  },
  created() {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.setTabState(false);
      } else {
        this.setTabState(true);
      }
    });
  },
  methods: {
    ...mapActions({
      setTabState: "global/setTabState",
      logout: "auth/logout",
    }),
    handleLogout() {
      this.logout();
    },
  },
};
</script>

<style lang="scss">
@import "./assets/sass/app.scss";
.preloader {
  position: fixed;
  top: 40%;
  left: 47%;
}

.limited-access {
  display: block;
  text-align:center;
  font-size: 48px;
  color: #878787;

}

</style>
