<template>
  <div>
    <template v-if="isParcel">
      <b-spinner v-if="isLoading" class="m-3" small label="Busy"></b-spinner>
      <b-form-group
        class="mb-1"
        :label="method.name"
        v-else
      >
        <v-select
          v-if="options"
          v-model="selectedValue"
          :options="options"
          :reduce="(item) => item.code"
          :clearable="false"
          @input="parcelChanged"
        ></v-select>
      </b-form-group>
    </template>
    <template v-else-if="method.type == 'courier'">
      <b-form-group class="mb-1" label="Maakond">
        <b-form-input
          v-model="selectedValue.courier_county"
          size="sm"
          @input="courierChanged"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="mb-1" label="Linn/Vald">
        <b-form-input
          v-model="selectedValue.courier_city"
          size="sm"
          @input="courierChanged"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="mb-1" label="Postiindeks">
        <b-form-input
          v-model="selectedValue.courier_zip"
          size="sm"
          @input="courierChanged"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="mb-1" label="Aadress">
        <b-form-input
          v-model="selectedValue.courier_address"
          size="sm"
          @input="courierChanged"
        ></b-form-input>
      </b-form-group>
    </template>
  </div>
</template>

<script>
import RestService from '../lib/RestService';

export default {
  props: {
    value: {},
    method: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isLoading: true,
      options: null,
      selectedValue: null,
    }
  },
  computed: {
    isParcel() {
      if (!this.method) {
        return false;
      }

      if (this.method.type == 'dpd-parcel' || this.method.type == 'smartpost-parcel' || this.method.type == 'smartpost-parcel-fi' || this.method.type == 'omniva-parcel' || this.method.type == 'venipak-parcel') {
        return true;
      }

      return false;
    }
  },
  created() {
    this.selectedValue = this.value;
    this.fetchData();
  },
  methods: {
    parcelChanged(value) {
      this.$emit('input', value)
    },
    courierChanged() {
      this.$emit('input', {
        courier_county: this.selectedValue.courier_county,
        courier_city: this.selectedValue.courier_city,
        courier_zip: this.selectedValue.courier_zip,
        courier_address: this.selectedValue.courier_address,
      })
    },
    fetchData() {
      if (this.method.type == 'dpd-parcel') {
        this.fetchDpdParcels();
      } else if (this.method.type == 'smartpost-parcel') {
        this.fetchSmartpostParcels();
      } else if (this.method.type == 'smartpost-parcel-fi') {
        this.fetchSmartpostParcelsFi();
      } else if (this.method.type == 'omniva-parcel') {
        this.fetchOmnivaParcels();
      } else if (this.method.type == 'venipak-parcel') {
        this.fetchVenipakParcels();
      }

      this.selectedValue = this.value;
    },
    fetchDpdParcels() {
      this.isLoading = true;
      RestService.getDpdParcels().then((response) => {
        this.setOptionsData(response.data.data)

        this.isLoading = false;
      });
    },
    fetchSmartpostParcels() {
      this.isLoading = true;
      RestService.getSmartPostParcels().then((response) => {
        this.setOptionsData(response.data.data)
        this.isLoading = false;
      });
    },
    fetchSmartpostParcelsFi() {
      this.isLoading = true;
      RestService.getSmartPostParcelsFi().then((response) => {
        this.setOptionsData(response.data.data)
        this.isLoading = false;
      });
    },
    fetchOmnivaParcels() {
      this.isLoading = true;
      RestService.getOmnivaParcels().then((response) => {
        this.setOptionsData(response.data.data)
        this.isLoading = false;
      });
    },
    fetchVenipakParcels() {
      this.isLoading = true;
      RestService.getVenipakParcels().then((response) => {
        this.setOptionsData(response.data.data)
        this.isLoading = false;
      });
    },
    setOptionsData(data) {
      this.options = [];
      for (let property in data) {
        data[property].forEach((i) => {
          this.options.push({
            code: i.id,
            label: i.name,
          });
        })
      }
    }
  },
  watch: {
    method: {
      deep: true,
      handler() {
        this.fetchData();
      },
    },
  },
}
</script>