import Vue from "vue";

export const SET_PRODUCTS = (state, payload) => {
  state.products = payload;
};

export const UPDATE_PRODUCT = (state, payload) => {
  const pindex = state.products.findIndex((p) => p.id == payload.id);
  if (pindex > -1) {
    Vue.set(state.products, pindex, payload);
  }
};