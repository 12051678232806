import Vue from "vue";

export const OrderStatuses = Vue.component(
  "order-statuses",
  require("./OrderStatuses.vue").default
);
export const OrderStatusEdit = Vue.component(
  "order-status-edit",
  require("./OrderStatusEdit.vue").default
);
export const OrderStatusNew = Vue.component(
  "order-status-new",
  require("./OrderStatusNew.vue").default
);
