<template>
  <b-tr :variant="rowVariant">
    <b-td class="text-center">
      <span class="checkable" @click.prevent="handleCheck">
        <font-awesome-icon
          class="check-icon"
          :icon="['far', 'check-square']"
          v-if="isChecked"
        />
        <font-awesome-icon
          class="check-icon inactive"
          :icon="['far', 'square']"
          v-else
        />
      </span>
    </b-td>
    <b-td>
      <!-- <img src="//placehold.it/300" alt="" style="max-width: 100px;" /> -->
      <img
        v-bind:src="product.main_image"
        style="max-width: 100px;"
        alt=""
        class="img-fluid"
      />
    </b-td>
    <b-td class="position-relative">
      <loader :overlay="true" v-if="isLoading" />

      <router-link
        class="product-link"
        :to="{
          name: 'products.edit',
          params: { productId: product.id },
        }"
      >
        <strong>
          {{ product.name }}
        </strong>
      </router-link>
      <br />
      <strong>ID: </strong>{{ product.id }}<br />
      <template v-if="product.codes.length">
        <ul class="list-unstyled small">
          <li v-for="code in product.codes" :key="code.id">
            <strong> {{ code.type }}: </strong>
            {{ code.code }}
          </li>
        </ul>
      </template>
      <small>
        <strong>Kuva facebookis: </strong>
        <a href="#" @click.prevent="handleShowInFacebook">
          <b-badge variant="success" v-if="product.show_in_fb">Jah</b-badge>
          <b-badge variant="danger" v-else>Ei</b-badge>
        </a>
      </small>
      <br>
      <small>
        <strong>Mahub pakiautomaati: </strong>
        <a href="#" @click.prevent="handleParcelMachineCheck">
          <b-badge variant="success" v-if="product.is_valid_parcel">Jah</b-badge>
          <b-badge variant="danger" v-else-if="product.is_valid_parcel === false">Ei</b-badge>
          <b-badge variant="dark" v-else>Puudub</b-badge>
        </a>
      </small>
    </b-td>
    <b-td>
      <b-table-simple>
        <b-tr variant="primary">
          <b-th style="width: 20%;">
            Name
          </b-th>
          <b-th style="width: 40%;">
            In Stock
          </b-th>
          <b-th style="width: 40%;">
            Price
          </b-th>
          <b-th style="width: 5%;">
            History
          </b-th>
        </b-tr>
        <StockRow
          v-for="stock in product.stocks"
          :stock="stock"
          :key="stock.id"
        />
      </b-table-simple>
    </b-td>
  </b-tr>
</template>

<script>
import StockRow from "@/app/products/components/productList/StockRow";
import { mapGetters } from "vuex";
import RestService from '@/lib/RestService';
import { errorMessage } from '@/lib/messages';

export default {
  props: {
    product: {
      type: Object,
    },
  },
  components: {
    StockRow,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedProductIds: "catalog/selectedProductIds",
    }),
    isChecked() {
      return this.selectedProductIds.includes(this.product.id);
    },
    rowVariant() {
      return this.isChecked ? "warning" : "";
    },
  },
  methods: {
    handleCheck() {
      if (!this.isChecked) {
        this.$emit("added", this.product.id);
      } else {
        this.$emit("removed", this.product.id);
      }
    },
    handleShowInFacebook() {
      this.isLoading = true;
      RestService.postProductSetShowInFb(this.product.id)
      .then((response) => {
        this.$emit('update', response.data.data)
        this.isLoading = false;
      })
      .catch((error) => {
        if (error.response.data.message) {
          errorMessage("Viga!", error.response.data.message);
        }
        this.isLoading = false;
      });
    },
    handleParcelMachineCheck() {
      this.isLoading = true;
      RestService.postProductSetParcelMachineFit(this.product.id)
      .then((response) => {
        this.$emit('update', response.data.data)
        this.isLoading = false;
      })
      .catch((error) => {
        if (error.response.data.message) {
          errorMessage("Viga!", error.response.data.message);
        }
        this.isLoading = false;
      });
    }
  },
};
</script>

<style lang="scss">
.checkable {
  cursor: pointer;

  .check-icon {
    font-size: 32px;

    &.inactive {
      color: #c1c1c1;
    }
  }
}
</style>
