<template>
  <div
    class="order-item"
    :class="[
      statusClass,
      {
        important: !!order.important_message,
        'has-assignee': !!order.adminUser,
        'is-me': isMe,
      },
    ]"
  >
    <div class="assignee" :class="{ 'is-me': isMe }" v-if="order.adminUser">
      {{ order.adminUser.name }}
    </div>
    <div class="important-message" v-if="order.important_message"></div>
    <loader :overlay="true" v-if="isLoading" />
    <b-row no-gutters>
      <b-col lg="1">
        <div class="order-number">
          <router-link
            :to="{ name: 'order.show', params: { orderId: order.id } }"
          >
            #{{ order.id }} 
            <font-awesome-icon class="text-danger small" icon="exclamation-triangle" v-if="!order.merit_sent_at" />
            <br />
          </router-link>
        </div>

        <b-button
          variant="outline-primary"
          size="sm"
          @click.prevent="toggleShowMore"
          class="py-0 px-4"
        >
          <font-awesome-icon icon="angle-down" v-if="!showMore" />
          <font-awesome-icon icon="angle-up" v-if="showMore" />
        </b-button>
      </b-col>
      <b-col style="line-height: 1.1" lg="2">
        <template v-if="order.user">
          <router-link
            class="customer-link"
            :to="{ name: 'user.edit', params: { userId: order.user.id } }"
          >
            {{ curtomerName }} <small>(#{{ order.user.id }})</small> <br />
            <small class="font-weight-bold" v-if="order.customer_type != 1">
              {{ order.company }}
            </small>
          </router-link>
        </template>
        <template v-else>
          {{ curtomerName }} <br />
          <small class="font-weight-bold" v-if="order.customer_type != 1">
            {{ order.company }}
          </small>
        </template>
      </b-col>
      <b-col lg="2">
        <div class="pb-1" style="line-height: 1.5; font-size: 14px;">
          {{ order.email }} <br />
          {{ order.phone }}
        </div>
      </b-col>
      <b-col class="text-center" lg="1">
        <b-badge :variant="customerTypeVariant">{{
          order.customer_type_name
        }}</b-badge>
        <br />
        {{ order.payment_type_name }}<br />
      </b-col>
      <b-col class="text-center" lg="2">
        <span :class="shippingClass">{{ order.shipping_method_name }}</span>

        <div style="line-height: 1.2">
          <small> Eeldatav tarne: </small> <br />
          <strong style="font-size: 14px;">
            <span
              :class="isRedEstimatedDeliveryClass"
              v-if="order.estimated_delivery_date"
            >
              {{ order.estimated_delivery_date | moment("DD.MM.YYYY") }}
            </span>
            <span class="badge badge-danger" v-else>Puudub</span>
          </strong>
        </div>
      </b-col>
      <b-col class="text-center" lg="2">
        <template>
          <b-badge class="status" :class="statusClass">
            {{ order.primary_status_name }}
          </b-badge>
          <br />
          <strong>
            <small class="order-sub-status" :style="orderStatusColor">
              {{ order.order_status_name }}
            </small>
          </strong>
        </template>
        <br />
      </b-col>
      <b-col class="text-right" lg="2">
        <b-row no-gutters>
          <b-col>
            <div class="price">{{ order.total | currency }} &euro;</div>
            <span class="badge badge-success" v-if="order.is_paid">
              Makstud
            </span>
            <span class="badge badge-danger" v-else>
              Maksmata
            </span>

            <div
              style="line-height: 1.2"
              v-if="!order.is_paid && order.is_waiting_payment"
            >
              <small> Arve tähtaeg: <br /> </small>
              <strong style="font-size: 14px;">
                {{ order.payment_date | moment("DD.MM.YYYY") }}
              </strong>
            </div>
          </b-col>
          <b-col>
            <div class="mb-2" style="line-height: 1.2">
              <small>Tellimus loodud: <br /> </small>
              <strong style="font-size: 14px;">
                {{ order.created_at | moment("DD.MM.YYYY") }} <br />
                <span style="font-size: 13px; ">
                  {{ order.created_at | moment("HH:mm") }}
                </span>
              </strong>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="showMore">
      <b-col md="12">
        <b-table-simple bordered small class="mt-2">
          <b-tr variant="primary">
            <b-th>Nimi</b-th>
            <b-th class="text-center">Tarneaeg</b-th>
            <b-th class="text-center">Kogus</b-th>
            <b-th class="text-center">Ühiku&nbsp;hind</b-th>
            <b-th class="text-center">Kokku</b-th>
          </b-tr>
          <OrderItemRow
            v-for="product in order.product_data"
            :product="product"
            :key="product.stock_id"
            @updateStatus="updateOrderProductStatus"
            :list="true"
          />
        </b-table-simple>
        <b-button
          variant="outline-primary"
          size="sm"
          @click.prevent="toggleShowMore"
          class="py-0 px-4"
        >
          <font-awesome-icon icon="angle-up" v-if="showMore" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import OrderItemRow from "./OrderItemRow";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: ["order"],
  components: {
    OrderItemRow,
  },
  data() {
    return {
      showMore: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    isMe() {
      if (!this.order.adminUser) {
        return false;
      }

      if (this.order.adminUser.id != this.user.data.id) {
        return false;
      }

      return true;
    },
    curtomerName() {
      return `${this.order.first_name} ${this.order.last_name}`;
    },
    customerTypeVariant() {
      if (this.order.customer_type == 1) {
        return "primary";
      }

      return "warning";
    },
    statusClass() {
      if (this.order.primary_status_id == -1) {
        return "canceled";
      }

      if (this.order.primary_status_id == 0) {
        return "pending";
      }

      if (this.order.primary_status_id == 1) {
        return "processing";
      }

      if (this.order.primary_status_id == 2) {
        return "ordered";
      }

      if (this.order.primary_status_id == 3) {
        return "packing";
      }

      if (this.order.primary_status_id == 4) {
        return "completed";
      }

      return null;
    },
    orderStatusColor() {
      let color = "#000";
      let backgroundColor = "transparent";

      if (this.order.order_status_text_color) {
        color = this.order.order_status_text_color;
      }

      if (this.order.order_status_bg_color) {
        backgroundColor = this.order.order_status_bg_color;
      }

      return {
        backgroundColor: backgroundColor,
        color: color,
      };
    },
    isRedEstimatedDeliveryClass() {
      let date = moment(this.order.estimated_delivery_date);
      let now = moment();

      if (date.isBefore(now)) {
        return "badge badge-danger";
      }

      return "";
    },
    shippingClass() {
      if (this.order.shipping_method.type == 'local-pickup' || this.order.shipping_method.type == 'at-store') {
        return "badge badge-success";
      }

      return "";
    },
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    updateOrderProductStatus(payload) {
      this.isLoading = true;
      RestService.postUpdateOrderProductStatus(this.order.id, payload).then(
        (response) => {
          this.$emit("newInfo", response.data.data);
          this.isLoading = false;
        }
      );
    },
  },
};
</script>

<style lang="scss">
.order-item {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 5px;
  border-left: 6px solid #e3e3e3;
  margin-bottom: 15px;
  position: relative;

  &.has-assignee {
    background-color: #eee;

    &.is-me {
      background-color: #e7f1ff;
    }
  }

  &.important {
    padding-top: 10px;
  }

  &.canceled {
    border-left-color: #dc3545;
  }

  &.pending {
    border-left-color: #e3e3e3;
  }

  &.processing {
    border-left-color: #007bff;
  }

  &.ordered {
    border-left-color: orange;
  }

  &.packing {
    border-left-color: orange;
  }

  &.completed {
    border-left-color: #7a9b09;
  }

  .order-sub-status {
    padding: 2px 5px;
    border-radius: 6px;
  }

  .assignee {
    top: -13px;
    position: absolute;
    font-size: 12px;
    background-color: #ffa500;
    padding: 2px 3px;
    border-radius: 5px;
    font-weight: 600;
    z-index: 1;

    &.is-me {
      background-color: #007bff;
      color: #fff;
    }
  }

  .important-message {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 4px;
    background-color: #dc3545;
    border-top-right-radius: 6px;
  }

  .status {
    color: #000;
    &.canceled {
      background-color: #dc3545;
      color: #ffffff;
    }

    &.pending {
      background-color: #e3e3e3;
    }

    &.processing {
      background-color: #007bff;
      color: #ffffff;
    }

    &.ordered {
      background-color: orange;
    }

    &.packing {
      background-color: orange;
    }

    &.completed {
      background-color: #7a9b09;
      color: #ffffff;
    }
  }

  .customer-link,
  .order-number a {
    color: #000;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .order-number {
    display: block;
    margin: 0;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .price {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;

    .euro-mark {
      font-size: 18px;
    }
  }
}
</style>
