import Vue from "vue";

export const SET_CATEGORIES = (state, payload) => {
  state.categories = payload;
};

export const SET_CATEGORY_SETTINGS = (state, payload) => {
  state.categorySettings = payload;
};

export const UPDATE_CATEGORY = (state, payload) => {
  if (!state.categories) {
    return;
  }

  let categoryIndex = state.categories.findIndex((c) => c.id == payload.id);

  if (!categoryIndex) {
    return;
  }

  Vue.set(state.categories, categoryIndex, payload);
};
