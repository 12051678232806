<template>
  <div class="d-relative">
    <loader :overlay="true" v-if="isLoading" />
    <h1>
      Uue inventuuri teostamine
      <b-button
        v-if="inventoryData"
        class="float-right"
        variant="success"
        @click.prevent="confirmInventory"
      >
        Kinnita inventuur
      </b-button>
    </h1>

    <b-row v-if="inventoryData">
      <b-col md="6">
        <b-card no-body>
          <b-card-header class="pr-3">
            Laos olevad tooted
            <div class="float-right">
              <b-form-checkbox v-model="showFilteredProducts" switch>
                Kuva filtreeritud
              </b-form-checkbox>
            </div>
          </b-card-header>
          <div class="p-1">
            <b-table-simple bordered small>
              <b-tr variant="primary">
                <b-th>Nimi</b-th>
                <b-th style="width: 5%;">Kogus</b-th>
                <b-th style="width: 25%;">Koodid</b-th>
                <b-th style="width: 5%;"></b-th>
              </b-tr>
              <b-tr v-for="product in filteredProducts" :key="product.id">
                <b-td>
                  <strong>{{ product.name }}</strong> <br />
                  <small> <strong>ID: </strong> {{ product.id }} </small>
                </b-td>
                <b-td class="text-center">
                  <b-badge
                    variant="danger"
                    v-if="product.scannedQty < product.total"
                  >
                    {{ product.scannedQty }} /
                    {{ product.total }}
                  </b-badge>
                  <b-badge
                    variant="success"
                    v-else-if="product.scannedQty == product.total"
                  >
                    {{ product.scannedQty }} /
                    {{ product.total }}
                  </b-badge>
                  <b-badge variant="warning" v-else>
                    {{ product.scannedQty }} /
                    {{ product.total }}
                  </b-badge>
                </b-td>
                <b-td>
                  <template v-if="product.codes && product.codes.length">
                    <small>
                      <div v-for="code in product.codes" :key="code.id">
                        <strong>{{ code.type }}:</strong> {{ code.code }}
                      </div>
                    </small>
                    <br />
                  </template>
                </b-td>
                <b-td>
                  <b-button
                    size="sm"
                    variant="success"
                    @click.prevent="addProduct(product.id)"
                  >
                    <font-awesome-icon icon="plus" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-table-simple>
          </div>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card no-body>
          <b-card-header class="pr-3">
            Skännitud tooted
            <div class="float-right">
              <b-form-checkbox v-model="showFilteredCodes" switch>
                Kuva filtreeritud
              </b-form-checkbox>
            </div>
          </b-card-header>
          <div class="p-1">
            <form class="mb-3" @submit.prevent="codeSubmitted()">
              <input
                type="text"
                class="form-control"
                placeholder="EAN"
                v-model="codeInput"
                :autofocus="'autofocus'"
              />
            </form>
            <b-table-simple bordered small>
              <b-tr
                v-for="code in filteredScannedData"
                :key="code.id"
                :variant="getCodeVariant(code.type)"
              >
                <b-td>
                  <small>
                    <strong>{{ code.name }}</strong> <br />
                    {{ code.code }}
                  </small>
                </b-td>
                <b-td>
                  <b-button
                    size="sm"
                    variant="danger"
                    @click.prevent="removeCode(code.id)"
                  >
                    <font-awesome-icon icon="times" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-table-simple>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";

export default {
  data() {
    return {
      isLoading: false,
      codeInput: null,
      inventoryData: null,
      showFilteredProducts: true,
      showFilteredCodes: true,
    };
  },
  computed: {
    filteredScannedData() {
      if (!this.inventoryData) {
        return [];
      }

      if (!this.showFilteredCodes) {
        return this.inventoryData.scanned_data;
      }

      return this.inventoryData.scanned_data.filter((i) => i.type != "ok");
    },
    filteredProducts() {
      if (!this.inventoryData) {
        return [];
      }

      if (!this.showFilteredProducts) {
        return this.inventoryData.products;
      }

      return this.inventoryData.products.filter((i) => i.total != i.scannedQty);
    },
  },
  mounted() {
    this.fetchInventory();
  },
  methods: {
    getCodeVariant(type) {
      if (type == "missing") {
        return "danger";
      }

      if (type == "multiple") {
        return "primary";
      }

      return "";
    },
    fetchInventory() {
      this.isLoading = true;
      return RestService.getStorageInventory(this.$route.params.inventoryId)
        .then((response) => {
          this.inventoryData = response.data.data;

          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isLoading = false;
        });
    },
    codeSubmitted() {
      RestService.postStorageInventoryCode(this.inventoryData.id, {
        code: this.codeInput,
      })
        .then((response) => {
          this.inventoryData = response.data.data;
        })
        .catch((error) => {
          errorMessage("Viga!", error.response.data.message);
        });
      this.codeInput = null;
    },
    addProduct(productId) {
      RestService.postStorageInventoryAddProduct(this.inventoryData.id, {
        product_id: productId,
      })
        .then((response) => {
          this.inventoryData = response.data.data;
        })
        .catch((error) => {
          errorMessage("Viga!", error.response.data.message);
        });
    },
    removeCode(codeId) {
      RestService.postStorageInventoryRemoveCode(this.inventoryData.id, {
        code_id: codeId,
      })
        .then((response) => {
          this.inventoryData = response.data.data;
        })
        .catch((error) => {
          errorMessage("Viga!", error.response.data.message);
        });
    },
    confirmInventory() {},
  },
};
</script>
