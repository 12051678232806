import RestService from "@/lib/RestService";

export const getPages = ({ commit }, payload) => {
  return RestService.getPages(payload).then((response) => {
    commit("SET_PAGES", response.data.data);
    commit("SET_PAGES_META", response.data.meta);

    return response;
  });
};

export const deletePage = ({ commit }, payload) => {
  return RestService.postDeletePage(payload.id).then((response) => {
    commit("fun");
    return response;
  });
};
