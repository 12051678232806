<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Tellimuse staatused
      <b-button variant="primary" size="sm" @click.prevent="addNew">
        Lisa uus
      </b-button>
    </h1>

    <b-table-simple bordered responsive small>
      <b-tr variant="primary">
        <b-th>
          Nimi
        </b-th>
        <b-th>Peastaatus</b-th>
        <b-th></b-th>
      </b-tr>

      <b-tr v-for="status in statuses" :key="status.id">
        <b-td>
          <span
            class="badge"
            :style="{
              backgroundColor: status.bg_color,
              color: status.text_color,
            }"
          >
            {{ status.name }}
          </span>
        </b-td>
        <b-td>
          <b-badge class="status" :class="getStatusClass(status.status_type)">
            {{ status.status_type_name }}
          </b-badge>
        </b-td>
        <b-td class="text-right">
          <router-link
            class="btn btn-warning btn-sm mr-2"
            :to="{
              name: 'orderStatus.edit',
              params: { statusId: status.id },
            }"
          >
            Muuda
          </router-link>

          <b-button
            variant="danger"
            size="sm"
            @click.prevent="deleteStatus(status)"
          >
            X
          </b-button>
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      statuses: "orderStatuses/statuses",
    }),
  },
  mounted() {
    this.fetchOrderStatuses();
  },
  methods: {
    ...mapActions({
      fetchStatuses: "orderStatuses/fetchStatuses",
      deleteOrderStatus: "orderStatuses/deleteOrderStatus",
    }),
    fetchOrderStatuses() {
      this.isLoading = true;
      this.fetchStatuses().then(() => {
        this.isLoading = false;
      });
    },
    addNew() {
      this.$router.push({ name: "orderStatus.new" });
    },
    deleteStatus(status) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.deleteOrderStatus(status.id);
          }
        },
      });
    },
    getStatusClass(type) {
      if (type == -1) {
        return "canceled";
      }

      if (type == 0) {
        return "pending";
      }

      if (type == 1) {
        return "processing";
      }

      if (type == 2) {
        return "ordered";
      }

      if (type == 3) {
        return "packing";
      }

      if (type == 4) {
        return "completed";
      }

      return null;
    },
  },
};
</script>

<style lang="scss">
.status {
  color: #000;
  &.canceled {
    background-color: #dc3545;
    color: #ffffff;
  }

  &.pending {
    background-color: #e3e3e3;
  }

  &.processing {
    background-color: #007bff;
    color: #ffffff;
  }

  &.ordered {
    background-color: orange;
  }

  &.packing {
    background-color: orange;
  }

  &.completed {
    background-color: #7a9b09;
    color: #ffffff;
  }
}
</style>
