<template>
  <b-card class="mt-3" no-body>
    <b-card-header class="pr-1">
      Tooted
      <small> - Käibemaks {{ !order.without_vat ? order.vat_percent : 0 }}%</small>
      <div class="float-right">
        <b-button
          v-b-modal.product-text-1
          class="mr-1"
          size="sm"
          pill
          variant="outline-primary"
        >
          <font-awesome-icon class="mx-1" icon="info" />
        </b-button>
      </div>
    </b-card-header>

    <b-modal id="product-text-1" centered hide-footer size="xl" title="Tooted (Ilma käibemaksuta)">
      <b-table-simple bordered small>
        <b-thead>
          <b-tr variant="primary">
            <b-th style="width: 40%">Nimi</b-th>
            <b-th class="text-center">Ostuhind</b-th>
            <b-th class="text-center">Transport</b-th>
            <b-th class="text-center">Marginal</b-th>
            <b-th class="text-center">Müügihind</b-th>
            <b-th class="text-center">Kogus</b-th>
            <b-th class="text-center">Summa</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="product in order.product_data" :key="`text-l${product.id}`">
            <b-td>
              <small>{{ product.name }}</small> <br />
              <small>
                <strong>SKU:</strong> {{ product.sku }} <br>
                <template v-if="product.stock_name">
                  <strong>Ladu: </strong> {{ product.supplier_name }} ({{
                    product.stock_name
                  }}) <br />
                </template>
              </small>
            </b-td>
            <b-td class="text-center">
              {{ product.margin_net | currency }}
            </b-td>
            <b-td class="text-center">
              {{ product.margin_shipping | currency }}
            </b-td>
            <b-td class="text-center">
              {{ product.margin_single | currency }}
            </b-td>
            <b-td class="text-center">
              {{ product.item_price_subtotal | currency }}
            </b-td>
            <b-td class="text-center">
              {{ product.quantity }}
            </b-td>
            <b-td class="text-center">
              {{ product.price_subtotal | currency }}
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr variant="secondary">
            <b-td class="text-right" colspan="6">
              <strong>Transport kokku: </strong>
            </b-td>
            <b-td>
              {{ totalDelivery | currency }}
            </b-td>
          </b-tr>
          <b-tr variant="secondary">
            <b-td class="text-right" colspan="6">
              <strong>Marginal kokku: </strong>
            </b-td>
            <b-td>
              {{ totalMarginal | currency }}
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-modal>

    <div class="p-1">
      <b-table-simple class="mt-3 mb-2" bordered small>
        <b-thead>
          <b-tr variant="primary">
            <b-th>Toote nimi</b-th>
            <b-th style="width: 10%;" class="text-center">Tarneaeg</b-th>
            <b-th style="width: 10%;" class="text-center">Kogus</b-th>
            <b-th style="width: 10%;" class="text-center">Ühiku hind</b-th>
            <b-th style="width: 10%;" class="text-center">Kokku</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <OrderItemRow
            v-for="product in order.product_data"
            :product="product"
            :key="product.stock_id"
            @updateStatus="updateOrderProductStatus"
            @updateDeliveryDate="updateDeliveryDate"
          >
            <b-button
              class="mt-3"
              variant="warning"
              size="sm"
              @click.prevent="orderItem(product)"
              v-if="
                order.primary_status_id == 1 && !product.is_ordered && !isEemeli
              "
            >
              Telli
            </b-button>
          </OrderItemRow>
        </b-tbody>
        <b-tfoot>
          <b-tr variant="success" v-if="order.discount_data">
            <b-td class="text-right font-weight-bold" colspan="4">
              Sooduskood:
              <strong>
                {{ order.discount_data.code }} -
                <small>{{ order.discount_data.text }}</small>
              </strong>
            </b-td>
            <b-td>
              {{ order.discount_data.discount_amount | currency }} &euro;
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="font-weight-bold">
              Transport: <small>{{ order.shipping_method_name }}</small>
            </b-td>
            <b-td></b-td>
            <b-td class="text-center">
              1
            </b-td>
            <b-td class="text-center">
              <span v-b-tooltip.hover.noninteractive title="Ilma käibemaksuta">
                {{ order.shipping_price | currency }}&nbsp;&euro;
              </span>
              <br>
              <small v-b-tooltip.hover.noninteractive title="Koos käibemaksuga">
                {{ order.shipping_price_with_tax | currency }}&nbsp;&euro;
              </small>
            </b-td>
            <b-td class="text-center">
              <span v-b-tooltip.hover.noninteractive title="Ilma käibemaksuta">
                {{ order.shipping_price | currency }}&nbsp;&euro;
              </span>
              <br>
              <small v-b-tooltip.hover.noninteractive title="Koos käibemaksuga">
                {{ order.shipping_price_with_tax | currency }}&nbsp;&euro;
              </small>  
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-right font-weight-bold" colspan="4">
              Kokku ilma km-ita:
            </b-td>
            <b-td>{{ order.subtotal | currency }} &euro;</b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-right font-weight-bold" colspan="4">
              Käibemaks {{ order.vat_percent }}%:
            </b-td>
            <b-td>{{ order.vat_total | currency }} &euro;</b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-right font-weight-bold" colspan="4">
              Kokku:
            </b-td>
            <b-td>{{ order.total | currency }} &euro;</b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
      <ManualSupplierOrdering
        v-if="!isEemeli"
        :orderId="order.id"
        @refreshOrder="refreshOrder"
      >
      </ManualSupplierOrdering>
    </div>
  </b-card>
</template>

<script>
import RestService from "@/lib/RestService";
import OrderItemRow from "../OrderItemRow";
import ManualSupplierOrdering from "./ManualSupplierOrdering";
import { errorMessage } from "@/lib/messages";
import { successMessage } from "@/lib/messages";
import { mapGetters } from "vuex";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    OrderItemRow,
    ManualSupplierOrdering,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isEemeli: "global/isEemeli",
    }),
    totalDelivery() {
      if (!this.order) {
        return 0;
      }

      if (!this.order.product_data) {
        return 0;
      }

      let total = 0;
      this.order.product_data.forEach((item) => {
        total += (item.margin_shipping || 0) * item.quantity 
      });

      return total
    },
    totalMarginal() {
      if (!this.order) {
        return 0;
      }

      if (!this.order.product_data) {
        return 0;
      }

      let total = 0;
      this.order.product_data.forEach((item) => {
        total += (item.margin_single || 0) * item.quantity 
      });

      return total
    }
  },
  methods: {
    updateOrderProductStatus(payload) {
      this.$emit("startLoading");
      RestService.postUpdateOrderProductStatus(this.order.id, payload)
        .then((response) => {
          this.$emit("stopLoading", response.data.data);
        })
        .catch((error) => {
          if (error.response) {
            errorMessage("Viga!", error.response.data.message);
            this.$emit("stopLoading", null);
          }
        });
    },
    updateDeliveryDate(payload) {
      this.$emit("startLoading");
      RestService.postUpdateOrderProductDeliveryDate(this.order.id, payload)
        .then((response) => {
          this.$emit("stopLoading", response.data.data);
        })
        .catch((error) => {
          if (error.response) {
            errorMessage("Viga!", error.response.data.message);
            this.$emit("stopLoading", null);
          }
        });
    },
    orderItem(product) {
      this.$emit("startLoading");
      RestService.postOrderMakeSupplierOrder(this.order.id, {
        uuid: product.id || null,
        stock_id: product.stock_id,
      })
        .then((response) => {
          successMessage("Teade!", "Tellimus tehtud. Info on kuvatud allpool.");
          this.$emit("stopLoading", response.data.data);
        })
        .catch((error) => {
          if (error.response) {
            errorMessage("Viga!", error.response.data.message);
            this.$emit("stopLoading", null);
          }
        });
    },
    refreshOrder() {
      this.$emit("refreshOrder");
    },
  },
};
</script>
