<template>
  <div class="d-relative">
    <loader :overlay="true" v-if="isLoading" />
    <h1>
      Uue inventuuri alustamine
    </h1>

    <div class="text-center m-5" v-if="!inventoryData">
      <b-button variant="primary" size="lg" @click.prevent="startInventory">
        Alusta inventuuriga
      </b-button>
    </div>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    startInventory() {
      this.isLoading = true;
      return RestService.postStartNewInventory()
        .then((response) => {
          this.$router.push({
            name: "stockInventoryProcess",
            params: { inventoryId: response.data.data.id },
          });
        })
        .catch((error) => {
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isLoading = false;
        });
    },
  },
};
</script>
