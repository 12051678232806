<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      Lisa uus bänner
    </h2>

    <b-row>
      <b-col md="6">
        <b-card header="Bänneri info">
          <b-form @submit.prevent="saveBanner">
            <b-form-group class="mb-1" label="Nimi">
              <b-form-input
                v-model="form.name"
                size="sm"
                :state="getFieldStateFromErrors('name')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("name") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Asukoht">
              <b-form-select
                v-model="form.position"
                :options="positionOptions"
                size="sm"
                :state="getFieldStateFromErrors('position')"
              ></b-form-select>
              <b-form-invalid-feedback>
                {{ getFieldError("position") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Url">
              <b-form-input
                v-model="form.url"
                size="sm"
                :state="getFieldStateFromErrors('url')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("url") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Keel">
              <b-form-select
                v-model="form.locale"
                :options="selectLocales"
                size="sm"
                :state="getFieldStateFromErrors('locale')"
              ></b-form-select>
              <b-form-invalid-feedback>
                {{ getFieldError("locale") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Alguse aeg">
              <v-date-picker
                v-model="form.starts_at"
                mode="dateTime"
                is24hr
                :model-config="dateTimeConfig"
                :masks="dateTimeMasks"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    readonly
                    :value="inputValue"
                    @click="togglePopover({ placement: 'auto-start' })"
                  />
                </template>
              </v-date-picker>

              <b-form-invalid-feedback>
                {{ getFieldError("starts_at") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Lõpuaeg">
              <v-date-picker
                v-model="form.ends_at"
                mode="dateTime"
                is24hr
                :model-config="dateTimeConfig"
                :masks="dateTimeMasks"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    readonly
                    :value="inputValue"
                    @click="togglePopover({ placement: 'auto-start' })"
                  />
                </template>
              </v-date-picker>

              <b-form-invalid-feedback>
                {{ getFieldError("ends_at") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Pilt:">
              <b-form-file
                v-model="form.file"
                accept="image/*"
                size="sm"
                :state="getFieldStateFromErrors('file')"
                placeholder="Vali fail"
                drop-placeholder="Lohista fail siia"
              ></b-form-file>
              <b-form-invalid-feedback>
                {{ getFieldError("file") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <div class="text-right mt-3">
              <b-button type="submit" variant="success">
                Salvesta
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,
      form: {
        name: null,
        position: "home",
        locale: "et",
        url: null,
        starts_at: new Date(),
        ends_at: new Date(),
        file: null,
      },
      positionOptions: [
        { value: "home", text: "Avaleht" },
        { value: "home-sidebar", text: "Avalehe menüü all" },
      ],
      dateTimeConfig: {
        type: "string",
        mask: "iso",
      },
      dateTimeMasks: {
        inputDateTime24hr: "DD.MM.YYYY HH:mm",
      },
    };
  },
  computed: {
    ...mapGetters({
      languages: "generic/languages",
    }),
    selectLocales() {
      return this.languages.map((locale) => {
        return {
          value: locale.locale,
          text: locale.name,
        };
      });
    },
  },
  methods: {
    async saveBanner() {
      this.isLoading = true;

      let file = await this.toBase64(this.form.file);

      RestService.postSaveNewBanner({ ...this.form, ...{ file: file } })
        .then((response) => {
          this.$router.push({
            name: "banners.edit",
            params: { bannerId: response.data.data.id },
          });

          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>
