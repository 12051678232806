<template>
  <b-navbar class="border-bottom" toggleable="lg" type="light" variant="light">
    <b-button
      class="d-block d-md-none"
      size="sm"
      variant="outline-primary"
      @click.prevent="toggleSidebar"
    >
      <font-awesome-icon icon="bars" />
    </b-button>
    <b-navbar-nav class="ml-auto">
      <b-nav-item-dropdown right>
        <template #button-content>
          {{ user.data.name }}
        </template>

        <b-dropdown-item :to="{ name: 'account.settings' }">
          Konto seaded
        </b-dropdown-item>
        <b-dropdown-item href="#" @click.prevent="handleLogout">
          Logi välja
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { successMessage } from "@/lib/messages";

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    if (window.Echo) {
      window.Echo.private("OrderChannel").listen(".new-order", (e) => {
        successMessage("Uus tellimus", `Uus tellimus #${e.orderId}`, {
          autoHideDelay: 15000,
        });
      });
    }
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      toggleSidebar: "global/toggleSidebar",
    }),
    handleLogout() {
      this.logout();
    },
  },
};
</script>
