import { ImportLog, ImportStatus } from "../components";

export default [
  {
    path: "/import/logs",
    component: ImportLog,
    name: "import-logs",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/import/status",
    component: ImportStatus,
    name: "import-status",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
