<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      Muuda administraatorit
      <small v-if="adminUser">- {{ adminUser.name }}</small>
    </h2>

    <template v-if="adminUser">
      <b-row>
        <b-col md="6">
          <b-card header="Uue administraatori info">
            <b-form>
              <b-form-group class="mb-1" label="Nimi">
                <b-form-input
                  v-model="adminUser.name"
                  size="sm"
                  :state="getFieldStateFromErrors('name')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("name") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Email">
                <b-form-input
                  type="email"
                  v-model="adminUser.email"
                  size="sm"
                  :state="getFieldStateFromErrors('email')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("email") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Parool">
                <b-form-input
                  type="password"
                  v-model="adminUser.password"
                  size="sm"
                  :state="getFieldStateFromErrors('password')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("password") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  v-model="adminUser.allow_only_office"
                  :value="true"
                  :unchecked-value="false"
                >
                  Lubatud kasutada ainult kontoris
                </b-form-checkbox>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  v-model="adminUser.is_limited"
                  :value="true"
                  :unchecked-value="false"
                >
                  Limiteeritud õigustega kasutaja
                </b-form-checkbox>
              </b-form-group>

              <div class="text-right mt-3">
                <b-button
                  type="submit"
                  variant="success"
                  @click.prevent="saveAdminUser"
                >
                  Salvesta
                </b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,
      adminUser: null,
    };
  },
  mounted() {
    this.fetchAdminUser();
  },
  methods: {
    fetchAdminUser() {
      this.isLoading = true;
      RestService.getAdminUser(this.$route.params.adminUserId).then(
        (response) => {
          this.adminUser = response.data.data;
          this.clearValidationErrors();

          this.isLoading = false;
        }
      );
    },
    saveAdminUser() {
      this.isLoading = true;

      RestService.postSaveAdminUser(this.adminUser.id, this.adminUser)
        .then((response) => {
          this.adminUser = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
  },
};
</script>
