<template>
  <b-card class="mb-3" header="Tellimuse staatus" body-class="p-2">
    <b-form-group
      class="mb-1"
      label="Staatus"
      v-if="orderStatuses"
    >
      <multiselect
        v-model="selectedStatus"
        :options="orderStatuses"
        placeholder="Vali staatus"
        label="label"
        group-values="items"
        group-label="label"
      ></multiselect>
    </b-form-group>

    <b-form-group class="mb-1" label="Kommentaar">
      <b-form-textarea
        v-model="statusForm.comment"
        rows="2"
        size="sm"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group class="mb-1">
      <b-form-checkbox
        v-model="statusForm.notify"
        :value="true"
        :unchecked-value="false"
      >
        Teavita klienti
      </b-form-checkbox>
    </b-form-group>
    <b-button variant="success" size="sm" :disabled="!selectedStatus" @click.prevent="updateOrderStatus">
      Uuenda tellimuse staatust
    </b-button>

    <b-card class="mt-3" no-body border-variant="primary">
      <b-card-header
        header-tag="header"
        header-bg-variant="primary"
        header-text-variant="white"
        class="p-1 pl-3 cursor-pointer"
        @click.prevent="showStatusHistory = !showStatusHistory"
      >
        Staatuste ajalugu:
        <div class="float-right mr-3">
          <font-awesome-icon icon="angle-up" v-if="showStatusHistory" />
          <font-awesome-icon icon="angle-down" v-if="!showStatusHistory" />
        </div>
      </b-card-header>

      <b-collapse v-model="showStatusHistory">
        <b-table-simple class="mb-0" bordered small responsive>
          <b-tr v-for="(status, statusKey) in statusHistory" :key="statusKey">
            <b-td>
              <ul class="list-unstyled mb-0">
                <li>
                  <strong>Aeg:</strong>
                  {{ status.updated_at | moment("DD.MM.YYYY HH:mm:ss") }}
                </li>
                <li>
                  <strong>Staatus:</strong>
                  {{ status.name }}
                </li>
                <li>
                  <strong>Teavitus: </strong>
                  <b-badge variant="success" v-if="status.customer_notification"
                    >Jah</b-badge
                  >
                  <b-badge variant="danger" v-else>Ei</b-badge>
                </li>
                <li>
                  <strong>Kommentaar:</strong><br />
                  <b-alert
                    v-if="status.comment"
                    class="p-1 m-0"
                    variant="warning"
                    show
                  >
                    <small style="white-space: pre;">
                      {{ status.comment }}
                    </small>
                  </b-alert>
                </li>
              </ul>
            </b-td>
          </b-tr>
        </b-table-simple>
      </b-collapse>
    </b-card>
  </b-card>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapGetters } from "vuex";
import moment from "moment";
import { errorMessage } from "@/lib/messages";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusForm: {
        comment: null,
        notify: false,
      },
      selectedStatus: null,
      showStatusHistory: true,
    };
  },
  computed: {
    ...mapGetters({
      orderStatuses: "generic/orderStatuses",
    }),
    statusHistory() {
      let data = JSON.parse(JSON.stringify(this.order.status_history));

      return data.sort((a, b) => {
        return moment(b.updated_at) - moment(a.updated_at);
      });
    },
  },
  mounted() {
    this.setCurrentStatus();
  },
  methods: {
    updateOrderStatus() {
      let callback = () => {
        this.$emit("startLoading");
        RestService.updateOrderStatus({
          id: this.order.id,
          order_status: this.selectedStatus.code,
          ...this.statusForm,
        })
          .then((response) => {
            this.statusForm = {
              comment: null,
              notify: false,
            };

            this.$emit("stopLoading", response.data.data);
          })
          .catch((error) => {
            if (error.response) {
              errorMessage("Viga!", error.response.data.message);
            }
          });
      };

      if (this.statusForm.notify) {
        this.$confirm({
          message: `Are you sure you want send info to client?`,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              callback();
            }
          },
        });
      } else {
        callback();
      }
    },
    setCurrentStatus() {
      this.orderStatuses.forEach((e) => {
        e.items.forEach((i) => {
          if (this.order.order_status == i.code) {
            this.selectedStatus = i;
          }
        });
      });
    },
  },
  watch: {
    order: {
      deep: true,
      handler() {
        this.setCurrentStatus();
      },
    },
  },
};
</script>
