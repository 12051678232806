<template>
  <div>
    <b-input-group class="mb-1" size="sm">
      <b-form-input
        :value="formattedValue"
        type="text"
        placeholder="DD.MM.YYYY"
        autocomplete="off"
        readonly=""
      ></b-form-input>
      <b-input-group-append>
        <b-form-datepicker
          boundary="viewport"
          size="sm"
          v-model="currentValue"
          button-only
          variant="primary"
          right
          @context="changed"
        ></b-form-datepicker>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["value"],
  data() {
    return {
      currentValue: this.value,
    };
  },
  computed: {
    formattedValue() {
      if (!this.currentValue) {
        return null;
      }

      return moment(this.currentValue).format("DD.MM.YYYY");
    },
  },
  methods: {
    changed() {
      this.$emit("input", this.currentValue);
    },
  },
};
</script>
