import {
  CategoryMapper,
  CategoryMaps,
  AllMaps,
  IcecatMaps,
} from "../components";

export default [
  {
    path: "/category-mapper",
    component: CategoryMapper,
    name: "categoryMapper",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/category-mapper/all",
    component: AllMaps,
    name: "CategoryMapsAll",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/category-mapper/icecat",
    component: IcecatMaps,
    name: "CategoryMapsIcecat",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/category-mapper/:supplierId",
    component: CategoryMaps,
    name: "CategoryMaps",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
