import RestService from "@/lib/RestService";

export const reset = ({ commit }) => {
  commit("RESET");
};

export const fetchCategoryMaps = ({ commit }, supplierId) => {
  return RestService.getCategoryMaps(supplierId).then((response) => {
    commit("SET_CATEGORY_MAPS", response.data.data);
  });
};

export const setCategoryMaps = ({ commit }, payload) => {
  commit("SET_CATEGORY_MAPS", payload);
};

export const getCategoryMap = ({ commit }, mapId) => {
  return RestService.getCategoryMap(mapId).then((response) => {
    commit("SET_SELECTED_MAP", response.data.data);
  });
};

export const setSearchKey = ({ commit }, searchkey) => {
  commit("SET_SEARCH_KEY", searchkey);
};

export const setShowNotMapped = ({ commit }, trueOrFalse) => {
  commit("SET_SHOW_NOT_MAPPED", trueOrFalse);
};

export const setShowWithProducts = ({ commit }, trueOrFalse) => {
  commit("SET_SHOW_WITH_PRODUCTS", trueOrFalse);
};

export const addCategoryToMap = ({ commit }, payload) => {
  return RestService.addCategoryToMap(payload.mapId, payload.categoryId).then(
    (response) => {
      commit("SET_SELECTED_MAP_CATEGORIES", response.data.data);
    }
  );
};

export const removeCategoryFromMap = ({ commit }, payload) => {
  return RestService.removeCategoryFromMap(
    payload.mapId,
    payload.categoryId
  ).then((response) => {
    commit("SET_SELECTED_MAP_CATEGORIES", response.data.data);
  });
};

export const setIgnoreCategoryMap = ({ commit }, payload) => {
  return RestService.postSetCategoryMapImportIgnore(
    payload.mapId,
    payload.value
  ).then((response) => {
    commit("SET_CATEGORY_MAP", response.data.data);
    commit("SET_SELECTED_MAP", response.data.data);
  });
};

export const setCategoryMapDeliveryPrice = ({ commit }, payload) => {
  return RestService.postSetCategoryMapDeliveryPrice(
    payload.mapId,
    payload.price
  ).then((response) => {
    commit("SET_CATEGORY_MAP", response.data.data);
    commit("SET_SELECTED_MAP", response.data.data);
  });
};
