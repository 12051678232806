<template>
  <div>
    <b-form-group>
      <b-form-input
        class="form-control"
        v-model="search"
        size="sm"
        placeholder="Search keyword"
      ></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-checkbox-group v-model="showStatuses">
        <b-form-checkbox value="ordered">Ordered</b-form-checkbox>
        <b-form-checkbox value="in-stock">In stock</b-form-checkbox>
        <b-form-checkbox value="out-of-stock">Out of stock</b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>

    <b-table-simple bordered responsive small>
      <b-tr variant="primary">
        <b-th>Name</b-th>
        <b-th style="width: 10%;">Place</b-th>
        <b-th style="width: 10%;">Price</b-th>
        <b-th style="width: 5%;">Status</b-th>
        <b-th style="width: 10%;">Actions</b-th>
      </b-tr>
      <StockItemRow v-for="item in items" :item="item" :key="item.id" />
      <b-tr variant="primary">
        <b-td></b-td>
        <b-td></b-td>
        <b-td class="font-weight-bold">{{ totalValue | currency }} &euro;</b-td>
        <b-td></b-td>
        <b-td></b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import StockItemRow from "./StockItemRow";

export default {
  components: {
    StockItemRow,
  },
  props: {
    stockItems: {
      type: Array,
    },
  },
  data() {
    return {
      search: "",
      showStatuses: ["ordered", "in-stock"],
    };
  },
  computed: {
    items() {
      return this.stockItems.filter((item) => {
        if (!this.showStatuses.includes(item.status)) {
          return false;
        }

        if (!this.search) {
          return true;
        }

        if (
          item.name &&
          item.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1
        ) {
          return true;
        }

        if (
          item.additional_info &&
          item.additional_info
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) != -1
        ) {
          return true;
        }

        return false;
      });
    },
    totalValue() {
      return this.items.reduce((a, b) => +a + +b.price, 0);
    },
  },
};
</script>
