import { Suppliers, SupplierEdit, SupplierNew } from "../components";

export default [
  {
    path: "/suppliers",
    component: Suppliers,
    name: "suppliers",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/suppliers/new",
    component: SupplierNew,
    name: "suppliers.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/suppliers/:supplierId",
    component: SupplierEdit,
    name: "suppliers.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
