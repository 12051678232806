import Vue from "vue";

export const Orders = Vue.component("orders", require("./Orders.vue").default);
export const GroupedOrders = Vue.component(
  "GroupedOrders",
  require("./GroupedOrders.vue").default
);
export const SearchOrders = Vue.component(
  "SearchOrders",
  require("./SearchOrders.vue").default
);
export const WaitingForOffice = Vue.component(
  "WaitingForOffice",
  require("./WaitingForOffice.vue").default
);
export const AttentionNeededOrders = Vue.component(
  "AttentionNeededOrders",
  require("./AttentionNeededOrders.vue").default
);
export const MyOrders = Vue.component(
  "MyOrders",
  require("./MyOrders.vue").default
);
export const UnPaidOrders = Vue.component(
  "UnPaidOrders",
  require("./UnPaidOrders.vue").default
);
export const NotSentToMeritOrders = Vue.component(
  "NotSentToMeritOrders",
  require("./NotSentToMeritOrders.vue").default
);
export const Order = Vue.component("order", require("./Order.vue").default);
export const NewOrder = Vue.component(
  "new-order",
  require("./new/NewOrder.vue").default
);
export const MarginalReport = Vue.component(
  "reports-marginal",
  require("./reports/Marginal.vue").default
);
export const RmaList = Vue.component(
  "rma-list",
  require("./rma/RmaList.vue").default
);
export const RmaShow = Vue.component(
  "rma-show",
  require("./rma/RmaShow.vue").default
);
