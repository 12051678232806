<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Kliendi lisamine
    </h1>

    <b-row>
      <b-col md="6">
        <b-card header="Konto info">
          <b-form @submit.prevent="saveUser">
            <b-row>
              <b-col>
                <b-form-group class="mb-1" label="Eesnimi">
                  <b-form-input
                    v-model="form.first_name"
                    size="sm"
                    :state="getFieldStateFromErrors('first_name')"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ getFieldError("first_name") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-1" label="Perenimi">
                  <b-form-input
                    v-model="form.last_name"
                    size="sm"
                    :state="getFieldStateFromErrors('last_name')"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ getFieldError("last_name") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group class="mb-1" label="Email">
                  <b-form-input
                    v-model="form.email"
                    size="sm"
                    :state="getFieldStateFromErrors('email')"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ getFieldError("email") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-1" label="Telefon">
                  <b-form-input
                    v-model="form.phone"
                    size="sm"
                    :state="getFieldStateFromErrors('phone')"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ getFieldError("phone") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group class="mb-1" label="Parool">
                  <b-form-input
                    type="password"
                    v-model="form.password"
                    size="sm"
                    :state="getFieldStateFromErrors('password')"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ getFieldError("password") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-1" label="Parool uuesti">
                  <b-form-input
                    type="password"
                    v-model="form.password_confirmation"
                    size="sm"
                    :state="getFieldStateFromErrors('password_confirmation')"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ getFieldError("password_confirmation") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="text-right mt-3">
              <b-button type="submit" variant="success" size="sm">
                Salvesta
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
      <b-col md="6"> </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,
      form: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        password: null,
        password_confirmation: null,
      },
    };
  },
  methods: {
    saveUser() {
      this.isLoading = true;
      RestService.postSaveNewUser(this.form)
        .then((response) => {
          this.$router.push({
            name: "user.edit",
            params: { userId: response.data.data.id },
          });

          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
  },
};
</script>
