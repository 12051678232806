<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Menüüd
      <router-link
        class="btn btn-primary btn-sm"
        :to="{
          name: 'menu.new',
        }"
      >
        Lisa uus
      </router-link>
    </h1>

    <b-table-simple bordered responsive small>
      <b-tr variant="primary">
        <b-th>Nimi</b-th>
        <b-th>Keel</b-th>
        <b-th style="width: 5%;"></b-th>
      </b-tr>
      <b-tr v-for="menu in menus" :key="menu.id">
        <b-td>{{ menu.name }}</b-td>
        <b-td>{{ menu.locale }}</b-td>
        <b-td>
          <router-link
            class="btn btn-warning btn-sm"
            :to="{
              name: 'menu.edit',
              params: { menuId: menu.id },
            }"
          >
            Muuda
          </router-link>
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      menus: "menus/menus",
    }),
  },
  mounted() {
    this.isLoading = true;
    this.fetchMenus().then(() => {
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions({
      fetchMenus: "menus/fetchMenus",
    }),
  },
};
</script>
