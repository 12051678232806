export default {
  adminUsers: [],
  countries: [],
  order_statuses: [],
  product_stock_statuses: [],
  suppliers: [],
  product_code_types: [],
  shippingMethods: [],
  main_categories: [],
  languages: [
    {
      locale: "et",
      name: "Eesti",
    },
  ],
};
