export const stockItems = (state) => {
  return state.stockItems;
};

export const orderedStockItems = (state) => {
  return state.stockItems.filter(item => item.status == 'ordered');
};

export const inStockStockItems = (state) => {
  return state.stockItems.filter(item => item.status == 'in-stock');
};

export const outOfStockStockItems = (state) => {
  return state.stockItems.filter(item => item.status == 'out-of-stock');
};
