<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <b-row>
      <b-col md="4">
        <h1>
          Kategooriad
          <b-button
            class="mr-2"
            variant="primary"
            size="sm"
            @click.prevent="fetchCategories"
          >
            <font-awesome-icon icon="sync" />
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            @click.prevent="updateProductsCount"
          >
            Sync
          </b-button>
        </h1>
        <vue-tree-list
          v-if="categories"
          :model="categories"
          @click="clickNode"
          @rightClick="openContextMenu"
          @drop="nodeDropped"
          @drop-before="nodeMoved"
          @dragChanged="changeDragging"
          default-tree-node-name="new node"
          default-leaf-node-name="new leaf"
          v-bind:default-expanded="false"
          :class="{ 'category-dragging': isDragging }"
        >
          <template v-slot:itemName="slotProps">
            <span
              :class="{
                'text-danger':
                  !slotProps.model.isLeaf && slotProps.model.maps_count,
              }"
            >
              {{ slotProps.model.name }}
            </span>
          </template>
        </vue-tree-list>

        <b-button
          class="mt-3"
          variant="primary"
          size="sm"
          @click.prevent="addNewMainCategory"
        >
          Lisa uus peakategooria
        </b-button>

        <VueContext
          class="categories"
          ref="categoryContextMenu"
          v-slot="{ data }"
        >
          <li>
            <a href="#" @click.prevent="addNewCategory(data)">
              Lisa uus
            </a>
          </li>
          <li>
            <a
              class="text-danger"
              href="#"
              @click.prevent="deleteCategory(data)"
            >
              Kustuta
            </a>
          </li>
        </VueContext>
        <b-modal
          v-model="showNewModal"
          size="lg"
          title="Lisa uus kategooria"
          hide-footer
        >
          <loader v-if="isModalLoading" :overlay="true" />
          <b-form @submit.prevent="handleNewCategorySubmit">
            <strong>Eesti keel</strong>
            <b-form-group class="mb-1">
              <b-input-group size="sm" prepend="Nimi">
                <b-form-input
                  v-model="newForm.name"
                  size="sm"
                  required
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group class="mb-1">
              <b-input-group size="sm" prepend="Slug">
                <b-form-input v-model="newForm.slug" size="sm"></b-form-input>
              </b-input-group>
            </b-form-group>
            <div class="text-right">
              <b-button type="submit" class="mt-3" variant="success" size="sm">
                Lisa
              </b-button>
            </div>
          </b-form>
        </b-modal>
      </b-col>
      <b-col md="8">
        <h1>Valitud kategooria</h1>
        <CategoryEdit
          :categoryId="selectedCategoryId"
          v-if="selectedCategoryId"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueTreeList from "../../../helpers/vue-tree-list/VueTreeList";
import { Tree, TreeNode } from "../../../helpers/vue-tree-list/Tree";
import CategoryEdit from "./CategoryEdit";
import { mapActions } from "vuex";
import RestService from "@/lib/RestService";
import { successMessage } from "@/lib/messages";
import VueContext from "vue-context";
import { errorMessage } from "@/lib/messages";

export default {
  components: {
    VueTreeList,
    CategoryEdit,
    VueContext,
  },
  data() {
    return {
      isDragging: false,
      selectedCategoryId: null,
      isModalLoading: false,
      isLoading: true,
      categories: null,
      showNewModal: false,
      newForm: {
        name: null,
        slug: null,
      },
      newModel: null,
    };
  },
  computed: {},
  mounted() {
    this.fetchCategorySettings();
    this.fetchCategories();
  },
  methods: {
    ...mapActions({
      fetchCategorySettings: "categories/fetchCategorySettings",
    }),
    fetchCategories() {
      this.isLoading = true;
      return RestService.getCategories()
        .then((response) => {
          this.categories = new Tree(response.data.data);
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    updateProductsCount() {
      this.isLoading = true;
      return RestService.postUpdateCategoryProductCounts().then(() => {
        this.isLoading = false;
        successMessage(
          "Teade",
          "Toodete sünkroneerimise protsess hakkas tööle! See võib võtta veits aega, enne kui see valmis saab."
        );
      });
    },
    openContextMenu(payload) {
      this.$refs.categoryContextMenu.open(payload.e, payload.data);
    },
    clickNode(node) {
      this.selectedCategoryId = node.id;
    },
    nodeDropped(payload) {
      this.isLoading = true;
      return RestService.postMoveCategory({
        id: payload.node.id,
        parent: payload.target.id,
      })
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isLoading = false;
        });
    },
    nodeMoved(payload) {
      this.isLoading = true;
      return RestService.postMoveCategory({
        id: payload.node.id,
        next: payload.target.id,
      })
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isLoading = false;
        });
    },
    addNewMainCategory() {
      this.newModel = null;
      this.showNewModal = true;
    },
    addNewCategory(model) {
      this.newModel = model;
      this.showNewModal = true;
    },
    deleteCategory(model) {
      if (model.children && model.children.length) {
        errorMessage(
          "Viga!",
          "Kategooriat ei saa kustutada kuna sellel on alamkategooriaid."
        );
        return;
      }

      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.postDeleteCategory({
              id: model.id,
            })
              .then(() => {
                this.isLoading = false;
                model.remove();
              })
              .catch(() => {
                errorMessage(
                  "Viga!",
                  "Kategooriat ei saa kustutada kuna sellel on alamkategooriaid."
                );
                this.isLoading = false;
              });
          }
        },
      });
    },
    handleNewCategorySubmit() {
      this.isModalLoading = true;
      let parentId = null;
      if (this.newModel) {
        parentId = this.newModel.id;
      }

      return RestService.postCreateCategory({
        parent: parentId,
        ...this.newForm,
      }).then((response) => {
        var node = new TreeNode(response.data.data);
        if (this.newModel) {
          this.newModel.addChildren(node, true);
        } else {
          this.categories.addChildren(node, true);
        }

        this.showNewModal = false;
        this.isModalLoading = false;

        this.newModel = null;
        this.newForm = {
          name: null,
          slug: null,
        };
      });
    },
    changeDragging(value) {
      this.isDragging = value;
    },
  },
};
</script>

<style lang="scss">
.vtl {
  .vtl-drag-disabled {
    background-color: #d0cfcf;
    &:hover {
      background-color: #d0cfcf;
    }
  }
  .vtl-disabled {
    background-color: #d0cfcf;
  }
}

.category-dragging {
  .vtl-node-main {
    .vtl-node-content {
      pointer-events: none;
      span {
        pointer-events: none;
      }
    }
  }
}

.v-context.categories {
  &,
  & ul {
    padding: 0;

    > li {
      > a {
        padding: 2px 10px;
        border-bottom: 1px solid #eee;
        font-size: 14px;

        &:nth-last-child(0) {
          border: 0;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.icon {
  &:hover {
    cursor: pointer;
  }
}
</style>
