import Vue from "vue";

export const Suppliers = Vue.component(
  "suppliers",
  require("./Suppliers.vue").default
);
export const SupplierEdit = Vue.component(
  "supplier-edit",
  require("./SupplierEdit.vue").default
);
export const SupplierNew = Vue.component(
  "supplier-new",
  require("./SupplierNew.vue").default
);
