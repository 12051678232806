<template>
  <div class="position-relative">
    <loader :overlay="true" v-if="isLoading" />
    <h1>
      Duplikaat toodete matchimine
    </h1>

    <b-row class="mb-3">
      <b-col md="6">
        <b-form @submit.prevent="search">
          <b-form-group class="mb-1">
            <b-input-group>
              <b-form-input
                class="form-control"
                v-model="form.id"
                placeholder="Toote ID"
                size="sm"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" size="sm" type="submit">
                  Lae duplikaadid
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-form>
      </b-col>
      <b-col md="6">
        <b-button variant="primary" size="sm" @click.prevent="fetchProducts">
          Lae järjest duplikaate
        </b-button>
      </b-col>
    </b-row>

    <b-table-simple small bordered v-if="products">
      <b-tr variant="primary">
        <b-th></b-th>
        <b-th></b-th>
        <b-th>Nimi</b-th>
        <b-th>Laod</b-th>
        <b-th></b-th>
      </b-tr>
      <b-tr v-for="product in products" :key="product.id">
        <b-td class="text-center">
          <Checkbox v-model="product.isChecked" />
        </b-td>
        <b-td>
          <img v-bind:src="product.main_image" alt="" class="img-fluid" style="max-width: 100px;" />
        </b-td>
        <b-td>
          <router-link
            class="product-link"
            :to="{
              name: 'products.edit',
              params: { productId: product.id },
            }"
          >
            {{ product.name }}</router-link
          >

          <br />
          <strong>Tootja: </strong> {{ product.manufacturer }}
          <br />

          <template v-if="product.codes.length">
            <strong>Toote koodid:</strong> <br />
            <ul class="list-unstyled small">
              <li v-for="code in product.codes" :key="code.id">
                <strong> {{ code.type }}: </strong>
                {{ code.code }}
              </li>
            </ul>
          </template>
        </b-td>
        <b-td>
          <b-table-simple bordered small v-if="product.stocks">
            <b-tr variant="primary">
              <b-th>Ladu</b-th>
              <b-th>Laoseis</b-th>
              <b-th>Hind</b-th>
            </b-tr>
            <b-tr v-for="stock in product.stocks" :key="stock.id">
              <b-td>{{ stock.supplier.name }}</b-td>
              <b-td>{{ stock.in_stock }}</b-td>
              <b-td>{{ stock.price }}</b-td>
            </b-tr>
          </b-table-simple>
        </b-td>
        <b-td style="width:10%;" class="text-right">
          <b-button
            size="sm"
            variant="warning"
            @click.prevent="handleNotDuplicate(product.id)"
          >
            Ei&nbsp;ole&nbsp;duplikaat
          </b-button>
        </b-td>
      </b-tr>
    </b-table-simple>
    <div class="text-right">
      <b-button
        variant="primary"
        size="sm"
        @click.prevent="createMerge"
        v-if="selectedProducts.length >= 2"
      >
        Merge valitud
      </b-button>
      <b-button
        variant="warning"
        size="sm"
        @click.prevent="createAutoMerge"
        v-if="selectedProducts.length >= 2"
      >
        Auto merge
      </b-button>
    </div>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import Checkbox from "@/components/Checkbox";
import { errorMessage } from "@/lib/messages";

export default {
  components: {
    Checkbox,
  },
  data() {
    return {
      isLoading: false,
      products: null,
      form: {
        id: null,
      },
    };
  },
  computed: {
    selectedProducts() {
      if (!this.products) {
        return [];
      }

      return this.products.filter((p) => p.isChecked);
    },
  },
  methods: {
    fetchProducts() {
      this.products = null;
      this.isLoading = true;
      RestService.getAutoMatchedProductsFirst()
        .then((response) => {
          this.products = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    search() {
      this.products = null;
      this.isLoading = true;
      RestService.getAutoMatchedProductsForId(this.form)
        .then((response) => {
          this.products = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    handleNotDuplicate(id) {
      this.isLoading = true;

      RestService.postSetProductNotDuplicate(id)
        .then(() => {
          this.fetchProducts();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    createMerge() {
      this.isLoading = true;
      let selectedProductIds = this.selectedProducts.map((p) => p.id);

      RestService.postCreateProductMerge({
        ids: selectedProductIds,
      })
        .then(() => {
          this.fetchProducts();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    createAutoMerge() {
      this.isLoading = true;
      let selectedProductIds = this.selectedProducts.map((p) => p.id);

      RestService.postCreateProductMergeAuto({
        ids: selectedProductIds,
        main_id: null,
      })
        .then(() => {
          this.fetchProducts();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
  },
};
</script>
