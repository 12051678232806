import Vue from "vue";

export const DiscountCodes = Vue.component(
  "discount-codes",
  require("./DiscountCodes.vue").default
);
export const DiscountCodeEdit = Vue.component(
  "discount-code-edit",
  require("./DiscountCodeEdit.vue").default
);
export const DiscountCodeNew = Vue.component(
  "discount-code-new",
  require("./DiscountCodeNew.vue").default
);
