<template>
  <b-tr>
    <b-td>{{ synonym.main_name }}</b-td>
    <b-td>
      <template v-if="isEditing">
        <b-form-group
          label="Sünonüümid"
          description="Sisestage uued sünonüümid, eraldades need tühiku, koma või semikooloniga"
        >
          <b-form-tags
            v-model="newForm.synonyms"
            separator=" ,;"
            required
          ></b-form-tags>
        </b-form-group>
      </template>
      <template v-else>
        <b-badge class="ml-1" v-for="(s, sKey) in synonym.synonyms" :key="sKey">{{ s }}</b-badge>
      </template>
    </b-td>
    <b-td class="text-right">
      <b-button :disabled="isLoading" variant="success" size="sm" @click.prevent="saveSynonym" v-if="isEditing">
        Salvesta
        <b-spinner label="Loading..." small v-if="isLoading"></b-spinner>
      </b-button>
      <b-button variant="warning" size="sm" @click.prevent="showEdit" v-else>Muuda</b-button>
      <b-button class="ml-2" variant="danger" size="sm" v-if="!isEditing" @click.prevent="deleteSynonym">X</b-button>
    </b-td>
  </b-tr>
</template>

<script>
import { errorMessage } from '@/lib/messages';
import RestService from '@/lib/RestService';

export default {
  props: {
    synonym: {}
  },
  data() {
    return {
      isLoading: false,
      isEditing: false,
      newForm: {
        synonyms: []
      }
    };
  },
  mounted() {},
  methods: {
    deleteSynonym() {
      this.$emit('delete', this.synonym.id)
    },
    showEdit() {
      this.newForm.synonyms = this.synonym.synonyms
      this.isEditing = true
    },
    saveSynonym() {
      this.isLoading = true;
      return RestService.postUpdateSearchSynonyms(this.synonym.id ,this.newForm)
        .then((response) => {
          this.$emit('update', response.data.data)
          this.isEditing = false
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    }
  },
};
</script>

<style lang="scss"></style>
