<template>
  <div>
    <h3>Stocks</h3>

    <b-tabs>
      <b-tab class="pt-3" title="Inline" active>
        <loader v-if="!stockItems.length" />
        <StockInline :stockItems="stockItems" v-if="stockItems.length" />
      </b-tab>
      <b-tab class="pt-3" title="List">
        <b-row>
          <b-col md="6">
            <h3>Stocks</h3>

            <b-card no-body>
              <b-tabs class="stock-list">
                <b-tab title="Ordered" active>
                  <StockItem
                    v-for="item in orderedStockItems"
                    :item="item"
                    v-bind:key="item.id"
                  />
                </b-tab>
                <b-tab title="In stock">
                  <StockItem
                    v-for="item in inStockStockItems"
                    :item="item"
                    v-bind:key="item.id"
                  />
                </b-tab>
                <b-tab title="Out of stock">
                  <StockItem
                    v-for="item in outOfStockStockItems"
                    :item="item"
                    v-bind:key="item.id"
                  />
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
          <b-col md="6">
            <h3>Add new stock item</h3>
            <NewStockItem />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import StockItem from "./StockItem";
import StockInline from "./StockInline";
import NewStockItem from "./NewStockItem";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    StockItem,
    NewStockItem,
    StockInline,
  },
  mounted() {
    this.fetchStorageItems();
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      stockItems: "stock/stockItems",
      orderedStockItems: "stock/orderedStockItems",
      inStockStockItems: "stock/inStockStockItems",
      outOfStockStockItems: "stock/outOfStockStockItems",
    }),
  },
  methods: {
    ...mapActions({
      fetchStorageItems: "stock/fetchStorageItems",
    }),
  },
};
</script>

<style lang="scss">
.stock-list {
  .nav-tabs {
    margin: 10px;
  }
  .tab-content {
    padding: 0.5rem;
  }
}
</style>
