<template>
  <div>
    <b-button
      class="float-right"
      variant="primary"
      size="sm"
      @click.prevent="showSupplierModal = true"
    >
      Telli toode käsitsi
    </b-button>

    <b-modal
      v-model="showSupplierModal"
      hide-footer
      size="lg"
      title="Tellimine"
      body-class="p-0"
    >
      <loader :overlay="true" v-if="isModalLoading" />

      <b-card no-body>
        <b-tabs small card vertical pills nav-class="p-2">
          <b-tab title="MT Shop">
            <b-alert variant="warning" show>
              Hetkel ei saa seda veel tellida!
            </b-alert>
          </b-tab>
          <b-tab title="Morele" active>
            <b-form @submit.prevent="orderMorele">
              <b-form-group class="mb-1" label="Toote nimi">
                <b-form-input
                  v-model="moreleForm.product_name"
                  size="sm"
                ></b-form-input>
              </b-form-group>
              <b-form-group class="mb-1" label="Morele ID">
                <b-form-input
                  v-model="moreleForm.product_id"
                  size="sm"
                ></b-form-input>
              </b-form-group>

              <b-form-group class="mb-1" label="Morele Hind">
                <b-form-input
                  v-model="moreleForm.price"
                  size="sm"
                ></b-form-input>
              </b-form-group>

              <b-form-group class="mb-1" label="Kogus">
                <b-form-input
                  v-model.number="moreleForm.quantity"
                  type="number"
                  size="sm"
                ></b-form-input>
              </b-form-group>

              <div class="text-right">
                <b-button class="mt-2" variant="success" type="submit">
                  Telli
                </b-button>
              </div>
            </b-form>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
export default {
  props: {
    orderId: {
      default: null,
    },
  },
  data() {
    return {
      isModalLoading: false,
      showSupplierModal: false,
      moreleForm: {
        product_name: null,
        product_id: null,
        price: 0,
        quantity: 1,
      },
    };
  },
  methods: {
    orderMorele() {
      this.isModalLoading = true;
      RestService.postOrderSupplierProduct({
        order_id: this.orderId,
        type: "morele",
        ...this.moreleForm,
      })
        .then(() => {
          this.showSupplierModal = false;
          this.$emit("refreshOrder");
          this.isModalLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }

          this.isModalLoading = false;
        });
    },
  },
};
</script>
