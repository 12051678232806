<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>Brändid</h1>

    <b-row>
      <b-col md="6">
        <b-form @submit.prevent="fetchManufacturers">
          <b-form-group class="mb-1">
            <b-input-group>
              <b-form-input
                class="form-control"
                v-model="keyword"
                size="sm"
                placeholder="Otsi brändi"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" size="sm" type="submit">
                  Otsi
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group class="mb-1">
              <b-form-checkbox v-model="only_with_products" :value="1" :unchecked-value="0" switch>
                Kuva ainult millel on tooteid
              </b-form-checkbox>
            </b-form-group>
        </b-form>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col md="6">
        <b-table-simple bordered responsive small class="mb-0" v-if="manufacturers.length">
          <b-tr variant="primary">
            <b-th>
              Tootja
            </b-th>
            <b-th class="text-center" style="width: 15%;">On tooteid</b-th>
            <b-th class="text-center" style="width: 15%;">Featured</b-th>
          </b-tr>

          <b-tr v-for="manufacturer in manufacturers" :key="manufacturer.id">
            <b-td>
              <router-link
                :to="{
                  name: 'brands.view',
                  params: { brandId: manufacturer.id },
                }"
              >
                {{ manufacturer.name }}
              </router-link>
            </b-td>
            <b-td class="text-center">
              <font-awesome-icon class="text-success" v-if="manufacturer.has_products" icon="check" />
              <font-awesome-icon class="text-danger" v-else icon="times" />
            </b-td>
            <b-td class="text-center">
              <font-awesome-icon class="text-success" v-if="manufacturer.is_featured" icon="check" />
              <font-awesome-icon class="text-danger" v-else icon="times" />
            </b-td>
          </b-tr>
        </b-table-simple>

        <template v-if="pagination">
          <small>Kokku: {{ pagination.total }}</small>
          <b-pagination
            class="mt-3"
            v-model="currentPage"
            :total-rows="pagination.total"
            :per-page="pagination.per_page"
            @change="pageChange"
          ></b-pagination>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";

export default {
  data() {
    return {
      isLoading: false,
      keyword: null,
      only_with_products: 1,
      currentPage: 1,
      manufacturers: [],
      pagination: null,
    };
  },
  mounted() {
    this.fetchManufacturers();
  },
  methods: {
    fetchManufacturers(page = 1) {
      this.isLoading = true;
      RestService.getBrandManufacturers({
        page: page,
        keyword: this.keyword,
        with_products: this.only_with_products,
      })
        .then((response) => {
          this.manufacturers = response.data.data;
          this.pagination = response.data.meta.pagination;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    pageChange(page) {
      this.fetchManufacturers(page);
    },
  },
};
</script>
