<template>
  <div>
    <b-overlay :show="isLoading">
      <label
        class="dropzone"
        :class="{ dragover: dragOver }"
        @dragenter="dragEnter"
        @dragleave="dragLeave"
        @drop.prevent="addFile"
        @dragover.prevent
      >
        <span>Vali fail või lohista siia</span>
        <input
          type="file"
          name="file"
          :multiple="multiple"
          @change="handleFileChange"
        />
      </label>
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dragOver: false,
    };
  },
  methods: {
    handleFileChange(e) {
      if (e.target.files.length) {
        this.uploadFile(e.target.files);
      }
    },
    addFile(e) {
      let files = e.dataTransfer.files;
      if (files.length) {
        this.uploadFile(files);
      }

      this.dragOver = false;
    },
    uploadFile(files) {
      this.$emit("handleUpload", files);
    },
    dragEnter() {
      this.dragOver = true;
    },
    dragLeave() {
      this.dragOver = false;
    },
  },
};
</script>

<style lang="scss">
.dropzone {
  border: 3px dashed #a6a6a6;
  padding: 30px;
  margin-bottom: 25px;
  text-align: center;
  color: #a6a6a6;
  cursor: pointer;
  display: block;

  span {
    pointer-events: none;
  }

  input {
    display: none;
  }

  &.dragover {
    border: 3px dashed #7a9b09;
    color: #7a9b09;
  }
}
</style>
