<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Toote lao staatused
      <router-link
        class="btn btn-primary btn-sm"
        :to="{
          name: 'productStockStatus.new',
        }"
      >
        Lisa uus
      </router-link>
    </h1>

    <b-table-simple bordered responsive small>
      <b-tr variant="primary">
        <b-th>Nimi</b-th>
        <b-th>Key</b-th>
        <b-th style="width: 15%;">Tarne päevades</b-th>
        <b-th style="width: 5%;"></b-th>
      </b-tr>
      <b-tr v-for="stockStatus in stockStatuses" :key="stockStatus.id">
        <b-td>{{ stockStatus.name }}</b-td>
        <b-td>{{ stockStatus.key }}</b-td>
        <b-td>{{ stockStatus.delivery_in_days }}</b-td>
        <b-td class="text-right">
          <router-link
            class="btn btn-warning btn-sm"
            :to="{
              name: 'productStockStatus.edit',
              params: { statusId: stockStatus.id },
            }"
          >
            Muuda
          </router-link>
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";

export default {
  data() {
    return {
      isLoading: false,
      stockStatuses: [],
    };
  },
  computed: {},
  mounted() {
    this.fetchStockStatuses();
  },
  methods: {
    fetchStockStatuses() {
      this.isLoading = true;

      RestService.getProductStockStatuses().then((response) => {
        this.stockStatuses = response.data.data;
        this.isLoading = false;
      });
    },
  },
};
</script>
