<template>
  <div class="mapper">
    <b-overlay :show="isLoading" opacity="0.3" variant="white" rounded="sm">
      <h1>Kategooria mapper (Kõik tarnijad)</h1>
      <div class="row">
        <div class="col">
          <b-form @submit.prevent="search">
            <b-form-group class="mb-1">
              <b-input-group>
                <b-form-input
                  class="form-control"
                  v-model="searchKey"
                  size="sm"
                ></b-form-input>
                <b-input-group-append>
                  <b-button variant="primary" size="sm" type="submit">
                    Otsi
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-form>

          <div class="list-group scroll-list">
            <button
              type="button"
              class="list-group-item list-group-item-action"
              v-for="map in filteredCategoryMaps"
              v-bind:key="map.id"
              @click.prevent="getMap(map)"
            >
              {{ map.supplier_category }}

              <div class="float-right">
                <span
                  class="badge badge-light badge-pill mr-2"
                  v-if="map.has_products"
                >
                  on tooteid
                </span>
                <span
                  class="badge badge-warning badge-pill mr-2"
                  v-if="map.ignore_on_import"
                >
                  ignore
                </span>
                <span
                  class="badge badge-success badge-pill mr-2"
                  v-if="map.categories.length"
                >
                  ok
                </span>
                <span class="badge badge-primary badge-pill">
                  {{ map.supplier.data.name }}
                </span>
              </div>
            </button>
          </div>

          <b-alert
            v-if="!filteredCategoryMaps.length"
            class="mt-2 p-2"
            variant="warning"
            show
          >
            Tulemusi ei leitud!
          </b-alert>
        </div>
        <div class="col">
          <b-card v-if="selectedMap">
            <MapInfo :map="selectedMap" />
          </b-card>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import MapInfo from "./MapInfo";
import { mapActions, mapGetters } from "vuex";
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";

export default {
  components: {
    MapInfo,
  },
  data() {
    return {
      isLoading: false,
      searchKey: "",
      showNotMapped: false,
    };
  },
  computed: {
    ...mapGetters({
      filteredCategoryMaps: "categoryMapper/filteredCategoryMaps",
      selectedMap: "categoryMapper/selectedMap",
    }),
  },
  mounted() {
    this.reset();
    this.fetchCategories();
  },
  methods: {
    ...mapActions({
      reset: "categoryMapper/reset",
      fetchCategories: "categories/fetchCategories",
      getCategoryMap: "categoryMapper/getCategoryMap",
      setCategoryMaps: "categoryMapper/setCategoryMaps",
    }),
    getMap(map) {
      this.isLoading = true;
      this.getCategoryMap(map.id).then(() => {
        this.isLoading = false;
      });
    },
    search() {
      this.isLoading = true;
      RestService.getSearchCategoryMaps({
        query: this.searchKey,
      })
        .then((response) => {
          this.setCategoryMaps(response.data.data);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.scroll-list {
  max-height: 70vh;
  overflow-y: auto;
}

.mapper {
  .list-group-item {
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
  }
}
</style>
