<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Lisa uus staatus
      <b-button
        variant="success"
        size="sm"
        class="float-right"
        @click.prevent="handleSave"
      >
        Salvesta
      </b-button>
    </h1>

    <template>
      <b-row>
        <b-col md="6">
          <b-card header="Staatuse info">
            <b-form-group class="mb-1" label="Key">
              <b-form-input
                v-model="form.key"
                size="sm"
                :state="getFieldStateFromErrors('key')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("key") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group class="mb-1" label="Tarne päevades">
              <b-form-input
                type="number"
                v-model.number="form.delivery_in_days"
                size="sm"
                :state="getFieldStateFromErrors('delivery_in_days')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("delivery_in_days") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card>
            <template #header>
              <div>
                Tõlked
                <b-dropdown
                  class="float-right"
                  variant="primary"
                  size="sm"
                  text="Lisa tõlge"
                >
                  <b-dropdown-item
                    v-for="language in languages"
                    :key="language.locale"
                    @click.prevent="addNewLanguage(language.locale)"
                    :disabled="false"
                  >
                    {{ language.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <div
              v-for="translation in form.translations"
              :key="translation.id"
              class="language"
            >
              <div
                class="deletable-language"
                v-if="isLanguageDeleted(translation.locale)"
              >
                This language is marked for delete <br />
                <b-button
                  variant="warning"
                  @click.prevent="restoreLanguage(translation.locale)"
                >
                  Restore
                </b-button>
              </div>
              <h4>
                {{ getLocaleName(translation.locale) }}
                <b-button
                  variant="danger"
                  size="sm"
                  @click.prevent="removeLanguage(translation.locale)"
                >
                  Delete language
                </b-button>
              </h4>
              <b-form-group label="Nimi" class="mb-1">
                <b-form-input
                  v-model="translation.name"
                  size="sm"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Info" class="mb-1">
                <b-form-textarea
                  v-model="translation.description"
                  rows="5"
                  size="sm"
                ></b-form-textarea>
              </b-form-group>
              <hr />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapGetters } from "vuex";
import { errorMessage } from "@/lib/messages";
import ErrorsMixin from "@/helpers/ErrorsMixin";
import TranslationsMixin from "@/helpers/TranslationsMixin";

export default {
  mixins: [ErrorsMixin, TranslationsMixin],
  data() {
    return {
      isLoading: false,
      form: {
        key: null,
        delivery_in_days: 0,
        translations: [],
        deletable_translations: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      languages: "generic/languages",
    }),
  },
  methods: {
    handleSave() {
      this.isLoading = true;

      let payload = this.form;

      RestService.postSaveNewProductStockStatus(payload)
        .then((response) => {
          this.$router.push({
            name: "productStockStatus.edit",
            params: { statusId: response.data.data.id },
          });
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
    addNewLanguage(locale) {
      if (this.form.translations.filter((t) => t.locale == locale).length) {
        errorMessage("Viga", "See keel on juba lisatud");
        return;
      }

      this.form.translations.push({
        locale: locale,
        name: null,
        description: null,
      });
    },
  },
};
</script>
