<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Otsingu sünonüümid
      <b-button
        class="mr-2"
        variant="primary"
        size="sm"
        @click.prevent="saveSearchSettings"
      >
        <font-awesome-icon icon="sync" />
      </b-button>
    </h1>

    <b-card class="mb-3" header="Lisa uus" no-body small>
      <b-form class="p-3" @submit.prevent="addNewSynonym">
        <b-form-group label="Põhisõna">
          <b-form-input
            v-model="newForm.main_name"
            trim
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Sünonüümid"
          description="Sisestage uued sünonüümid, eraldades need tühiku, koma või semikooloniga"
        >
          <b-form-tags
            v-model="newForm.synonyms"
            separator=" ,;"
            required
          ></b-form-tags>
        </b-form-group>
        <b-button type="submit" variant="success" size="sm">Lisa uus</b-button>
      </b-form>
    </b-card>

    <b-table-simple small bordered>
      <b-tr variant="primary">
        <b-th style="width: 25%">Põhisõna</b-th>
        <b-th>Sünonüümid</b-th>
        <b-th style="width: 10%">Tegevused</b-th>
      </b-tr>
      <SynonymRow
        v-for="synonym in synonyms"
        :synonym="synonym"
        :key="synonym.id"
        @delete="deleteSynonym"
        @update="updateSynonym"
      ></SynonymRow>
    </b-table-simple>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
import SynonymRow from "./SynonymRow.vue";

export default {
  components: {
    SynonymRow,
  },
  data() {
    return {
      isLoading: false,
      synonyms: [],
      newForm: {
        main_name: null,
        synonyms: [],
      },
    };
  },
  mounted() {
    this.fetchSynonyms();
  },
  methods: {
    fetchSynonyms() {
      this.isLoading = true;
      return RestService.getSearchSynonyms()
        .then((response) => {
          this.synonyms = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    },
    addNewSynonym() {
      this.isLoading = true;
      return RestService.postCreateSearchSynonyms(this.newForm)
        .then(() => {
          this.fetchSynonyms();
          this.newForm = {
            main_name: null,
            synonyms: [],
          };
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    },
    deleteSynonym(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.postDeleteSearchSynonyms(id)
              .then(() => {
                this.fetchSynonyms();
              })
              .catch((error) => {
                this.isLoading = false;
                if (error.response.data.message) {
                  errorMessage("Viga!", error.response.data.message);
                } else {
                  errorMessage("Viga!", error);
                }
              });
          }
        },
      });
    },
    updateSynonym(synonym) {
      let index = this.synonyms.findIndex((s) => s.id == synonym.id)
      this.$set(this.synonyms, index, synonym);
    },
    saveSearchSettings() {
      this.isLoading = true;
      return RestService.postSaveSearchSynonymsSettings()
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    }
  },
};
</script>

<style lang="scss"></style>
