<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="category" />
    <template v-if="category">
      <h3>
        <small>{{ category.name }}</small>
      </h3>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Info" class="p-2" active>
            <b-row>
              <b-col md="4">
                <b-table-simple bordered small responsive="">
                  <b-tr>
                    <b-td style="width: 50%;">
                      ID
                    </b-td>
                    <b-td>
                      <strong>
                        {{ category.id }}
                      </strong>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td style="width: 50%;">
                      Total maps
                    </b-td>
                    <b-td>
                      <strong>
                        {{ category.total_maps }}
                      </strong>
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </b-col>
              <b-col>
                <b-table-simple bordered small responsive="">
                  <b-tr>
                    <b-td style="width: 50%;">
                      Total live products with sub categories
                    </b-td>
                    <b-td>
                      <strong>
                        {{ category.product_count_all }}
                      </strong>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td style="width: 50%;">
                      Total live products in this category
                    </b-td>
                    <b-td>
                      <strong>
                        {{ category.product_count }}
                      </strong>
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </b-col>
            </b-row>
            <b-card
              no-body
              class="p-2 mb-2"
              v-for="translation in category.translations"
              :key="translation.locale"
            >
              <strong class="mb-2">
                {{ getLocaleName(translation.locale) }}
              </strong>
              <b-form-group class="mb-1">
                <b-input-group size="sm" prepend="Nimi">
                  <b-form-input
                    v-model="translation.name"
                    size="sm"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group class="mb-1">
                <b-input-group size="sm" prepend="Slug">
                  <b-form-input
                    v-model="translation.slug"
                    size="sm"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group class="mb-1">
                <b-input-group size="sm" prepend="Uri">
                  <b-form-input
                    v-model="translation.uri"
                    size="sm"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-card>
          </b-tab>
          <b-tab title="Pildid" class="p-2">
            <b-row>
              <b-col md="12">
                <h5 class="font-weight-bold">Thumbnail pilt</h5>
              </b-col>
              <b-col md="6">
                <upload
                  :isLoading="isUploadLoading"
                  @handleUpload="handleThumbnailImageUpload"
                />
              </b-col>
              <b-col md="6">
                <img
                  v-if="category.thumbnail_image"
                  :src="category.thumbnail_image"
                  class="img-fluid"
                  style="max-height: 100px;"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <h5 class="font-weight-bold">Avalehe pilt</h5>
              </b-col>
              <b-col md="6">
                <upload
                  :isLoading="isUploadLoading"
                  @handleUpload="handleHomeImageUpload"
                />
              </b-col>
              <b-col md="6">
                <img
                  v-if="category.home_image"
                  :src="category.home_image"
                  class="img-fluid"
                  style="max-height: 100px;"
                />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Mapid" class="p-2">
            <b-table-simple
              bordered
              responsive
              small
              v-if="category.categoryMaps.length"
            >
              <b-tr variant="primary">
                <b-th>Tarnija kategooria</b-th>
                <b-th>Tarnija</b-th>
                <b-th></b-th>
              </b-tr>
              <b-tr v-for="map in category.categoryMaps" :key="map.id">
                <b-td>{{ map.supplier_category }}</b-td>
                <b-td>{{ map.supplier.data.name }}</b-td>
                <b-td class="text-right">
                  <b-button
                    variant="danger"
                    size="sm"
                    @click.prevent="removeMapFromCategory(map.id)"
                  >
                    X
                  </b-button>
                </b-td>
              </b-tr>
            </b-table-simple>
            <b-alert v-else variant="warning" show>
              Mappe ei ole siia kategooria külge lisatud.
            </b-alert>
          </b-tab>
          <b-tab v-if="!isEemeli" title="Filtrid" class="p-2 position-relative" style="min-height: 150px;" @click="fetchCategoryPossibleFilters" lazy>
            <loader v-if="isFiltersLoading" :overlay="true" />
            <div v-if="possibleFilters">
              <div v-if="possibleFilters">
                <strong>Tooteid kokku: </strong> {{ possibleFilters.products_total }}<br> 
                <strong>Tooteid andmetega: </strong> {{ possibleFilters.products_with_specs }}<br> 
              </div>
              <b-table-simple bordered small v-if="possibleFilters && possibleFilters.possible_filters">
                <b-tr variant="primary">
                  <b-th style="width:70%;">Parameeter</b-th>
                  <b-th class="text-center" style="width:15%;">Kokku tooteid</b-th>
                  <b-th class="text-center" style="width:15%;"></b-th>
                </b-tr>
                <b-tr v-for="filter in possibleFilters.possible_filters" :key="filter.id">
                  <b-td>{{ filter.name }}</b-td>
                  <b-td class="text-center">{{ filter.total }}</b-td>
                  <b-td class="text-center">
                    <b-button variant="success" size="sm" v-if="!filter.has_assigned" @click.prevent="addFilterToCategory(filter)">Lisa kategooriale</b-button>
                    <b-button variant="outline-danger" size="sm" v-else @click.prevent="removeFilterFromCategory(filter)">Eemalda</b-button>
                  </b-td>
                </b-tr>
              </b-table-simple>
            </div>
            <div v-else>
              <b-button variant="primary" @click.prevent="fetchCategoryPossibleFilters">Lae andmed</b-button>
            </div>
          </b-tab>
          <b-tab title="Muu" class="p-2">
            <b-form-group label="Kategooria tüüp" class="mb-1">
              <b-form-select
                v-if="categoryTypesSelect.length"
                v-model="category.type"
                :options="categoryTypesSelect"
                size="sm"
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Ikoon" class="mb-3">
              <b-form-select
                v-if="categoryIconsSelect.length"
                v-model="category.icon"
                :options="categoryIconsSelect"
                size="sm"
              ></b-form-select>
            </b-form-group>

            <b-form-group class="mb-1">
              <b-form-checkbox v-model="category.is_featured" switch>
                Is featured
              </b-form-checkbox>
            </b-form-group>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="category.is_visible" switch>
                Is visible
              </b-form-checkbox>
            </b-form-group>
          </b-tab>
          <div class="text-right mb-2 mr-2">
            <b-button variant="success" size="sm" @click.prevent="saveCategory">
              Salvesta kategooria
            </b-button>
          </div>
        </b-tabs>
      </b-card>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    categoryId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isUploadLoading: false,
      isFiltersLoading: false,
      category: null,
      possibleFilters: null,
    };
  },
  computed: {
    ...mapGetters({
      isEemeli: "global/isEemeli",
      languages: "generic/languages",
      categoryTypesSelect: "categories/categoryTypesSelect",
      categoryIconsSelect: "categories/categoryIconsSelect",
    }),
  },
  mounted() {
    this.fetchCategory();
  },
  methods: {
    ...mapActions({
      updateCategory: "categories/updateCategory",
    }),
    fetchCategory() {
      this.isLoading = true;
      return RestService.getCategory(this.categoryId).then((response) => {
        this.category = response.data.data;
        this.possibleFilters = null;
        this.isLoading = false;
      });
    },
    getLocaleName(locale) {
      let language = this.languages.find((l) => l.locale == locale);

      if (!language) {
        return locale;
      }

      return language.name;
    },
    saveCategory() {
      this.isLoading = true;
      this.updateCategory(this.category)
        .then((response) => {
          this.category = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    removeMapFromCategory(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.removeCategoryFromMap(id, this.categoryId).then(() => {
              this.fetchCategory();
            }).catch((error) => {
              this.isLoading = false;
              if (error.response.data.message) {
                errorMessage("Viga!", error.response.data.message);
              }
            });
          }
        },
      });
    },
    handleThumbnailImageUpload(files) {
      let formData = new FormData();
      formData.append("file", files[0]);

      this.isLoading = true;
      RestService.postCategoryUploadThumbnailImage(this.category.id, formData)
        .then(() => {
          this.fetchCategory();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    handleHomeImageUpload(files) {
      let formData = new FormData();
      formData.append("file", files[0]);

      this.isLoading = true;
      RestService.postCategoryUploadHomeImage(this.category.id, formData)
        .then(() => {
          this.fetchCategory();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    fetchCategoryPossibleFilters() {
      this.isFiltersLoading = true;
      RestService.getCategoryPossibleFilters(this.category.id)
        .then((response) => {
          this.possibleFilters = response.data.data
          this.isFiltersLoading = false;
        })
        .catch((error) => {
          this.isFiltersLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    addFilterToCategory(filter) {
      this.isFiltersLoading = true;
      RestService.addCategoryFilterAttribute(this.category.id, {
        import_product_attribute_id: filter.import_product_attribute_id
      })
        .then(() => {
          filter.has_assigned = true;
          // let fIndex = this.possibleFilters.possible_filters.findIndex((i) => i.import_product_attribute_id = filter.import_product_attribute_id);
          // this.$set(this.possibleFilters.possible_filters[fIndex], 'has_assigned', true);
          this.isFiltersLoading = false;
        })
        .catch((error) => {
          this.isFiltersLoading = false;
          if (error.response && error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    removeFilterFromCategory(filter) {
      this.isFiltersLoading = true;
      RestService.removeCategoryFilterAttribute(this.category.id, {
        import_product_attribute_id: filter.import_product_attribute_id
      })
        .then(() => {
          filter.has_assigned = false;
          // let fIndex = this.possibleFilters.possible_filters.findIndex((i) => i.import_product_attribute_id = filter.import_product_attribute_id);
          // this.$set(this.possibleFilters.possible_filters[fIndex], 'has_assigned', false);
          this.isFiltersLoading = false;
        })
        .catch((error) => {
          this.isFiltersLoading = false;
          if (error.response && error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    }
  },
  watch: {
    categoryId() {
      this.fetchCategory();
    },
  },
};
</script>
