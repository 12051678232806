<template>
  <b-tr :variant="rowVariant">
    <b-td>
      <a href="#" class="text-danger" @click.prevent="deleteStock">
        <font-awesome-icon icon="times" />
      </a>
      {{ stock.supplier.name }}
    </b-td>
    <b-td class="text-center">
      <span class="net-price">
        {{ stock.net_price | currency }}
      </span>
    </b-td>
    <b-td class="text-center">
      <template v-if="stock.manual_price !== null">
        <b-input-group size="sm">
          <template #prepend>
            <b-input-group-text>
              <a href="#" class="text-danger" @click.prevent="removeStockPrice">
                <font-awesome-icon icon="times" />
              </a>
            </b-input-group-text>
          </template>
          <template #append>
            <b-input-group-text>
              <small style="text-decoration: line-through;">
                {{ stock.price | currency }}
              </small>
            </b-input-group-text>
          </template>
          <b-form-input
            name="smanual_price[]"
            autocomplete="false"
            v-model="stock.manual_price"
            size="sm"
          ></b-form-input>
        </b-input-group>
      </template>
      <template v-else>
        {{ stock.price | currency }}
        <a href="#" @click.prevent="editStockPrice">
          <font-awesome-icon icon="edit" class="ml-1" />
        </a>
      </template>
    </b-td>
    <b-td class="text-center">
      <b-form-input
        name="soriginal_price[]"
        autocomplete="false"
        v-model="stock.original_price"
        size="sm"
      ></b-form-input>
    </b-td>
    <b-td class="text-center">
      <b-form-input
        name="sin_stock[]"
        autocomplete="false"
        v-model="stock.in_stock"
        size="sm"
      ></b-form-input>
    </b-td>
    <b-td class="text-center">
      <b-form-select
        v-if="stockStatusesSelect.length"
        v-model="stock.stock_status_id"
        :options="stockStatusesSelect"
        size="sm"
      ></b-form-select>
    </b-td>

    <b-td class="text-center">
      <StockRowHistory :stock="stock" />
    </b-td>
    <b-td class="text-center">
      <b-form-checkbox v-model="stock.is_disabled" switch></b-form-checkbox>
    </b-td>
  </b-tr>
</template>

<script>
import { mapGetters } from "vuex";
import StockRowHistory from "../productList/StockRowHistory.vue"

export default {
  props: {
    stock: {
      type: Object,
    },
  },
  components: {
    StockRowHistory
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      stockStatusesSelect: "generic/stockStatusesSelect",
    }),
    rowVariant() {
      if (this.stock.is_disabled) {
        return "danger";
      }

      return "";
    },
  },
  methods: {
    editStockPrice() {
      this.stock.manual_price = 0;
    },
    removeStockPrice() {
      this.stock.manual_price = null;
    },
    deleteStock() {
      this.$emit("deleteStock", this.stock.id);
    },
  },
};
</script>

<style lang="scss">
.net-price {
  font-size: 12px;
}
</style>