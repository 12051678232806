<template>
  <div>
    <loader :overlay="true" v-if="isLoading" />
    <b-row class="mb-4">
      <b-col md="6">
        <b-form @submit.prevent="fetchProducts">
          <b-row>
            <b-col sm="12">
              <b-form-group>
                <b-form-radio-group size="sm" v-model="form.type">
                  <b-form-radio value="difference">Väikseim vahe konkurendist</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Min hind" class="mb-1">
                <b-form-input
                  class="form-control"
                  v-model="form.min_mts_price"
                  size="sm"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Max hind" class="mb-1">
                <b-form-input
                  class="form-control"
                  v-model="form.max_mts_price"
                  size="sm"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Min vahe" class="mb-1">
                <b-form-input
                  class="form-control"
                  v-model="form.min_difference"
                  size="sm"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Max vahe" class="mb-1">
                <b-form-input
                  class="form-control"
                  v-model="form.max_difference"
                  size="sm"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-button class="mt-3" variant="primary" size="sm" type="submit">
                Otsi
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <b-row v-if="products">
      <b-col>
        <b-table-simple bordered responsive small v-if="products.length">
          <b-tr variant="primary">
            <b-th style="width: 1%"></b-th>
            <b-th style="width: 5%"></b-th>
            <b-th style="width: 50%">Nimi</b-th>
            <b-th>Laod</b-th>
            <b-th style="width: 5%"></b-th>
          </b-tr>
          <ProductRow
            v-for="product in products"
            :product="product"
            :key="product.id"
            @update="handleProductUpdate"
          />
        </b-table-simple>
        <b-alert v-else variant="warning" show> Tulemusi ei leitud! </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
import ProductRow from "../productList/ProductRow";

export default {
  components: {
    ProductRow,
  },
  data() {
    return {
      isLoading: false,
      products: null,
      form: {
        type: "difference",
        min_mts_price: 100,
        max_mts_price: 100000,
        min_difference: 0,
        max_difference: 1,
      },
    };
  },
  methods: {
    fetchProducts() {
      this.isLoading = true;
      RestService.getProductsDataCollectionProducts(this.form)
        .then((response) => {
          this.products = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isLoading = false;
        });
    },
    handleProductUpdate(product) {
      const pindex = this.products.findIndex((p) => p.id == product.id);
      if (pindex > -1) {
        this.$set(this.products, pindex, product);
      }
    },
  },
};
</script>
