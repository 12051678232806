import {
  Products,
  ProductEdit,
  ProductNew,
  ProductsSearch,
  AutoMatching,
  ProductsMerge,
  NewProductsMerge,
  ConfirmProductsMerge,
  IcecatSearch,
  FixingProducts,
} from "../components";

export default [
  {
    path: "/products",
    redirect: { name: "productsPager", params: { page: 1 } },
    name: "products",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/products/search",
    component: ProductsSearch,
    name: "products-search",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/products/icecat",
    component: IcecatSearch,
    name: "products-icecat",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/products/auto/matching",
    component: AutoMatching,
    name: "products-auto-matching",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/products/merge",
    component: ProductsMerge,
    name: "products-merge",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/products/merge/new",
    component: NewProductsMerge,
    name: "new-products-merge",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/products/merge/confirm/:productId",
    component: ConfirmProductsMerge,
    name: "confirm-products-merge",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/products/fixing",
    component: FixingProducts,
    name: "fixing-products",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/products/:page",
    component: Products,
    name: "productsPager",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/product/new",
    component: ProductNew,
    name: "products.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/product/:productId",
    component: ProductEdit,
    name: "products.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
