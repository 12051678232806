export const categories = (state) => {
  return state.categories;
};

export const categoryTypesSelect = (state) => {
  if (!state.categorySettings) {
    return [];
  }

  if (!state.categorySettings.types) {
    return [];
  }

  return state.categorySettings.types;
};

export const categoryIconsSelect = (state) => {
  if (!state.categorySettings) {
    return [];
  }

  if (!state.categorySettings.icons) {
    return [];
  }

  return state.categorySettings.icons;
};
