<template>
  <div>
    <b-overlay :show="isLoading" opacity="0.3" variant="white" rounded="sm">
      <b-card>
      <b-form-group label="Toote nimi">
        <b-form-input
          v-model="form.name"
          placeholder="Toote nimi"
          size="sm"
          :state="getFieldStateFromErrors('name')"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Tellimise koht">
        <b-form-input
          v-model="form.orderedPlace"
          placeholder="Tellimise koht"
          size="sm"
          :state="getFieldStateFromErrors('ordered_place')"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Hind">
        <b-form-input
          v-model="form.price"
          placeholder="Hind"
          :state="getFieldStateFromErrors('price')"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Lisainfo">
        <b-form-textarea
          placeholder="Lisainfo..."
          v-model="form.additional_info"
          rows="3"
          size="sm"
          :state="getFieldStateFromErrors('additional_info')"
        ></b-form-textarea>
      </b-form-group>

      <div class="text-right">
        <b-button variant="primary" size="sm" @click.prevent="addNew">
          Add new stock item
        </b-button>
      </div>
      <template v-slot:overlay>
        <div class="text-center">
          
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ErrorsMixin from "@/helpers/ErrorsMixin";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,
      form: {
        name: null,
        orderedPlace: null,
        price: null,
        additional_info: null,
      },
    };
  },
  methods: {
    ...mapActions({
      addNewStockItem: "stock/addNewStockItem",
    }),
    addNew() {
      this.isLoading = true;

      this.addNewStockItem({
        ordered_place: this.form.orderedPlace,
        name: this.form.name,
        status: "ordered",
        price: this.form.price,
        additional_info: this.form.additional_info,
      })
        .then(() => {
          this.clearForm();
          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
    clearForm() {
      this.clearValidationErrors();
      this.form = {
        name: null,
        orderedPlace: null,
        price: null,
        additional_info: null,
      };
    },
  },
};
</script>
