import RestService from "@/lib/RestService";

export const fetchShippingZones = ({ commit }) => {
  return RestService.getAllShippingZones().then((response) => {
    commit("SET_SHIPPING_ZONES", response.data.data);
  });
};

export const fetchShippingZone = ({ commit }, payload) => {
  return RestService.getShippingZone(payload.id).then(
    (response) => {
      commit("SET_SHIPPING_ZONE", response.data.data);
    }
  );
};

export const newShippingZone = ({ commit }, payload) => {
  return RestService.postNewShippingZone(payload).then(
    (response) => {
      commit("SET_SHIPPING_ZONE", response.data.data);

      return response;
    }
  );
};

export const saveShippingZone = ({ commit }, payload) => {
  return RestService.postUpdateShippingZone(payload.id, payload.form).then(
    (response) => {
      commit("SET_SHIPPING_ZONE", response.data.data);
    }
  );
};

export const updateSupplierShipping = ({ commit }, payload) => {
  return RestService.postUpdateSupplierShipping(payload.id, payload).then(
    (response) => {
      commit("UPDATE_SUPPLIER_SHIPPING", response.data.data);
    }
  );
};

export const addNewSupplierShipping = ({ commit }, payload) => {
  return RestService.postNewSupplierShipping(payload).then(
    (response) => {
      commit("ADD_SUPPLIER_SHIPPING", response.data.data);
    }
  );
};

export const deleteSupplierShipping = ({ commit }, id) => {
  return RestService.postDeleteSupplierShipping(id).then(
    () => {
      commit("REMOVE_SUPPLIER_SHIPPING", id);
    }
  );
};

export const fetchCountries = ({ commit }) => {
  return RestService.getCountries().then((response) => {
    commit("SET_COUNTRIES", response.data.data);
  });
};

export const fetchShippingMethods = ({ commit }) => {
  return RestService.getAllShippingMethods().then((response) => {
    let list = [];

    response.data.data.forEach((e) => {
      list.push({
        value: e.id,
        text: e.name,
      });
    });

    commit("SET_SHIPPING_METHODS", list);
  });
};

export const fetchSuppliers = ({ commit }) => {
  return RestService.getSuppliers().then((response) => {
    let list = [{ value: null, text: "All" }];

    response.data.data.forEach((e) => {
      list.push({
        value: e.id,
        text: e.name,
      });
    });

    commit("SET_SUPPLIERS", list);
  });
};