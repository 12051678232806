<template>
  <div class="position-relative">
    <OrdersHeader type="search" />
    <loader :overlay="true" v-if="isLoading" />
    <b-row>
      <b-col md="6">
        <b-form @submit.prevent="search">
          <b-form-group class="mb-1">
            <b-input-group>
              <b-form-input
                class="form-control"
                v-model="form.keyword"
                size="sm"
                placeholder="Search keyword"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" size="sm" type="submit">
                  Otsi
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <b-form-radio-group size="sm" v-model="form.type">
              <b-form-radio value="name">Nimi</b-form-radio>
              <b-form-radio value="id">Tellimuse ID</b-form-radio>
              <b-form-radio value="product">Toote nimi</b-form-radio>
              <b-form-radio value="status">Staatuse ajalugu</b-form-radio>
              <b-form-radio value="morele" v-if="!isEemeli">
                Morele tellimuse ID
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>

    <template v-if="orders !== null && !orders.length">
      <b-alert variant="warning" show>
        Tellimusi ei leitud.
      </b-alert>
    </template>
    <template v-else>
      <OrderItem v-for="order in orders" :order="order" :key="order.id" />

      <div class="text-center" v-if="showLoadMore">
        <b-button variant="primary" size="sm" @click.prevent="loadMore">
          Lae juurde
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import OrderItem from "./OrderItem";
import OrdersHeader from "./OrdersHeader";
import RestService from "@/lib/RestService";
import { mapGetters } from "vuex";
import { errorMessage } from "@/lib/messages";

export default {
  components: {
    OrderItem,
    OrdersHeader,
  },
  data() {
    return {
      isLoading: false,
      orders: null,
      showLoadMore: false,
      form: {
        keyword: null,
        type: "name",
      },
    };
  },
  computed: {
    ...mapGetters({
      isEemeli: "global/isEemeli",
    }),
    lastId() {
      if (!this.orders) {
        return null;
      }

      let order = this.orders[this.orders.length - 1];

      if (!order) {
        return null;
      }

      return order.id;
    },
  },
  methods: {
    search() {
      this.isLoading = true;
      RestService.getOrders(this.form)
        .then((response) => {
          this.orders = response.data.data;
          this.isLoading = false;

          if (this.orders.length == response.data.meta.per_page) {
            this.showLoadMore = true;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    },
    loadMore() {
      this.isLoading = true;
      RestService.getOrders({
        ...this.form,
        last_id: this.lastId,
      }).then((response) => {
        this.orders = this.orders.concat(response.data.data);
        this.isLoading = false;

        if (response.data.data.length == response.data.meta.per_page) {
          this.showLoadMore = true;
        } else {
          this.showLoadMore = false;
        }
      });
    },
  },
};
</script>
