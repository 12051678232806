export const SET_COUNTRIES = (state, payload) => {
  state.countries = payload;
};

export const SET_ORDER_STATUSES = (state, payload) => {
  state.order_statuses = payload;
};

export const SET_PRODUCT_STOCK_STATUSES = (state, payload) => {
  state.product_stock_statuses = payload;
};

export const SET_LANGUAGES = (state, payload) => {
  state.languages = payload;
};

export const SET_SUPPLIERS = (state, payload) => {
  state.suppliers = payload;
};

export const SET_PRODUCT_CODE_TYPES = (state, payload) => {
  state.product_code_types = payload;
};

export const SET_ADMIN_USERS = (state, payload) => {
  state.adminUsers = payload;
};

export const SET_SHIPPING_METHODS = (state, payload) => {
  state.shippingMethods = payload;
};

export const SET_MAIN_CATEGORIES = (state, payload) => {
  state.main_categories = payload;
};
