<template>
  <div>
    <h1>
      Shipping Zone
    </h1>
    <b-row>
      <b-col md="6">
        <loader :overlay="true" v-if="isSaveLoading" />
        <b-form-group label="Name">
          <b-form-input v-model="form.name" trim></b-form-input>
        </b-form-group>

        <b-form-group label="Countries" v-if="countries">
          <v-select
            v-model="form.countries"
            :options="countries"
            :reduce="(item) => item.code"
            multiple
          ></v-select>
        </b-form-group>
        <b-button
          class="mb-3"
          variant="primary"
          size="sm"
          @click.prevent="save"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  mounted() {
    this.fetchCountries();
  },
  data() {
    return {
      isSaveLoading: false,
      form: {
        name: null,
        countries: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      countries: "shippingZones/countries",
    }),
  },
  methods: {
    ...mapActions({
      fetchCountries: "shippingZones/fetchCountries",
      newShippingZone: "shippingZones/newShippingZone",
    }),
    save() {
      this.isSaveLoading = true;
      this.newShippingZone(this.form).then((response) => {
          this.$router.push({ name: 'shippingZones.edit', params: { shippingZoneId: response.data.data.id } })
      });
    },
  },
};
</script>
