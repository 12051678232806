import { Offers, Offer } from '../components'

export default [
    {
        path: '/offers/:statusId?',
        component: Offers,
        name: 'offers',
        meta: {
            guest: false,
            needsAuth: true,
        }
    },
    {
        path: '/offers/new',
        component: Offer,
        name: 'offers.new',
        meta: {
            guest: false,
            needsAuth: true,
        }
    },
    {
        path: '/offers/o/:offerId',
        component: Offer,
        name: 'offer.show',
        meta: {
            guest: false,
            needsAuth: true,
        }
    },
]
