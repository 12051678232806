import {
  StockMain,
  StockList,
  Stock,
  StockInventory,
  StockInventoryNew,
  StockInventoryProcess,
} from "../components";

export default [
  {
    path: "/stock",
    component: StockMain,
    name: "stock",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/stock/list",
    component: StockList,
    name: "stockList",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/stock/ean",
    component: Stock,
    name: "stockEan",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/stock/inventory",
    component: StockInventory,
    name: "stockInventory",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/stock/inventory/new",
    component: StockInventoryNew,
    name: "stockInventoryNew",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/stock/inventory/:inventoryId",
    component: StockInventoryProcess,
    name: "stockInventoryProcess",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
