<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.$router.push({
      name: "orders.status.type",
      params: { statusTypeId: 1 },
    });
  },
};
</script>
