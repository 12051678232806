import RestService from "@/lib/RestService";

export const searchProducts = ({ commit }, payload) => {
  return RestService.searchProducts(payload.formdata, payload.page).then((response) => {
    commit("SET_PRODUCTS", response.data.data);
    // commit("SET_PAGINATION", response.data.meta.pagination);

    return response;
  });
};

export const updateProductData = ({ commit }, payload) => {
  commit("UPDATE_PRODUCT", payload);
};
