import Vue from "vue";

export const Banners = Vue.component(
  "banners",
  require("./Banners.vue").default
);
export const BannerEdit = Vue.component(
  "banner-edit",
  require("./BannerEdit.vue").default
);
export const BannerNew = Vue.component(
  "banner-new",
  require("./BannerNew.vue").default
);
