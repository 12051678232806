import RestService from "@/lib/RestService";

export const fetchCategories = ({ commit }) => {
  return RestService.getCategories().then((response) => {
    commit("SET_CATEGORIES", response.data.data);
  });
};

export const fetchCategory = ({ commit }, id) => {
  return RestService.getCategory(id).then((response) => {
    commit("SET_SELECTED_CATEGORY", response.data.data);
  });
};

export const fetchCategoryProducts = ({ commit, getters }, payload = {}) => {
  commit("SET_PRODUCTS_LOADING", true);
  let page = payload.page ? payload.page : 1;
  let params = {...getters.filters };

  let categoryId = null;
  if (payload.id) {
    categoryId = payload.id;
  } else if (getters.selectedCategory)  {
    categoryId = getters.selectedCategory.id;
  }

  return RestService.getCategoryProducts(categoryId, page, params).then(
    (response) => {
      commit("SET_CATEGORY_PRODUCTS", response.data.data);
      commit("SET_CATEGORY_PRODUCTS_META", response.data.meta);
      commit("SET_PRODUCTS_LOADING", false);
    }
  );
};

export const removeSelectedProductsFromCategory = (
  { commit, dispatch },
  payload
) => {
  commit("SET_PRODUCTS_LOADING", true);

  return RestService.postCategoryProductsRemove(
    payload.category_id,
    payload
  ).then(() => {
    commit("REMOVE_SELECTED_PRODUCTS");
    dispatch("fetchCategoryProducts", {
      id: payload.category_id,
    });
  });
};

export const moveCategoryProducts = ({ commit, dispatch }, payload) => {
  commit("SET_PRODUCTS_LOADING", true);

  return RestService.postCategoryProductsMove(payload).then(() => {
    commit("REMOVE_SELECTED_PRODUCTS");
    dispatch("fetchCategoryProducts");
  });
};

export const addSelectedProductsToNewCategory = ({ commit }, payload) => {
  commit("SET_PRODUCTS_LOADING", true);

  return RestService.postCategoryProductsAddToNew(payload).then(() => {
    commit("REMOVE_SELECTED_PRODUCTS");
    commit("SET_PRODUCTS_LOADING", false);
  });
};

export const handleShowFacebook = ({ commit, dispatch }, payload) => {
  commit("SET_PRODUCTS_LOADING", true);

  return RestService.postProductsBulkShowFb(payload.fb).then(() => {
    dispatch("fetchCategoryProducts", payload.category);
    commit("REMOVE_SELECTED_PRODUCTS");
  });
};

export const handleSetParcelMachine = ({ commit, dispatch }, payload) => {
  commit("SET_PRODUCTS_LOADING", true);

  return RestService.postProductsBulkParcelMachineFit(payload.parcel).then(() => {
    dispatch("fetchCategoryProducts", payload.category);
    commit("REMOVE_SELECTED_PRODUCTS");
  });
};

export const handleDownloadIcecat = ({ commit, dispatch }, payload) => {
  commit("SET_PRODUCTS_LOADING", true);

  return RestService.postProductsBulkDownloadIcecat(payload.product_ids).then(() => {
    dispatch("fetchCategoryProducts", payload.category);
    commit("REMOVE_SELECTED_PRODUCTS");
  });
};

export const addSelectedProductId = ({ commit }, id) => {
  commit("ADD_SELECTED_PRODUCT_ID", id);
};

export const removeSelectedProductId = ({ commit }, id) => {
  commit("REMOVE_SELECTED_PRODUCT_ID", id);
};

export const selectAllFromThisPage = ({ commit }) => {
  commit("SELECT_ALL_FROM_THIS_PAGE");
};

export const setFilterData = ({ commit, dispatch }, payload) => {
  commit("SET_FILTERS", payload);
  commit("SET_PRODUCTS_LOADING", true);
  dispatch("fetchCategoryProducts");
};

export const updateCategoryProductData = ({ commit }, payload) => {
  commit("UPDATE_CATEGORY_PRODUCT", payload);
};
