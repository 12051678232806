<template>
  <div class="offer-item">
    <loader :overlay="true" v-if="isLoading" />
    <b-row no-gutters>
      <b-col lg="2">
        <div class="order-number">
          <router-link
            :to="{ name: 'offer.show', params: { offerId: offer.id } }"
          >
            #{{ offer.offer_nr }} <br>
          </router-link>
        </div>
      </b-col>
      <b-col style="line-height: 1.1" lg="3">
          {{ offer.firstname }} {{ offer.lastname }} <br>
      </b-col>
      <b-col class="text-center" lg="2">
        some other info
      </b-col>
      <b-col class="text-center" lg="2">
        {{ offer.status_name }}
      </b-col>
      <b-col class="text-center" lg="2">
        Summa <br>
         Kehtib kuni
      </b-col>
      <b-col class="text-right" lg="1">
        <b-button variant="outline-success" size="sm">PDF</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  props: ["offer"],
  components: {
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
  },
  methods: {

  },
};
</script>

<style lang="scss">
.offer-item {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 5px;
  border-left: 6px solid #e3e3e3;
  margin-bottom: 15px;
  position: relative;

  &.canceled {
    border-left-color: #dc3545;
  }

  &.pending {
    border-left-color: #e3e3e3;
  }

  &.processing {
    border-left-color: #007bff;
  }

  &.ordered {
    border-left-color: orange;
  }

  &.packing {
    border-left-color: orange;
  }

  &.completed {
    border-left-color: #7a9b09;
  }

  .customer-link,
  .order-number a {
    color: #000;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .order-number {
    display: block;
    margin: 0;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .price {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;

    .euro-mark {
      font-size: 18px;
    }
  }
}
</style>
