export const pages = (state) => {
  return state.pages;
};

export const pagination = (state) => {
  if (!state.pages_meta) {
    return null;
  }

  return state.pages_meta.pagination;
};
