var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"d-inline-block mr-3 float-left"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('b-nav',{staticClass:"float-left",attrs:{"pills":"","small":""}},[_c('b-nav-item',{attrs:{"to":{
        name: 'offers.new',
      },"active":_vm.type == 'new'}},[_vm._v(" Uus pakkumine ")]),_c('b-nav-item',{attrs:{"to":{
        name: 'offers',
        params: { status: 1 },
      },"active":_vm.type == 'orders'}},[_vm._v(" Pakkumised ")]),_c('b-nav-item',{attrs:{"to":{
        name: 'offers.search',
      },"active":_vm.type == 'search'}},[_vm._v(" Otsing ")])],1),_c('div',{staticClass:"clearfix"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }