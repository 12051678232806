import { Menus, MenuEdit, MenuNew } from "../components";

export default [
  {
    path: "/menus",
    component: Menus,
    name: "menus",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/menus/new",
    component: MenuNew,
    name: "menu.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/menus/:menuId",
    component: MenuEdit,
    name: "menu.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
