<template>
  <div class="d-relative">
    <loader :overlay="true" v-if="isLoading" />
    <h1>
      Tellimuse raportid
    </h1>

    <b-row class="mb-3">
      <b-col md="6">
        <date-range-picker
          ref="picker"
          :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy' }"
          :showWeekNumbers="false"
          :ranges="false"
          v-model="dateRange"
        >
        </date-range-picker>
        <b-button
          class="ml-3"
          variant="primary"
          @click.prevent="fetchMarginalRaport"
          >Genereeri</b-button
        >
      </b-col>
    </b-row>
    <b-row v-if="report">
      <b-col md="6">
        <b-table-simple bordered small>
          <b-tr>
            <b-td class="text-right">Tooteid ei leitud: </b-td>
            <b-td style="width: 30%;">
              {{ report.notFound }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-right">MT Shop tooteid ilma marginalita</b-td>
            <b-td>
              {{ report.mtsProducts }}
            </b-td>
          </b-tr>
          <b-tr variant="warning">
            <b-td class="text-right">Kokku puudu olev müük: </b-td>
            <b-td> {{ report.totalOtherSale | currency }}&nbsp;&euro; </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-center"> - </b-td>
            <b-td class="text-center"> - </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-right">Kokku tooteid: </b-td>
            <b-td>
              {{ report.totalProducts }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-right">Kokku Net ost: </b-td>
            <b-td> {{ report.totalNetSale | currency }}&nbsp;&euro; </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-right">Kokku müüki: </b-td>
            <b-td> {{ report.totalSale | currency }}&nbsp;&euro; </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-right">Kokku makstuid ostukorvi tarneid: </b-td>
            <b-td>
              <strong>
                {{ report.totalPaidShipping | currency }}&nbsp;&euro;
              </strong>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-right">Kokku makstuid toote tarneid: </b-td>
            <b-td>
              <strong>
                {{ report.totalPaidProductShipping | currency }}&nbsp;&euro;
              </strong>
            </b-td>
          </b-tr>
          <b-tr variant="success">
            <b-td class="text-right">Kokku marginaal: </b-td>
            <b-td>
              <strong>
                {{ report.totalMarginal | currency }}&nbsp;&euro;
              </strong>
            </b-td>
          </b-tr>
        </b-table-simple>
      </b-col>
      <b-col md="6">
        <b-table-simple bordered small>
          <b-tr variant="primary">
            <b-th style="width: 20%;">Tarnija</b-th>
            <b-th class="text-center" style="width: 10%;">Tooteid</b-th>
            <b-th class="text-center" style="width: 15%;">Net ost (km-ta)</b-th>
            <b-th class="text-center" style="width: 15%;">Müük (km-ta)</b-th>
            <b-th class="text-center" style="width: 15%;">Transport (km-ta)</b-th>
            <b-th class="text-center" style="width: 15%;">
              Marginal (km-ta)
            </b-th>
            <b-th class="text-center">%</b-th>
          </b-tr>
          <b-tr v-for="(item, itemKey) in report.suppliersData" :key="itemKey">
            <b-td>
              {{ item.name }}
            </b-td>
            <b-td class="text-center">
              {{ item.value }}
              <a href="#" @click.prevent="openProductsInfoModal(item)">
                <font-awesome-icon class="mx-1" icon="eye" />
            </a>
            </b-td>
            <b-td class="text-center">
              {{ item.margin_net | currency }}
            </b-td>
            <b-td class="text-center">
              {{ item.total | currency }}
            </b-td>
            <b-td class="text-center">
              {{ item.margin_shipping | currency }}
            </b-td>
            <b-td class="text-center">
              {{ item.margin | currency }}
            </b-td>
            <b-td class="text-center">
              {{ ((item.margin / item.total) * 100) | currency }}%
            </b-td>
          </b-tr>
        </b-table-simple>

        <b-modal v-model="showProductsModal" scrollable hide-footer size="xl" :title="selectedModalContentName">
          <div class="mb-2">
              <b-table-simple bordered small v-if="selectedModalContent">
                <b-thead>
                  <b-tr variant="primary">
                    <b-th style="width: 40%">Nimi</b-th>
                    <b-th class="text-center">Ostuhind</b-th>
                    <b-th class="text-center">Transport</b-th>
                    <b-th class="text-center">Marginal</b-th>
                    <b-th class="text-center">Müügihind</b-th>
                    <b-th class="text-center">Kogus</b-th>
                    <b-th class="text-center">Summa</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="product in selectedModalContent.items" :key="product.item_id">
                    <b-td>
                      <small>{{ product.name }}</small> <br />
                      <small>
                        <strong>SKU:</strong> {{ product.item_sku }} <br>
                        <strong>Tellimus:</strong> #{{ product.order_id }}
                      </small>
                    </b-td>
                    <b-td class="text-center">
                      {{ product.margin_net | currency }}
                    </b-td>
                    <b-td class="text-center">
                      {{ product.margin_shipping | currency }}
                    </b-td>
                    <b-td class="text-center">
                      {{ product.margin_single | currency }}
                    </b-td>
                    <b-td class="text-center">
                      {{ product.item_price | currency }}
                    </b-td>
                    <b-td class="text-center">
                      {{ product.quantity }}
                    </b-td>
                    <b-td class="text-center">
                      {{ product.price | currency }}
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr variant="secondary">
                    <b-td class="text-right font-weight-bold">
                      Kokku:
                    </b-td>
                    <b-td class="text-center font-weight-bold">
                      {{ selectedModalContent.margin_net | currency }}
                    </b-td>
                    <b-td class="text-center font-weight-bold">
                      {{ selectedModalContent.margin_shipping | currency }}
                    </b-td>
                    <b-td class="text-center font-weight-bold">
                      {{ selectedModalContent.margin | currency }}
                    </b-td>
                    <b-td class="text-center">

                    </b-td>
                    <b-td class="text-center font-weight-bold">
                      {{ selectedModalContent.value }}
                    </b-td>
                    <b-td class="text-center font-weight-bold">
                      {{ selectedModalContent.total | currency }}
                    </b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
          </div>
        </b-modal>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      isLoading: false,
      report: null,
      dateRange: {
        startDate: moment()
          .subtract(1, "months")
          .startOf("month")
          .format(),
        endDate: moment()
          .subtract(1, "months")
          .endOf("month")
          .format(),
      },
      showProductsModal: false,
      selectedModalContent: null,
    };
  },
  computed: {
    selectedModalContentName() {
      if (!this.selectedModalContent) {
        return 'Tarnija info';
      }
      
      return 'Tarnija info' + this.selectedModalContent.name;
    }
  },
  methods: {
    fetchMarginalRaport() {
      this.isLoading = true;
      return RestService.getOrderRaportsMarginals({
        start_date: this.dateRange.startDate,
        end_date: this.dateRange.endDate,
      })
        .then((response) => {
          this.report = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    openProductsInfoModal(item) {
      this.selectedModalContent = item;
      this.showProductsModal = true;
    }
  },
};
</script>
