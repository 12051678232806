<template>
  <div>
    <OrdersHeader type="orders" />

    <b-nav class="mt-3 mb-3" small tabs fill v-if="orderTypes.length">
      <b-nav-item
        v-for="type in orderTypes"
        :key="type.value"
        :to="{
          name: 'orders.status.type',
          params: { statusTypeId: type.value },
        }"
        :active="type.value == currentOrderType"
      >
        {{ type.text }}
        <b-badge variant="secondary" v-if="type.total">
          {{ type.total }}
        </b-badge>
      </b-nav-item>
    </b-nav>

    <OrdersList :statusType="currentOrderType" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OrdersHeader from "./OrdersHeader";
import OrdersList from "./OrdersList";

export default {
  components: {
    OrdersHeader,
    OrdersList,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      orderTypes: "orders/orderTypes",
      isTabActive: "global/isTabActive",
    }),
    currentOrderType() {
      return this.$route.params.statusTypeId;
    },
  },
  mounted() {
    this.fetchOrderTypes();
  },
  methods: {
    ...mapActions({
      fetchOrderTypes: "orders/fetchOrderTypes",
    }),
  },
  watch: {
    isTabActive(newValue) {
      if (newValue) {
        this.fetchOrderTypes();
      }
    },
  },
};
</script>
