export const categoryMaps = (state) => {
  return state.categoryMaps;
};

export const filteredCategoryMaps = (state) => {
  let results = state.categoryMaps.filter((map) => {
    if (state.showNotMapped) {
        if (map.categories.length) {
            return false;
        }
    }

    if (state.showWithProducts) {
      if (!map.has_products) {
        return false;
      }
    }

    if (!state.searchKey) {
        return true;
    }

    return map.supplier_category.toLowerCase().indexOf(state.searchKey.toLowerCase()) != -1;
  });

  return results;
};

export const selectedMap = (state) => {
  return state.selectedMap;
};
