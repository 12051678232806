import store from "../vuex";

const beforeEach = (to, from, next) => {
  if (store.getters["auth/isAuthenticated"]) {
    if (to.meta.guest) {
      next({ name: "dashboard" });
    }

    next();
  } else {
    if (to.meta.needsAuth) {
      next({ name: "login" });
      return;
    }

    next();
  }
};

export default beforeEach;
