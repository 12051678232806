<template>
  <b-card
    :bg-variant="variant"
    text-variant="white"
    class="text-center"
    @click="updateStatus"
  >
    <b-card-text class="font-weight-bold py-3" style="font-size: 24px;">
      <slot />
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "light",
    },
  },
  methods: {
    updateStatus() {
      this.$emit("update");
    },
  },
};
</script>
