import Vue from "vue";

export const SupplierOrders = Vue.component(
  "SupplierOrders",
  require("./SupplierOrders.vue").default
);

export const SupplierOrdersMorele = Vue.component(
  "SupplierOrdersMorele",
  require("./SupplierOrdersMorele.vue").default
);
