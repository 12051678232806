import {
  ProductStockStatuses,
  ProductStockStatusEdit,
  ProductStockStatusNew,
} from "../components";

export default [
  {
    path: "/product/stock/statuses",
    component: ProductStockStatuses,
    name: "productStockStatuses",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/product/stock/statuses/new",
    component: ProductStockStatusNew,
    name: "productStockStatus.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/product/stock/statuses/:statusId",
    component: ProductStockStatusEdit,
    name: "productStockStatus.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
