import Vue from 'vue';

export const SET_STORAGE_ITEMS = (state, payload) => {
  state.stockItems = payload;
};

export const ADD_NEW_STOCK_ITEM = (state, payload) => {
  state.stockItems.unshift(payload);
};

export const SET_STORAGE_ITEM = (state, payload) => {
  let itemIndex = state.stockItems.findIndex(item => item.id === payload.id);
  Vue.set(state.stockItems, itemIndex, payload);
};
