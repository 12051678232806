import { Users, UserEdit, UserNew } from "../components";

export default [
  {
    path: "/users",
    component: Users,
    name: "users",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/users/new",
    component: UserNew,
    name: "user.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/users/:userId",
    component: UserEdit,
    name: "user.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
