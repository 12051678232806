<template>
  <b-modal hide-footer 
      visible
      title="Otsi klienti"
      size="lg"
      @hidden="hideModal"
    >
    <loader :overlay="true" v-if="isLoading" />
      <b-form @submit.prevent="searchCustomer">
          <b-form-group class="mb-1">
            <b-input-group>
              <b-form-input
                class="form-control"
                v-model="keyword"
                size="sm"
                placeholder="Otsi klienti"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" size="sm" type="submit">
                  Otsi
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-form>
        <hr class="my-2">
        <div v-if="results !== null">
          <h3>Tulemused</h3>
          <b-table-simple small bordered>
            <b-tr v-for="user in results" :key="user.id">
              <b-td>
                {{ user.first_name }} {{ user.last_name }} <br>
                <small>{{ user.company_name }}</small>
              </b-td>
              <b-td>
                {{ user.email }} <br>
                <small>{{ user.phone }}</small>
              </b-td>
              <b-td class="text-center">
                <b-badge variant="primary" v-if="user.person_type == 1">Eraisik</b-badge>
              <b-badge variant="warning" v-else-if="user.person_type == 2">Ettevõte</b-badge>
              <b-badge variant="warning" v-else-if="user.person_type == 3">Riigiasutus</b-badge>
              </b-td>
              <b-td class="text-right">
                <b-button size="sm" variant="primary" @click.prevent="assignCustomer(user)">
                  Määra
                </b-button>
              </b-td>
            </b-tr>
          </b-table-simple>
        </div>
    </b-modal>
</template>

<script>
import RestService from '@/lib/RestService';
import { errorMessage } from '@/lib/messages';

export default {
  data() {
    return {
      isLoading: false,
      keyword: null,
      results: null
    }
  },
  methods: {
    hideModal() {
      this.$emit('close')
    },
    searchCustomer() {
      this.isLoading = true;
      RestService.getUsers({
        keyword: this.keyword,
      }).then((response) => {
        this.results = response.data.data
        this.isLoading = false;
      }).catch((error) => {
          if (error.response) {
            errorMessage("Viga", error.response.data.message);
          }

          this.isLoading = false;
        });
    },
    assignCustomer(user) {
      this.$emit('selected', user)
    }
  }
}
</script>