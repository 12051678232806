<template>
  <div class="position-relative">
    <loader :overlay="true" v-if="isLoading" />
    <h1>
      Toodete parandamine
    </h1>

    <ProductBatch v-for="batch in batches" :key="batch.key" :batch="batch"></ProductBatch>
    
  </div>
</template>

<script>
import RestService from '@/lib/RestService';
import ProductBatch from "./fixing/ProductBatch.vue"
import { errorMessage } from '@/lib/messages';

export default {
  components: {
    ProductBatch
  },
  data() {
    return {
      isLoading: false,
      batches: [],
    };
  },
  created() {
    this.fetchProductBatches();
  },
  methods: {
    fetchProductBatches() {
      this.isLoading = true;
      RestService.getProductsFixingList()
        .then((response) => {
          this.batches = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    }
  },
};
</script>
