import Vue from "vue";

export const SET_SHIPPING_ZONES = (state, payload) => {
  state.shippingZones = payload;
};

export const SET_SHIPPING_ZONE = (state, payload) => {
  state.shippingZone = payload;
};

export const UPDATE_SUPPLIER_SHIPPING = (state, payload) => {
  if (!state.shippingZone) {
    return false;
  }

  let supplierShippingIndex = state.shippingZone.supplierShippings.findIndex(
    (item) => item.id == payload.id
  );
  Vue.set(state.shippingZone.supplierShippings, supplierShippingIndex, payload);
};

export const ADD_SUPPLIER_SHIPPING = (state, payload) => {
  if (!state.shippingZone) {
    return false;
  }

  if (!state.shippingZone.supplierShippings) {
    state.shippingZone.supplierShippings = [payload];
  } else {
    state.shippingZone.supplierShippings.push(payload);
  }
};

export const REMOVE_SUPPLIER_SHIPPING = (state, id) => {
  if (!state.shippingZone) {
    return false;
  }

  let supplierShippingIndex = state.shippingZone.supplierShippings.findIndex(
    (item) => item.id == id
  );

  state.shippingZone.supplierShippings.splice(supplierShippingIndex, 1);
};

export const SET_COUNTRIES = (state, payload) => {
  state.countries = payload;
};

export const SET_SHIPPING_METHODS = (state, payload) => {
  state.shippingMethods = payload;
};

export const SET_SUPPLIERS = (state, payload) => {
  state.suppliers = payload;
};
