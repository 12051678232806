<template>
  <div class="position-relative">
    <loader :overlay="true" v-if="isLoading" />
    <h1>
      Toodete merge

      <router-link
        :to="{ name: 'new-products-merge' }"
        class="btn btn-primary btn-sm ml-2"
      >
        Koosta uus merge
      </router-link>
    </h1>

    <b-table-simple
      small
      bordered
      v-for="(mergable, mergableKey) in merges"
      :key="mergableKey"
    >
      <b-tr variant="primary">
        <b-th style="width: 50%">Nimi</b-th>
        <b-th style="width: 35%">Laod</b-th>
        <b-th style="width: 15%" class="text-right">
          <router-link
            :to="{
              name: 'confirm-products-merge',
              params: { productId: mergable[0].product_id },
            }"
            class="btn btn-primary btn-sm mr-3"
          >
            Merge
          </router-link>
          <b-button
            variant="danger"
            size="sm"
            @click.prevent="deleteMerge(mergable[0].product_id)"
          >
            Kustuta
          </b-button>
        </b-th>
      </b-tr>
      <b-tr v-for="merge in mergable" :key="merge.product.id">
        <b-td>
          <router-link
            class="product-link"
            :to="{
              name: 'products.edit',
              params: { productId: merge.product.id },
            }"
          >
            {{ merge.product.name }}</router-link
          >

          <br />

          <template v-if="merge.product.codes.length">
            <strong>Product codes:</strong> <br />
            <ul class="list-unstyled small">
              <li v-for="code in merge.product.codes" :key="code.id">
                <strong> {{ code.type }}: </strong>
                {{ code.code }}
              </li>
            </ul>
          </template>
        </b-td>
        <b-td>
          <b-table-simple
            class="mb-0"
            bordered
            small
            v-if="merge.product.stocks"
          >
            <b-tr variant="primary">
              <b-th>Ladu</b-th>
              <b-th>Laoseis</b-th>
              <b-th>Hind</b-th>
            </b-tr>
            <b-tr v-for="stock in merge.product.stocks" :key="stock.id">
              <b-td>{{ stock.supplier.name }}</b-td>
              <b-td>{{ stock.in_stock }}</b-td>
              <b-td>{{ stock.price }}</b-td>
            </b-tr>
          </b-table-simple>
        </b-td>
        <b-td style="vertical-align: middle;" class="text-right">
          <b-button
            variant="warning"
            size="sm"
            @click.prevent="
              removeProductFromMerge(
                merge.product_id,
                merge.mergable_product_id
              )
            "
          >
            Eemalda
          </b-button>
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";

export default {
  data() {
    return {
      isLoading: false,
      merges: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      RestService.getProductMerges()
        .then((response) => {
          this.merges = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    deleteMerge(productId) {
      this.isLoading = true;
      RestService.postProductMergeDelete(productId)
        .then(() => {
          this.fetchData();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    removeProductFromMerge(mergeProductId, productId) {
      this.isLoading = true;
      RestService.postProductMergeRemoveFromMerge(mergeProductId, {
        productId: productId,
      })
        .then(() => {
          this.fetchData();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
  },
};
</script>
