<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Tarnija tellimused
    </h1>

    <b-table-simple class="mb-0" bordered small v-if="supplierOrders">
      <b-tr variant="primary">
        <b-th style="width: 5%;">Tarnija</b-th>
        <b-th>Nimi</b-th>
        <b-th class="text-center" style="width: 10%;">Tellimuse nr</b-th>
        <b-th class="text-center" style="width: 10%;">Meie tellimus</b-th>
        <b-th class="text-center" style="width: 5%;">Kontrollitud</b-th>
        <b-th class="text-center" style="width: 5%;">Staatus</b-th>
      </b-tr>
      <b-tr
        v-for="order in supplierOrders"
        :key="order.id"
        :variant="getRowVariant(order.status)"
      >
        <b-td>
          {{ order.supplier.name }}
        </b-td>
        <b-td>
          {{ order.name }} <br />
          <small>
            <strong>Kogus: </strong> {{ order.quantity }}<br />
            <strong>SKU: </strong> {{ order.sku }}<br />
          </small>
          <b-alert class="p-1 mb-0" variant="warning" v-if="order.comment" show>
            <span v-html="order.comment"></span>
          </b-alert>
        </b-td>
        <b-td class="text-center">
          {{ order.external_order_id }}
        </b-td>
        <b-td class="text-center">
          <router-link
            :to="{ name: 'order.show', params: { orderId: order.order_id } }"
          >
            {{ order.order_id }}
          </router-link>
        </b-td>
        <b-td class="text-center">
          {{ order.updated_at | moment("MM.DD.YYYY HH:mm:ss") }}
        </b-td>
        <b-td class="text-center">
          {{ order.status_name }}
        </b-td>
      </b-tr>
    </b-table-simple>

    <template v-if="pagination">
      <small>Kokku: {{ pagination.total }}</small>
      <b-pagination
        class="mt-3"
        v-model="currentPage"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        @change="fetchSupplierOrders"
      ></b-pagination>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
export default {
  data() {
    return {
      isLoading: false,
      supplierOrders: null,
      currentPage: 1,
      pagination: null,
    };
  },
  mounted() {
    this.fetchSupplierOrders();
  },
  methods: {
    fetchSupplierOrders(page = 1) {
      this.isLoading = true;
      RestService.getSupplierOrders({
        page: page,
      })
        .then((response) => {
          this.supplierOrders = response.data.data;
          this.pagination = response.data.meta.pagination;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga", error.response.data.message);
          }
        });
    },
    getRowVariant(status) {
      if (status == -1) {
        return "danger";
      }

      if (status == 2) {
        return "success";
      }

      return "";
    },
  },
};
</script>
