import Vue from "vue";

export const Stock = Vue.component("stock", require("./Stock.vue").default);
export const StockList = Vue.component(
  "stock-list",
  require("./StockList.vue").default
);
export const StockMain = Vue.component(
  "stock-main",
  require("./StockMain.vue").default
);
export const StockInventory = Vue.component(
  "stock-inventory",
  require("./inventory/StockInventory.vue").default
);
export const StockInventoryNew = Vue.component(
  "stock-inventory-new",
  require("./inventory/StockInventoryNew.vue").default
);

export const StockInventoryProcess = Vue.component(
  "stock-inventory-process",
  require("./inventory/StockInventoryProcess.vue").default
);
