<template>
  <div>
    <a href="#" @click.prevent="openStockHistory">
        <font-awesome-icon icon="eye" />
      </a>
      <b-modal
        size="lg"
        :title="`${stock.supplier.frontend_name} - History`"
        scrollable
        :hide-footer="true"
        v-if="showHistoryModal"
        v-model="showHistoryModal"
      >
        <loader v-if="isLoading" />
        <b-table-simple bordered small responsive="" v-else>
          <b-tr variant="primary">
            <b-th class="text-center">Net</b-th>
            <b-th class="text-center">Delivery</b-th>
            <b-th class="text-center">Purchase</b-th>
            <b-th class="text-center">Manual</b-th>
            <b-th class="text-center">Price</b-th>
            <b-th class="text-center">Marginal</b-th>
            <b-th class="text-center">%</b-th>
            <b-th class="text-center">Updated at</b-th>
          </b-tr>
          <b-tr v-for="(entry, entryKey) in stockHistory" :key="entryKey">
            <b-td class="text-center">
              {{ entry.net_price | currency }}
            </b-td>
            <b-td class="text-center">
              {{ entry.delivery_price | currency }}
            </b-td>
            <b-td class="text-center">
              {{ entry.purchase_price | currency }}
            </b-td>
            <b-td class="text-center">
              {{ entry.manual_price | currency }}
            </b-td>
            <b-td class="text-center">
              {{ entry.price | currency }}
            </b-td>
            <b-td class="text-center">
              {{ entry.margin | currency }}
            </b-td>
            <b-td class="text-center">
              {{ entry.margin_percent }} %
            </b-td>
            <b-td class="text-center">
              {{ entry.updated_at | moment("DD.MM.YYYY HH:mm:ss") }}
            </b-td>
          </b-tr>
        </b-table-simple>
      </b-modal>
  </div>
</template>

<script>
import { errorMessage } from '@/lib/messages';
import RestService from '@/lib/RestService';

export default {
  props: {
    stock: {
      type: Object
    }
  },
  data() {
    return {
      showHistoryModal: false,
      isLoading: false,
      stockHistory: []
    }
  },
  methods: {
    openStockHistory() {
      this.isLoading = true;
      this.showHistoryModal = true;

      RestService.getProductStockHistory(this.stock.id).then((response) => {
        this.stockHistory = response.data.data;
        this.isLoading = false;
      }).catch((error) => {
        if (error.response) {
          errorMessage("Viga", error.response.data.message);
        }

        this.isLoading = false;
      });
    },
  },
}
</script>
