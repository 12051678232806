<template>
  <div class="d-relative">
    <loader :overlay="true" v-if="isLoading" />
    <h2>Pakkumine !</h2>
    <b-row>
      <b-col md="8">
        <b-card header="Tellija andmed">
          <b-row>
            <b-col md="6">
              <b-form-group class="mb-1" label="Pakkumise keel">
                <b-form-select
                  v-model="offer.locale"
                  size="sm"
                  :options="selectLocales"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group class="mb-1" label="Eesnimi">
                <b-form-input
                  v-model="offer.firstname"
                  size="sm"
                ></b-form-input>
              </b-form-group>

              <b-form-group class="mb-1" label="Perenimi">
                <b-form-input
                  v-model="offer.lastname"
                  size="sm"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="mb-1" label="Email">
                <b-form-input
                  v-model="offer.email"
                  size="sm"
                ></b-form-input>
              </b-form-group>

              <b-form-group class="mb-1" label="Telefon">
                <b-form-input
                  v-model="offer.phone"
                  size="sm"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2 mb-2">
            <b-col>
              <b-form-radio-group size="sm" v-model="offer.customer_type">
                <b-form-radio value="1">Eraisik</b-form-radio>
                <b-form-radio value="2">Ettevõte</b-form-radio>
                <b-form-radio value="3">Riigiasutus</b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <template
            v-if="offer.customer_type == 2 || offer.customer_type == 3"
          >
            <b-row>
              <b-col>
                <b-form-group class="mb-1" label="Ettevõtte nimi">
                  <b-form-input
                    v-model="offer.company_name"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-1" label="Ettevõtte reg nr">
                  <b-input-group>
                    <b-form-input
                      v-model="offer.company_code"
                      size="sm"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button size="sm" variant="outline-primary">
                        <font-awesome-icon icon="search" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <b-form-group class="mb-1" label="KMKR nr">
                  <b-form-input
                    v-model="offer.company_vat"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h6 class="mt-3 font-weight-bold">
                  Ettevõtte / Maksja aadress:
                </h6>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group class="mb-1" label="Riik" v-if="countries">
                  <v-select
                    v-model="offer.country"
                    :options="countries"
                    :reduce="(item) => item.code"
                    :clearable="false"
                  ></v-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-1" label="Maakond">
                  <b-form-input
                    v-model="offer.county"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group class="mb-1" label="Linn/Vald">
                  <b-form-input
                    v-model="offer.city"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-1" label="Postiindeks">
                  <b-form-input
                    v-model="offer.zip"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group class="mb-1" label="Aadress">
                  <b-form-input
                    v-model="offer.address"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-1 mt-4">
                  <b-form-checkbox
                    v-model="offer.einvoice"
                    :value="true"
                    :unchecked-value="false"
                  >
                    E-arve
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
        </b-card>

        <b-card class="mt-3" no-body>
            <template #header>
              Tooted
              <b-button
                class="float-right"
                variant="primary"
                size="sm"
                @click.prevent="openSearch"
              >
                Otsi toode
              </b-button>
              <b-button
                class="float-right mr-3"
                variant="outline-primary"
                size="sm"
                @click.prevent="addCustomProduct"
              >
                Lisa custom
              </b-button>
            </template>
            <div class="p-1">
              <b-table-simple class="mb-0" bordered small v-if="offer.products.length">
                <b-thead>
                  <b-tr variant="primary">
                    <b-th style="width: 10%;" class="text-center">SKU</b-th>
                    <b-th>Nimi</b-th>
                    <b-th style="width: 8%;" class="text-center">Kogus</b-th>
                    <b-th style="width: 10%;" class="text-center">
                      Hind (km-ita)
                    </b-th>
                    <b-th style="width: 10%;" class="text-center">
                      Hind (km-iga)
                    </b-th>
                    <b-th style="width: 15%;" class="text-center">Tarneaeg (TP)</b-th>
                    <b-th style="width: 5%;" class="text-right"></b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(product, productKey) in offer.products"
                    :key="productKey"
                  >
                    <b-td>
                      <b-form-input
                        v-model="product.sku"
                        size="sm"
                      ></b-form-input>
                      <small v-if="product.import_product_stock_id">
                        <strong>
                          Stock id:
                        </strong>
                        {{ product.import_product_stock_id }}
                      </small>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="product.name"
                        size="sm"
                        class="mb-1"
                      ></b-form-input>
                      <b-form-input
                        v-model="product.info"
                        placeholder="Lisainfo"
                        size="sm"
                        class="mb-1"
                      ></b-form-input>
                      <b-form-input
                        v-model="product.ean"
                        placeholder="EAN"
                        size="sm"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="product.quantity"
                        type="number"
                        class="text-center"
                        size="sm"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="product.item_price_subtotal"
                        type="number"
                        class="text-center"
                        size="sm"
                      ></b-form-input>
                    </b-td>
                    <b-td class="text-center">
                      {{ (product.item_price_subtotal * 1.2) | currency }}&nbsp;&euro;
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="product.delivery_days"
                        type="number"
                        class="text-center"
                        size="sm"
                      ></b-form-input>
                    </b-td>
                    <b-td class="text-center">
                      <b-button
                        size="sm"
                        variant="outline-danger"
                        @click.prevent="removeProductLine(productKey)"
                      >
                        <font-awesome-icon icon="times" />
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </b-card>
      </b-col>
      <b-col>
        <b-card header="Pakkumise andmed" no-body>
          <div class="p-3">
            <b-form-group class="mb-1" label="Pakkumise nimi (Admin)">
              <b-form-input
                v-model="offer.name"
                size="sm"
                class="mb-1"
              ></b-form-input>
            </b-form-group>

            <b-form-group class="mb-1" label="Pakkumine kehtib kuni">
              <date-picker
                v-model="offer.due_date"
                size="sm"
                class="mb-1"
              ></date-picker>
            </b-form-group>

            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="offer.withOutVat"
                :value="true"
                :unchecked-value="false"
              >
                Pakkumine Ilma KM-ita
              </b-form-checkbox>
            </b-form-group>

            <b-form-group class="mb-3" label="PDF Pakkumise kommentaar">
              <b-form-textarea
                v-model="offer.offer_comment"
                size="sm"
              ></b-form-textarea>
            </b-form-group>

            <div class="mb-1">
              Transport <b-button class="float-right" variant="outline-primary" size="sm" @click.prevent="openShippingModal">Muuda</b-button>
            </div>

            <b-badge :variant="!!selectedShipping ? 'primary' :''">
              <span v-if="selectedShipping">{{ selectedShipping.name }}</span>
              <span v-else>Puudub</span>
            </b-badge> 
            <br>
            <small v-if="selectedShippingInfo">{{ selectedShippingInfo }}</small>

            <b-modal v-model="showShippingModal" centered hide-footer size="lg" title="Transport">
              <b-form-group class="mb-1" label="Tarnemeetod">
                <b-form-select
                  v-model="offer.shipping.shipping_method"
                  size="sm"
                  :options="selectShippings"
                >
                </b-form-select>
              </b-form-group>

              <div class="mt-3">
                <b-form-group
                  class="mb-1"
                  label="DPD Pakiautomaat"
                  v-if="offer.shipping.shipping_method == 5"
                >
                  <b-form-select
                    v-model="offer.shipping.additional_value"
                    :options="selectDpdParcel"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  class="mb-1"
                  label="Omniva pakiautomaat"
                  v-if="offer.shipping.shipping_method == 4"
                >
                  <b-form-select
                    v-model="offer.shipping.additional_value"
                    :options="selectOmnivaParcel"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  class="mb-1"
                  label="SmartPost Pakiautomaat"
                  v-if="offer.shipping.shipping_method == 2"
                >
                  <b-form-select
                    v-model="offer.shipping.additional_value"
                    :options="selectSmartpostParcel"
                  ></b-form-select>
                </b-form-group>

                <template v-if="offer.shipping.shipping_method == 3">
                  <b-form-group class="mb-1" label="Riik" v-if="countries">
                    <v-select
                      v-model="offer.shipping.courier_values.country"
                      :options="countries"
                      :reduce="(item) => item.code"
                      :clearable="false"
                    ></v-select>
                  </b-form-group>
                  <b-form-group class="mb-1" label="Maakond">
                    <b-form-input
                      v-model="offer.shipping.courier_values.county"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-1" label="Linn/Vald">
                    <b-form-input
                      v-model="offer.shipping.courier_values.city"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-1" label="Postiindeks">
                    <b-form-input
                      v-model="offer.shipping.courier_values.zip"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-1" label="Aadress">
                    <b-form-input
                      v-model="offer.shipping.courier_values.address"
                      size="sm"
                    ></b-form-input>
                  </b-form-group>
                </template>
              </div>

              <hr>
              
              <b-form-group class="mb-1" label="Transpordi hind (ilma km-ita)">
                <b-form-input
                  v-model="offer.shipping.shipping_price"
                  type="number"
                  size="sm"
                  class="mb-1"
                ></b-form-input>
              </b-form-group>
            </b-modal>

            <hr>
            <div class="text-right">
              Transport: <strong>{{ offerShippingPrice | currency }} &euro;</strong> <br>
              Kokku ilma km-ita: <strong>{{ offerTotal | currency }} &euro;</strong> <br>
              Käibemaks (20%): <strong>{{ offerVat | currency }} &euro;</strong> <br>
              Kokku: <strong>{{ offerTotalWithVat | currency }} &euro;</strong> <br>
            </div>
            <hr>

            <div class="mb-2">
              Pakkumise staatus: <b-badge :variant="statusVariant">{{ offer.status_name }}</b-badge>
            </div>
            
            <b-button variant="primary" size="sm" @click.prevent="saveOffer" :disabled="saving">
              <b-spinner small v-if="saving"></b-spinner>
              Salvesta
            </b-button>

            <b-button class="float-right" variant="success" size="sm" @click.prevent="activateOffer" v-if="offer.status == 0">
              Salvesta ja aktiveeri
            </b-button>
            
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RestService from "@/lib/RestService";
import moment from 'moment';
import { errorMessage } from '@/lib/messages';

export default {
  data() {
    return {
      isLoading: true,
      saving: false,
      showShippingModal: false,
      defaultData: null,
      offer: {
        name: "",
        due_date: moment().add(7, 'd').toISOString(),
        offer_comment: null,
        send_email: false,
        status_name: "Salvestamata",

        locale: "et",
        customer_type: 1,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,

        withOutVat: false,
        einvoice: false,
        company_name: null,
        company_code: null,
        company_vat: null,
        country: "EE",
        county: null,
        city: null,
        zip: null,
        address: null,

        shipping: {
          shipping_method: 0,
          shipping_price: 0,
          additional_value: null,
          additional_info: null,
          courier_values: {
            country: "EE",
            county: null,
            city: null,
            zip: null,
            address: null
          }
        },

        products: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      countries: "generic/countries",
      languages: "generic/languages",
    }),
    selectLocales() {
      return this.languages.map((locale) => {
        return {
          value: locale.locale,
          text: locale.name,
        };
      });
    },
    statusVariant() {
      if (this.offer.status == 0) {
        return 'warning';
      }

      if (this.offer.status == 1) {
        return 'primary';
      }

      if (this.offer.status == 2) {
        return 'success';
      }

      if (this.offer.status == -1) {
        return 'danger';
      }

      return null;
    },
    offerShippingPrice() {
      return parseFloat(this.offer.shipping.shipping_price);
    },
    offerTotal() {
      let total = 0;

      this.offer.products.forEach((p) => {
        total += parseFloat(p.item_price_subtotal) * parseFloat(p.quantity);
      });

      total += parseFloat(this.offerShippingPrice);

      return total;
    },
    offerTotalWithVat() {
      return this.offerTotal * 1.2;
    },
    offerVat() {
      return this.offerTotalWithVat - this.offerTotal;
    },
    selectedShipping() {
      if (!this.defaultData) {
        return null;
      }

      if (!this.offer.shipping.shipping_method) {
        return null;
      }

      let shipping = this.defaultData.shipping_methods.find((s) => s.id == this.offer.shipping.shipping_method)
      if (!shipping) {
        return null
      }

      return shipping;
    },
    selectedShippingInfo() {
      if (!this.selectedShipping) {
        return null;
      }

      if (this.selectedShipping.id == 3) {
        return `${this.offer.shipping.courier_values.address}, ${this.offer.shipping.courier_values.city}, ${this.offer.shipping.courier_values.county}, ${this.offer.shipping.courier_values.zip}, ${this.offer.shipping.courier_values.country}`;
      }

      if (this.selectedShipping.id == 2) {
        let smartpostValue = this.defaultData.smartpost.find((s) => s.id == this.offer.shipping.additional_value);
        return smartpostValue ? smartpostValue.name : null;
      }

      if (this.selectedShipping.id == 4) {
        let omnivaValue = this.defaultData.omniva_parcel.find((s) => s.id == this.offer.shipping.additional_value);
        return omnivaValue ? omnivaValue.name : null;
      }

      if (this.selectedShipping.id == 5) {
        let dpdValue = this.defaultData.dpd_parcel.find((s) => s.id == this.offer.shipping.additional_value);
        return dpdValue ? dpdValue.name : null;
      }

      if (this.selectedShipping.id == 7) {
        let smartpostFiValue = this.defaultData.smartpost_fi.find((s) => s.id == this.offer.shipping.additional_value);
        return smartpostFiValue ? smartpostFiValue.name : null;
      }

      return null;
    },
    selectShippings() {
      if (!this.defaultData) {
        return [];
      }

      return this.defaultData.shipping_methods.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
    },
    selectDpdParcel() {
      if (!this.defaultData) {
        return [];
      }

      let data = [];

      for (let property in this.defaultData.dpd_parcel) {
        data.push({
          label: property,
          options: this.defaultData.dpd_parcel[property].map((i) => {
            return {
              value: i.id,
              text: i.name,
            };
          }),
        });
      }

      return data;
    },
    selectSmartpostParcel() {
      if (!this.defaultData) {
        return [];
      }

      let data = [];

      for (let property in this.defaultData.smartpost) {
        data.push({
          label: property,
          options: this.defaultData.smartpost[property].map((i) => {
            return {
              value: i.id,
              text: i.name,
            };
          }),
        });
      }

      return data;
    },
    selectSmartpostParcelFi() {
      if (!this.defaultData) {
        return [];
      }

      let data = [];

      for (let property in this.defaultData.smartpost_fi) {
        data.push({
          label: property,
          options: this.defaultData.smartpost_fi[property].map((i) => {
            return {
              value: i.id,
              text: i.name,
            };
          }),
        });
      }

      return data;
    },
    selectOmnivaParcel() {
      if (!this.defaultData) {
        return [];
      }

      let data = [];

      for (let property in this.defaultData.omniva_parcel) {
        data.push({
          label: property,
          options: this.defaultData.omniva_parcel[property].map((i) => {
            return {
              value: i.id,
              text: i.name,
            };
          }),
        });
      }

      return data;
    },
  },
  created() {
    this.fetchCountries()
    this.fetchDefaultData().then(() => {
      this.fetchOffer()
    });
  },
  methods: {
    ...mapActions({
      fetchCountries: "generic/fetchCountries",
    }),
    fetchDefaultData() {
      return RestService.getOfferDefaultData().then((response) => {
        this.defaultData = response.data.data;
      }).catch((error) => {
        if (error.response && error.response.data.message) {
          errorMessage("Viga", error.response.data.message);
        }
      });
    },
    fetchOffer() {
      if (this.$route.params.offerId) {
        this.isLoading = true;
        RestService.getOffer(this.$route.params.offerId).then((response) => {
          this.offer = response.data.data;
          this.isLoading = false;
        }).catch(() => {
          console.log('error')
        })
      } else {
        this.isLoading = false;
      }
    },
    openShippingModal() {
      this.showShippingModal = true;
    },
    addCustomProduct() {
      this.offer.products.push({
        sku: "001",
        ean: null,
        mpn: null,
        name: "Toote nimi",
        info: null,
        quantity: 1,
        price: 0,
        import_product_stock_id: null,
        delivery_days: 5,
      });
    },
    removeProductLine(index) {
      this.offer.products.splice(index, 1);
    },
    saveOffer() {
      this.saving = true;
      RestService.postSaveOffer(this.offer).then((response) => {
        if (this.$route.name != 'offer.show') {
          this.$router.push({
            name: "offer.show",
            params: { offerId: response.data.data.id },
          });
        } else {
          this.offer = response.data.data;
        }

        this.saving = false;
      }).catch((error) => {
        if (error.response) {
          errorMessage("Viga", error.response.data.message);
        }

        this.saving = false;
      });
    },
    activateOffer() {
      RestService.postSaveOffer(this.offer).then((response) => {
        console.log(response)
      }).catch((error) => {
        if (error.response) {
          errorMessage("Viga", error.response.data.message);
        }
      });
    },
  },
};
</script>
