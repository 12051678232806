import RestService from "@/lib/RestService";
import { successMessage } from "@/lib/messages";

export const fetchStatuses = ({ commit }) => {
  return RestService.getOrderStatuses().then((response) => {
    commit("SET_ORDER_STATUSES", response.data.data);

    return response;
  });
};

export const deleteOrderStatus = ({ commit }, id) => {
  return RestService.postDeleteOrderStatus(id).then(() => {
    successMessage("Teade!", "Staatus on kustutatud.");

    commit("REMOVE_ORDER_STATUS", id);
  });
};
