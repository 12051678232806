export const countries = (state) => {
  return state.countries;
};

export const orderStatuses = (state) => {
  return state.order_statuses;
};

export const stockStatuses = (state) => {
  return state.product_stock_statuses;
};

export const stockStatusesSelect = (state) => {
  return state.product_stock_statuses.map((status) => {
    return {
      value: status.id,
      text: `${status.name} (${status.key})`,
    };
  });
};

export const stockStatusesSelectByKey = (state) => {
  return state.product_stock_statuses.map((status) => {
    return {
      value: status.key,
      text: `${status.name} (${status.key})`,
    };
  });
};

export const stockStatusesSelectLabel = (state) => {
  return state.product_stock_statuses.map((status) => {
    return {
      value: status.id,
      label: `${status.name} (${status.key})`,
    };
  });
};

export const languages = (state) => {
  return state.languages;
};

export const suppliers = (state) => {
  return state.suppliers;
};

export const productCodeTypes = (state) => {
  return state.product_code_types;
};

export const suppliersSelect = (state) => {
  return state.suppliers.map((s) => {
    return {
      value: s.id,
      text: `${s.name}`,
    };
  });
};

export const adminUsers = (state) => {
  return state.adminUsers;
};

export const shippingMethods = (state) => {
  return state.shippingMethods;
};

export const mainCategories = (state) => {
  return state.main_categories;
};

export const mainCategoriesSelectLabel = (state) => {
  return state.main_categories.map((c) => {
    return {
      value: c.id,
      label: c.name,
    };
  });
};