import Vue from "vue";

export const SET_CATEGORIES = (state, payload) => {
  state.categories = payload;
};

export const SET_SELECTED_CATEGORY = (state, payload) => {
  state.selectedCategory = payload;
};

export const SET_CATEGORY_PRODUCTS = (state, payload) => {
  state.categoryProducts = payload;
};

export const SET_CATEGORY_PRODUCTS_META = (state, payload) => {
  state.categoryProductsMeta = payload;
};

export const SET_PRODUCTS_LOADING = (state, payload) => {
  state.isProductsLoading = payload;
};

export const ADD_SELECTED_PRODUCT_ID = (state, id) => {
  state.selectedProductIds.push(id);
};

export const REMOVE_SELECTED_PRODUCTS = (state) => {
  state.selectedProductIds = [];
};

export const REMOVE_SELECTED_PRODUCT_ID = (state, id) => {
  const index = state.selectedProductIds.indexOf(id);
  if (index > -1) {
    state.selectedProductIds.splice(index, 1);
  }
};

export const SELECT_ALL_FROM_THIS_PAGE = (state) => {
  state.categoryProducts.forEach((p) => {
    if (!state.selectedProductIds.includes(p.id)) {
      state.selectedProductIds.push(p.id);
    }
  });
};

export const SET_FILTERS = (state, payload) => {
  state.filters.per_page = payload.perPage
  state.filters.only_with_stocks = payload.onlyStocks ? 1 : 0
  state.filters.only_without_icecat = payload.onlyWithoutIcecat ? 1 : 0
  state.filters.needs_review = payload.needs_review ? 1 : 0
  state.filters.parcel_machine = payload.parcel_machine ? 1 : 0
};

export const UPDATE_CATEGORY_PRODUCT = (state, payload) => {
  const pindex = state.categoryProducts.findIndex((p) => p.id == payload.id);
  if (pindex > -1) {
    Vue.set(state.categoryProducts, pindex, payload);
  }
};
