<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      Menüü lisamine
    </h2>

    <template>
      <b-row>
        <b-col md="6">
          <b-card header="Menüü">
            <b-form @submit.prevent="saveMenu">
              <b-form-group class="mb-1" label="Nimi">
                <b-form-input
                  v-model="form.name"
                  size="sm"
                  :state="getFieldStateFromErrors('name')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("name") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Asukoht">
                <b-form-select
                  v-model="form.slug"
                  size="sm"
                  :options="selectLocations"
                  :state="getFieldStateFromErrors('slug')"
                >
                </b-form-select>

                <b-form-invalid-feedback>
                  {{ getFieldError("slug") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Keel">
                <b-form-select
                  v-model="form.locale"
                  size="sm"
                  :options="selectLocales"
                  :state="getFieldStateFromErrors('locale')"
                >
                </b-form-select>

                <b-form-invalid-feedback>
                  {{ getFieldError("locale") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <div class="text-right mt-3">
                <b-button type="submit" variant="success">
                  Salvesta
                </b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
        <b-col md="6"> </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapGetters } from "vuex";
import ErrorsMixin from "@/helpers/ErrorsMixin";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      locations: null,
      isLoading: false,
      form: {
        name: null,
        locale: null,
        slug: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      languages: "generic/languages",
    }),
    selectLocales() {
      return this.languages.map((locale) => {
        return {
          value: locale.locale,
          text: locale.name,
        };
      });
    },
    selectLocations() {
      if (!this.locations) {
        return [];
      }

      return this.locations.map((location) => {
        return {
          value: location.slug,
          text: location.name,
        };
      });
    },
  },
  mounted() {
    this.fetchLocations();
  },
  methods: {
    fetchLocations() {
      this.isLoading = true;
      return RestService.getMenuLocations().then((response) => {
        this.locations = response.data.data;
        this.isLoading = false;
      });
    },
    saveMenu() {
      this.isLoading = true;
      return RestService.postSaveNewMenu(this.form)
        .then((response) => {
          this.$router.push({
            name: "menu.edit",
            params: { menuId: response.data.data.id },
          });
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
  },
};
</script>
