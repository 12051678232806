export const shippingZones = (state) => {
  return state.shippingZones;
};

export const shippingZone = (state) => {
  return state.shippingZone;
};

export const countries = (state) => {
  return state.countries;
};

export const shippingMethods = (state) => {
  return state.shippingMethods;
};

export const suppliers = (state) => {
  return state.suppliers;
};
