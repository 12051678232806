<template>
  <div
    class="checkboxWrapper"
    :class="{ disabled: disabled }"
    @click="setValue"
  >
    <div class="customCheckbox" :class="{ active: isActive }">
      <!-- <i class="fa fa-check" v-if="isActive"></i> -->
      <font-awesome-icon v-if="isActive" icon="check" />
      <input type="checkbox" class="input" />
    </div>
    <span>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isActive() {
      return this.value == true;
    },
  },
  methods: {
    setValue() {
      if (this.disabled) {
        return false;
      }

      this.$emit("input", !this.value);
    },
  },
};
</script>

<style lang="scss">
.checkboxWrapper {
  display: block;
  cursor: pointer;
  margin-bottom: 15px;
  padding-left: 30px;
  line-height: 1;

  .customCheckbox {
    margin-left: -30px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 24px;
    height: 24px;
    background: #f2f2f2;
    border: 1px solid #dedede;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 14px;
    margin-right: 4px;

    .input {
      opacity: 0;
    }

    &.active {
      border: 2px solid #7a9b09;
      svg {
        position: absolute;
        font-size: 20px;
        color: #7a9b09;
        padding: 3px;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
      }
    }
  }

  &.disabled {
    color: #cccccc;

    .customCheckbox {
      border: 1px solid #cccccc;

      &.active {
        border: 1px solid #cccccc;

        &::after {
          background-color: #cccccc;
        }
      }
    }
  }
}
</style>
