import { Tracking } from '../components'

export default [
    {
        path: '/product-tracking',
        component: Tracking,
        name: 'product-tracking',
        meta: {
            guest: false,
            needsAuth: true,
        }
    },
]
