<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Kategooria mapper

      <router-link
        class="btn btn-primary btn-sm"
        :to="{
          name: 'CategoryMapsAll',
        }"
      >
        Kõik tarnijad
      </router-link>

      <router-link
       v-if="!isEemeli"
        class="btn btn-primary btn-sm ml-3"
        :to="{
          name: 'CategoryMapsIcecat',
        }"
      >
        Icecati kategooriad
      </router-link>
    </h1>
    <b-row>
      <b-col md="6">
        <b-table-simple bordered responsive small>
          <b-tr variant="primary">
            <b-th>Nimi</b-th>
          </b-tr>
          <b-tr v-for="supplier in filteredSuppliers" :key="supplier.id">
            <b-td>
              <router-link
                :to="{
                  name: 'CategoryMaps',
                  params: { supplierId: supplier.id },
                }"
              >
                {{ supplier.name }}
              </router-link>
            </b-td>
          </b-tr>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      isLoading: false,
      suppliers: [],
    };
  },
  computed: {
    ...mapGetters({
      isEemeli: "global/isEemeli",
    }),
    filteredSuppliers() {
      return this.suppliers.filter((s) => s.is_hidden === false);
    },
  },
  mounted() {
    this.getSuppliers();
  },
  methods: {
    getSuppliers() {
      this.isLoading = true;
      return RestService.getSuppliers().then((response) => {
        this.suppliers = response.data.data;
        this.isLoading = false;
      });
    },
  },
};
</script>
