<template>
  <div class="bg-light border-right" id="sidebar-wrapper">
    <div class="sidebar-heading">
      <router-link :to="{ name: 'dashboard' }">
        <MTLogo class="logo" v-if="!isEemeli"></MTLogo>
        <EemeliLogo class="logo" v-else></EemeliLogo>
      </router-link>
    </div>

    <SidebarContent />
  </div>
</template>

<script>
import MTLogo from "../MTLogo";
import EemeliLogo from "../EemeliLogo";
import SidebarContent from "./SidebarContent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    MTLogo,
    EemeliLogo,
    SidebarContent,
  },
  computed: {
    ...mapGetters({
      isEemeli: "global/isEemeli",
    }),
  },
};
</script>
