<template>
  <b-tr>
    <b-td>
      <template v-if="isEditing">
        <b-form-group class="mb-1" v-if="isUnAssigned">
          <b-form-input
            v-model="form.name"
            placeholder="Toote nimi"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <b-textarea
            rows="6"
            size="sm"
            v-model="form.additional_info"
          ></b-textarea>
        </b-form-group>
      </template>
      <template v-else>
        <span v-if="isUnAssigned">{{ item.name }}<br /></span>
        <small>
          {{ item.additional_info }}
        </small>
      </template>
    </b-td>
    <b-td class="text-center" style="width: 15%;">
      <template v-if="isEditing">
        <b-form-group class="mb-1">
          <b-form-input
            v-model="form.ordered_place"
            placeholder="Tellitud koht"
            size="sm"
          ></b-form-input>
        </b-form-group>
      </template>
      <template v-else>
        <small>
          {{ item.ordered_place }}
        </small>
      </template>
    </b-td>
    <b-td class="text-center" style="width: 15%;">
      <template v-if="isEditing">
        <b-form-group class="mb-1">
          <b-form-input
            type="number"
            v-model.number="form.price"
            placeholder="Hind"
            size="sm"
          ></b-form-input>
        </b-form-group>
      </template>
      <template v-else>
        <small> {{ item.price | currency }} &euro; </small>
      </template>
    </b-td>
    <b-td class="text-center" style="width: 10%;">
      <b-badge v-if="item.status == 'ordered'" variant="warning">
        Tellitud
      </b-badge>
      <b-badge v-if="item.status == 'in-stock'" variant="success">
        Laos
      </b-badge>
      <b-badge v-if="item.status == 'reserved'" variant="primary">
        Broneeritud
      </b-badge>
      <b-badge v-if="item.status == 'out-of-stock'" variant="danger">
        Müüdud
      </b-badge>
    </b-td>
    <b-td style="width: 10%;" class="text-right">
      <template v-if="isEditing">
        <b-button
          size="sm"
          variant="danger"
          class="mr-1"
          @click.prevent="cancelEditing"
        >
          <font-awesome-icon icon="times" />
        </b-button>
        <b-button size="sm" variant="success" @click.prevent="saveEditing">
          <font-awesome-icon icon="check" />
        </b-button>
      </template>
      <template v-else>
        <b-dropdown
          variant="outline-primary"
          size="sm"
          boundary="window"
          :disabled="isLoading"
        >
          <template #button-content>
            <b-spinner
              v-if="isLoading"
              variant="primary"
              label="Loading..."
              small
            ></b-spinner>
            <span v-else>Tegevused</span>
          </template>
          <b-dropdown-item @click="editRow">
            Muuda
          </b-dropdown-item>
          <b-dropdown-item
            v-if="item.status == 'in-stock'"
            @click="reservProduct"
          >
            Broneeri
          </b-dropdown-item>
          <b-dropdown-item
            v-if="item.status == 'reserved'"
            @click="arrivedInStock"
          >
            Tühista broneering
          </b-dropdown-item>
          <b-dropdown-item
            v-if="item.status == 'ordered'"
            @click="arrivedInStock"
          >
            Saabus lattu
          </b-dropdown-item>
          <b-dropdown-item
            v-if="item.status == 'in-stock' || item.status == 'reserved'"
            @click="sendOutOfStock(true)"
          >
            Saada laost välja
          </b-dropdown-item>
          <b-dropdown-item v-if="!isUnAssigned" @click="duplicateItem">
            Lisa uus sellest
          </b-dropdown-item>
          <b-dropdown-item v-if="isUnAssigned" @click="assignProduct(true)">
            Määra toode
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <b-modal
        v-model="showOutOfStockModal"
        title="Tellimuse number"
        size="sm"
        centered
        button-size="sm"
        @ok="sendOutOfStock(false)"
      >
        <b-form-group label="Order ID">
          <b-form-input
            size="sm"
            type="number"
            v-model.number="orderId"
          ></b-form-input>
        </b-form-group>
      </b-modal>

      <b-modal
        v-model="showAssignProductModal"
        title="Toote ID"
        size="sm"
        centered
        button-size="sm"
        @ok.prevent="assignProduct(false)"
      >
        <b-form-group label="Toote ID">
          <b-form-input
            size="sm"
            type="number"
            v-model.number="productId"
          ></b-form-input>
        </b-form-group>
      </b-modal>
    </b-td>
  </b-tr>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";

export default {
  props: {
    item: {},
    isUnAssigned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isEditing: false,
      showOutOfStockModal: false,
      showAssignProductModal: false,
      orderId: null,
      productId: null,
      form: {
        name: null,
        additional_info: null,
        ordered_place: null,
        price: null,
      },
    };
  },
  methods: {
    editRow() {
      this.form.name = this.item.name;
      this.form.additional_info = this.item.additional_info;
      this.form.ordered_place = this.item.ordered_place;
      this.form.price = this.item.price;

      this.isEditing = true;
    },
    cancelEditing() {
      this.form = {
        name: null,
        additional_info: null,
        ordered_place: null,
        price: null,
      };

      this.isEditing = false;
    },
    saveEditing() {
      this.isLoading = true;
      return RestService.putStorageItemInfo(this.item.id, this.form).then(
        (response) => {
          this.$emit("itemUpdated", response.data.data);
          this.isEditing = false;
          this.isLoading = false;
        }
      );
    },
    reservProduct() {
      this.isLoading = true;
      return RestService.putStorageItemStatus(this.item.id, {
        status: "reserved",
      }).then((response) => {
        this.$emit("itemUpdated", response.data.data);
        this.isLoading = false;
      });
    },
    arrivedInStock() {
      this.isLoading = true;
      return RestService.putStorageItemStatus(this.item.id, {
        status: "in-stock",
      }).then((response) => {
        this.$emit("itemUpdated", response.data.data);
        this.isLoading = false;
      });
    },
    sendOutOfStock(showModal = false) {
      if (showModal) {
        this.orderId = null;
        return (this.showOutOfStockModal = true);
      }

      this.isLoading = true;
      return RestService.putStorageItemStatus(this.item.id, {
        status: "out-of-stock",
        order_id: this.orderId,
      }).then((response) => {
        this.$emit("removeItem", response.data.data);
        this.isLoading = false;
      });
    },
    assignProduct(showModal = false) {
      if (showModal) {
        this.productId = null;
        return (this.showAssignProductModal = true);
      }

      if (!this.productId) {
        errorMessage("Viga!", "Toote id on kohustuslik!");
        return;
      }

      this.showAssignProductModal = false;

      this.isLoading = true;
      return RestService.postStorageItemProduct(this.item.id, {
        product_id: this.productId,
      })
        .then(() => {
          this.$emit("loadNew");
          this.isLoading = false;
        })
        .catch(() => {
          errorMessage("Viga!", "Toodet ei leitud!");
        });
    },
    duplicateItem() {
      this.$emit("duplicate", this.item);
    },
  },
};
</script>
