import Vue from "vue";

export const Emails = Vue.component("emails", require("./Emails.vue").default);
export const EmailEdit = Vue.component(
  "email-edit",
  require("./EmailEdit.vue").default
);
export const EmailNew = Vue.component(
  "email-new",
  require("./EmailNew.vue").default
);
