var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative"},[(_vm.isLoading)?_c('loader',{attrs:{"overlay":true}}):_vm._e(),_c('h1',[_vm._v(" Kategooria mapper "),_c('router-link',{staticClass:"btn btn-primary btn-sm",attrs:{"to":{
        name: 'CategoryMapsAll',
      }}},[_vm._v(" Kõik tarnijad ")]),(!_vm.isEemeli)?_c('router-link',{staticClass:"btn btn-primary btn-sm ml-3",attrs:{"to":{
        name: 'CategoryMapsIcecat',
      }}},[_vm._v(" Icecati kategooriad ")]):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-table-simple',{attrs:{"bordered":"","responsive":"","small":""}},[_c('b-tr',{attrs:{"variant":"primary"}},[_c('b-th',[_vm._v("Nimi")])],1),_vm._l((_vm.filteredSuppliers),function(supplier){return _c('b-tr',{key:supplier.id},[_c('b-td',[_c('router-link',{attrs:{"to":{
                name: 'CategoryMaps',
                params: { supplierId: supplier.id },
              }}},[_vm._v(" "+_vm._s(supplier.name)+" ")])],1)],1)})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }