<template>
  <div>
    <b-button
      variant="warning"
      size="sm"
      @click.prevent="openModal"
    >
      Koosta RMA
    </b-button>

    <b-modal size="lg" v-model="showModal" hide-footer title="Koosta RMA">
      <loader :overlay="true" v-if="isModalLoading" />

      <b-form-group>
        <b-form-radio-group
          v-model="rmaType"
          buttons
          button-variant="outline-primary"
        >
          <b-form-radio name="rmaType" value="warranty">Garantii</b-form-radio>
          <b-form-radio name="rmaType" value="return">Tagastus</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      
      <b-form-checkbox-group
        v-model="selectedProducts"
        name="selected-rma-products"
      >
        <b-table-simple small bordered>
          <b-tr v-for="product in order.product_data" :key="product.id">
            <b-td class="text-center" style="width:1%">
              <b-form-checkbox class="m-0" size="lg" :value="product.id"></b-form-checkbox>
            </b-td>
            <b-td>
              <small>
                {{ product.name }}
              </small>
            </b-td>
          </b-tr>
        </b-table-simple>
      </b-form-checkbox-group>

      <b-form-group
        label="Kliendi kommentaar / probleem"
        class="mb-0"
      >
        <b-form-textarea
          v-model="clientComment"
        ></b-form-textarea>
      </b-form-group>

      <div class="text-right mt-3">
        <b-button variant="success" @click.prevent="createRma">
          Koosta RMA
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RestService from '@/lib/RestService';
import { errorMessage } from '@/lib/messages';

export default {
  props: {
    order: {
      type: Object,
    }
  },
  data() {
    return {
      selectedProducts: [],
      clientComment: null,
      isModalLoading: false,
      showModal: false,
      rmaType: null,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    createRma() {
      this.isModalLoading = true;
        RestService.postCreateNewRma({
          orderId: this.order.id,
          comment: this.clientComment,
          type: this.rmaType,
          products: this.selectedProducts,
        }).then((response) => {
          this.isModalLoading = false;
          this.showModal = true;

          this.$router.push({
            name: "order.rma.show",
            params: { rmaId: response.data.data.id },
          });
        }).catch((error) => {
          if (error.response) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isModalLoading = false;
        });
    }
  }
}
</script>