<template>
  <b-card :class="`border-left-${variant} shadow py-2 mb-4`">
    <div class="row no-gutters align-items-center">
      <div class="col mr-2">
        <div :class="`font-weight-bold text-${variant} mb-1`">
          {{ label }}
        </div>
        <div class="font-weight-bold" v-if="isCurrency">
          {{ value | currency }} &euro;
        </div>
        <div class="font-weight-bold" v-else>
          {{ value | number }}
        </div>
      </div>
      <div class="col-auto" v-if="icon">
        <font-awesome-icon :class="`text-${variant} fa-2x`" :icon="icon" />
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: null,
    },
    isCurrency: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
