<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Tarnijad
      <router-link
        class="btn btn-primary btn-sm"
        :to="{
          name: 'suppliers.new',
        }"
      >
        Lisa uus
      </router-link>
    </h1>

    <b-row>
      <b-col md="6">
        <b-table-simple bordered responsive small class="mb-0">
          <b-tr variant="primary">
            <b-th>
              Nimi
            </b-th>
            <b-th style="width: 15%;" class="text-center">
              Peidetud
            </b-th>
            <b-th style="width: 15%;"></b-th>
          </b-tr>

          <b-tr v-for="supplier in suppliers" :key="supplier.id">
            <b-td>
              <b-badge>
                {{ supplier.frontend_name }}
              </b-badge>
              <router-link
                :to="{
                  name: 'suppliers.edit',
                  params: { supplierId: supplier.id },
                }"
              >
                {{ supplier.name }}
              </router-link>
            </b-td>
            <b-td class="text-center">
              <font-awesome-icon
                class="text-success"
                icon="check"
                v-if="supplier.is_hidden"
              />
              <font-awesome-icon class="text-danger" icon="times" v-else />
            </b-td>
            <b-td class="text-right">
              <router-link
                class="btn btn-warning btn-sm mr-2"
                :to="{
                  name: 'suppliers.edit',
                  params: { supplierId: supplier.id },
                }"
              >
                Muuda
              </router-link>
            </b-td>
          </b-tr>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";

export default {
  data() {
    return {
      isLoading: false,
      suppliers: [],
    };
  },
  mounted() {
    this.fetchSuppliers();
  },
  methods: {
    fetchSuppliers() {
      this.isLoading = true;
      return RestService.getSuppliers().then((response) => {
        this.suppliers = response.data.data;
        this.isLoading = false;
      });
    },
  },
};
</script>
