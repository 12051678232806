<template>
  <div class="mapper">
    <b-overlay :show="isLoading" opacity="0.3" variant="white" rounded="sm">
      <h1>Kategooria mapper (Icecat)</h1>
      <div class="row">
        <div class="col">
          <b-form-group class="mb-3">
            <b-form-input
              class="form-control"
              v-model="searchKey"
              placeholder="Otsing"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <!-- <b-form-group>
            <b-form-checkbox
              v-model="showNotMapped"
              name="check-button"
              switch
              @change="setShowNotMappedFilter"
            >
              Show only not mapped
            </b-form-checkbox>
          </b-form-group> -->

          <div class="list-group scroll-list">
            <button
              type="button"
              class="list-group-item list-group-item-action"
              v-for="map in filteredMaps"
              v-bind:key="map.id"
              @click.prevent="getMap(map)"
            >
              {{ map.name }}

              <div class="float-right">
                <span
                  class="badge badge-success badge-pill mr-2"
                  v-if="map.categories.length"
                >
                  ok
                </span>
              </div>
            </button>
          </div>
        </div>
        <div class="col">
          <IcecatMapInfo
            v-if="selectedMap"
            :selectedMap="selectedMap"
            @startLoading="startLoading"
            @stopLoading="stopLoading"
          />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapActions, mapGetters } from "vuex";
import IcecatMapInfo from "./IcecatMapInfo";

export default {
  components: {
    IcecatMapInfo,
  },
  data() {
    return {
      isLoading: false,
      searchKey: "",
      showNotMapped: false,
      categories: [],
      selectedMap: null,
    };
  },
  computed: {
    ...mapGetters({}),
    filteredMaps() {
      let results = this.categories.filter((map) => {
        if (this.showNotMapped) {
          if (map.categories.length) {
            return false;
          }
        }

        if (!this.searchKey) {
          return true;
        }

        return (
          map.name.toLowerCase().indexOf(this.searchKey.toLowerCase()) != -1
        );
      });

      return results;
    },
  },
  mounted() {
    this.fetchIcecatCategories();
    this.fetchCategories();
  },
  methods: {
    ...mapActions({
      fetchCategories: "categories/fetchCategories",
    }),
    search() {},
    getMap(icecatCategory) {
      this.isLoading = true;
      this.selectedMap = null;
      RestService.getCategoryMapIcecat(icecatCategory.id).then((response) => {
        this.selectedMap = response.data.data;
        this.isLoading = false;
      });
    },
    fetchIcecatCategories() {
      this.isLoading = true;
      RestService.getCategoryMapsIcecat().then((response) => {
        this.categories = response.data.data;
        this.isLoading = false;
      });
    },
    startLoading() {
      this.isLoading = true;
    },
    stopLoading(data) {
      this.selectedMap = data;

      console.log(data);

      let index = this.categories.findIndex((c) => c.id == data.id);
      this.$set(this.categories, index, data);

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.scroll-list {
  height: 70vh;
  overflow-y: auto;
}

.mapper {
  .list-group-item {
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
  }
}
</style>
