<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Lehed
      <router-link
        class="btn btn-primary btn-sm"
        :to="{
          name: 'page.new',
        }"
      >
        Lisa uus
      </router-link>
    </h1>

    <b-table-simple bordered responsive small class="mb-0">
      <b-tr variant="primary">
        <b-th style="width: 5%;" class="text-center">#</b-th>
        <b-th>
          Nimi
        </b-th>
        <b-th style="width: 15%;" class="text-center">
          Aktiivne
        </b-th>
        <b-th style="width: 15%;"></b-th>
      </b-tr>

      <b-tr v-for="(page, pageKey) in pages" :key="page.id">
        <b-td class="text-center">
          {{ (pageKey + 1) * currentPage }}
        </b-td>
        <b-td>
          {{ page.title }}
        </b-td>
        <b-td class="text-center">
          <font-awesome-icon
            class="text-success"
            icon="check"
            v-if="page.is_active"
          />
          <font-awesome-icon class="text-danger" icon="times" v-else />
        </b-td>
        <b-td class="text-right">
          <router-link
            class="btn btn-warning btn-sm mr-2"
            :to="{
              name: 'page.edit',
              params: { pageId: page.id },
            }"
          >
            Muuda
          </router-link>

          <b-button
            variant="danger"
            size="sm"
            @click.prevent="deletePageConfirm(page)"
          >
            X
          </b-button>
        </b-td>
      </b-tr>
    </b-table-simple>

    <template v-if="pagination">
      <small>Lehti kokku: {{ pagination.total }}</small>
      <b-pagination
        class="mt-3"
        v-model="currentPage"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        @change="pageChange"
      ></b-pagination>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      pages: "pages/pages",
      pagination: "pages/pagination",
    }),
  },
  mounted() {
    if (!this.pages.length) {
      this.currentPage = 1;
      this.fetchPages();
    } else {
      this.currentPage = this.pagination.current_page;
      this.fetchPages(this.currentPage);
    }
  },
  methods: {
    ...mapActions({
      getPages: "pages/getPages",
      deletePage: "pages/deletePage",
    }),
    fetchPages(page = 1) {
      this.isLoading = true;
      this.getPages({
        page: page,
      }).then(() => {
        this.isLoading = false;
      });
    },
    pageChange(page) {
      this.fetchPages(page);
    },
    deletePageConfirm(page) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            this.deletePage(page).then(() => {
              this.fetchPages(this.currentPage);
            });
          }
        },
      });
    },
  },
};
</script>
