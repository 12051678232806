var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"d-inline-block mr-3 float-left"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('b-button',{staticClass:"d-block d-md-none float-right",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){$event.preventDefault();return _vm.toggleOrdersSidebar.apply(null, arguments)}}},[_c('font-awesome-icon',{attrs:{"icon":"bars"}})],1),_c('div',{staticClass:"d-none d-sm-block",class:{ 'd-block': _vm.showMenu }},[_c('b-nav',{staticClass:"float-left",attrs:{"pills":"","small":""}},[_c('b-nav-item',{attrs:{"to":{
          name: 'order.new',
        },"active":_vm.type == 'new'}},[_vm._v(" Uus tellimus ")]),_c('b-nav-item',{attrs:{"to":{
          name: 'orders.mine',
        },"active":_vm.type == 'mine'}},[_vm._v(" Minu tellimused ")]),_c('b-nav-item',{attrs:{"to":{
          name: 'orders.status.type',
          params: { statusTypeId: 1 },
        },"active":_vm.type == 'orders'}},[_vm._v(" Tellimused ")]),_c('b-nav-item',{attrs:{"to":{
          name: 'orders.search',
        },"active":_vm.type == 'search'}},[_vm._v(" Otsing ")]),_c('b-nav-item',{attrs:{"to":{
          name: 'orders.waiting-office',
        },"active":_vm.type == 'stock'}},[_vm._v(" Lattu saabuvad tooted ")]),_c('b-nav-item',{attrs:{"to":{
          name: 'orders.attention',
        },"active":_vm.type == 'attention'}},[_vm._v(" Tähelepanu vajavad tellimused ")]),_c('b-nav-item',{attrs:{"to":{
          name: 'orders.unpaid',
        },"active":_vm.type == 'unpaid-orders'}},[_vm._v(" Maksmata tellimused ")]),(!_vm.isEemeli)?_c('b-nav-item',{attrs:{"to":{
          name: 'orders.not-sent-merit',
        },"active":_vm.type == 'not-sent-merit-orders'}},[_vm._v(" Meritisse saatmata tellimused ")]):_vm._e()],1)],1),_c('div',{staticClass:"clearfix"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }