<template>
  <div>
    <h1>Stock</h1>
    <div class="row">
      <div class="col">
        <form @submit.prevent="codeSubmitted()">
          <input
            type="text"
            class="form-control"
            rows="5"
            v-model="input"
            :autofocus="'autofocus'"
          />
          {{ battery }} <br />
          {{ totalCodes }}
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h3>Scanned codes:</h3>

        <ul class="list-unstyled">
          <li v-for="(code, index) in scannedCodes" v-bind:key="index">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td colspan="2">
                    <strong>Code:</strong> {{ code.code || code.input }}
                    <font-awesome-icon
                      icon="spinner"
                      spin
                      v-if="!code.code && !code.message"
                    />
                    <span class="text-danger">{{ code.message }}</span>
                  </td>
                  <td></td>
                </tr>
                <template v-if="code.code && code.results">
                  <tr
                    v-for="(product, resultKey) in code.results"
                    v-bind:key="resultKey"
                  >
                    <td style="width: 5%;">
                      <img
                        :src="product.main_image"
                        class="img-fluid"
                        style="max-height: 100px;"
                      />
                    </td>
                    <td>
                      <router-link
                        class="product-link"
                        :to="{
                          name: 'products.edit',
                          params: { productId: product.id },
                        }"
                      >
                        <strong>
                          {{ product.name }}
                        </strong>
                      </router-link>
                      <br />
                      <template v-if="product.codes.length">
                        <strong>Product codes:</strong> <br />
                        <ul class="list-unstyled small">
                          <li v-for="code in product.codes" :key="code.id">
                            <strong> {{ code.type }}: </strong>
                            {{ code.code }}
                          </li>
                        </ul>
                      </template>
                    </td>
                    <td style="width: 30%;">
                      <b-table-simple bordered responsive small>
                        <b-tr variant="primary">
                          <b-th style="width: 20%;">
                            Name
                          </b-th>
                          <b-th style="width: 40%;">
                            In Stock
                          </b-th>
                          <b-th style="width: 40%;">
                            Price
                          </b-th>
                          <b-th style="width: 5%;">
                            History
                          </b-th>
                        </b-tr>
                        <StockRow
                          v-for="stock in product.stocks"
                          :stock="stock"
                          :key="stock.id"
                        />
                      </b-table-simple>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import StockRow from "@/app/products/components/productList/StockRow";

export default {
  components: {
    StockRow,
  },
  data() {
    return {
      input: null,
      battery: "",
      totalCodes: "",
      scannedCodes: [],
    };
  },
  methods: {
    codeSubmitted() {
      if (!this.input) {
        return;
      } else if (this.input.startsWith("Dump Energy")) {
        this.battery = this.input;
        this.input = null;

        return;
      } else if (this.input.startsWith("Total Counters")) {
        this.totalCodes = this.input;
        this.input = null;

        return;
      }

      this.getResult(this.input);
      this.input = null;
    },
    getResult(codeI) {
      var code = codeI;
      this.errorMesage = null;
      this.scannedCodes.unshift({
        input: code,
      });

      axios.get("storages/ean/find?ean=" + code, {}).then((response) => {
        if (!response.data.status) {
          this.scannedCodes.forEach((codeItem, index) => {
            if (codeItem.code != undefined) {
              return;
            }

            if (code == codeItem.input) {
              this.$set(this.scannedCodes, index, {
                input: code,
                message: response.data.message,
              });
              return;
            }
          });
          return;
        }

        this.scannedCodes.forEach((codeItem, index) => {
          if (codeItem.code != undefined) {
            return;
          }

          if (response.data.data.input == codeItem.input) {
            this.$set(this.scannedCodes, index, response.data.data);
            return false;
          }
        });
      });
    },
  },
};
</script>
