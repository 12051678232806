import Vue from "vue";

export const Leases = Vue.component("leases", require("./Leases.vue").default);
export const LeaseEdit = Vue.component(
  "lease-edit",
  require("./LeaseEdit.vue").default
);
export const LeaseNew = Vue.component(
  "lease-new",
  require("./LeaseNew.vue").default
);
