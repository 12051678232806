<template>
  <div>
    <OffersHeader type="offers" />
    <b-nav class="mt-3 mb-3" small tabs>
      <b-nav-item v-for="type in offerTypes" :key="type.value" :active="type.value == currentOfferType"
        :to="{
          name: 'offers',
          params: { statusId: type.value }
        }"
      >
        {{ type.text }}
      </b-nav-item>
    </b-nav>

    <OffersList :statusId="currentOfferType"/>
    
  </div>
</template>

<script>
import OffersHeader from "./OffersHeader.vue";
import OffersList from "./OffersList.vue";

export default {
  components: {
    OffersHeader,
    OffersList
  },
  data() {
    return {
      offerTypes: [
        {
          text: 'Tühistatud',
          value: 0,
        },
        {
          text: 'Pooleli',
          value: 1,
        },
        {
          text: 'Saadetud',
          value: 2,
        },
        {
          text: 'Aksepteeritud',
          value: 3,
        }
      ]
    };
  },
  computed: {
    currentOfferType() {
      if (this.$route.params.statusId === undefined) {
        return 1
      }

      return this.$route.params.statusId;
    }
  },
  methods: {},
};
</script>
