import { ShippingZones } from '../components'
import { ShippingZoneEdit } from '../components'
import { ShippingZoneNew } from '../components'

export default [
    {
        path: '/shipping/zones',
        component: ShippingZones,
        name: 'shippingZones',
        meta: {
            guest: false,
            needsAuth: true,
        }
    },
    {
        path: '/shipping/zones/new',
        component: ShippingZoneNew,
        name: 'shippingZones.new',
        meta: {
            guest: false,
            needsAuth: true,
        }
    },
    {
        path: '/shipping/zones/:shippingZoneId',
        component: ShippingZoneEdit,
        name: 'shippingZones.edit',
        meta: {
            guest: false,
            needsAuth: true,
        }
    },
]
