<template>
  <div>
    <loader :overlay="true" v-if="isLoading" />

    <b-row v-if="products">
      <b-col>
        <b-table-simple bordered responsive small v-if="products.length">
          <b-tr variant="primary">
            <b-th style="width: 1%"></b-th>
            <b-th style="width: 5%"></b-th>
            <b-th style="width: 50%">Nimi</b-th>
            <b-th>Laod</b-th>
            <b-th style="width: 5%"></b-th>
          </b-tr>
          <ProductRow
            v-for="product in products"
            :product="product"
            :key="product.id"
            @update="handleProductUpdate"
          />
        </b-table-simple>
        <b-alert v-else variant="warning" show>
          Tulemusi ei leitud!
        </b-alert>

        <template v-if="pagination">
          <small>Tooteid kokku: {{ pagination.total }}</small>
          <b-pagination
            class="mt-3"
            v-model="currentPage"
            :total-rows="pagination.total"
            :per-page="pagination.per_page"
            @change="pageChange"
          ></b-pagination>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
import ProductRow from "../productList/ProductRow";

export default {
  props: {
    type: {
      type: String,
      default: "ordered",
    },
  },
  components: {
    ProductRow,
  },
  data() {
    return {
      isLoading: false,
      products: null,
      pagination: null,
      currentPage: 1,
    };
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    fetchProducts(page = 1) {
      this.isLoading = true;
      RestService.getProductsManualPrice({ page })
        .then((response) => {
          this.products = response.data.data;
          this.pagination = response.data.meta.pagination;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isLoading = false;
        });
    },
    pageChange(page) {
      this.fetchProducts(page);
    },
    handleProductUpdate(product) {
      const pindex = this.products.findIndex((p) => p.id == product.id);
      if (pindex > -1) {
        this.$set(this.products, pindex, product);
      }
    }
  },
};
</script>
