import { Checkout } from "../components";

export default [
  {
    path: "/checkout",
    component: Checkout,
    name: "checkout",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
