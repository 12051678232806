<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      Järelmaksu muutmine <small v-if="lease">- {{ lease.name }}</small>
    </h2>

    <template v-if="lease">
      <b-row>
        <b-col md="6">
          <b-card header="Järelmaksu info">
            <b-form>
              <b-form-group class="mb-1" label="Nimi">
                <b-form-input
                  v-model="lease.name"
                  size="sm"
                  :state="getFieldStateFromErrors('name')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("name") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Tüüp">
                <b-form-select
                  v-model="lease.type"
                  :options="typeOptions"
                  size="sm"
                ></b-form-select>
                <b-form-invalid-feedback>
                  {{ getFieldError("type") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Teenustasu">
                <b-form-input
                  type="number"
                  v-model.number="lease.fee"
                  size="sm"
                  :state="getFieldStateFromErrors('fee')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("fee") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Intress">
                <b-form-input
                  type="number"
                  v-model.number="lease.interest"
                  size="sm"
                  :state="getFieldStateFromErrors('interest')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("interest") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Ostukorvi miinimum summa">
                <b-form-input
                  type="number"
                  v-model.number="lease.total_min"
                  size="sm"
                  :state="getFieldStateFromErrors('total_min')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("total_min") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Ostukorvi maksimum summa">
                <b-form-input
                  type="number"
                  v-model.number="lease.total_max"
                  size="sm"
                  :state="getFieldStateFromErrors('total_max')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("total_max") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <div class="text-right mt-3">
                <b-button
                  type="submit"
                  variant="success"
                  @click.prevent="saveLease"
                >
                  Salvesta
                </b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,
      lease: null,
      typeOptions: [
        { value: "NORMAL", text: "Tavaline järelmaks" },
        { value: "SLICE", text: "Maksa kolmes osas" },
        { value: "PAY_LATER", text: "Maksa hiljem" },
      ],
    };
  },
  mounted() {
    this.fetchLease();
  },
  methods: {
    fetchLease() {
      RestService.getLease(this.$route.params.leaseId).then((response) => {
        this.lease = response.data.data;
        this.clearValidationErrors();

        this.isLoading = false;
      });
    },
    saveLease() {
      this.isLoading = true;

      RestService.postSaveLease(this.lease.id, this.lease)
        .then((response) => {
          this.lease = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
  },
};
</script>
