<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Sooduskoodid
      <router-link
        class="btn btn-primary btn-sm"
        :to="{
          name: 'discount-codes.new',
        }"
      >
        Lisa uus
      </router-link>
    </h1>

    <b-table-simple bordered responsive small class="mb-0" v-if="codes">
      <b-tr variant="primary">
        <b-th>
          Nimi
        </b-th>
        <b-th class="text-center">
          Kehtiv
        </b-th>
        <b-th class="text-center">
          Kasutatud
        </b-th>
        <b-th class="text-center">
          Aktiivne
        </b-th>
        <b-th style="width: 15%;"></b-th>
      </b-tr>

      <b-tr v-for="code in codes" :key="code.id">
        <b-td>
          {{ code.code }}
        </b-td>
        <b-td class="text-center">
          <small>
            {{ code.starts_at | moment("DD.MM.YYYY HH:mm:ss") }} -
            {{ code.ends_at | moment("DD.MM.YYYY HH:mm:ss") }}
          </small>
        </b-td>
        <b-td class="text-center">
          {{ code.used_count }}
        </b-td>
        <b-td class="text-center">
          <b-badge variant="success" v-if="code.is_active_now">
            Jah
          </b-badge>
          <b-badge variant="danger" v-else>Ei</b-badge>
        </b-td>
        <b-td class="text-right">
          <router-link
            class="btn btn-warning btn-sm mr-2"
            :to="{
              name: 'discount-codes.edit',
              params: { discountCodeId: code.id },
            }"
          >
            Muuda
          </router-link>

          <b-button variant="danger" size="sm">
            X
          </b-button>
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";

export default {
  data() {
    return {
      isLoading: false,
      codes: null,
    };
  },
  computed: {},
  mounted() {
    this.fetchCodes();
  },
  methods: {
    fetchCodes() {
      this.isLoading = true;
      RestService.getDiscountCodes().then((response) => {
        this.codes = response.data.data;
        this.isLoading = false;
      });
    },
  },
};
</script>
