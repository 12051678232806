import Vue from "vue";
import Vuex from "vuex";
import global from "./global";
import generic from "./generic";
import auth from "../app/auth/vuex";
import categories from "../app/categories/vuex";
import stock from "../app/stock/vuex";
import categoryMapper from "../app/categoryMapper/vuex";
import offers from "../app/offers/vuex";
import shippingZones from "../app/shippingZones/vuex";
import catalog from "../app/catalog/vuex";
import users from "../app/users/vuex";
import pages from "../app/pages/vuex";
import products from "../app/products/vuex";
import orderStatuses from "../app/orderStatuses/vuex";
import menus from "../app/menus/vuex";
import orders from "../app/orders/vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    global: global,
    generic: generic,
    auth: auth,
    categories: categories,
    stock: stock,
    categoryMapper: categoryMapper,
    offers: offers,
    shippingZones: shippingZones,
    catalog: catalog,
    users: users,
    pages: pages,
    products: products,
    orderStatuses: orderStatuses,
    menus: menus,
    orders: orders,
  },
});
