<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Morele tellimused
    </h1>

    <b-card class="mb-3" header="" no-body v-for="order in orders" :key="order.id">
      <b-card-header class="pr-1">
        Tellimus nr <strong>{{ order.id }}</strong>
        <b-button :href="`https://www.morele.net/profil/szczegoly-zamowienia/${order.id}`" class="ml-3" size="sm" variant="outline-primary" target="_blank">Ava</b-button>
      </b-card-header>
      <div class="p-2">
        <b-table-simple small bordered>
          <b-tr variant="primary">
            <b-th>Nimi</b-th>
            <b-th class="text-center" style="width: 10%">Hind</b-th>
            <b-th class="text-center" style="width: 10%">Kogus</b-th>
            <b-th class="text-center" style="width: 10%">Summa</b-th>
          </b-tr>
          <b-tr v-for="product in order.products" :key="`${order.id}-${product.sku}`" :variant="getItemVariant(product)">
            <b-td>
              {{ product.name }} <br>
              <small>
                <strong>Tellimus: </strong> #{{ product.order_id }} <br>
                <strong>Morele id: </strong> {{ product.morele_id }} <br>
              </small>
            </b-td>
            <b-td class="text-center">{{ product.item_price | currency }}&nbsp;&euro;</b-td>
            <b-td class="text-center">{{ product.quantity }}</b-td>
            <b-td class="text-center">{{ product.price | currency }}&nbsp;&euro;</b-td>
          </b-tr>
          <b-tr class="font-weight-bold" variant="secondary">
            <b-td></b-td>
            <b-td></b-td>
            <b-td class="text-center">{{ order.total_products }}</b-td>
            <b-td class="text-center">{{ order.total_sale | currency }}&nbsp;&euro;</b-td>
          </b-tr>
        </b-table-simple>
      </div>
    </b-card>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";

export default {
  data() {
    return {
      isLoading: false,
      orders: null,
    };
  },
  mounted() {
    this.fetchSupplierMoreleOrders();
  },
  methods: {
    fetchSupplierMoreleOrders() {
      this.isLoading = true;
      RestService.getSupplierOrdersMoreleMain({})
        .then((response) => {
          this.orders = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga", error.response.data.message);
          }
        });
    },
    getItemVariant(product) {
      if (product.status == -1) {
        return "danger";
      }

      if (product.price == 0) {
        return "warning";
      }

      if (product.status == 2) {
        return "success";
      }

      return "";
    }
  },
};
</script>
