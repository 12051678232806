<template>
  <div class="position-relative">
    <OrdersHeader type="not-sent-merit-orders" />
    <loader v-if="isLoading" :overlay="true" />

    <template v-if="orders !== null && !orders.length">
      <b-alert variant="warning" show>
        Tellimusi ei leitud.
      </b-alert>
    </template>
    <template v-else>
      <OrderItem v-for="order in orders" :order="order" :key="order.id" />
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
import OrderItem from "./OrderItem";
import OrdersHeader from "./OrdersHeader";

export default {
  components: {
    OrderItem,
    OrdersHeader,
  },
  data() {
    return {
      isLoading: false,
      orders: null,
    };
  },
  mounted() {
    this.fetchOrders();
  },
  methods: {
    fetchOrders() {
      this.isLoading = true;

      RestService.getNotSentMeritOrders()
        .then((response) => {
          this.orders = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    },
  },
};
</script>
