<template>
  <div class="position-relative">
    <loader :overlay="true" v-if="isProductsLoading" />
    <div class="mb-3">
      <strong>{{ selectedCategory.name }}</strong>
      <b-button
        :disabled="!selectedProductIds.length"
        class="ml-2"
        variant="danger"
        size="sm"
        @click.prevent="removeSelectedProductsFromCategoryConfirm"
      >
        Remove from this category
      </b-button>
      <b-button
        :disabled="!selectedProductIds.length"
        class="ml-2"
        variant="warning"
        size="sm"
        @click.prevent="removeAndSelectNewCategory"
      >
        Remove and select new category
      </b-button>
      <b-button
        :disabled="!selectedProductIds.length"
        class="ml-2"
        variant="success"
        size="sm"
        @click.prevent="addSelectedProductsToNewCategoryModal"
      >
        Add to new category
      </b-button>

      <b-button
        v-if="!isEemeli"
        :disabled="!selectedProductIds.length"
        class="ml-2"
        variant="outline-primary"
        size="sm"
        @click.prevent="addSelectedProductsToIcecatDownload"
      >
        Download icecat
      </b-button>

      <b-dropdown
        variant="outline-primary"
        size="sm"
        text="Facebook"
        class="m-md-2"
      >
        <b-dropdown-item @click="handleShowFacebookHere(true)">
          Kuva facebookis
        </b-dropdown-item>
        <b-dropdown-item @click="handleShowFacebookHere(false)">
          Ära kuva facebookis
        </b-dropdown-item>
      </b-dropdown>
      
      <b-dropdown
        variant="outline-primary"
        size="sm"
        text="Pakiautomaat"
        class="m-md-2"
      >
        <b-dropdown-item @click="handleSetParcelMachineHere(true)">
          Jah
        </b-dropdown-item>
        <b-dropdown-item @click="handleSetParcelMachineHere(false)">
          Ei
        </b-dropdown-item>
      </b-dropdown>

      <!-- <b-form-group
        label="Per page"
        class="float-right mb-1"
        style="max-width: 100px;"
      >
        <b-form-select
          v-model="perPage"
          :options="perPageOptions"
          size="sm"
        ></b-form-select>
      </b-form-group> -->

      <slot></slot>
    </div>

    <div class="mb-3">
      Selected products IDs: <small>{{ selectedProductIds }}</small>
    </div>

    <template v-if="categoryProducts && categoryProducts.length">
      <b-table-simple sticky-header="1000px" bordered responsive small>
        <b-thead>
          <b-tr variant="primary">
            <b-th class="text-center" style="width: 1%;">
              <font-awesome-icon
                class="check-icon"
                :icon="['far', 'check-square']"
                @click.prevent="selectAllFromThisPage"
              />
            </b-th>
            <b-th style="width: 5%;">Image</b-th>
            <b-th>Name</b-th>
            <b-th>Stocks</b-th>
          </b-tr>
        </b-thead>

        <b-tbody>
          <ProductRow
            v-for="product in categoryProducts"
            :product="product"
            :key="product.id"
            @added="addSelectedProductId"
            @removed="removeSelectedProductId"
            @update="handleProductUpdate"
          />
        </b-tbody>
      </b-table-simple>
      <b-pagination
        v-if="categoryProductsMeta"
        v-model="currentPage"
        :total-rows="categoryProductsMeta.pagination.total"
        :per-page="categoryProductsMeta.pagination.per_page"
        @change="fetchNewPage"
        class="mb-0"
      ></b-pagination>
      <small v-if="categoryProductsMeta">
        <strong>Total products in this category: </strong
        >{{ categoryProductsMeta.pagination.total }}
      </small>
    </template>
    <template v-else-if="categoryProducts !== null">
      <b-alert variant="warning" show>Tooteid ei leitud</b-alert>
    </template>

    <b-modal
      v-model="showMoveModal"
      title="Select category"
      size="xl"
      @ok="confirmProductsModal"
    >
      <b-row>
        <b-col>
          <h4>Categories</h4>
          <TreeView :items="categories" @itemClicked="categorySelected" />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          :disabled="!newMovingCategoryId"
          size="sm"
          variant="success"
          @click="ok()"
        >
          <span v-if="modalType == 'move'">
            Move products to new category
          </span>
          <span v-else>
            Add to new category
          </span>
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { successMessage } from "@/lib/messages";
import { mapActions, mapGetters } from "vuex";
import ProductRow from "./ProductRow";
import TreeView from "@/components/treeView/TreeView";

export default {
  components: {
    ProductRow,
    TreeView,
  },
  data() {
    return {
      currentPage: 1,
      products: [],
      showMoveModal: false,
      newMovingCategoryId: null,
      modalType: null,
      perPage: 30,
      perPageOptions: [30, 100, 250, 500, 1000],
    };
  },
  computed: {
    ...mapGetters({
      isEemeli: "global/isEemeli",
      categories: "catalog/categories",
      isProductsLoading: "catalog/isProductsLoading",
      selectedCategory: "catalog/selectedCategory",
      categoryProducts: "catalog/categoryProducts",
      categoryProductsMeta: "catalog/categoryProductsMeta",
      selectedProductIds: "catalog/selectedProductIds",
    }),
  },
  methods: {
    ...mapActions({
      fetchCategoryProducts: "catalog/fetchCategoryProducts",
      addSelectedProductId: "catalog/addSelectedProductId",
      removeSelectedProductId: "catalog/removeSelectedProductId",
      removeSelectedProductsFromCategory:
        "catalog/removeSelectedProductsFromCategory",
      selectAllFromThisPage: "catalog/selectAllFromThisPage",
      moveCategoryProducts: "catalog/moveCategoryProducts",
      addSelectedProductsToNewCategory:
        "catalog/addSelectedProductsToNewCategory",
      handleShowFacebook: "catalog/handleShowFacebook",
      handleDownloadIcecat: "catalog/handleDownloadIcecat",
      updateCategoryProductData: "catalog/updateCategoryProductData",
      handleSetParcelMachine: "catalog/handleSetParcelMachine",
    }),
    fetchNewPage(page) {
      this.fetchCategoryProducts({
        id: this.selectedCategory.id,
        page: page,
        per_page: this.perPage,
      });
    },
    removeSelectedProductsFromCategoryConfirm() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.removeSelectedProductsFromCategory({
              category_id: this.selectedCategory.id,
              product_ids: this.selectedProductIds,
            }).then(() => {
              successMessage("Teade!", "Selected products removed");
              this.currentPage = 1;
            });
          }
        },
      });
    },
    removeAndSelectNewCategory() {
      this.modalType = "move";
      this.showMoveModal = true;
    },
    addSelectedProductsToNewCategoryModal() {
      this.modalType = "add";
      this.showMoveModal = true;
    },
    categorySelected(val) {
      this.newMovingCategoryId = val;
    },
    confirmProductsModal() {
      if (this.modalType == "move") {
        this.moveCategoryProducts({
          old_category_id: this.selectedCategory.id,
          new_category_id: this.newMovingCategoryId,
          product_ids: this.selectedProductIds,
        });
      } else if (this.modalType == "add") {
        this.addSelectedProductsToNewCategory({
          new_category_id: this.newMovingCategoryId,
          product_ids: this.selectedProductIds,
        });
      }

      this.showMoveModal = false;
    },
    handleShowFacebookHere(status = true) {
      this.handleShowFacebook({
        fb: {
          product_ids: this.selectedProductIds,
          show: status,
        },
        category: {
          id: this.selectedCategory.id,
          page: this.categoryProductsMeta.pagination.current_page,
        },
      });
    },
    handleSetParcelMachineHere(status = true) {
      this.handleSetParcelMachine({
        parcel: {
          product_ids: this.selectedProductIds,
          show: status,
        },
        category: {
          id: this.selectedCategory.id,
          page: this.categoryProductsMeta.pagination.current_page,
        },
      });
    },
    addSelectedProductsToIcecatDownload() {
      this.handleDownloadIcecat({
          product_ids: this.selectedProductIds,
          category: {
            id: this.selectedCategory.id,
            page: this.categoryProductsMeta.pagination.current_page,
          },
      });
    },
    handleProductUpdate(product) {
      this.updateCategoryProductData(product);
    }
  },
  watch: {
    showMoveModal(newVal) {
      if (newVal === false) {
        this.newMovingCategoryId = null;
      }
    },
    perPage() {
      this.currentPage = 1;
      this.fetchNewPage(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.check-icon {
  font-size: 18px;
  cursor: pointer;
}
</style>
