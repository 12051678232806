<template>
  <b-tr>
    <b-td>
      <template v-if="isEditing">
        <b-select
          v-model="form.shipping_method_id"
          :options="shippingMethods"
          size="sm"
        ></b-select>
      </template>
      <template v-else>
        {{ method.shippingMethod.name }}
      </template>
    </b-td>
    <b-td>
      <template v-if="isEditing">
        <b-select
          v-model="form.supplier_id"
          :options="suppliers"
          size="sm"
        ></b-select>
      </template>
      <template v-else>
        <template v-if="method.supplier_id">
          {{ method.supplier.name }}
        </template>
        <template v-else>ALL</template>
      </template>
    </b-td>
    <b-td>
      <template v-if="isEditing">
        <b-form-input
          v-model="form.price"
          autocomplete="0"
          placeholder="Price"
          size="sm"
        ></b-form-input>
      </template>
      <template v-else>
        {{ method.price | currency }}
      </template>
    </b-td>
    <b-td>
      <template v-if="isEditing">
        <b-form-input
          v-model="form.min_free_price"
          autocomplete="0"
          placeholder="Min free price"
          size="sm"
        ></b-form-input>
      </template>
      <template v-else>
        <span v-if="method.min_free_price !== null">{{ method.min_free_price | currency }}</span>
        <span v-else>Puudub</span>
      </template>
    </b-td>
    <b-td>
      <template v-if="isEditing">
        <b-form-checkbox v-model="form.is_active" switch>
          Is active
        </b-form-checkbox>
      </template>
      <template v-else>
        <b-badge variant="success" v-if="method.is_active">Active</b-badge>
        <b-badge v-else>Inactive</b-badge>
      </template>
    </b-td>
    <b-td>
      <template v-if="isEditing">
        <b-button
          class="mr-1"
          variant="success"
          size="sm"
          :disabled="isSaveLoading"
          @click.prevent="save"
        >
          <b-spinner small v-if="isSaveLoading"></b-spinner>
          save
        </b-button>
        <b-button variant="danger" size="sm" @click.prevent="cancel">
          cancel
        </b-button>
      </template>
      <template v-else>
        <b-button
          class="mr-1"
          variant="warning"
          size="sm"
          @click.prevent="edit"
        >
          edit
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          :disabled="isDeleteLoading"
          @click.prevent="deleteRow"
        >
          <b-spinner small v-if="isDeleteLoading"></b-spinner>
          delete
        </b-button>
      </template>
    </b-td>
  </b-tr>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    method: {
      type: Object,
    },
  },
  data() {
    return {
      isDeleteLoading: false,
      isSaveLoading: false,
      isEditing: false,
      form: null,
    };
  },
  computed: {
    ...mapGetters({
      shippingMethods: "shippingZones/shippingMethods",
      suppliers: "shippingZones/suppliers",
    }),
  },
  methods: {
    ...mapActions({
      updateSupplierShipping: "shippingZones/updateSupplierShipping",
      deleteSupplierShipping: "shippingZones/deleteSupplierShipping",
    }),
    edit() {
      this.setFormData();
      this.isEditing = true;
    },
    save() {
      this.isSaveLoading = true;
      this.updateSupplierShipping(this.form).then(() => {
        this.isEditing = false;
        this.isSaveLoading = false;
      });
    },
    cancel() {
      this.form = null;
      this.isEditing = false;
    },
    deleteRow() {
      this.isDeleteLoading = true;
      this.deleteSupplierShipping(this.method.id);
    },
    setFormData() {
      this.form = JSON.parse(JSON.stringify(this.method));
    },
  },
};
</script>
