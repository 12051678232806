import { SupplierOrders, SupplierOrdersMorele } from "../components";

export default [
  {
    path: "/orders/suppliers",
    component: SupplierOrders,
    name: "supplier.orders",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/orders/suppliers/morele",
    component: SupplierOrdersMorele,
    name: "supplier.orders.morele",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
