<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Import staatus
    </h1>

    <b-row v-if="suppliers">
      <b-col md="3" v-for="supplier in suppliers" :key="supplier.id">
        <b-card no-body :class="`border-left-${supplier.report.variant} shadow p-2 mb-4`">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div :class="`font-weight-bold text-${supplier.report.variant} mb-1`">
                {{ supplier.name }}
              </div>
              <div class="font-weight-bold">
                {{ supplier.report.successful }}/{{ supplier.report.total }}
              </div>
              <div class="font-weight-bold">
                {{ supplier.report.time_since_last_import_finished_human }}
              </div>
            </div>
            <div>

            </div>
          </div>
          <div class="status-bar">
            <div class="status" :class="{ danger: s == 0, warning: s == 2, success: s == 1}" v-for="s in supplier.report.last_imports" :key="s"></div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { errorMessage } from '@/lib/messages';
import RestService from '@/lib/RestService';


export default {
  data() {
    return {
      isLoading: false,
      suppliers: null,
    };
  },
  computed: {

  },
  mounted() {
    this.fetchImportStatus();
  },
  methods: {
    fetchImportStatus() {
      this.isLoading = true;
      return RestService.getImportStatus()
        .then((response) => {
          this.suppliers = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    }
  },
};
</script>

<style lang="scss">
.status-bar {
  margin-top: 5px;
  .status {
    background: gray;
    width: 15px;
    height: 15px;
    display: block;
    border-radius: 100%;
    margin-right: 5px;
    float: left;

    &.danger {
      background: #e74a3b;
    }
    &.warning {
      background: #ffc107;
    }
    &.success {
      background: #28a745;
    }
  }
}
</style>