<template>
  <div>
    <template v-if="orders === null">
      <loader />
    </template>
    <template v-else-if="!orders.length">
      <b-alert variant="warning" show>
        Tellimusi ei leitud.
      </b-alert>
    </template>
    <template v-else>
      <OrderItem
        v-for="order in orders"
        :order="order"
        :key="order.id"
        @newInfo="updateOrder"
      />

      <div class="text-center" v-if="showLoadMore">
        <b-button variant="primary" size="sm" @click.prevent="loadMore()">
          Lae juurde
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import OrderItem from "./OrderItem";
import RestService from "@/lib/RestService";

export default {
  components: {
    OrderItem,
  },
  props: {
    statusType: {
      default: null,
    },
  },
  data() {
    return {
      orders: null,
      showLoadMore: false,
    };
  },
  computed: {
    lastId() {
      if (!this.orders) {
        return null;
      }

      let order = this.orders[this.orders.length - 1];

      if (!order) {
        return null;
      }

      return order.id;
    },
  },
  mounted() {
    this.fetchOrders();
  },
  methods: {
    fetchOrders() {
      RestService.getOrders({
        status: this.statusType,
      }).then((response) => {
        this.orders = response.data.data;

        if (this.orders.length == response.data.meta.per_page) {
          this.showLoadMore = true;
        }
      });
    },
    loadMore() {
      RestService.getOrders({
        status: this.statusType,
        last_id: this.lastId,
      }).then((response) => {
        this.orders = this.orders.concat(response.data.data);
        if (response.data.data.length == response.data.meta.per_page) {
          this.showLoadMore = true;
        } else {
          this.showLoadMore = false;
        }
      });
    },
    updateOrder(payload) {
      let orderIndex = this.orders.findIndex((o) => o.id == payload.id);

      this.$set(this.orders, orderIndex, payload);
    },
  },
  watch: {
    statusType() {
      (this.orders = null), this.fetchOrders();
    },
  },
};
</script>
