<template>
  <b-tr>
    <b-td>
      <template v-if="!isEditing">
        <strong>{{ item.name }}</strong> <br />
        <small v-if="item.additional_info">
          {{ item.additional_info }}
        </small>
      </template>
      <template v-else>
        <b-form-group class="mb-1">
          <b-form-input
            v-model="newName"
            placeholder="Toote nimi"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <b-textarea rows="6" size="sm" v-model="newInfo"></b-textarea>
        </b-form-group>
      </template>
    </b-td>
    <b-td>
      <template v-if="!isEditing">
        {{ item.ordered_place }}
      </template>
      <template v-else>
        <b-form-group class="mb-1">
          <b-form-input
            v-model="newPlace"
            placeholder="Place"
            size="sm"
          ></b-form-input>
        </b-form-group>
      </template>
    </b-td>
    <b-td>{{ item.price | currency }} &euro;</b-td>
    <b-td>
      <b-badge variant="warning" v-if="item.status == 'ordered'">
        Tellitud
      </b-badge>
      <b-badge variant="success" v-if="item.status == 'in-stock'">
        Laos
      </b-badge>
      <b-badge variant="dark" v-if="item.status == 'out-of-stock'">
        Välja saadetud
      </b-badge>
    </b-td>
    <b-td>
      <template v-if="!isEditing">
        <b-button
          class="mb-1"
          variant="warning"
          size="sm"
          v-if="!isEditing"
          @click.prevent="startEditing"
          >Edit</b-button
        >
        <br />

        <b-overlay
          :show="isBtnLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          v-if="item.status == 'ordered'"
        >
          <b-dropdown
            text="Toode jõudis lattu"
            variant="success"
            class="stock-btn"
            size="sm"
            v-if="item.status == 'ordered'"
            :disabled="isBtnLoading"
          >
            <b-dropdown-form class="p-1 text-center">
              <b-button
                variant="primary"
                size="sm"
                @click.prevent="setItemStatus('in-stock')"
              >
                Kinnita
              </b-button>
            </b-dropdown-form>
          </b-dropdown>
        </b-overlay>
        <b-overlay
          :show="isBtnLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          v-if="item.status == 'in-stock'"
        >
          <b-dropdown
            text="Saada laost välja"
            variant="danger"
            class="stock-btn"
            size="sm"
            v-if="item.status == 'in-stock'"
            :disabled="isBtnLoading"
          >
            <b-dropdown-form class="p-1 text-center">
              <b-form-group label="Order id">
                <b-form-input size="sm" v-model="orderId"></b-form-input>
              </b-form-group>
              <b-button
                variant="primary"
                size="sm"
                @click.prevent="setItemStatus('out-of-stock')"
              >
                Kinnita
              </b-button>
            </b-dropdown-form>
          </b-dropdown>
        </b-overlay>
      </template>
      <template v-else>
        <b-overlay
          :show="isSaveBtnLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
        >
          <b-button block variant="primary" size="sm" @click.prevent="saveInfo">
            Save
          </b-button>
        </b-overlay>
      </template>
    </b-td>
  </b-tr>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      isEditing: false,
      isBtnLoading: false,
      isSaveBtnLoading: false,
      orderId: null,
      newName: null,
      newInfo: null,
      newPlace: null,
    };
  },
  methods: {
    ...mapActions({
      updateStorageItemStatus: "stock/updateStorageItemStatus",
      saveStorageItemInfo: "stock/saveStorageItemInfo",
    }),
    startEditing() {
      this.newInfo = this.item.additional_info;
      this.newName = this.item.name;
      this.newPlace = this.item.ordered_place;
      this.isEditing = true;
    },
    setItemStatus(status) {
      this.isBtnLoading = true;
      this.updateStorageItemStatus({
        id: this.item.id,
        status: status,
        order_id: this.orderId,
      }).then(() => {
        this.isBtnLoading = false;
      });
    },
    saveInfo() {
      this.isSaveBtnLoading = true;
      this.saveStorageItemInfo({
        id: this.item.id,
        additional_info: this.newInfo,
        name: this.newName,
        ordered_place: this.newPlace,
      }).then(() => {
        this.isSaveBtnLoading = false;
        this.isEditing = false;
      });
    },
  },
};
</script>
