var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative"},[(_vm.isLoading)?_c('loader',{attrs:{"overlay":true}}):_vm._e(),_c('h1',[_vm._v(" Toodete merge "),_c('router-link',{staticClass:"btn btn-primary btn-sm ml-2",attrs:{"to":{ name: 'new-products-merge' }}},[_vm._v(" Koosta uus merge ")])],1),_vm._l((_vm.merges),function(mergable,mergableKey){return _c('b-table-simple',{key:mergableKey,attrs:{"small":"","bordered":""}},[_c('b-tr',{attrs:{"variant":"primary"}},[_c('b-th',{staticStyle:{"width":"50%"}},[_vm._v("Nimi")]),_c('b-th',{staticStyle:{"width":"35%"}},[_vm._v("Laod")]),_c('b-th',{staticClass:"text-right",staticStyle:{"width":"15%"}},[_c('router-link',{staticClass:"btn btn-primary btn-sm mr-3",attrs:{"to":{
            name: 'confirm-products-merge',
            params: { productId: mergable[0].product_id },
          }}},[_vm._v(" Merge ")]),_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.deleteMerge(mergable[0].product_id)}}},[_vm._v(" Kustuta ")])],1)],1),_vm._l((mergable),function(merge){return _c('b-tr',{key:merge.product.id},[_c('b-td',[_c('router-link',{staticClass:"product-link",attrs:{"to":{
            name: 'products.edit',
            params: { productId: merge.product.id },
          }}},[_vm._v(" "+_vm._s(merge.product.name))]),_c('br'),(merge.product.codes.length)?[_c('strong',[_vm._v("Product codes:")]),_vm._v(" "),_c('br'),_c('ul',{staticClass:"list-unstyled small"},_vm._l((merge.product.codes),function(code){return _c('li',{key:code.id},[_c('strong',[_vm._v(" "+_vm._s(code.type)+": ")]),_vm._v(" "+_vm._s(code.code)+" ")])}),0)]:_vm._e()],2),_c('b-td',[(merge.product.stocks)?_c('b-table-simple',{staticClass:"mb-0",attrs:{"bordered":"","small":""}},[_c('b-tr',{attrs:{"variant":"primary"}},[_c('b-th',[_vm._v("Ladu")]),_c('b-th',[_vm._v("Laoseis")]),_c('b-th',[_vm._v("Hind")])],1),_vm._l((merge.product.stocks),function(stock){return _c('b-tr',{key:stock.id},[_c('b-td',[_vm._v(_vm._s(stock.supplier.name))]),_c('b-td',[_vm._v(_vm._s(stock.in_stock))]),_c('b-td',[_vm._v(_vm._s(stock.price))])],1)})],2):_vm._e()],1),_c('b-td',{staticClass:"text-right",staticStyle:{"vertical-align":"middle"}},[_c('b-button',{attrs:{"variant":"warning","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.removeProductFromMerge(
              merge.product_id,
              merge.mergable_product_id
            )}}},[_vm._v(" Eemalda ")])],1)],1)})],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }