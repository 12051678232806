<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      <b-button variant="success" class="float-right" @click.prevent="saveMenu">
        Salvesta
      </b-button>
      Menüü muutmine <small v-if="menu">- {{ menu.name }}</small>
    </h2>

    <template>
      <b-row>
        <b-col md="6">
          <b-card v-if="menu" header="Menüü">
            <b-form-group class="mb-1" label="Nimi">
              <b-form-input
                v-model="menu.name"
                size="sm"
                :state="getFieldStateFromErrors('name')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("name") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Asukoht">
              <b-form-input
                v-model="menu.slug"
                size="sm"
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group class="mb-1" label="Keel">
              <b-form-select
                v-model="menu.locale"
                size="sm"
                :options="selectLocales"
                disabled
              >
              </b-form-select>
            </b-form-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card v-if="menuItems">
            <template #header>
              <div>
                Lingid

                <b-button
                  class="float-right"
                  variant="primary"
                  size="sm"
                  @click.prevent="openNewModal"
                >
                  Lisa
                </b-button>
              </div>
            </template>
            <b-list-group>
              <draggable v-model="menuItems" @change="itemsChanged">
                <b-list-group-item
                  class="pl-3 p-1"
                  v-for="item in menuItems"
                  :key="item.id"
                >
                  {{ item.title }}
                  <b-button
                    class="float-right font-weight-bold"
                    variant="outline-danger"
                    size="sm"
                    @click.prevent="deleteMenuItem(item.id)"
                  >
                    X
                  </b-button>
                  <div class="clearfix"></div>
                </b-list-group-item>
              </draggable>
            </b-list-group>

            <b-modal v-model="showNewModal" title="Lisa uus link" hide-footer>
              <loader v-if="isModalLoading" :overlay="true" />
              <b-form @submit.prevent="handleNewMenuItemSubmit">
                <b-form-group class="mb-1" label="Leht">
                  <b-form-select
                    v-model="newForm.pageId"
                    size="sm"
                    :options="selectPages"
                  >
                  </b-form-select>
                </b-form-group>

                <template v-if="newForm.pageId">
                  <div class="text-right">
                    <b-button
                      type="submit"
                      class="mt-3"
                      variant="success"
                      size="sm"
                    >
                      Lisa
                    </b-button>
                  </div>
                </template>
              </b-form>
            </b-modal>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  mixins: [ErrorsMixin],
  components: {
    draggable,
  },
  data() {
    return {
      pages: [],
      isLoading: false,
      isModalLoading: false,
      showNewModal: false,
      menu: null,
      menuItems: null,
      newForm: {
        pageId: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      languages: "generic/languages",
    }),
    selectLocales() {
      return this.languages.map((locale) => {
        return {
          value: locale.locale,
          text: locale.name,
        };
      });
    },
    selectLocations() {
      if (!this.locations) {
        return [];
      }

      return this.locations.map((location) => {
        return {
          value: location.slug,
          text: location.name,
        };
      });
    },
    selectPages() {
      if (!this.pages) {
        return [];
      }

      return this.pages.map((page) => {
        return {
          value: page.id,
          text: page.title,
        };
      });
    },
  },
  mounted() {
    this.fetchPages();
    this.fetchMenu();
  },
  methods: {
    saveMenu() {
      this.isLoading = true;
      RestService.postUpdateMenu(this.menu.id, this.menu).then((response) => {
        this.menu = response.data.data;
        this.isLoading = false;
      });
    },
    fetchPages() {
      RestService.getPages().then((response) => {
        this.pages = response.data.data;
      });
    },
    fetchMenu() {
      this.isLoading = true;
      const promise1 = RestService.getMenu(this.$route.params.menuId).then(
        (response) => {
          this.menu = response.data.data;
        }
      );

      const promise2 = RestService.getMenuItems(this.$route.params.menuId).then(
        (response) => {
          this.menuItems = response.data.data;
        }
      );

      Promise.all([promise1, promise2]).then(() => {
        this.isLoading = false;
      });
    },
    itemsChanged() {
      return RestService.postUpdateMenuItemsOrder(this.menu.id, {
        items: this.menuItems,
      });
    },
    deleteMenuItem(id) {
      this.isLoading = true;

      return RestService.postDeleteMenuItem(id).then(() => {
        this.menuItems = this.menuItems.filter((m) => m.id != id);
        this.isLoading = false;
      });
    },
    openNewModal() {
      this.showNewModal = true;
    },
    handleNewMenuItemSubmit() {
      this.isModalLoading = true;

      return RestService.postAddNewMenuItem(this.menu.id, this.newForm).then(
        (response) => {
          this.menuItems = response.data.data;
          this.isModalLoading = false;
          this.showNewModal = false;
          this.newForm.pageId = null;
        }
      );
    },
  },
};
</script>
