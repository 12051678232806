<template>
  <b-card class="mt-3" header="Audit logi" no-body>
    <div class="p-1">
      <b-table-simple small class="mb-0">
        <b-tr v-for="log in order.logs" :key="log.id">
          <b-td style="width: 20%">
            <small>
              {{ log.created_at | moment("DD.MM.YYYY HH:mm:ss") }} -
              <strong>{{ log.created_by }}</strong>
            </small>
          </b-td>
          <b-td>
            <small
              :class="{
                'text-warning': log.type == 1,
                'text-danger': log.type == 2,
              }"
            >
              {{ log.message }}
            </small>
          </b-td>
        </b-tr>
      </b-table-simple>
      <div></div>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
};
</script>
