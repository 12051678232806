<template>
  <div>
    <h1 class="d-inline-block mr-3 float-left">
      {{ name }}
    </h1>

    <b-nav class="float-left" pills small>
      <b-nav-item
        :to="{
          name: 'offers.new',
        }"
        :active="type == 'new'"
      >
        Uus pakkumine
      </b-nav-item>
      <!-- <b-nav-item
        :to="{
          name: 'orders.mine',
        }"
        :active="type == 'mine'"
      >
        Minu Pakkumised
      </b-nav-item> -->
      <b-nav-item
        :to="{
          name: 'offers',
          params: { status: 1 },
        }"
        :active="type == 'orders'"
      >
        Pakkumised
      </b-nav-item>
      <b-nav-item
        :to="{
          name: 'offers.search',
        }"
        :active="type == 'search'"
      >
        Otsing
      </b-nav-item>
    </b-nav>

    <div class="clearfix"></div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
    name: {
      type: String,
      default: "Pakkumised",
    },
  },
};
</script>
