import Vue from "vue";

export const Products = Vue.component(
  "products",
  require("./Products.vue").default
);
export const ProductEdit = Vue.component(
  "productEdit",
  require("./ProductEdit.vue").default
);
export const ProductNew = Vue.component(
  "productNew",
  require("./ProductNew.vue").default
);
export const ProductsSearch = Vue.component(
  "ProductsSearch",
  require("./ProductsSearch.vue").default
);
export const AutoMatching = Vue.component(
  "ProductsAutoMatching",
  require("./AutoMatching.vue").default
);
export const ProductsMerge = Vue.component(
  "ProductsMerge",
  require("./merge/ProductsMerge.vue").default
);
export const NewProductsMerge = Vue.component(
  "NewProductsMerge",
  require("./merge/NewProductsMerge.vue").default
);
export const ConfirmProductsMerge = Vue.component(
  "ConfirmProductsMerge",
  require("./merge/ConfirmProductsMerge.vue").default
);
export const IcecatSearch = Vue.component(
  "IcecatSearch",
  require("./icecat/Icecat.vue").default
);
export const FixingProducts = Vue.component(
  "FixingProducts",
  require("./FixingProducts.vue").default
);
