import { Pages, PageEdit, PageNew } from "../components";

export default [
  {
    path: "/pages",
    component: Pages,
    name: "pages",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/pages/new",
    component: PageNew,
    name: "page.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/pages/:pageId",
    component: PageEdit,
    name: "page.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
