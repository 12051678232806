import Vue from 'vue'
import Router from 'vue-router'
import { routes as routes } from '../app/index'
import beforeEach from './beforeEach'
import NProgress from 'nprogress';

Vue.use(Router)

const router = new Router({
    routes: routes,
    mode: 'history',
})

router.beforeEach(beforeEach)

router.beforeEach((to, from, next) => {
    if (to.name) {
        NProgress.start()
    }

    next()
})

router.afterEach(() => {
    NProgress.done()
})

export default router
