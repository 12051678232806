import {
  DiscountCodeEdit,
  DiscountCodeNew,
  DiscountCodes,
} from "../components";

export default [
  {
    path: "/discount-codes",
    component: DiscountCodes,
    name: "discount-codes",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/discount-codes/new",
    component: DiscountCodeNew,
    name: "discount-codes.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/discount-codes/:discountCodeId",
    component: DiscountCodeEdit,
    name: "discount-codes.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
