import Vue from "vue";

export const successMessage = (title, message, options = {}) => {
  Vue.$toast.open({
    message: `<strong>${title}</strong><br>${message}`,
    type: "success",
    position: "top-right",
    ...options,
  });
};

export const warningMessage = (title, message, options = {}) => {
  Vue.$toast.open({
    message: `<strong>${title}</strong><br>${message}`,
    type: "warning",
    position: "top-right",
    ...options,
  });
};

export const errorMessage = (title, message, options = {}) => {
  Vue.$toast.open({
    message: `<strong>${title}</strong><br>${message}`,
    type: "error",
    position: "top-right",
    ...options,
  });
};
