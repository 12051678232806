<template>
  <div class="stock-item" :class="statusClass">
    

    <div class="place">
      {{ item.ordered_place }}
    </div>

    <div class="right-btn"></div>

    <template v-if="isEditing">
      <b-form-group class="mb-1" style="max-width: 75%;">
        <b-form-input
          v-model="newName"
          placeholder="Toote nimi"
          size="sm"></b-form-input>
      </b-form-group>

      <b-form-group>
        <b-textarea rows="6" size="sm" v-model="newInfo"></b-textarea>
      </b-form-group>
      
      <b-form-group block class="mb-3">
        <b-overlay
          :show="isSaveBtnLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
        >
          <b-button block  variant="primary" size="sm" @click.prevent="saveInfo">Save</b-button>
        </b-overlay>
      </b-form-group>
    </template>
    <template v-else>
      <h3>{{ item.name }}</h3>
      <p class="comments">
        <a href="#" class="edit" @click.prevent="edit">
          <font-awesome-icon icon="edit" />
        </a>
        {{ item.additional_info }}
      </p>
    </template>

    <div class="bottom-row">
      <span class="status">
        {{ statusName }}
      </span>

      <b-overlay
        :show="isBtnLoading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block"
        v-if="item.status == 'ordered'"
      >
        <b-dropdown
          text="Toode jõudis lattu"
          variant="success"
          class="stock-btn"
          size="sm"
          v-if="item.status == 'ordered'"
          :disabled="isBtnLoading"
        >
          <b-dropdown-form class="p-1 text-center">
            <b-button
              variant="primary"
              size="sm"
              @click.prevent="setItemStatus('in-stock')"
            >
              Kinnita
            </b-button>
          </b-dropdown-form>
        </b-dropdown>
      </b-overlay>
      <b-overlay
        :show="isBtnLoading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block"
        v-if="item.status == 'in-stock'"
      >
        <b-dropdown
          text="Saada laost välja"
          variant="danger"
          class="stock-btn"
          size="sm"
          v-if="item.status == 'in-stock'"
          :disabled="isBtnLoading"
        >
          <b-dropdown-form class="p-1 text-center">
            <b-form-group label="Order id">
              <b-form-input size="sm" v-model="orderId"></b-form-input>
            </b-form-group>
            <b-button
              variant="primary"
              size="sm"
              @click.prevent="setItemStatus('out-of-stock')"
            >
              Kinnita
            </b-button>
          </b-dropdown-form>
        </b-dropdown>
      </b-overlay>

      <div class="price">
        {{ item.price | currency }} <span class="euro-mark">EUR</span>
      </div>
    </div>
    <div class="history" v-if="isOpen">
      <b-table-simple class="mb-0" small responsive bordered>
        <b-tbody>
          <b-tr v-for="(entry, index) in item.history" v-bind:key="index">
            <b-td style="width:50%;">
              {{ entry.updated_at | moment("DD.MM.YYYY HH:mm:ss") }}
            </b-td>
            <b-td class="text-center" style="width:50%;">
              {{ entry.status }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div class="bottom-btn" @click.prevent="isOpen = !isOpen">
      <font-awesome-icon icon="angle-up" v-if="isOpen" />
      <font-awesome-icon icon="angle-down" v-if="!isOpen" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      newInfo: null,
      newName: null,
      isSaveBtnLoading: false,
      isEditing: false,
      isBtnLoading: false,
      isOpen: false,
      orderId: null,
    };
  },
  computed: {
    statusClass() {
      return this.item.status;
    },
    statusName() {
      if (this.item.status == "ordered") {
        return "Ordered";
      }

      if (this.item.status == "in-stock") {
        return "In stock";
      }

      if (this.item.status == "out-of-stock") {
        return "Sold";
      }

      return null;
    },
  },
  methods: {
    ...mapActions({
      updateStorageItemStatus: "stock/updateStorageItemStatus",
      saveStorageItemInfo: "stock/saveStorageItemInfo",
    }),
    setItemStatus(status) {
      this.isBtnLoading = true;
      this.updateStorageItemStatus({
        id: this.item.id,
        status: status,
        order_id: this.orderId,
      }).then(() => {
        this.isBtnLoading = false;
      });
    },
    edit() {
      this.newInfo = this.item.additional_info;
      this.newName = this.item.name;
      this.isEditing = true;
    },
    saveInfo() {
      this.isSaveBtnLoading = true;
      this.saveStorageItemInfo({
        id: this.item.id,
        additional_info: this.newInfo,
        name: this.newName,
      }).then(() => {
        this.isSaveBtnLoading = false;
        this.isEditing = false;
      });
      
    }
  },
};
</script>

<style lang="scss">
.stock-item {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 5px 5px 15px 5px;
  border-left: 6px solid #7a9b09;
  margin-bottom: 15px;

  .bottom-btn {
    cursor: pointer;
    position: absolute;
    left: calc(50% - 20px);
    bottom: -10px;
    width: 40px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    text-align: center;
    line-height: 1.1;
    font-size: 18px;
  }

  .history {
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .stock-btn {
    font-size: 0.8rem;
    line-height: 1.1;

    .btn-text {
      padding-right: 0.5rem;
    }

    button.dropdown-toggle {
      font-size: 0.8rem;
      line-height: 1.1;
    }

    > .dropdown-menu {
      .b-dropdown-form {
        padding: 0 5px;
      }
    }
  }

  .comments {
    position: relative;
    color: #6f6f6f;
    font-size: 14px;
    padding-right: 20px;

    .edit {
      position: absolute;
      right: 0;
    }
  }

  .bottom-row {
    margin-top: auto;
  }

  .status {
    vertical-align: middle;
    padding-right: 15px;
    font-weight: 700;
  }

  &.ordered {
    border-left-color: orange;
    .status {
      color: orange;
    }
  }

  &.in-stock {
    border-left-color: #7a9b09;
    .status {
      color: #7a9b09;
    }
  }

  &.out-of-stock {
    border-left-color: #ababab;
    .status {
      color: #ababab;
    }
  }

  h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
    padding-right: 90px;
  }

  .place {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: gray;
  }

  .price {
    float: right;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;

    .euro-mark {
      font-size: 18px;
    }
  }
}
</style>
