<template>
  <b-card class="mt-3" no-body>
    <b-card-header class="pr-1">
      Tellimusega seotud laotooted
    </b-card-header>
    <div class="p-1">
      <b-table-simple class="mb-0" bordered small>
        <b-tr variant="primary">
          <b-th>Nimi</b-th>
          <b-th>Hind (km-ita)</b-th>
        </b-tr>
        <b-tr v-for="item in order.storageItems" :key="item.id">
          <b-td>
            {{ item.name }} <br />
            <small>
              <strong>{{ item.ordered_place }}</strong> <br>
              {{ item.additional_info }}
            </small>
          </b-td>
          <b-td> {{ item.price | currency }}&nbsp;&euro; </b-td>
        </b-tr>
      </b-table-simple>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
