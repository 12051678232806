import { DeliveryMapper } from "../components";

export default [
  {
    path: "/delivery/mapper",
    component: DeliveryMapper,
    name: "deliveryMapper",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
