<template>
  <div class="position-relative">
    <h1>
      Andmebaasi otsing
    </h1>

    <b-tabs content-class="p-3">
      <b-tab title="Otsing" active>
        <CodeSearch />
      </b-tab>
      <b-tab title="Tooted tarnija järgi">
        <StocksSearch />
      </b-tab>
      <b-tab title="Enim ostetud tooted" lazy>
        <TopOrdered />
      </b-tab>
      <b-tab title="Enim vaadatud tooted" lazy>
        <Top type="viewed" />
      </b-tab>
      <b-tab title="Featured tooted" lazy>
        <Featured />
      </b-tab>
      <b-tab v-if="!isEemeli" title="Tooted mis sisaldavad MTS ladu" lazy>
        <MtsProducts />
      </b-tab>
      <b-tab v-if="!isEemeli" title="Muudetud hinnad" lazy>
        <ManualPriceProducts />
      </b-tab>
      <b-tab v-if="!isEemeli" title="Statistika" lazy>
        <DataCollectionProducts />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Top from "./search/Top";
import TopOrdered from "./search/TopOrdered";
import Featured from "./search/Featured";
import CodeSearch from "./search/CodeSearch";
import MtsProducts from "./search/MtsProducts";
import ManualPriceProducts from "./search/ManualPriceProducts";
import StocksSearch from "./search/StocksSearch";
import DataCollectionProducts from "./search/DataCollectionProducts";
import { mapGetters } from "vuex";

export default {
  components: {
    CodeSearch,
    Top,
    Featured,
    MtsProducts,
    ManualPriceProducts,
    StocksSearch,
    TopOrdered,
    DataCollectionProducts,
  },
  computed: {
    ...mapGetters({
      isEemeli: "global/isEemeli",
    }),
  },
};
</script>
