<template>
  <div class="position-relative">
    <loader :overlay="true" v-if="isLoading" />

    <h2 class="mb-3">
      Lisa uus toode
    </h2>

    <b-row class="mb-3">
      <b-col md="6">
        <b-card header="Toote koodid">
          <b-form-group class="mb-1" label="GTIN-14 (EAN)">
            <b-form-input v-model="form.ean" size="sm"></b-form-input>
          </b-form-group>
          <b-form-group class="mb-3" label="MPN">
            <b-form-input v-model="form.mpn" size="sm"></b-form-input>
          </b-form-group>

          <b-alert variant="danger" show v-if="codeErrors.length">
            <p
              class="m-0"
              v-for="(codeError, codeErrorKey) in codeErrors"
              :key="codeErrorKey"
            >
              {{ codeError }}
            </p>
          </b-alert>

          <b-alert
            variant="success"
            show
            v-if="!codeErrors.length && codesChecked"
          >
            Duplikaate ean-iga ei leitud.
          </b-alert>

          <div class="text-center pt-3">
            <b-button variant="primary" @click.prevent="checkCodes">
              Kontrolli koode
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-col md="6" v-if="codesChecked && !codeErrors.length">
        <b-card header="Toote üldinfo">
          <b-form-group class="mb-1" label="Tootja">
            <b-form-input v-model="form.manufacturer" size="sm"></b-form-input>
          </b-form-group>
          <b-form-group class="mb-1" label="Kategooria">
            <v-select
              v-if="flatCategories.length"
              v-model="form.categories"
              :options="flatCategories"
              :reduce="(item) => item.code"
              multiple
            ></v-select>
            <b-spinner v-else small></b-spinner>
          </b-form-group>

          <b-form-group class="mb-1" label="Soovituslik hind">
            <b-form-input
              v-model="form.recommended_price"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group class="mb-1" label="Toote kaal (kg)">
            <b-form-input
              type="number"
              v-model="form.weight"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group class="mb-1">
            <b-form-checkbox v-model="form.is_featured" switch>
              Is featured
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="mb-1">
            <b-form-checkbox v-model="form.show_in_fb" switch>
              Kuva facebookis
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="mb-1">
            <b-form-checkbox v-model="form.is_category_ready" switch>
              Is category ready
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="mb-1">
            <b-form-checkbox v-model="form.is_disabled" switch>
              Is disabled
            </b-form-checkbox>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="codesChecked && !codeErrors.length">
      <b-col>
        <b-card>
          <template #header>
            <div>
              Tõlked
              <b-dropdown
                class="float-right"
                variant="primary"
                size="sm"
                text="Lisa Tõlge"
              >
                <b-dropdown-item
                  v-for="language in languages"
                  :key="language.locale"
                  @click.prevent="addNewLanguage(language.locale)"
                  :disabled="false"
                >
                  {{ language.name }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
          <div
            v-for="translation in form.translations"
            :key="translation.id"
            class="language"
          >
            <h4>
              {{ getLocaleName(translation.locale) }}
              <b-button
                variant="danger"
                size="sm"
                @click.prevent="removeLanguage(translation.locale)"
              >
                Kustuta tõlge
              </b-button>
            </h4>
            <b-form-group label="Nimi" class="mb-1">
              <b-form-input v-model="translation.name" size="sm"></b-form-input>
            </b-form-group>
            <b-form-group label="Meta sõnad" class="mb-1">
              <b-form-tags
                v-model="translation.keywords"
                size="sm"
              ></b-form-tags>
            </b-form-group>
            <b-form-group label="Lühikirjeldus" class="mb-3">
              <b-button
                variant="outline-primary"
                size="sm"
                v-if="translation.short_description === null"
                @click.prevent="addShortDesc(translation.id)"
              >
                Lisa lühikirjeldus
              </b-button>
              <Editor
                v-model="translation.short_description"
                v-if="!isLoading && translation.short_description !== null"
                :basic="true"
              />
            </b-form-group>
            <b-form-group label="Kirjeldus" class="mb-3">
              <Editor v-model="translation.description" v-if="!isLoading" />
            </b-form-group>
            <hr />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="codesChecked && !codeErrors.length">
      <b-col clas="text-center">
        <b-button variant="success" @click.prevent="createNewProduct"
          >Loo uus toode</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
import { mapActions, mapGetters } from "vuex";
import Editor from "@/components/Editor";

export default {
  components: {
    Editor,
  },
  data() {
    return {
      isLoading: false,
      codesChecked: false,
      codeErrors: [],
      form: {
        ean: null,
        mpn: null,
        manufacturer: null,
        categories: [],
        recommended_price: null,
        weight: null,
        is_featured: false,
        show_in_fb: false,
        is_category_ready: false,
        is_disabled: false,
        translations: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      languages: "generic/languages",
      categories: "categories/categories",
    }),
    flatCategories() {
      let categories = [];

      if (this.categories) {
        categories = this.makeCategoryList(this.categories);
      }

      return categories;
    },
  },
  mounted() {
    this.fetchCategories();
  },
  methods: {
    ...mapActions({
      fetchCategories: "categories/fetchCategories",
    }),
    checkCodes() {
      this.isLoading = true;
      RestService.postNewProductCheckCodes({
        ean: this.form.ean,
        mpn: this.form.mpn,
      })
        .then((response) => {
          this.codesChecked = true;
          this.codeErrors = response.data.data.errors;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    createNewProduct() {
      this.isLoading = true;
      RestService.postCreateNewProduct(this.form)
        .then((response) => {
          this.$router.push({
            name: "products.edit",
            params: { productId: response.data.data.id },
          });
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    addNewLanguage(locale) {
      if (this.form.translations.filter((t) => t.locale == locale).length) {
        errorMessage("Viga", "See keel on juba lisatud");
        return;
      }

      this.form.translations.push({
        locale: locale,
        name: null,
        description: null,
        short_description: null,
      });
    },
    addShortDesc(translationId) {
      let translationIndex = this.form.translations.findIndex(
        (t) => t.id == translationId
      );
      this.form.translations[translationIndex]["short_description"] = "";
    },
    getLocaleName(locale) {
      let language = this.languages.find((l) => l.locale == locale);

      if (!language) {
        return locale;
      }

      return language.name;
    },
    removeLanguage(locale) {
      this.form.translations = this.form.translations.filter(
        (t) => t.locale != locale
      );
    },
    makeCategoryList(items, name = "") {
      let list = [];

      items.forEach((item) => {
        list.push({
          label: `${name}${item.name}`,
          code: item.id,
        });

        let childList = [];
        if (item.children.length) {
          childList = this.makeCategoryList(
            item.children,
            `${name}${item.name} > `
          );
        }

        list = list.concat(childList);
      });

      return list;
    },
  },
};
</script>
