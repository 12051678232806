export const user = (state) => {
  return state.user;
};

export const isAuthenticated = (state) => {
  if (!state.user) {
    return false;
  }
  return state.user.authenticated;
};
