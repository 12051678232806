<template>
  <div class="vtl">
    <div
      v-if="model.name !== 'root'"
      :id="model.id"
      class="vtl-node"
      :class="{ 'vtl-leaf-node': model.isLeaf, 'vtl-tree-node': !model.isLeaf }"
    >
      <div
        class="vtl-border vtl-up"
        :class="{ 'vtl-active': isDragEnterUp }"
        @drop="dropBefore"
        @dragenter="dragEnterUp"
        @dragover="dragOverUp"
        @dragleave="dragLeaveUp"
      />
      <div
        :class="treeNodeClass"
        :draggable="!model.dragDisabled"
        @dragstart="dragStart"
        @dragover="dragOver"
        @dragenter="dragEnter"
        @dragleave="dragLeave"
        @drop="drop"
        @dragend="dragEnd"
        @click.stop="click"
        @contextmenu.prevent="openContextMenu($event)"
      >
        <span
          class="vtl-caret vtl-is-small"
          v-if="model.children && model.children.length > 0"
        >
          <i
            class="vtl-icon"
            :class="caretClass"
            @click.prevent.stop="toggle"
          ></i>
        </span>

        <span>
          <i class="vtl-icon vtl-menu-icon vtl-icon-file"></i>
        </span>

        <div class="vtl-node-content">
          <slot name="itemName" :model="model" :root="rootNode">
            {{ model.name }}
          </slot>
        </div>
      </div>

      <div
        v-if="model.children && model.children.length > 0 && expanded"
        class="vtl-border vtl-bottom"
        :class="{ 'vtl-active': isDragEnterBottom }"
        @drop="dropAfter"
        @dragenter="dragEnterBottom"
        @dragover="dragOverBottom"
        @dragleave="dragLeaveBottom"
      ></div>
    </div>

    <div
      :class="{ 'vtl-tree-margin': model.name !== 'root' }"
      v-show="model.name === 'root' || expanded"
      v-if="isFolder"
    >
      <item
        v-for="model in model.children"
        :default-tree-node-name="defaultTreeNodeName"
        :default-leaf-node-name="defaultLeafNodeName"
        :default-expanded="defaultExpanded"
        :model="model"
        :key="model.id"
      >
        <template v-slot:itemName="slotProps">
          <slot name="itemName" v-bind="slotProps" />
        </template>
      </item>
    </div>
  </div>
</template>

<script>
import { TreeNode } from "./Tree";
let compInOperation = null;

export default {
  name: "vue-tree-list",
  data: function() {
    return {
      editable: false,
      isDragEnterUp: false,
      isDragEnterBottom: false,
      isDragEnterNode: false,
      expanded: this.defaultExpanded,
    };
  },
  props: {
    model: {
      type: Object,
    },
    defaultLeafNodeName: {
      type: String,
      default: "New leaf node",
    },
    defaultTreeNodeName: {
      type: String,
      default: "New tree node",
    },
    defaultExpanded: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    rootNode() {
      var node = this.$parent;
      while (node._props.model.name !== "root") {
        node = node.$parent;
      }
      return node;
    },
    caretClass() {
      return this.expanded ? "vtl-icon-caret-down" : "vtl-icon-caret-right";
    },
    isFolder() {
      return this.model.children && this.model.children.length;
    },
    treeNodeClass() {
      const {
        model: { dragDisabled, disabled },
        isDragEnterNode,
      } = this;
      return {
        "vtl-node-main": true,
        "vtl-active": isDragEnterNode,
        "vtl-drag-disabled": dragDisabled,
        "vtl-disabled": disabled,
      };
    },
  },
  beforeCreate() {
    this.$options.components.item = require("./VueTreeList").default;
  },
  methods: {
    toggle() {
      if (this.isFolder) {
        this.expanded = !this.expanded;
      }
    },
    click() {
      this.rootNode.$emit("click", this.model);
    },
    openContextMenu($event) {
      this.rootNode.$emit("rightClick", { e: $event, data: this.model });
    },
    addChild() {
      const name = this.defaultLeafNodeName;
      this.expanded = true;
      var node = new TreeNode({ name });
      this.model.addChildren(node, true);
      this.rootNode.$emit("add-node", node);
    },
    dragStart(e) {
      if (!(this.model.dragDisabled || this.model.disabled)) {
        this.rootNode.$emit("dragChanged", true);
        compInOperation = this;
        // for firefox
        e.dataTransfer.setData("data", "data");
        e.dataTransfer.effectAllowed = "move";
        return true;
      }
      return false;
    },
    dragEnd() {
      this.rootNode.$emit("dragChanged", false);
      compInOperation = null;
    },
    dragOver(e) {
      e.preventDefault();
      return true;
    },
    dragEnter() {
      if (compInOperation.model.id === this.model.id || !compInOperation)
        return;
      this.isDragEnterNode = true;
    },
    dragLeave() {
      this.isDragEnterNode = false;
    },
    drop() {
      if (!compInOperation) return;
      const oldParent = compInOperation.model.parent;
      compInOperation.model.moveInto(this.model);
      this.isDragEnterNode = false;
      this.rootNode.$emit("drop", {
        target: this.model,
        node: compInOperation.model,
        src: oldParent,
      });
    },
    dragEnterUp() {
      if (!compInOperation) return;
      this.isDragEnterUp = true;
    },
    dragOverUp(e) {
      e.preventDefault();
      return true;
    },
    dragLeaveUp() {
      if (!compInOperation) return;
      this.isDragEnterUp = false;
    },
    dropBefore() {
      if (!compInOperation) return;
      const oldParent = compInOperation.model.parent;
      compInOperation.model.insertBefore(this.model);
      this.isDragEnterUp = false;
      this.rootNode.$emit("drop-before", {
        target: this.model,
        node: compInOperation.model,
        src: oldParent,
      });
    },
    dragEnterBottom() {
      if (!compInOperation) return;
      this.isDragEnterBottom = true;
    },
    dragOverBottom(e) {
      e.preventDefault();
      return true;
    },
    dragLeaveBottom() {
      if (!compInOperation) return;
      this.isDragEnterBottom = false;
    },
    dropAfter() {
      if (!compInOperation) return;
      const oldParent = compInOperation.model.parent;
      compInOperation.model.insertAfter(this.model);
      this.isDragEnterBottom = false;
      this.rootNode.$emit("drop-after", {
        target: this.model,
        node: compInOperation.model,
        src: oldParent,
      });
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?ui1hbx");
  src: url("fonts/icomoon.eot?ui1hbx#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?ui1hbx") format("truetype"),
    url("fonts/icomoon.woff?ui1hbx") format("woff"),
    url("fonts/icomoon.svg?ui1hbx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
.vtl-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.vtl-menu-icon {
    margin-right: 4px;
    &:hover {
      color: inherit;
    }
  }
  &:hover {
    color: blue;
  }
}
.vtl-icon-file:before {
  content: "\e906";
}
.vtl-icon-folder:before {
  content: "\e907";
}
.vtl-icon-caret-down:before {
  content: "\e901";
}
.vtl-icon-caret-right:before {
  content: "\e900";
}
.vtl-icon-edit:before {
  content: "\e902";
}
.vtl-icon-folder-plus-e:before {
  content: "\e903";
}
.vtl-icon-plus:before {
  content: "\e904";
}
.vtl-icon-trash:before {
  content: "\e905";
}
.vtl-border {
  height: 5px;
  &.vtl-up {
    margin-top: -5px;
    background-color: transparent;
  }
  &.vtl-bottom {
    background-color: transparent;
  }
  &.vtl-active {
    border-bottom: 3px dashed blue;
    /*background-color: blue;*/
  }
}
.vtl-node-main {
  display: flex;
  align-items: center;
  padding: 0 0 0 1rem;
  cursor: pointer;
  .vtl-input {
    border: none;
    max-width: 150px;
    border-bottom: 1px solid blue;
  }
  &:hover {
    background-color: #f0f0f0;
  }
  &.vtl-active {
    outline: 2px dashed pink;
  }
  .vtl-caret {
    margin-left: -1rem;
  }
  .vtl-operation {
    margin-left: 2rem;
    letter-spacing: 1px;
  }
}
.vtl-item {
  cursor: pointer;
}
.vtl-tree-margin {
  margin-left: 1em;
}
</style>
